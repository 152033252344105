import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getRepositionOrdersByOrganization,
  deleteRepositionOrder,
  markReceivedRepositionOrder,
  editRepositionOrderStatus,
  getArticlesFiltered,
  getSkusFiltered,
  createAcceptedPaymentMethod,
  ruleCreateRepositionOrdersForStock0,
  ruleCreateRepositionOrdersForStockMin,
} from "../../api/Services";
import { purchaseOrderStatus } from "../../helpers/Constants";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import { BusinessUniteContext } from "../../components/laoyut/Layout";

import {
  Table,
  message,
  Modal,
  Button,
  Select,
  Form,
  Input,
  Spin,
  DatePicker,
  InputNumber,
  Cascader,
  Checkbox,
  Dropdown,
  Menu,
  Typography,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  LoadingOutlined,
  SendOutlined,
  MoreOutlined,
  RedoOutlined,
} from "@ant-design/icons";

import styles from "./RepositionOrders.module.scss";

const RepositionOrders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingRules, setLoadingRules] = useState(false);
  const [loadingArticles, setLoadingArticles] = useState(true);
  const [actualPage, setActualPage] = useState(1);
  const [actualFilterStatus, setActualFilterStatus] = useState<any>(null);
  const [actualFilterBranchOfficeTo, setActualFilterBranchOfficeTo] =
    useState<any>(null);
  const [actualFilterDepositTo, setActualFilterDepositTo] = useState<any>(null);
  const [actualFilterBranchOfficeFrom, setActualFilterBranchOfficeFrom] =
    useState<any>(null);
  const [actualFilterDepositFrom, setActualFilterDepositFrom] =
    useState<any>(null);
  const [actualFilterStartCreatedDate, setActualFilterStartCreatedDate] =
    useState<any>(null);
  const [actualFilterEndCreatedDate, setActualFilterEndCreatedDate] =
    useState<any>(null);
  const [actualOrderBy, setActualOrderBy] = useState<any>(null);
  const [depositsToOptions, setDepositsToOptions] = useState<any>([]);
  const [depositsFromOptions, setDepositsFromOptions] = useState<any>([]);
  const [branchOfficeToOptions, setBranchOfficeToOptions] = useState<any>([]);
  const [branchOfficeFromOptions, setBranchOfficeFromOptions] = useState<any>(
    []
  );
  const [articleOptions, setArticleOptions] = useState<any[]>([]);
  const [skuOptions, setSkuOptions] = useState<any[]>([]);
  const [articleSelected, setArticleSelected] = useState([]);
  const [skuSelected, setSkuSelected] = useState(undefined);

  const [totalSize, setTotalSize] = useState(0);

  const [receivedForm] = Form.useForm();
  const [statusForm] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_REPOSITION_ORDERS)) {
      handleFetchRepositionOrders();
    }
  }, [businessUnitSelected]);

  const handleSearchArticles = async (value: string) => {
    setLoadingArticles(true);
    const response = await getArticlesFiltered(0, { articleName: value }, true);
    const options = response.articles.map((item: any) => ({
      value: item.id,
      text: item.name,
    }));
    setArticleOptions(options);
    setLoadingArticles(false);
  };

  const handleSearchSkus = async (value: string) => {
    if (value.length > 2) {
      const response = await getSkusFiltered(0, { skuName: value });
      setSkuOptions(response.skus);
    } else setSkuOptions([]);
  };

  const handleFetchRepositionOrders = useCallback(
    async (
      pagination = actualPage,
      status?: string | null,
      startCreatedDate?: Date | null,
      endCreatedDate?: Date | null,
      depositTo?: number | null,
      branchOfficeTo?: number | null,
      depositFrom?: number | null,
      branchOfficeFrom?: number | null,
      articlesIds?: number[],
      skuId?: string | null,
      orderBy?: string
    ) => {
      setLoading(true);
      if (businessUnitSelected) {
        const response = await getRepositionOrdersByOrganization({
          pagination: pagination - 1,
          status,
          startCreatedDate: startCreatedDate
            ? moment(startCreatedDate, "YYYY-MM-DD").format("YYYY-MM-DD")
            : null,
          endCreatedDate: endCreatedDate
            ? moment(endCreatedDate, "YYYY-MM-DD").format("YYYY-MM-DD")
            : null,
          depositTo,
          branchOfficeTo,
          depositFrom,
          branchOfficeFrom,
          orderBy: orderBy ?? actualOrderBy,
          businessUnitId: businessUnitSelected,
          articlesIds,
          skuId,
        });

        const allOrders = response.repositionOrders;

        setData(allOrders);

        if (
          depositsToOptions.length === 0 &&
          branchOfficeToOptions.length === 0
        ) {
          const depositsFrom: any[] = [];
          const branchOfficesFrom: any[] = [];
          const depositsTo: any[] = [];
          const branchOfficesTo: any[] = [];

          allOrders?.forEach((purchaseOrder: any) => {
            if (
              purchaseOrder.depositTo !== null &&
              depositsTo.find(
                (element) => element.value == purchaseOrder.depositTo.id
              ) === undefined
            )
              depositsTo.push({
                label: purchaseOrder.depositTo.name,
                value: purchaseOrder.depositTo.id,
              });
            if (
              purchaseOrder.branchOfficeTo != null &&
              branchOfficesTo.find(
                (element) => element.value === purchaseOrder.branchOfficeTo.id
              ) === undefined
            )
              branchOfficesTo.push({
                label: purchaseOrder.branchOfficeTo.name,
                value: purchaseOrder.branchOfficeTo.id,
              });

            if (
              purchaseOrder.branchOfficeFrom != null &&
              branchOfficesFrom.find(
                (element) => element.value === purchaseOrder.branchOfficeFrom.id
              ) === undefined
            ) {
              branchOfficesFrom.push({
                label: purchaseOrder.branchOfficeFrom.name,
                value: purchaseOrder.branchOfficeFrom.id,
              });
            }
            if (
              purchaseOrder.depositFrom != null &&
              depositsFrom.find(
                (element) => element.value === purchaseOrder.depositFrom.id
              ) === undefined
            )
              depositsFrom.push({
                label: purchaseOrder.depositFrom.name,
                value: purchaseOrder.depositFrom.id,
              });
          });

          setDepositsToOptions(depositsTo);
          setBranchOfficeToOptions(branchOfficesTo);
          setDepositsFromOptions(depositsFrom);
          setBranchOfficeFromOptions(branchOfficesFrom);
        }

        setTotalSize(response.totalElements);
      }
      setLoading(false);
    },
    [businessUnitSelected]
  );

  const showDeleteConfirm = (repositionOrderId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteRepositionOrder(repositionOrderId);
      },
    });
  };

  const handleDeleteRepositionOrder = async (repositionOrderId: number) => {
    setLoading(true);
    const deleteStatus = await deleteRepositionOrder(repositionOrderId);

    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));

    await handleFetchRepositionOrders();
    setLoading(false);
  };

  const handleChangeStatusFilter = (value: any) => {
    setActualFilterStatus(value);
    setActualPage(1);
    handleFetchRepositionOrders(
      1,
      value,
      actualFilterStartCreatedDate,
      actualFilterEndCreatedDate,
      actualFilterDepositTo,
      actualFilterBranchOfficeTo,
      actualFilterDepositFrom,
      actualFilterBranchOfficeFrom,
      articleSelected,
      skuSelected
    );
  };

  const handleChangeArticlesFilter = (value: any) => {
    setArticleSelected(value);
    setActualPage(1);
    handleFetchRepositionOrders(
      1,
      actualFilterStatus,
      actualFilterStartCreatedDate,
      actualFilterEndCreatedDate,
      actualFilterDepositTo,
      actualFilterBranchOfficeTo,
      actualFilterDepositFrom,
      actualFilterBranchOfficeFrom,
      value,
      skuSelected
    );
  };

  const handleChangeSkuFilter = (value: any) => {
    setSkuSelected(value);
    setActualPage(1);
    handleFetchRepositionOrders(
      1,
      actualFilterStatus,
      actualFilterStartCreatedDate,
      actualFilterEndCreatedDate,
      actualFilterDepositTo,
      actualFilterBranchOfficeTo,
      actualFilterDepositFrom,
      actualFilterBranchOfficeFrom,
      articleSelected,
      value
    );
  };

  const handleChangeDateFilter = (value: any) => {
    setActualFilterStartCreatedDate(value ? value[0] ?? null : null);
    setActualFilterEndCreatedDate(value ? value[1] ?? null : null);
    setActualPage(1);
    handleFetchRepositionOrders(
      1,
      actualFilterStatus,
      value ? value[0] ?? null : null,
      value ? value[1] ?? null : null,
      actualFilterDepositTo,
      actualFilterBranchOfficeTo,
      actualFilterDepositFrom,
      actualFilterBranchOfficeFrom,
      articleSelected,
      skuSelected
    );
  };

  const handleChangeDestinyFilter = (value: any) => {
    if (value.length === 0) {
      setActualPage(1);
      setActualFilterBranchOfficeTo(null);
      setActualFilterDepositTo(null);
      handleFetchRepositionOrders(
        1,
        actualFilterStatus,
        actualFilterStartCreatedDate,
        actualFilterEndCreatedDate,
        null,
        null,
        actualFilterDepositFrom,
        actualFilterBranchOfficeFrom,
        articleSelected,
        skuSelected
      );
      return;
    }
    if (value[0] === "Deposit") {
      setActualFilterDepositTo(value[1]);
      setActualFilterBranchOfficeTo(null);
      setActualPage(1);
      handleFetchRepositionOrders(
        1,
        actualFilterStatus,
        actualFilterStartCreatedDate,
        actualFilterEndCreatedDate,
        value[1],
        actualFilterBranchOfficeTo,
        actualFilterDepositFrom,
        actualFilterBranchOfficeFrom,
        articleSelected,
        skuSelected
      );
    } else {
      setActualFilterBranchOfficeTo(value[1]);
      setActualPage(1);
      setActualFilterDepositTo(null);
      handleFetchRepositionOrders(
        1,
        actualFilterStatus,
        actualFilterStartCreatedDate,
        actualFilterEndCreatedDate,
        null,
        value[1],
        actualFilterDepositFrom,
        actualFilterBranchOfficeFrom,
        articleSelected,
        skuSelected
      );
    }
  };

  const handleChangeOriginFilter = (value: any) => {
    if (value.length === 0) {
      setActualFilterBranchOfficeTo(null);
      setActualFilterDepositTo(null);
      setActualPage(1);
      handleFetchRepositionOrders(
        1,
        actualFilterStatus,
        actualFilterStartCreatedDate,
        actualFilterEndCreatedDate,
        actualFilterDepositTo,
        actualFilterBranchOfficeTo,
        null,
        null,
        articleSelected,
        skuSelected
      );
      return;
    }
    if (value[0] === "Deposit") {
      setActualFilterDepositFrom(value[1]);
      setActualFilterBranchOfficeFrom(null);
      setActualPage(1);
      handleFetchRepositionOrders(
        1,
        actualFilterStatus,
        actualFilterStartCreatedDate,
        actualFilterEndCreatedDate,
        actualFilterDepositTo,
        actualFilterBranchOfficeTo,
        value[1],
        null,
        articleSelected,
        skuSelected
      );
    } else {
      setActualFilterDepositFrom(null);
      setActualFilterBranchOfficeFrom(value[1]);
      setActualPage(1);
      handleFetchRepositionOrders(
        1,
        actualFilterStatus,
        actualFilterStartCreatedDate,
        actualFilterEndCreatedDate,
        actualFilterDepositTo,
        actualFilterBranchOfficeTo,
        null,
        value[1],
        articleSelected,
        skuSelected
      );
    }
  };

  const handleReceived = async () => {
    const receivedValues = receivedForm.getFieldsValue();
    const receivedValuesArray = Object.values(receivedValues);
    const receivedValuesBody: any = [];
    receivedValuesArray.forEach((value: any) => {
      value.date = value.date.format("YYYY-MM-DD");
      value.quantity = parseInt(value.quantity);
      receivedValuesBody.push(value);
    });
    const response = await markReceivedRepositionOrder(receivedValuesBody);
    if (response) {
      Modal.destroyAll();
      handleFetchRepositionOrders(
        actualPage,
        actualFilterStatus,
        actualFilterStartCreatedDate,
        actualFilterEndCreatedDate,
        actualFilterDepositTo,
        actualFilterBranchOfficeTo,
        actualFilterDepositFrom,
        actualFilterBranchOfficeFrom,
        articleSelected,
        skuSelected
      );
      message.success(t("Common-ProcessSuccessMessage"));
    } else message.error(t("Common-ErrorMessage"));
  };

  const handleChangeStatus = async () => {
    const { id, status } = statusForm.getFieldsValue();
    const response = await editRepositionOrderStatus(id, status);
    if (response) {
      Modal.destroyAll();
      message.success(t("Common-ProcessSuccessMessage"));
      handleFetchRepositionOrders(actualPage);
    } else message.error(t("Common-ErrorMessage"));
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setActualPage(pagination.current);
    if (sorter.order) {
      const orderBy = `${sorter.field};${
        sorter.order === "ascend" ? "ASC" : "DESC"
      }`;
      setActualOrderBy(orderBy);
      handleFetchRepositionOrders(
        pagination.current,
        actualFilterStatus,
        actualFilterStartCreatedDate,
        actualFilterEndCreatedDate,
        actualFilterDepositTo,
        actualFilterBranchOfficeTo,
        actualFilterDepositFrom,
        actualFilterBranchOfficeFrom,
        articleSelected,
        skuSelected,
        orderBy
      );
    } else {
      setActualOrderBy("");
      handleFetchRepositionOrders(
        pagination.current,
        actualFilterStatus,
        actualFilterStartCreatedDate,
        actualFilterEndCreatedDate,
        actualFilterDepositTo,
        actualFilterBranchOfficeTo,
        actualFilterDepositFrom,
        actualFilterBranchOfficeFrom,
        articleSelected,
        skuSelected,
        ""
      );
    }
  };

  const showReceivedModal = (order: any) => {
    Modal.info({
      title: t("Common-Reception"),
      closable: true,
      maskClosable: true,
      okButtonProps: { style: { display: "none" } },
      width: 500,
      icon: null,
      content: (
        <Form
          name="receivedOrder"
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={receivedForm}
          preserve={false}
        >
          {order.skus.map((sku: any) => {
            const maxToBeReceived =
              sku.quantity -
              sku.received.reduce(
                (sum: number, order: any) => sum + order.quantity,
                0
              );

            const minToBeReceived = order.skus.length > 1 ? 0 : 1;

            return (
              <>
                <Form.Item hidden initialValue={sku.id} name={[sku.id, "id"]} />

                <Typography.Title level={5}>{sku.skuName}</Typography.Title>
                <Form.Item
                  className={styles.editItem}
                  label={t("Common-Quantity")}
                  name={[sku.id, "quantity"]}
                  rules={[
                    {
                      max: maxToBeReceived,
                      min: minToBeReceived,
                      required: true,
                      type: "number",
                      message: t("Common-QuantityMax", {
                        max: maxToBeReceived,
                      }),
                    },
                  ]}
                >
                  <InputNumber placeholder={t("Common-Quantity")} />
                </Form.Item>
                <Form.Item
                  className={styles.editItem}
                  label={t("Common-WhoReceived")}
                  name={[sku.id, "whoReceived"]}
                  rules={[
                    {
                      required: true,
                      message: t("Common-WhoReceived"),
                    },
                  ]}
                >
                  <Input placeholder={t("Common-WhoReceived")} />
                </Form.Item>
                <Form.Item
                  className={styles.editItem}
                  label={t("Common-Observation")}
                  name={[sku.id, "observations"]}
                  rules={[
                    {
                      required: true,
                      message: t("Common-Observation"),
                    },
                  ]}
                >
                  <Input placeholder={t("Common-Observation")} />
                </Form.Item>

                <Form.Item
                  className={styles.newItem}
                  label={t("Common-Date")}
                  name={[sku.id, "date"]}
                  rules={[
                    {
                      required: true,
                      message: t("Common-Date"),
                    },
                  ]}
                >
                  <DatePicker placeholder={t("Common-Date")} />
                </Form.Item>
              </>
            );
          })}
          <Form.Item>
            <Button
              className={styles.saveButton}
              type="primary"
              htmlType="submit"
              disabled={loading}
              onClick={handleReceived}
            >
              {loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined className="ol-loading-spin-icon" />
                  }
                  delay={500}
                />
              ) : (
                t("Common-Save")
              )}
            </Button>
          </Form.Item>
        </Form>
      ),
    });
  };

  const handleRunRules = async (rule: number) => {
    let response;
    setLoadingRules(true);
    if (rule === 1) response = await ruleCreateRepositionOrdersForStock0();
    if (rule === 2) response = await ruleCreateRepositionOrdersForStockMin();
    if (response) {
      message.success(t("Common-RulesSuccessMessage"));
    } else message.error(t("Common-ErrorMessage"));

    setLoadingRules(false);
  };

  const showEditStatusModal = (order: any) => {
    Modal.info({
      title: t("Common-Status"),
      closable: true,
      maskClosable: true,
      okButtonProps: { style: { display: "none" } },
      width: 500,
      icon: null,
      content: (
        <Form
          name="receivedOrder"
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={statusForm}
          preserve={false}
        >
          <Form.Item hidden initialValue={order.id} name="id" />
          <Form.Item
            label={t("Common-Status")}
            name="status"
            initialValue={order.orderStatus.status}
            rules={[
              {
                required: true,
                message: t("Common-Status"),
              },
            ]}
          >
            <Select
              className={styles.filterSelect}
              allowClear
              defaultValue={order.orderStatus.status}
            >
              <Select.Option
                value={purchaseOrderStatus.SUGGESTED}
                key={purchaseOrderStatus.SUGGESTED}
              >
                {t("Common-Suggested")}
              </Select.Option>
              <Select.Option
                value={purchaseOrderStatus.GENERATED}
                key={purchaseOrderStatus.GENERATED}
              >
                {t("Common-Generated")}
              </Select.Option>
              <Select.Option
                value={purchaseOrderStatus.SENT}
                key={purchaseOrderStatus.SENT}
              >
                {t("Common-Sent")}
              </Select.Option>
              <Select.Option
                value={purchaseOrderStatus.IN_PROGRESS}
                key={purchaseOrderStatus.IN_PROGRESS}
              >
                {t("Common-InProgress")}
              </Select.Option>
              <Select.Option
                value={purchaseOrderStatus.COMPLETED}
                key={purchaseOrderStatus.COMPLETED}
              >
                {t("Common-Completed")}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              className={styles.saveButton}
              type="primary"
              htmlType="submit"
              disabled={loading}
              onClick={handleChangeStatus}
            >
              {loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined className="ol-loading-spin-icon" />
                  }
                  delay={500}
                />
              ) : (
                t("Common-Save")
              )}
            </Button>
          </Form.Item>
        </Form>
      ),
    });
  };

  const getRowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? styles.rowLight : styles.rowDark;
  };

  const columns = [
    {
      title: t("Common-Origin"),
      dataIndex: "origin",
      sorter: (a: any, b: any) => a.origin - b.origin,
      render: (text: string, record: any) => {
        const isDeposit = record.depositFrom !== null;
        const id = isDeposit
          ? record.depositFrom.id
          : record.branchOfficeFrom.id;
        const name = isDeposit
          ? record.depositFrom.name
          : record.branchOfficeFrom.name;

        return (
          <a
            className={styles.locationWrapper}
            href={`/${
              isDeposit ? "admin/deposits" : "admin/branchoffices"
            }/${id}/view`}
          >
            <span>{name}</span>
            <span>{id}</span>
          </a>
        );
      },
    },
    {
      title: t("Common-Destination"),
      dataIndex: "branchOfficeTo",
      sorter: (a: any, b: any) => a.origin - b.origin,
      render: (_: any, record: any) => {
        const isDeposit = record.depositTo !== null;
        const id = isDeposit ? record.depositTo.id : record.branchOfficeTo.id;
        const name = isDeposit
          ? record.depositTo.name
          : record.branchOfficeTo.name;

        return (
          <a
            className={styles.locationWrapper}
            href={`/${
              isDeposit ? "admin/deposits" : "admin/branchoffices"
            }/${id}/view`}
          >
            <span>{name}</span>
            <span>{id}</span>
          </a>
        );
      },
    },
    {
      title: t("Common-CreatedDate"),
      dataIndex: "createdDate",
      sorter: (a: any, b: any) => a.createdDate - b.createdDate,
      render: (date: any) =>
        date ? moment(date, "YYYY-MM-DD").format("DD-MM-YYYY") : "",
    },
    {
      title: t("Common-EstimatedDeliveryDate"),
      dataIndex: "estimatedDeliveryDate",
      sorter: (a: any, b: any) =>
        a.estimatedDeliveryDate - b.estimatedDeliveryDate,
      render: (date: any) =>
        date ? moment(date, "YYYY-MM-DD").format("DD-MM-YYYY") : "",
    },
    {
      title: t("Common-Status"),
      dataIndex: "orderStatus",
      key: "status",
      render: (status: any) => (
        <span className={styles.statusWrapper}>{status.description_es}</span>
      ),
    },
    {
      title: t("Common-Mode"),
      dataIndex: "orderOrigin",
      key: "orderOrigin",
      render: (orderOrigin: any) => orderOrigin?.description_es,
    },
    {
      title: t("Common-Analysis"),
      dataIndex: "analysis",
      key: "analysis",
    },
    {
      title: t("Common-Complete"),
      key: "complete",
      render: (record: any) => (
        <Checkbox
          className={styles.completedCheckbox}
          checked={record.orderStatus.status === purchaseOrderStatus.COMPLETED}
          onClick={() => {
            if (record.orderStatus.status !== purchaseOrderStatus.COMPLETED)
              showReceivedModal(record);
          }}
        />
      ),
    },
    {
      key: "actions",
      render: (record: any) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="view"
                onClick={() => {
                  navigate(`/repositionorders/${record.id}/view`);
                }}
                icon={<EyeOutlined />}
              >
                {t("Common-View")}
              </Menu.Item>

              {hasPermission(privilegesEnum.WRITE_REPOSITION_ORDERS) &&
                record.orderStatus.status != purchaseOrderStatus.COMPLETED && (
                  <Menu.Item
                    key="edit"
                    onClick={() => {
                      showEditStatusModal(record);
                    }}
                    icon={<SendOutlined />}
                  >
                    {t("Common-Edit")}
                  </Menu.Item>
                )}
              {hasPermission(privilegesEnum.DELETE_REPOSITION_ORDERS) &&
                record.orderStatus.status != purchaseOrderStatus.COMPLETED && (
                  <Menu.Item
                    key="delete"
                    onClick={() => {
                      showDeleteConfirm(record.id);
                    }}
                    icon={<DeleteOutlined />}
                  >
                    {t("Common-Delete")}
                  </Menu.Item>
                )}
            </Menu>
          }
        >
          <Button
            onClick={(e) => e.preventDefault()}
            className={styles.buttonMore}
          >
            <MoreOutlined className={styles.moreIcon} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-RepositionOrders")}</h1>
        <Dropdown
          className={styles.rulesDropdown}
          overlay={
            <>
              <Menu>
                <Menu.Item
                  disabled={loadingRules}
                  key="rule1"
                  onClick={() => {
                    handleRunRules(1);
                  }}
                >
                  <Tooltip title={t("Common-Rule1Description")}>{`${t(
                    "Common-Rule"
                  )} 1`}</Tooltip>
                </Menu.Item>
              </Menu>
              <Menu>
                <Menu.Item
                  disabled={loadingRules}
                  key="rule2"
                  onClick={() => {
                    handleRunRules(2);
                  }}
                >
                  <Tooltip title={t("Common-Rule2Description")}>{`${t(
                    "Common-Rule"
                  )} 2`}</Tooltip>
                </Menu.Item>
              </Menu>
            </>
          }
        >
          <Button loading={loadingRules} onClick={(e) => e.preventDefault()}>
            {t("Common-RunRules")}
          </Button>
        </Dropdown>
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_REPOSITION_ORDERS) && (
          <div className={styles.tableWrapper}>
            <div className={styles.headerTableWrapper}>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <DatePicker.RangePicker
                    className={styles.dateSelector}
                    format="YYYY-MM-DD"
                    allowEmpty={[true, true]}
                    onChange={handleChangeDateFilter}
                    placeholder={[t("Common-Start"), t("Common-End")]}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <Select
                    placeholder={t("Common-Status")}
                    className={styles.filterSelect}
                    onChange={handleChangeStatusFilter}
                    allowClear
                  >
                    <Select.Option
                      value={purchaseOrderStatus.SUGGESTED}
                      key={purchaseOrderStatus.SUGGESTED}
                    >
                      {t("Common-Suggested")}
                    </Select.Option>
                    <Select.Option
                      value={purchaseOrderStatus.GENERATED}
                      key={purchaseOrderStatus.GENERATED}
                    >
                      {t("Common-Generated")}
                    </Select.Option>
                    <Select.Option
                      value={purchaseOrderStatus.IN_PROGRESS}
                      key={purchaseOrderStatus.IN_PROGRESS}
                    >
                      {t("Common-InProgress")}
                    </Select.Option>
                    <Select.Option
                      value={purchaseOrderStatus.COMPLETED}
                      key={purchaseOrderStatus.COMPLETED}
                    >
                      {t("Common-Completed")}
                    </Select.Option>
                  </Select>
                </div>
                <div className={styles.filterWrapper}>
                  <Cascader
                    placeholder={t("Common-Origin")}
                    className={styles.filterSelect}
                    onChange={handleChangeOriginFilter}
                    options={[
                      {
                        label: t("Common-Deposit"),
                        value: "Deposit",
                        children: depositsFromOptions,
                        disabled: depositsFromOptions.length === 0,
                      },
                      {
                        label: t("Common-BranchOffice"),
                        value: "BranchOffice",
                        children: branchOfficeFromOptions,
                        disabled: branchOfficeFromOptions.length === 0,
                      },
                    ]}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <Cascader
                    placeholder={t("Common-Destiny")}
                    className={styles.filterSelect}
                    onChange={handleChangeDestinyFilter}
                    options={[
                      {
                        label: t("Common-Deposit"),
                        value: "Deposit",
                        children: depositsToOptions,
                        disabled: depositsToOptions.length === 0,
                      },
                      {
                        label: t("Common-BranchOffice"),
                        value: "BranchOffice",
                        children: branchOfficeToOptions,
                        disabled: branchOfficeToOptions.length === 0,
                      },
                    ]}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <Select
                    showSearch
                    value={articleSelected}
                    allowClear
                    onClear={() => {
                      handleChangeArticlesFilter([]);
                    }}
                    placeholder={t("Common-SearchArticleTextPlaceholder")}
                    onSelect={handleChangeArticlesFilter}
                    loading={loadingArticles}
                    className={styles.filterSelect}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearchArticles}
                    options={(articleOptions || []).map((d: any) => ({
                      value: d.value,
                      label: d.text,
                    }))}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <Select
                    showSearch
                    value={skuSelected}
                    allowClear
                    onClear={() => {
                      handleChangeSkuFilter([]);
                    }}
                    placeholder={t("Common-SearchSkuPlaceholder")}
                    onSelect={handleChangeSkuFilter}
                    loading={loadingArticles}
                    className={styles.filterSelect}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearchSkus}
                    options={(skuOptions || []).map((d: any) => ({
                      value: d.id,
                      label: d.name,
                    }))}
                  />
                </div>
              </div>
              <div className={styles.newWrapper}>
                <Button
                  icon={<RedoOutlined />}
                  onClick={() =>
                    handleFetchRepositionOrders(
                      actualPage,
                      actualFilterStatus,
                      actualFilterStartCreatedDate,
                      actualFilterEndCreatedDate,
                      actualFilterDepositTo,
                      actualFilterBranchOfficeTo,
                      actualFilterDepositFrom,
                      actualFilterBranchOfficeFrom,
                      articleSelected,
                      skuSelected
                    )
                  }
                />
                {hasPermission(privilegesEnum.WRITE_REPOSITION_ORDERS) && (
                  <Button
                    onClick={() => navigate("/repositionorders/create")}
                    className={styles.newButton}
                    type="primary"
                  >
                    {t("Common-NewOrder")}
                  </Button>
                )}
              </div>
            </div>
            <Table
              rowKey={(record) => record.id}
              size="small"
              className={styles.table}
              loading={loading}
              columns={columns}
              dataSource={data}
              onChange={handleTableChange}
              rowClassName={getRowClassName}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                current: actualPage,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RepositionOrders;
