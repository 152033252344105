import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import Kpi from "../../../components/repositionorder/kpi/Kpi";

import styles from "./RepositionOrderDashboard.module.scss";
import { Typography } from "antd";
import TableDashboard from "../../../components/repositionorder/tabledashboard/TableDashboard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const complianceEvolutionLabels = ["Q1", "Q2", "Q3"];
const leadTimeEvolutionLabels = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
];

const complianceData = {
  labels: complianceEvolutionLabels,
  datasets: [
    {
      label: "Dataset 1",
      data: [160, 125, 110],
      backgroundColor: ["#FF5454", "#FFB801", "#5DB34C"],
    },
  ],
};

const leadTimeData = {
  labels: leadTimeEvolutionLabels,
  datasets: [
    {
      label: "Text 1",
      data: [12, 10, 7, 15, 18, 22],
      borderColor: "#FF5454",
      backgroundColor: "#FF5454",
    },
    {
      label: "Text 2",
      data: [13, 9, 4, 0, 10, 24],
      borderColor: "#FFB801",
      backgroundColor: "#FFB801",
    },
  ],
};

const leadTimeOptions = {
  responsive: true,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  stacked: false,
  elements: {
    line: {
      tension: 0.6,
    },
  },
  scales: {
    y: {
      type: "linear" as const,
      display: true,
      position: "left" as const,
    },
  },
};

export const doughnutData = {
  labels: ["Causa 1", "Causa 2", "Causa 3"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 4],
      backgroundColor: ["#FF5454", "#FFB801", "#5DB34C", "#EFF3FA"],
      borderColor: ["#FF5454", "#FFB801", "#5DB34C", "#EFF3FA"],
      borderWidth: 1,
    },
  ],
};

const RepositionOrders = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.kpiWrapper}>
        <div className={styles.kpiLine}>
          <Kpi title={"Lead time average"} description={"4 days"} />
          <Kpi title={"Quiebres inminentes"} description={"44 skus"} />
          <Kpi title={"Quiebres actuales"} description={"100 skus"} />
        </div>
        <div className={styles.kpiLine}>
          <Kpi title={"Quiebres actuales"} description={"100 skus"} />
          <Kpi title={"Quiebres actuales"} description={"100 skus"} />
          <Kpi title={"Quiebres actuales"} description={"100 skus"} />
        </div>
      </div>
      <div className={styles.chartsWrapper}>
        <div
          className={`${styles.chartWrapper} ${styles.evolutionComplianceChart}`}
        >
          <Typography.Text className={styles.chartTitle}>
            Evolución cumplimiento
          </Typography.Text>
          <Bar data={complianceData} />
        </div>
        <div
          className={`${styles.chartWrapper} ${styles.evolutionLeadTimeChart}`}
        >
          <Typography.Text className={styles.chartTitle}>
            Evolución Lead time
          </Typography.Text>
          <Line data={leadTimeData} options={leadTimeOptions} />
        </div>
      </div>
      <div className={styles.tablesWrapper}>
        <TableDashboard
          title="Top performers/categoría"
          columns={[
            {
              title: "Categoría",
              dataIndex: "category",
            },
            {
              title: "Margin",
              dataIndex: "margin",
            },
            {
              title: "Rotación",
              dataIndex: "rotation",
            },
          ]}
          data={[
            { category: 996200, margin: "Usa Esat", rotation: 117200 },
            { category: 978034, margin: "Usa West", rotation: 113209 },
            { category: 689083, margin: "Usa North", rotation: 104289 },
            { category: 439183, margin: "Usa South", rotation: 99403 },
            { category: 349204, margin: "Lorem impsum", rotation: 94029 },
            { category: 248930, margin: "Lorem impsum", rotation: 83892 },
            { category: 120493, margin: "Lorem impsum", rotation: 80132 },
            { category: 120493, margin: "Lorem impsum", rotation: 80132 },
            { category: 120493, margin: "Lorem impsum", rotation: 80132 },
            { category: 120493, margin: "Lorem impsum", rotation: 80132 },
          ]}
        />
        <TableDashboard
          title="Low performers/categoría"
          columns={[
            {
              title: "Categoría",
              dataIndex: "category",
            },
            {
              title: "Margin",
              dataIndex: "margin",
            },
            {
              title: "Rotación",
              dataIndex: "rotation",
            },
          ]}
          data={[
            { category: 996200, margin: "Usa Esat", rotation: 117200 },
            { category: 978034, margin: "Usa West", rotation: 113209 },
            { category: 689083, margin: "Usa North", rotation: 104289 },
            { category: 439183, margin: "Usa South", rotation: 99403 },
            { category: 349204, margin: "Lorem impsum", rotation: 94029 },
            { category: 248930, margin: "Lorem impsum", rotation: 83892 },
            { category: 120493, margin: "Lorem impsum", rotation: 80132 },
            { category: 120493, margin: "Lorem impsum", rotation: 80132 },
            { category: 120493, margin: "Lorem impsum", rotation: 80132 },
            { category: 120493, margin: "Lorem impsum", rotation: 80132 },
          ]}
        />
        <TableDashboard
          title="Tendencia a futuro"
          columns={[
            {
              title: "Categoría",
              dataIndex: "category",
            },
            {
              title: "Margin",
              dataIndex: "margin",
            },
            {
              title: "Rotación",
              dataIndex: "rotation",
            },
          ]}
          data={[
            { category: 996200, margin: "Usa East", rotation: 117200 },
            { category: 992300, margin: "Usa West", rotation: 4359390 },
          ]}
        />
      </div>
      <div
        className={`${styles.chartWrapper}  ${styles.evolutionPerformanceChart}`}
      >
        <Typography.Text className={styles.chartTitle}>
          Evolución performance por categoría
        </Typography.Text>
        <Line
          data={leadTimeData}
          options={leadTimeOptions}
          width={500}
          height={200}
        />
      </div>
      <div className={styles.tablesWrapper}>
        <TableDashboard
          title="Quiebres actuales top performers"
          columns={[
            {
              title: "Artículo",
              dataIndex: "category",
            },
            {
              title: "Sucursal",
              dataIndex: "margin",
            },
            {
              title: "Fecha quiebre",
              dataIndex: "rotation",
            },
          ]}
          data={[
            { category: 996200, margin: "Usa Esat", rotation: 117200 },
            { category: 978034, margin: "Usa West", rotation: 113209 },
            { category: 689083, margin: "Usa North", rotation: 104289 },
            { category: 439183, margin: "Usa South", rotation: 99403 },
            { category: 349204, margin: "Lorem impsum", rotation: 94029 },
            { category: 248930, margin: "Lorem impsum", rotation: 83892 },
            { category: 120493, margin: "Lorem impsum", rotation: 72539 },
            { category: 110443, margin: "Lorem impsum", rotation: 70432 },
            { category: 120494, margin: "Lorem impsum", rotation: 58693 },
            { category: 124596, margin: "Lorem impsum", rotation: 49284 },
          ]}
        />
        <div className={`${styles.chartWrapper}`}>
          <Typography.Text className={styles.chartTitle}>
            Causas quiebres
          </Typography.Text>
          <Doughnut data={doughnutData} />
        </div>
        <TableDashboard
          title="Quiebres futuro top performers"
          columns={[
            {
              title: "Artículo",
              dataIndex: "category",
            },
            {
              title: "Sucursal",
              dataIndex: "margin",
            },
            {
              title: "Fecha Quiebre",
              dataIndex: "rotation",
            },
          ]}
          data={[
            { category: 996200, margin: "Usa East", rotation: 117200 },
            { category: 992300, margin: "Usa West", rotation: 4359390 },
          ]}
        />
      </div>
      <div className={styles.tablesWrapper}>
        <div className={`${styles.chartWrapper}`}>
          <Typography.Text className={styles.chartTitle}>
            Cumplimiento por origen
          </Typography.Text>
          <Doughnut data={doughnutData} />
        </div>
        <TableDashboard
          title="Demoras"
          columns={[
            {
              title: "Categoría",
              dataIndex: "category",
            },
            {
              title: "Margen",
              dataIndex: "margin",
            },
            {
              title: "Rotación",
              dataIndex: "rotation",
            },
          ]}
          data={[{ category: 996200, margin: "Usa Esat", rotation: 117200 }]}
        />
        <TableDashboard
          title="Quiebres futuro top performers"
          columns={[
            {
              title: "Categoría",
              dataIndex: "category",
            },
            {
              title: "Margen",
              dataIndex: "margin",
            },
            {
              title: "Rotación",
              dataIndex: "rotation",
            },
          ]}
          data={[
            { category: 996200, margin: "Usa East", rotation: 117200 },
            { category: 992300, margin: "Usa West", rotation: 4359390 },
          ]}
        />
      </div>
      <TableDashboard
        title="Tendencia deficit y sobrestock"
        columns={[
          {
            title: "Categoría",
            dataIndex: "category",
          },
          {
            title: "Margen",
            dataIndex: "margin",
          },
          {
            title: "Rotación",
            dataIndex: "rotation",
          },
          {
            title: "Test",
            dataIndex: "test1",
          },
          {
            title: "Test",
            dataIndex: "test2",
          },
        ]}
        data={[
          {
            category: 996200,
            margin: "Usa East",
            rotation: 117200,
            test1: 99,
            test2: 23,
          },
          {
            category: 992300,
            margin: "Usa West",
            rotation: 4359390,
            test1: 300,
            test2: 21,
          },
          {
            category: 9211,
            margin: "Usa West",
            rotation: 3029,
            test1: 10,
            test2: 23,
          },
          {
            category: 9211,
            margin: "Usa North",
            rotation: 1332,
            test1: 549,
            test2: 23,
          },
        ]}
      />
    </div>
  );
};

export default RepositionOrders;
