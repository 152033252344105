import { useTranslation } from "react-i18next";
import { groupById } from "../../helpers/Functions";

import { DatePicker, Table } from "antd";

import styles from "./OrderBySupplierPreviewDetail.module.scss";

const OrderBySupplierPreviewDetail = (props: any) => {
  const { t } = useTranslation();
  const { orders } = props;

  const ordersBySupplier: any = groupById(orders, "supplier");

  const articlesColumns = [
    {
      title: t("Common-Article"),
      dataIndex: "articleName",
      key: "articleName",
    },
    {
      title: t("Common-Name"),
      dataIndex: "skuName",
      key: "skuName",
    },
    {
      title: t("Common-Quantity"),
      dataIndex: "quantity",
      key: "quantity",
    },
  ];

  // @ts-ignore
  return (
    <div>
      {Object.entries(ordersBySupplier).map((orders: any) => {
        return (
          <div className={styles.wrapper}>
            {
              <h1 className={styles.subTitle}>{`${t("Common-Supplier")} ${
                orders[1][0].supplier.name
              }
              `}</h1>
            }
            {orders[1].map((order: any) => {
              return (
                <>
                  <p className={styles.text}>{`${t("Common-DeliverTo")} ${
                    order.branchOffice
                      ? order.branchOffice.name
                      : order.deposit.name
                  }`}</p>
                  <Table
                    size="small"
                    className={styles.tableArticles}
                    columns={articlesColumns}
                    dataSource={order.skus}
                    pagination={false}
                  />
                </>
              );
            })}
          </div>
        );
      })}
      {/*{for (const order in ordersBySupplier) {*/}
      {/*<h1 className={styles.subTitle}>{`${t("Common-Supplier")} ${*/}
      {/*    // order.supplier.name*/}
      {/*}`}</h1>*/}
      {/*<p className={styles.text}>{`${t("Common-DeliverTo")} ${*/}
      {/*  order.branchOffice ? order.branchOffice.name : order.deposit.name*/}
      {/*}`}</p>*/}
      {/*<div className={styles.dateWrapper}>*/}
      {/*  <p className={styles.text}>Fecha de entrega estimada</p>*/}
      {/*  /!*<DatePicker*!/*/}
      {/*  /!*    className={styles.dateSelector}*!/*/}
      {/*  /!*    onChange={handleChangeDate}*!/*/}
      {/*  /!*/
      /*/}
      {/*</div>*/}
    </div>
  );
};

export default OrderBySupplierPreviewDetail;
