import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "antd";
import Users from "../users/Users";
import Roles from "../roles/Roles";

import styles from "./UserRoles.module.scss";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import { Route } from "react-router-dom";

const UserRoles = () => {
  const { t } = useTranslation();

  const [selectedPage, setSelectedPage] = useState<string>("users");

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        className={styles.tabWrapper}
        type="card"
        // centered
        tabBarStyle={{ marginLeft: 10, marginTop: 20 }}
      >
        <Tabs.TabPane tab={t("Common-Users")} key="1">
          <Users />
        </Tabs.TabPane>
        {hasPermission(privilegesEnum.READ_ROLES) && (
          <Tabs.TabPane tab={t("Common-Roles")} key="2">
            <Roles />
          </Tabs.TabPane>
        )}
      </Tabs>
    </>
  );
};

export default UserRoles;
