import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getAllEndPricingTypes,
  getAllPricingTypes,
  createPricing,
  getCategoriesFiltered,
  getBranchOfficesFiltered,
} from "../../api/Services";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import {
  Form,
  message,
  Select,
  Button,
  Input,
  Switch,
  DatePicker,
  InputNumber,
  Table,
} from "antd";

import styles from "./NewPricingMockup.module.scss";
import AddArticleModal from "../../components/addarticlemodal/AddArticleModal";
import { BusinessUniteContext } from "../../components/laoyut/Layout";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const testData = {
  labels: [1, 2, 3],
  datasets: [
    {
      label: "test 1",
      data: [160, 125, 110],
      backgroundColor: ["#FF5454", "#FFB801", "#5DB34C"],
    },
  ],
};

const NewPricingMockup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const [loading, setLoading] = useState(false);
  const [branchOffices, setBranchOffices] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [pricingTypes, setPricingTypes] = useState<any[]>([]);
  const [endPricingTypes, setEndPricingTypes] = useState<any[]>([]);
  const [articlesSelected, setArticlesSelected] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [totalSize, setTotalSize] = useState(0);

  const [newPricingForm] = Form.useForm();
  const [conditionsForm] = Form.useForm();
  const [behaviourForm] = Form.useForm();

  const handleSearchBranchOffices = async (value: string) => {
    if (businessUnitSelected) {
      const response = await getBranchOfficesFiltered(0, {
        name: value,
        businessUnitId: businessUnitSelected,
      });
      setBranchOffices(response.branchOffices);
    } else setBranchOffices([]);
  };
  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
        ? item.parents.filter(Boolean).join(" - ")
        : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
        ? `${parentsString} - ${categoryName}`
        : categoryName;

      options.push(
        <Select.Option value={item.id} key={item.id}>
          {categoryTree}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsDescription = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.description}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsEndTypes = (items: any) => {
    const options: any[] = [];
    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.value} key={item.value}>
          {item.description_es}
        </Select.Option>
      );
    });

    return options;
  };

  const handleCreate = async () => {
    await newPricingForm.validateFields();
    await conditionsForm.validateFields();
    await behaviourForm.validateFields();

    setLoading(true);

    const data = newPricingForm.getFieldsValue();
    const conditions = conditionsForm.getFieldsValue();
    const behaviour = behaviourForm.getFieldsValue();

    const { name, pricingTypeId } = data;

    const { branchOfficeIds, categoryIds, value, percent, allConditions } =
      conditions;
    const articlesIds = articlesSelected.map((article) => article.id);

    const { date, endPrice, newPrice } = behaviour;

    const startDate = date ? date[0]?.format("YYYY-MM-DDTHH:mm:ss") : null;
    const endDate = date ? date[1]?.format("YYYY-MM-DDTHH:mm:ss") : null;

    let pricingInfo: any = {
      name,
      pricingTypeId,
      startDate,
      endDate,
      value,
      percent: percent ?? false,
      allConditions,
      endPrice,
      newPrice,
      branchOfficeIds: branchOfficeIds ?? [],
      articlesIds: articlesIds ?? [],
      categoryIds: categoryIds ?? [],
    };

    const response = await createPricing(pricingInfo);

    if (response) {
      message.success(t("Common-CreateSuccessMessage"));
      newPricingForm.resetFields();
      navigate("/pricings");
    } else message.error(t("Common-ErrorMessage"));

    setLoading(false);
  };

  const handleAddArticle = (articlesToAdd: any) => {
    const newArticles: any[] = [];
    articlesToAdd.forEach((articleToAdd: any) => {
      if (
        articlesSelected.find(
          (article: any) => article.id === articleToAdd.id
        ) === undefined
      )
        newArticles.push(articleToAdd);
    });
    setArticlesSelected([...articlesSelected, ...newArticles]);
  };

  const handleRemoveArticle = (articleRemove: any) => {
    const articlesWithoutRemoved = articlesSelected.filter(
      (article) => article.id !== articleRemove.id
    );

    setArticlesSelected(articlesWithoutRemoved);
  };

  const columns = [
    {
      title: "Id Artículo",
    },
    {
      title: "Nombre Artiículo",
    },
    {
      title: "Precio",
    },
    {
      title: "Costo Artículo",
    },
    {
      title: "Margen",
    },
    {
      title: "Fecha Inicio",
    },
    {
      title: "Fecha Fin",
    },
    {
      title: "Razón",
    },
    {
      title: "Usuario",
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewPricing")}</h1>
        <Button
          onClick={handleCreate}
          className={styles.saveButton}
          type="primary"
          loading={loading}
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.headerTableWrapper}>
            <h1 className={styles.subTitle}>{"Histórico de precios"}</h1>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}>
                <Select
                  className={styles.filterSelect}
                  showSearch
                  placeholder={t("Common-SearchByCategory")}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  className={styles.filterSelect}
                  showSearch
                  placeholder={t("Common-SearchByBrand")}
                />
              </div>
            </div>
          </div>

          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              // onChange: handlePagination,
            }}
          />
        </div>
        <div className={styles.formWrapper}>
          <h1 className={styles.subTitle}>{"Impacto en ventas"}</h1>
          <Bar data={testData} />
        </div>
        <div className={styles.formWrapper}>
          <h1 className={styles.subTitle}>{"Cambio de precio"}</h1>
          <Form
            name="conditionForm"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={conditionsForm}
          >
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Description")}
                name="percent"
                className={styles.doubleItemLine}
              >
                <Input />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t("Common-Value"),
                  },
                ]}
                label={t("Common-Strategy")}
                name="strategy"
                className={styles.doubleItemLine}
                initialValue={0}
              >
                <Select showSearch allowClear defaultValue={0}>
                  <Select.Option value={0}>{"Costo+beneficio"}</Select.Option>
                  <Select.Option value={1}>
                    {"Precio competitivo"}
                  </Select.Option>
                  <Select.Option value={2}>{"Promocional "}</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={"% Margen"}
                name="marginPercentage"
                className={styles.doubleItemLine}
              >
                <InputNumber className={styles.inputNumber} />
              </Form.Item>
              <Form.Item
                label={"Precio"}
                name="price"
                className={styles.doubleItemLine}
              >
                <InputNumber className={styles.inputNumber} />
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={"Margen"}
                name="margin"
                className={styles.doubleItemLine}
              >
                <InputNumber className={styles.inputNumber} />
              </Form.Item>
              <Form.Item
                label={"Precio competencia"}
                name="price"
                className={styles.doubleItemLine}
              >
                <InputNumber className={styles.inputNumber} />
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={"Fecha inicio"}
                name="startDate"
                className={styles.doubleItemLine}
              >
                <DatePicker
                  className={styles.dateSelector}
                  placeholder={"Fecha inicio"}
                  // onChange={handleChangeDate}
                />
              </Form.Item>
              <Form.Item
                label={"Fecha fin"}
                name="endDate"
                className={styles.doubleItemLine}
              >
                <DatePicker
                  className={styles.dateSelector}
                  placeholder={"Fecha fin"}
                  // onChange={handleChangeDate}
                />
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-BranchOffices")}
                name="branchOfficesIds"
                className={styles.doubleItemLine}
              >
                <Select
                  mode="multiple"
                  allowClear
                  className={styles.branchOfficeSelector}
                  onSearch={handleSearchBranchOffices}
                  placeholder={t("Common-SearchByName")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptions(branchOffices)}
                </Select>
              </Form.Item>
            </div>
            <AddArticleModal
              selectedArticles={articlesSelected}
              onAddArticle={handleAddArticle}
              onRemoveArticle={handleRemoveArticle}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};
export default NewPricingMockup;
