import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  deleteBrand,
  getBrands,
  createBrand,
  editBrand,
  processExcelTemplate,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import { handleDownloadTemplate } from "../../helpers/Helper";
import ImportModal from "../../components/importmodal/ImportModal";

import { Table, Form, Input, Button, message, Space, Modal, Spin } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import styles from "./Brands.module.scss";

const Brands = () => {
  const { t } = useTranslation();

  const [data, setData] = useState<any[]>([]);
  const [importErrors, setImportErrors] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [downloadingTemplate, setDownloadingTemplate] = useState(false);
  const [nameFilterValue, setNameFilterValue] = useState("");
  const [externalIdFilterValue, setExternalIdFilterValue] = useState("");

  const [form] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_BRANDS)) {
      handleFetchBrands();
    }
  }, [nameFilterValue, externalIdFilterValue]);

  const handleFetchBrands = async (pagination = actualPage) => {
    const response = await getBrands(pagination, {
      name: nameFilterValue,
      externalId: externalIdFilterValue,
    });

    setData(response.brands);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const showImportModal = () => {
    setIsImportModalVisible(true);
    setImportErrors("");
  };

  const handleCancelImportModal = () => {
    Modal.destroyAll();
    setIsImportModalVisible(false);
  };

  const handleImportFile = async (file: any) => {
    await new Promise((resolve) => setTimeout(resolve, 5000));
    if (file) {
      const response = await processExcelTemplate("brands", file);
      if (response.success) {
        setImportErrors(null);
        setIsImportModalVisible(false);
        message.success(
          t("Common-ImportSuccess", { quantity: response.data.added })
        );
        handleFetchBrands();
      } else {
        if (response.data?.errors) setImportErrors(response.data.errors);
        else message.error(t("Error-CommonError"));
      }
    }
  };

  const showDeleteConfirm = (brandId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDelete(brandId);
      },
    });
  };

  const handleDelete = async (brandId: number) => {
    setLoading(true);
    const deleteStatus = await deleteBrand(brandId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchBrands();
    setLoading(false);
  };

  const handleCreateBrand = async (data: any) => {
    setLoading(true);
    let response = await createBrand(
      data.name,
      data.externalId,
      data.description ?? ""
    );
    if (response) {
      message.success(t("Common-CreateSuccessMessage"));
      form.resetFields();
      await handleFetchBrands(actualPage);
    } else message.error(t("Common-CreateErrorMessage"));

    setLoading(false);
  };

  const handlePagination = (page: number) => {
    handleFetchBrands(page);
    setActualPage(page);
  };

  const handleEditBrand = async (data: any) => {
    setLoading(true);

    const { brandId, name, description, externalId } = data;

    let response = await editBrand(brandId, name, externalId, description);

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
    } else message.error(t("Common-EditErrorMessage"));

    Modal.destroyAll();
    await handleFetchBrands();
    setLoading(false);
  };

  const showEditModal = (brand: any) => {
    Modal.info({
      title: t("Common-Brand"),
      closable: true,
      maskClosable: true,
      okButtonProps: { style: { display: "none" } },
      width: 500,
      icon: null,
      content: (
        <Form
          name="basic"
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={handleEditBrand}
          preserve={false}
        >
          <Form.Item hidden initialValue={brand.id} name="brandId" />

          <Form.Item
            className={styles.editItem}
            label={t("Common-Name")}
            name="name"
            initialValue={brand.name}
            rules={[
              {
                required: true,
                message: t("Common-NameNotEntered"),
              },
            ]}
          >
            <Input placeholder={t("Common-Name")} />
          </Form.Item>

          <Form.Item
            className={styles.editItem}
            label={t("Common-ExternalId")}
            name="externalId"
            initialValue={brand.externalId}
          >
            <Input placeholder={t("Common-ExternalId")} />
          </Form.Item>

          <Form.Item
            className={styles.editItem}
            label={t("Common-Description")}
            name="description"
            initialValue={brand.description}
          >
            <Input placeholder={t("Common-Description")} />
          </Form.Item>

          <Form.Item>
            <Button
              className={styles.editButton}
              type="primary"
              htmlType="submit"
              disabled={loading}
            >
              {loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined className="ol-loading-spin-icon" />
                  }
                  delay={500}
                />
              ) : (
                t("Common-Save")
              )}
            </Button>
          </Form.Item>
        </Form>
      ),
    });
  };

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Common-ExternalId"),
      dataIndex: "externalId",
    },
    {
      title: t("Common-Description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {hasPermission(privilegesEnum.WRITE_BRANDS) && (
            <EditOutlined
              onClick={() => {
                showEditModal(record);
              }}
            />
          )}
          {hasPermission(privilegesEnum.DELETE_BRANDS) && (
            <DeleteOutlined
              onClick={() => {
                showDeleteConfirm(record.key);
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Brands")}</h1>
        {hasPermission(privilegesEnum.WRITE_BRANDS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={showImportModal}
              className={styles.importButton}
              type="primary"
            >
              {t("Common-Import")}
            </Button>
            <Button
              onClick={() =>
                handleDownloadTemplate("brands", setDownloadingTemplate)
              }
              className={styles.importButton}
              type="primary"
              loading={downloadingTemplate}
            >
              {t("Common-DownloadTemplate")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.headerTableWrapper}>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}>
                <Input
                  placeholder={t("Common-SearchByName")}
                  className={styles.filterInput}
                  onChange={(e) => setNameFilterValue(e.target.value)}
                  value={nameFilterValue}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Input
                  placeholder={t("Common-SearchByExternalId")}
                  className={styles.filterInput}
                  onChange={(e) => {
                    setExternalIdFilterValue(e.target.value);
                    setActualPage(1);
                  }}
                  value={externalIdFilterValue}
                />
              </div>
            </div>
          </div>

          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              onChange: handlePagination,
              current: actualPage
            }}
          />
        </div>
        {hasPermission(privilegesEnum.WRITE_BRANDS) && (
          <div className={styles.newWrapper}>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={handleCreateBrand}
              form={form}
              autoComplete="off"
              className={styles.formNew}
            >
              <h1 className={styles.formTitle}>{t("Common-NewBrand")}</h1>
              <Form.Item
                label={t("Common-Name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("Common-NameNotEntered"),
                  },
                ]}
              >
                <Input placeholder={t("Common-Name")} />
              </Form.Item>
              <Form.Item label={t("Common-ExternalId")} name="externalId">
                <Input placeholder={t("Common-ExternalId")} />
              </Form.Item>
              <Form.Item label={t("Common-Description")} name="description">
                <Input placeholder={t("Common-Description")} />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  {t("Common-Add")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
      <Modal
        title={t("Common-ImportEntity", {
          entity: t("Common-Brands"),
        })}
        visible={isImportModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelImportModal}
      >
        <ImportModal onOk={handleImportFile} errors={importErrors} />
      </Modal>
    </div>
  );
};

export default Brands;
