import { useTranslation } from "react-i18next";
import React, { FunctionComponent } from "react";

import styles from "./Kpi.module.scss";
import { Typography } from "antd";

type KpiProps = {
  title: string;
  description?: string;
};

const Kpi: FunctionComponent<KpiProps> = ({ title, description }) => {

  return (
    <div className={styles.wrapper}>
      <Typography.Text className={styles.title}>{title}</Typography.Text>
      <Typography.Text className={styles.description}>
        {description}
      </Typography.Text>
    </div>
  );
};

export default Kpi;
