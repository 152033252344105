import { t } from "i18next";
import { BrightMarketApi } from "./BrightMarketApi";
import { StatusResponse } from "./BrightMarketApiType";
import {
  Article,
  Supplier,
  BusinessUnit,
  BranchOffice,
  Category,
  ChannelType,
  Currency,
  Language,
  Organization,
  Privilege,
  Rol,
  RoundingType,
} from "../models/Models";
import { getOrganizationBySubdomain, getOrganizationId } from "./Helper";

export const login = async (
  username: string,
  password: string,
  organizationName: string
) => {
  let response;
  try {
    response = await BrightMarketApi.login(
      username,
      password,
      organizationName
    );

    if (response.data.status === StatusResponse.Ok) {
      const token = response.data.data.token;
      localStorage.setItem("token", token);

      const organization = await BrightMarketApi.fetchOrganizationByName(
        organizationName,
        token
      );
      localStorage.setItem("organizationId", organization.data.id);
      localStorage.setItem("superAdmin", response.data.data.superAdmin);
      localStorage.setItem(
        "privileges",
        JSON.stringify(response.data.data.privileges)
      );

      return {
        status: true,
        token: token,
        message: "",
      };
    } else {
      return {
        status: false,
        message: t("Error-IncorrectCredentials"),
      };
    }
  } catch (e) {
    console.log("We have the error", e);
  }
  return {
    status: false,
    message: t("Error-CommonError"),
  };
};

export const loginSuperAdmin = async (organizationName: string) => {
  let response;
  try {
    response = await BrightMarketApi.loginSuperAdmin(organizationName);

    if (response.data.status === StatusResponse.Ok) {
      const token = response.data.data.token;
      localStorage.setItem("token", token);

      const organization = await BrightMarketApi.fetchOrganizationByName(
        organizationName,
        token
      );
      localStorage.setItem("organizationId", organization.data.id);
      localStorage.setItem("superAdmin", response.data.data.superAdmin);
      localStorage.setItem(
        "privileges",
        JSON.stringify(response.data.data.privileges)
      );

      return {
        status: true,
        token: token,
        message: "",
      };
    } else {
      return {
        status: false,
        message: t("Error-IncorrectCredentials"),
      };
    }
  } catch (e) {
    console.log("We have the error", e);
  }
  return {
    status: false,
    message: t("Error-CommonError"),
  };
};

export const getOrganizations = async () => {
  const ret: Organization[] = [];

  try {
    const { organizations } = await BrightMarketApi.getOrganizations();
    organizations?.forEach(function (org: any) {
      let organization: Organization = {
        id: org.id,
        name: org.name,
      };
      ret.push(organization);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const registerOrganization = async (
  organizationName: string,
  username: string,
  password: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.registerOrganization(
      organizationName,
      username,
      password
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const validateEmail = async (token: string) => {
  let response: any = {};
  try {
    response = await BrightMarketApi.validateEmail(token);
  } catch (e) {
    console.log("We have the error", e);
  }
  if (response.data.status === StatusResponse.Ok) return response.data.data;
};

export const confirmUserWithToken = async (token: string) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.confirmUser(token);
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const confirmUserAndSetPassword = async (
  token: string,
  password: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.confirmUserAndSetPassword(
      token,
      password
    );
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const resendValidateEmail = async (
  email: string,
  organizationName: string
) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.resendValidateEmail(
      email,
      organizationName
    );
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const sendForgotPassword = async (email: string) => {
  let ret = false;
  const organizationName = await getOrganizationBySubdomain();
  try {
    let response = await BrightMarketApi.sendForgotPassword(
      email,
      organizationName
    );
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const sendResetPassword = async (password: string, token: string) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.sendResetPassword(password, token);
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const existsOrganizationByName = async (organizationName: string) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.existsOrganizationByName(
      organizationName
    );
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getUsers = async (filters: {
  email?: string;
  name?: string;
  businessUnitId?: string;
  rolId?: number;
  branchOfficeId?: number;
}) => {
  const ret: any[] = [];

  try {
    const organizationId = await getOrganizationId();

    const { users } = await BrightMarketApi.getUsers(organizationId, filters);
    users?.forEach(function (item: any) {
      let user: any = {
        id: item.id,
        name: item.name,
        lastName: item.lastname,
        email: item.email,
        roles: item.roles,
        enabled: item.enabled,
        businessUnits: item.businessUnits,
        branchOffices: item.branchOffices,
      };
      ret.push(user);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getUser = async () => {
  let ret: any = { success: false, user: null };

  try {
    const response = await BrightMarketApi.getUser();
    ret.success = true;
    ret.user = response;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createUser = async (
  username: string,
  roles: any,
  businessUnitsIds: any,
  branchOfficesIds: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createUser(
      username,
      roles,
      businessUnitsIds ?? [],
      branchOfficesIds ?? []
    );
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteUser = async (userId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteUser(userId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const fetchOrganizationByName = async () => {
  let ret;
  try {
    const organizationName = await getOrganizationBySubdomain();

    const response = await BrightMarketApi.fetchOrganizationByName(
      organizationName
    );
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};
export const fetchOrganizationById = async () => {
  let ret;
  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.fetchOrganizationById(
      organizationId
    );
    ret = response.data.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const updateOrganization = async (
  organizationId: string,
  realName: string,
  businessName: string,
  rut: string,
  address: string
) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.updateOrganization(
      organizationId,
      realName,
      businessName,
      rut,
      address
    );
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getRoles = async (
  pagination: number,
  filters: { name?: string; privilegeId?: number }
) => {
  const roles: Rol[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getRoles(
      organizationId,
      pagination,
      filters
    );

    let items = response.roles;
    totalElements = response.totalElements;

    items.roles.forEach(function (item: any) {
      let rol: Rol = {
        id: item.id,
        name: item.name,
        description: item.description,
        privileges: item.privileges,
      };
      roles.push(rol);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return { roles, totalElements };
};

export const createRol = async (
  name: string,
  description: string,
  privileges: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createRol(
      name,
      description,
      privileges
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getPrivileges = async () => {
  const privileges: any[] = [];

  try {
    const response = await BrightMarketApi.getPrivileges();
    let items = response.privileges;
    items?.forEach(function (item: any) {
      let privilege: Privilege = {
        id: item.id,
        name: item.name,
        descriptionEn: item.descriptionEn,
        descriptionEs: item.descriptionEs,
      };
      privileges.push(privilege);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return privileges;
};

export const getAllRoles = async () => {
  const roles: any[] = [];
  const organizationId = await getOrganizationId();

  try {
    const response = await BrightMarketApi.getAllRoles(organizationId);
    let items = response.roles;
    items?.forEach(function (item: any) {
      let rol: Rol = {
        id: item.id,
        name: item.name,
        description: item.description,
      };
      roles.push(rol);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return roles;
};

export const deleteRol = async (rolId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteRol(rolId);
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editRol = async (
  rolId: number,
  name: string,
  description: string,
  privileges: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editRol(
      rolId,
      name,
      description,
      privileges
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getCurrencies = async () => {
  const currencies: any[] = [];

  try {
    const response = await BrightMarketApi.getCurrencies();
    let items = response.currencies;
    items.forEach(function (item: any) {
      let currency: Currency = {
        id: item.id,
        name: item.name,
        shortName: item.shortName,
      };
      currencies.push(currency);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return currencies;
};

export const getLanguages = async () => {
  const languages: any[] = [];

  try {
    const response = await BrightMarketApi.getLanguages();
    let items = response.languages;
    items.forEach(function (item: any) {
      let language: Language = {
        id: item.id,
        name: item.name,
        shortName: item.shortName,
      };
      languages.push(language);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return languages;
};

export const getChannelsTypes = async () => {
  const types: any[] = [];

  try {
    const response = await BrightMarketApi.getChannelsTypes();
    let items = response.channelTypes;
    items.forEach(function (item: any) {
      let type: ChannelType = {
        id: item.id,
        type: item.type,
        descriptionEs: item.descriptionEs,
        descriptionEn: item.descriptionEn,
        currencies: item.currencies,
      };
      types.push(type);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return types;
};

export const getBusinessUnits = async (pagination: number) => {
  const businessUnits: BusinessUnit[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getBusinessUnits(
      organizationId,
      pagination
    );

    const items = response.businessUnits;
    totalElements = response.totalElements;

    items.businessUnits.forEach(function (item: any) {
      let unit: BusinessUnit = {
        id: item.id,
        name: item.name,
        language: item.language,
        currency: item.defaultCurrency,
        channels: item.channels,
        targetMargin: item.targetMargin,
      };
      businessUnits.push(unit);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return { businessUnits, totalElements };
};

export const getAllBusinessUnits = async () => {
  let businessUnits: any[] = [];

  try {
    const response = await BrightMarketApi.getAllBusinessUnits();
    const items = response.data;
    businessUnits = items;
  } catch (e) {
    console.log("We have the error", e);
  }
  return businessUnits;
};

export const createBusinessUnit = async (
  name: string,
  languageId: number,
  currencyId: number
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createBusinessUnit(
      name,
      languageId,
      currencyId
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createChannel = async (
  businessUnitId: number,
  currenciesIds: any,
  channelTypeId: number
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createChannel(
      businessUnitId,
      currenciesIds,
      channelTypeId
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getAllChannelsByBusinessUnits = async (businessUnitsIds: any) => {
  let channels: any[] = [];
  try {
    const organizationId = await getOrganizationId();
    const items = await BrightMarketApi.getAllChannelsByBusinessUnits(
      organizationId,
      businessUnitsIds
    );
    channels = items.channels;
  } catch (e) {
    console.log("We have the error", e);
  }
  return channels;
};

export const getChannels = async (pagination: number) => {
  let channels: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getChannels(
      organizationId,
      pagination
    );

    channels = response.channels.channels;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { channels, totalElements };
};
export const getAllChannels = async () => {
  let channels: any[] = [];

  try {
    const response = await BrightMarketApi.getAllChannels();
    channels = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return channels;
};

export const deleteChannel = async (channelId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteChannel(channelId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteBusinessUnit = async (businessUnitId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteBusinessUnit(businessUnitId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editBusinessUnit = async (
  businessUnitId: number,
  businessUnit: {
    name?: string;
    languageId?: string;
    currencyId?: string;
    targetMargin?: number;
  }
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editBusinessUnit(
      businessUnitId,
      businessUnit
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editChannel = async (
  channelId: number,
  type: string,
  currenciesIds: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editChannel(
      channelId,
      type,
      currenciesIds
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editUser = async (
  userId: number,
  name: string,
  lastName: string,
  roles: any,
  businessUnitsIds: any,
  branchOfficesIds: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editUser(
      userId,
      name,
      lastName,
      roles,
      businessUnitsIds ?? [],
      branchOfficesIds ?? []
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editUserPassword = async (
  oldPassword: string,
  newPassword: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editUserPassword(
      oldPassword,
      newPassword
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editUserProfile = async (name: string, lastName: string) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editUserProfile(name, lastName);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getCategoriesByOrganization = async (pagination: number) => {
  const categories: Category[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getCategoriesByOrganization(
      organizationId,
      pagination
    );

    const items = response.categories;
    totalElements = response.totalElements;

    items.categories.forEach(function (item: any) {
      let category: Category = {
        id: item.id,
        name: item.name,
        description: item.description,
        father: item.father,
        roundingType: item.roundingType,
        enable: item.enable,
      };
      categories.push(category);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return { categories, totalElements };
};

export const getCategoriesFiltered = async (name: string) => {
  let categories = [];

  try {
    const response = await BrightMarketApi.getCategoriesFiltered(name);
    categories = response.data.content;
  } catch (e) {
    console.log("We have the error", e);
  }
  return categories;
};

export const getSuppliersFiltered = async (
  pagination: number,
  filters: {
    name?: string;
    businessUnitId?: string;
    articleId?: string;
    externalId?: string;
  }
) => {
  let suppliers = [];
  let totalElements = 0;

  try {
    const response = await BrightMarketApi.getSuppliersFiltered(
      pagination,
      filters
    );
    suppliers = response.suppliers;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { suppliers, totalElements };
};

export const getBranchOfficesFiltered = async (
  pagination: number,
  filters: {
    name?: string;
    externalId?: string;
    businessUnitId?: string;
  }
) => {
  let branchOffices = [];
  let totalElements = 0;

  try {
    const response = await BrightMarketApi.getBranchOfficesFiltered(
      pagination,
      filters
    );
    branchOffices = response.branchoffices;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { branchOffices, totalElements };
};

export const getClientSegmentsFiltered = async (
  pagination: number,
  filters: {
    name?: string;
    description?: string;
    minMembers?: number;
    maxMembers?: number;
  }
) => {
  let clientSegments = [];
  let totalElements = 0;

  try {
    const response = await BrightMarketApi.getClientSegmentsFiltered(
      pagination,
      filters
    );
    clientSegments = response.data.content;
    totalElements = response.data.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { clientSegments, totalElements };
};

export const getBrandsFiltered = async (name: string) => {
  let brands = [];

  try {
    const response = await BrightMarketApi.getBrandsFiltered(name);
    brands = response.data.content;
  } catch (e) {
    console.log("We have the error", e);
  }
  return brands;
};

export const getPurchaseUnitsFiltered = async (name: string) => {
  let purchaseUnits = [];

  try {
    const response = await BrightMarketApi.getPurchaseUnitsFiltered(name);
    purchaseUnits = response.data.content;
  } catch (e) {
    console.log("We have the error", e);
  }
  return purchaseUnits;
};

export const getDepositsFiltered = async (
  pagination: number,
  filters: { name?: string }
) => {
  let deposits = [];
  let totalElements = 0;

  try {
    const response = await BrightMarketApi.getDepositsFiltered(
      pagination,
      filters
    );
    deposits = response.deposits;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { deposits, totalElements };
};

export const getCategoriesByFather = async (
  pagination: number,
  filters: { name?: string; externalId?: string; enable?: number },
  fatherId?: number
) => {
  let categories: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getCategoriesByFather(
      organizationId,
      pagination,
      filters,
      fatherId
    );

    const items = response.categories;
    totalElements = response.totalElements;

    categories = items.categories;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { categories, totalElements };
};

export const getRoundingTypes = async () => {
  const types: any[] = [];

  try {
    const response = await BrightMarketApi.getRoundingTypes();
    let items = response.roundingTypes;
    items.forEach(function (item: any) {
      let type: RoundingType = {
        id: item.id,
        type: item.type,
        descriptionEs: item.descriptionEs,
        descriptionEn: item.descriptionEn,
      };
      types.push(type);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return types;
};

export const getCategoryById = async (categoryId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getCategoryById(categoryId);
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createCategory = async (category: {
  name: string;
  externalId: string;
  description: string;
  fatherId: number;
  roundingTypeId: number;
  enable: boolean;
  businessUnitId: any;
}) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createCategory(category);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteCategory = async (categoryId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteCategory(categoryId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editCategory = async (
  categoryId: number,
  name: string,
  externalId: string,
  description: string,
  fatherId: number,
  roundingTypeId: number,
  enable: boolean,
  businessUnitId: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editCategory(
      categoryId,
      name,
      externalId,
      description,
      fatherId ?? "",
      roundingTypeId,
      enable,
      businessUnitId
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getAllCategories = async () => {
  const categories: Category[] = [];

  try {
    const response = await BrightMarketApi.getAllCategories();
    const items = response.data;
    items.forEach(function (item: any) {
      let category: Category = {
        id: item.id,
        name: item.name,
        description: item.description,
        father: item.father,
        roundingType: item.roundingType,
        enable: item.enable,
      };
      categories.push(category);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return categories;
};

export const getCategoriesPossibleFathers = async (categoryId: number) => {
  const categories: Category[] = [];

  try {
    const items = await BrightMarketApi.getCategoriesPossibleFathers(
      categoryId
    );
    items.forEach(function (item: any) {
      let category: Category = {
        id: item.id,
        name: item.name,
        description: item.description,
        father: item.father,
        roundingType: item.roundingType,
        enable: item.enable,
      };
      categories.push(category);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return categories;
};

export const getArticlesByName = async (name: string) => {
  let totalElements = 0;
  let articles = [];

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getArticlesByName(
      name,
      organizationId
    );

    articles = response.articles;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { articles, totalElements };
};

export const getArticlesByOrganization = async (pagination: number) => {
  const articles: Article[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getArticlesByOrganization(
      organizationId,
      pagination
    );

    const items = response.articles;
    totalElements = response.totalElements;

    items.articles.forEach(function (item: any) {
      let article: Article = {
        id: item.id,
        name: item.name,
        externalId: item.externalId,
        description: item.description,
        category: item.category,
        brand: item.brand,
        cost: item.cost,
        actualPrice: item.actualPrice,
        defaultPrice: item.defaultPrice,
        currency: item.currency,
        roundingType: item.roundingType,
        enable: item.enable,
        skus: item.skus,
        attributes: item.attributes,
        suppliers: item.suppliers,
      };
      articles.push(article);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return { articles, totalElements };
};

export const getArticlesFiltered = async (
  pagination: number,
  filters: {
    externalId?: string;
    id?: string;
    categoryId?: string;
    brandId?: number;
    articleName?: string;
    businessUnitId?: string;
  },
  onlyNameId?: boolean,
  projection?: string
) => {
  const articles: Article[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getArticlesFiltered(
      organizationId,
      pagination,
      filters,
      onlyNameId,
      projection
    );

    const items = response.articles;
    totalElements = response.totalElements;

    items.articles.forEach(function (item: any) {
      const article: Article = {
        id: item.id,
        name: item.name,
        externalId: item.externalId,
        description: item.description,
        category: item.category,
        brand: item.brand,
        cost: item.cost,
        actualPrice: item.actualPrice,
        defaultPrice: item.defaultPrice,
        currency: item.currency,
        roundingType: item.roundingType,
        enable: item.enable,
        skus: item.skus,
        attributes: item.attributes,
        suppliers: item.suppliers,
      };
      articles.push(article);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return { articles, totalElements };
};

export const getSkusFiltered = async (
  pagination: number,
  filters: {
    externalId?: string;
    id?: string;
    categoryId?: string;
    articleId?: string;
    articleName?: string;
    skuName?: string;
  }
) => {
  let skus: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getSkusFiltered(
      organizationId,
      pagination,
      filters
    );

    skus = response.skus;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { skus, totalElements };
};

export const getStockBreaks = async (
  pagination: number,
  filters: {
    articleId?: string;
    branchOfficeId?: string;
    minStock?: string;
    maxStock?: string;
  }
) => {
  let data: any[] = [];
  let totalElements = 0;

  try {
    const response = await BrightMarketApi.getStockBreaks(pagination, filters);
    data = response.data;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { data, totalElements };
};

export const getStockAdjustments = async (
  stockId: string,
  type: "deposits" | "branchOffices",
  pagination: number
) => {
  let data: any[] = [];
  let totalElements = 0;

  try {
    const response = await BrightMarketApi.getStockAdjustments(
      stockId,
      type,
      pagination
    );
    data = response.data;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { data, totalElements };
};

export const getArticlesWithStock = async (
  pagination: number,
  order = "asc",
  articlesId?: string | null,
  categoriesId?: string | null
) => {
  let articles: any[] = [];
  let totalElements = 0;

  try {
    const response = await BrightMarketApi.getArticlesWithStock(
      pagination,
      order,
      articlesId,
      categoriesId
    );

    const items = response.articles;
    totalElements = response.totalElements;
    articles = items;
  } catch (e) {
    console.log("We have the error", e);
  }

  return { articles, totalElements };
};

export const getArticlesAndSkuWithStock = async (
  pagination: number,
  order = "asc"
) => {
  const articles: any[] = [];
  let totalElements = 0;

  try {
    const response = await BrightMarketApi.getArticlesAndSkuWithStock(
      pagination,
      order
    );

    const items = response.articles;
    totalElements = response.totalElements;
    items.forEach(function (item: any) {
      let article = {
        id: item.id,
        name: item.name,
        suppliers: item.suppliers,
        totalStockArticles: item.totalStockArticles,
        skus: item.skus,
        quantityInPurchaseOrder: item.quantityInPurchaseOrder,
      };
      articles.push(article);
    });
  } catch (e) {
    console.log("We have the error", e);
  }

  return { articles, totalElements };
};

export const getSkusByBranchOfficeId = async (
  branchOfficeId: number,
  pagination: number
) => {
  let totalElements = 0;
  let skus = [];

  try {
    const response = await BrightMarketApi.getSkusByBranchOfficeId(
      branchOfficeId,
      pagination
    );

    skus = response.skus;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { skus, totalElements };
};

export const getBrandsByBranchOfficeId = async (
  entity: {
    branchOfficeId?: number;
    depositId?: number;
  },
  pagination: number
) => {
  let totalElements = 0;
  let brands = [];

  try {
    const response = await BrightMarketApi.getBrandsByBranchOfficeId(
      entity,
      pagination
    );

    brands = response.brands;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { brands, totalElements };
};

export const getBrandsCatalogue = async (pagination: number) => {
  let totalElements = 0;
  let brands = [];

  try {
    const response = await BrightMarketApi.getBrandsCatalogue(pagination);

    brands = response.brands;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { brands, totalElements };
};

export const getCategoriesByBrandCatalogue = async (
  brandId: number,
  pagination: number
) => {
  let totalElements = 0;
  let categories = [];

  try {
    const response = await BrightMarketApi.getCategoriesByBrandCatalogue(
      brandId,
      pagination
    );

    categories = response.categories;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { categories, totalElements };
};

export const getSkusByBrandCategoryCatalogue = async (
  brandId: number,
  categoryId: number,
  pagination: number
) => {
  let totalElements = 0;
  let skus = [];

  try {
    const response = await BrightMarketApi.getSkusByBrandCategoryCatalogue(
      brandId,
      categoryId,
      pagination
    );

    skus = response.skus;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { skus, totalElements };
};

export const getCategoriesByBrandAndBranchOfficeId = async (
  entity: {
    branchOfficeId?: number;
    depositId?: number;
  },
  brandId: number,
  pagination: number
) => {
  let totalElements = 0;
  let categories = [];

  try {
    const response =
      await BrightMarketApi.getCategoriesByBrandAndBranchOfficeId(
        entity,
        brandId,
        pagination
      );

    categories = response.categories;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { categories, totalElements };
};

export const getSkusByBrandCategoryAndBranchOfficeId = async (
  entity: {
    branchOfficeId?: number;
    depositId?: number;
  },
  brandId: number,
  categoryId: number,
  pagination: number,
  unsupply?: number
) => {
  let totalElements = 0;
  let skus = [];

  console.log(unsupply);

  try {
    const response =
      await BrightMarketApi.getSkusByBrandCategoryAndBranchOfficeId(
        entity,
        brandId,
        categoryId,
        pagination,
        unsupply
      );

    skus = response.skus;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { skus, totalElements };
};

export const getRepositionOrderRequests = async (
  pagination: number,
  branchOfficeDestinyId?: number
) => {
  let totalElements = 0;
  let data = [];

  try {
    const response = await BrightMarketApi.getRepositionOrderRequests(
      pagination,
      branchOfficeDestinyId
    );

    data = response.data;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }

  return { data, totalElements };
};

export const getSkusWithStockFiltered = async (request: {
  pagination: number;
  articleId?: number;
  skuId?: number;
  branchOfficeId: number;
  businessUnitId: string;
}) => {
  let totalElements = 0;
  let skus = [];

  try {
    const response = await BrightMarketApi.getSkusWithStockFiltered(request);
    skus = response.skus;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { skus, totalElements };
};
export const getBranchOfficesByName = async (name: string) => {
  let totalElements = 0;
  let branchOffices = [];

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getBranchOfficesByName(
      name,
      organizationId
    );

    branchOffices = response.branchOffices;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { branchOffices, totalElements };
};

export const getBranchOfficesByNameAndBU = async (
  name: string,
  businessUnitId: string
) => {
  let totalElements = 0;
  let branchOffices = [];

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getBranchOfficesByNameAndBU(
      name,
      organizationId,
      businessUnitId
    );
    branchOffices = response.branchOffices;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { branchOffices, totalElements };
};

export const getBusinessUnitsByName = async (name: string) => {
  let totalElements = 0;
  let businessUnits = [];

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getBusinessUnitsByName(
      name,
      organizationId
    );

    businessUnits = response.businessUnits;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { businessUnits, totalElements };
};

export const getAllArticles = async () => {
  const articles: any[] = [];

  try {
    const response = await BrightMarketApi.getAllArticles();
    const items = response.data;
    items.forEach(function (item: any) {
      let article = {
        id: item.id,
        name: item.name,
      };
      articles.push(article);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return articles;
};

export const createArticle = async (
  article: any,
  suppliers?: any,
  attributes?: any,
  skus?: any
) => {
  let ret: { success: boolean; article: any } = {
    success: false,
    article: null,
  };
  try {
    let response = await BrightMarketApi.createArticle(
      article,
      suppliers,
      attributes,
      skus ?? []
    );

    ret.success = response.data.status === StatusResponse.Ok;
    ret.article = response.data.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteArticle = async (articleId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteArticle(articleId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getSuppliersByOrganization = async (pagination: number) => {
  const suppliers: Supplier[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getSuppliersByOrganization(
      organizationId,
      pagination
    );

    const items = response.suppliers;
    totalElements = response.totalElements;

    items.suppliers.forEach(function (item: any) {
      let supplier: Supplier = {
        id: item.id,
        name: item.name,
        externalId: item.externalId,
        website: item.website,
        catalogueLink: item.catalogueLink,
        articles: item.articles,
        contacts: item.contacts,
      };
      suppliers.push(supplier);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return { suppliers, totalElements };
};

export const deleteSupplier = async (supplierId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteSupplier(supplierId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createSupplier = async (
  name: string,
  externalId: string,
  website: string,
  catalogueLink: string,
  businessUnitsIds: any,
  supplierPaymentMethodsIds: any,
  contacts?: any,
  articles?: any
) => {
  const ret: { success: boolean; supplier: any } = {
    success: false,
    supplier: null,
  };
  try {
    let response = await BrightMarketApi.createSupplier(
      name,
      externalId,
      website,
      catalogueLink,
      businessUnitsIds ?? [],
      supplierPaymentMethodsIds ?? [],
      contacts ?? [],
      articles ?? []
    );

    ret.success = response.data.status === StatusResponse.Ok;
    ret.supplier = response.data.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getArticleById = async (articleId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getArticleById(articleId);
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getSupplierById = async (supplierId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getSupplierById(supplierId);
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getAllSuppliers = async () => {
  const suppliers: any[] = [];

  try {
    const response = await BrightMarketApi.getAllSuppliers();
    const items = response.data;
    items.forEach(function (item: any) {
      suppliers.push(item);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return suppliers;
};

export const addArticleToSupplier = async (
  articleId: number,
  principal: string,
  supplierId: number,
  purchaseUnitId: number,
  commercialTerm?: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.addArticleToSupplier(
      articleId,
      principal,
      supplierId,
      purchaseUnitId,
      commercialTerm
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const addContactToSupplier = async (
  supplierId: number,
  name: string,
  role: string,
  phoneNumber: string,
  email: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.addContactToSupplier(
      supplierId,
      name,
      role,
      phoneNumber,
      email
    );
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getAllAttributes = async () => {
  const attributes: any[] = [];

  try {
    const response = await BrightMarketApi.getAllAttributes();
    const items = response.data;
    items.forEach(function (item: any) {
      attributes.push(item);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return attributes;
};

export const getAttributesFiltered = async (name: string) => {
  const attributes: any[] = [];

  try {
    const response = await BrightMarketApi.getAttributesFiltered(name);
    const items = response.data.content;
    items.forEach(function (item: any) {
      attributes.push(item);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return attributes;
};

export const getAttributes = async (
  pagination: number,
  filters: { attribute?: string; value?: string; businessUnitId?: string }
) => {
  const attributes: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getAttributes(
      organizationId,
      pagination,
      filters
    );

    let items = response.attributes;
    totalElements = response.totalElements;

    items.attributes.forEach(function (attribute: any) {
      attributes.push(attribute);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return { attributes, totalElements };
};

export const deleteAttribute = async (attributeId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteAttribute(attributeId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createAttribute = async (
  name: string,
  businessUnitsIds: any,
  enums: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createAttribute(
      name,
      businessUnitsIds ?? [],
      enums ?? []
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editAttribute = async (
  attributeId: number,
  name: string,
  businessUnitsIds: any,
  enums: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editAttribute(
      attributeId,
      name,
      businessUnitsIds ?? [],
      enums ?? []
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editSupplier = async (
  supplierId: string,
  name: string,
  externalId: string,
  website: string | null,
  catalogueLink: string | null,
  businessUnitsIds: any,
  supplierPaymentMethodsIds: any
) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.editSupplier(
      supplierId,
      name,
      externalId,
      website,
      catalogueLink,
      businessUnitsIds,
      supplierPaymentMethodsIds
    );
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editArticle = async (article: {
  articleId: string;
  name: string;
  externalId: string;
  description: string;
  webDescription: string;
  originType: string;
  categoryId: number;
  brandId: number;
  currencyId: number;
  roundingTypeId: number;
  cost: number;
  defaultPrice: number;
  minimumPrice: number;
  maximumPrice: number;
  minimumMargin: number;
  enable: boolean;
  discontinued: boolean;
  businessUnitsIds: any;
  photo1: string;
  photo2: string;
  photo3: string;
}) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.editArticle(article);
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const addAttributeToArticle = async (
  articleId: number,
  attributeId: number,
  enumId: number
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.addAttributeToArticle(
      articleId,
      attributeId,
      enumId
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const addSeasonToArticle = async (
  articleId: number,
  seasonId: number
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.addSeasonToArticle(
      articleId,
      seasonId
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const addPriceToArticle = async (
  articleId: number,
  branchOfficeId: number,
  price: string,
  date: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.addPriceToArticle(
      articleId,
      branchOfficeId,
      price,
      date
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const addAttributeToSku = async (
  skuId: number,
  attributeId: number,
  enumId: number
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.addAttributeToSku(
      skuId,
      attributeId,
      enumId
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteAttributeFromArticle = async (
  articleAttributeId: number
) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteAttributeFromArticle(
      articleAttributeId
    );
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteSeasonFromArticle = async (articleSeasonId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteSeasonFromArticle(
      articleSeasonId
    );
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteSkuAttributeFromArticle = async (skuAttributeId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteSkuAttributeFromArticle(
      skuAttributeId
    );
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editAttributeFromArticle = async (
  articleAttributeId: number,
  value: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editAttributeFromArticle(
      articleAttributeId,
      value
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editSkuAttributeFromArticle = async (
  articleSkuAttributeId: number,
  value: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editSkuAttributeFromArticle(
      articleSkuAttributeId,
      value
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteContact = async (contactId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteContact(contactId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteArticleSupplier = async (articleSupplierId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteArticleSupplier(
      articleSupplierId
    );
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editContact = async (
  contactId: number,
  name: string,
  role: string,
  email: string,
  phoneNumber: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editContact(
      contactId,
      name,
      role,
      email,
      phoneNumber
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editArticleToSupplier = async (
  id: number,
  articleId: number,
  principal: string,
  purchaseUnitId: number,
  commercialTerm?: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editArticleToSupplier(
      id,
      articleId,
      principal,
      purchaseUnitId,
      commercialTerm
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createSkuInArticle = async (
  articleId: string,
  sku: any,
  attribute: any
) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.createSkuInArticle(
      articleId,
      sku,
      attribute ?? []
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteSkuFromArticle = async (articleSkuId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteSkuFromArticle(articleSkuId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editSkuFromArticle = async (
  articleSkuId: number,
  name: string,
  enable: string,
  heightWitPackaging: number,
  lengthWitPackaging: number,
  widthWitPackaging: number,
  weightWitPackaging: number,
  height: number,
  length: number,
  width: number,
  weight: number,
  volumen: number,
  kit: boolean,
  salesUnit: string,
  storageUnit: string,
  shoeSize: number,
  clothSizeOp1: number,
  clothSizeOp2: string,
  colour: number,
  minimumStock: number,
  externalId: string,
  cost: number,
  replenish: boolean,
  currencyId: number
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editSkuFromArticle(
      articleSkuId,
      name,
      enable,
      heightWitPackaging,
      lengthWitPackaging,
      widthWitPackaging,
      weightWitPackaging,
      height,
      length,
      width,
      weight,
      volumen,
      kit,
      salesUnit,
      storageUnit,
      shoeSize,
      clothSizeOp1,
      clothSizeOp2,
      colour,
      minimumStock,
      externalId,
      cost,
      replenish,
      currencyId
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editSupplierToArticle = async (
  id: number,
  supplierId: number,
  principal: string,
  purchaseUnitId: number,
  commercialTerm?: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editSupplierToArticle(
      id,
      supplierId,
      principal,
      purchaseUnitId,
      commercialTerm
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getBusinessUnitById = async (businessUnitId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getBusinessUnitById(businessUnitId);
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getPricingTypes = async (pagination: number) => {
  const pricingTypes: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getPricingTypes(
      organizationId,
      pagination
    );

    let items = response.pricingTypes;
    totalElements = response.totalElements;

    items.pricingTypes.forEach(function (item: any) {
      let pricingType = {
        id: item.id,
        code: item.code,
        description: item.description,
      };
      pricingTypes.push(pricingType);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return { pricingTypes, totalElements };
};

export const createPricingType = async (code: string, description: string) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createPricingType(code, description);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deletePricingType = async (pricingTypeId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deletePricingType(pricingTypeId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editPricingType = async (
  pricingTypeId: number,
  code: string,
  description: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editPricingType(
      pricingTypeId,
      code,
      description
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getPromotionTypes = async (pagination: number) => {
  const promotionTypes: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getPromotionTypes(
      organizationId,
      pagination
    );

    let items = response.promotionTypes;
    totalElements = response.totalElements;

    items.promotionTypes.forEach(function (item: any) {
      let pricingType = {
        id: item.id,
        code: item.code,
        name: item.name,
      };
      promotionTypes.push(pricingType);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return { promotionTypes, totalElements };
};

export const createPromotionType = async (code: string, name: string) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createPromotionType(code, name);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deletePromotionType = async (promotionTypeId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deletePromotionType(promotionTypeId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editPromotionType = async (
  promotionTypeId: number,
  code: string,
  description: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editPromotionType(
      promotionTypeId,
      code,
      description
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteBranchOffice = async (branchOfficeId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteBranchOffice(branchOfficeId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getBranchOfficeById = async (branchOfficeId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getBranchOfficeById(branchOfficeId);
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getBranchOfficeSkusById = async (
  branchOfficeId: string,
  pagination: number,
  skuName: string
) => {
  const organizationId = await getOrganizationId();

  let skus;
  let totalElements = 0;
  try {
    const response = await BrightMarketApi.getBranchOfficeSkusById(
      branchOfficeId,
      pagination,
      organizationId,
      skuName
    );
    skus = response.skus;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { skus, totalElements };
};

export const getDepositsSkusById = async (
  depositId: string,
  pagination: number,
  skuName: string
) => {
  const organizationId = await getOrganizationId();

  let skus;
  let totalElements = 0;
  try {
    const response = await BrightMarketApi.getDepositsSkusById(
      depositId,
      pagination,
      organizationId,
      skuName
    );
    skus = response.skus;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { skus, totalElements };
};

export const editBranchOffice = async (branchOffice: BranchOffice) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.editBranchOffice(branchOffice);
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getAllBranchOfficesTypes = async () => {
  const types: any[] = [];

  try {
    const response = await BrightMarketApi.getAllBranchOfficesTypes();
    const items = response.data;
    items.forEach(function (item: any) {
      let type = {
        value: item.value,
        descriptionEn: item.description_en,
        descriptionEs: item.description_es,
      };
      types.push(type);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return types;
};

export const createBranchOffice = async (branchOffice: BranchOffice) => {
  let ret: { success: boolean; branchOffice: any } = {
    success: false,
    branchOffice: null,
  };
  try {
    let response = await BrightMarketApi.createBranchOffice(branchOffice);

    ret.success = response.data.status === StatusResponse.Ok;
    ret.branchOffice = response.data.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getAcceptedPaymentMethods = async (
  pagination: number,
  businessUnitId?: string
) => {
  let acceptedPaymentMethods: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getAcceptedPaymentMethods(
      organizationId,
      pagination,
      businessUnitId
    );

    let items = response.acceptedPaymentMethods;
    totalElements = response.totalElements;
    acceptedPaymentMethods = items.acceptedPaymentMethod;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { acceptedPaymentMethods, totalElements };
};

export const getAcceptedPaymentMethodById = async (paymentMethodId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getAcceptedPaymentMethodById(
      paymentMethodId
    );
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getAllAcceptedPaymentMethods = async () => {
  let clients: any[] = [];

  try {
    const response = await BrightMarketApi.getAllAcceptedPaymentMethods();
    clients = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return clients;
};

export const editAcceptedPaymentMethod = async (
  acceptedPaymentMethodId: number,
  name: string,
  externalId: string,
  typeId: number,
  feeRangeMin: number,
  feeRangeMax: number,
  businessUnitsIds: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editAcceptedPaymentMethod(
      acceptedPaymentMethodId,
      {
        name,
        externalId,
        typeId,
        feeRangeMin,
        feeRangeMax,
        businessUnitsIds,
      }
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getAcceptedPaymentMethodsTypes = async () => {
  const types: any[] = [];

  try {
    const response = await BrightMarketApi.getAcceptedPaymentMethodsTypes();
    let items = response.data;
    items.forEach(function (item: any) {
      let type: RoundingType = {
        id: item.id,
        type: item.type,
        descriptionEs: item.descriptionEs,
        descriptionEn: item.descriptionEn,
      };
      types.push(type);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return types;
};

export const getPaymentMethodsByBusinessUnitId = async (
  businessUnitId: number
) => {
  const acceptedPaymentMethods: any[] = [];

  try {
    const response = await BrightMarketApi.getPaymentMethodsByBusinessUnitId(
      businessUnitId
    );
    let items = response.data;
    items.forEach(function (item: any) {
      let pricingType = {
        id: item.id,
        code: item.code,
        name: item.name,
        type: item.type,
        transmitter: item.transmitter,
        feeRange: item.feeRange,
        acceptedGoods: item.acceptedGoods,
      };
      acceptedPaymentMethods.push(pricingType);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return acceptedPaymentMethods;
};

export const createAcceptedPaymentMethod = async (paymentMethod: {
  name: string;
  externalId: string;
  typeId: number;
  feeRangeMin: number;
  feeRangeMax: number;
  businessUnitsIds: any;
}) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createAcceptedPaymentMethod(
      paymentMethod
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteAcceptedPaymentMethod = async (
  acceptedPaymentMethodId: number
) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteAcceptedPaymentMethod(
      acceptedPaymentMethodId
    );
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteAcceptedPaymentMethodFromBusinessUnit = async (
  acceptedPaymentMethodId: number,
  businessUnitId: number
) => {
  let ret = false;

  try {
    let response =
      await BrightMarketApi.deleteAcceptedPaymentMethodFromBusinessUnit(
        acceptedPaymentMethodId,
        businessUnitId
      );
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const addAcceptedPaymentMethodToBusinessUnit = async (
  businessUnitId: number,
  acceptedPaymentMethodId: number
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.addAcceptedPaymentMethodToBusinessUnit(
      businessUnitId,
      acceptedPaymentMethodId
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getCompetitors = async (pagination: number) => {
  const competitors: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getCompetitors(
      organizationId,
      pagination
    );

    let items = response.competitors;
    totalElements = response.totalElements;

    items.competitors.forEach(function (item: any) {
      const competitor = {
        id: item.id,
        name: item.name,
        externalId: item.externalId,
        website: item.website,
        articles: item.articles,
      };
      competitors.push(competitor);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return { competitors, totalElements };
};

export const getCompetitorById = async (competitorId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getCompetitorById(competitorId);
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteCompetitor = async (competitorId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteCompetitor(competitorId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createCompetitor = async (
  name: string,
  externalId: string,
  website: string,
  articlesIds: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createCompetitor(
      name,
      externalId,
      website,
      articlesIds ?? []
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editCompetitor = async (
  competitorId: string,
  name: string,
  externalId: string,
  website: string,
  articleIds: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editCompetitor(
      competitorId,
      name,
      externalId,
      website,
      articleIds
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createSalePoint = async (
  branchOfficeId: number,
  name: string,
  acceptedPaymentMethodsIds: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createSalePoint(
      branchOfficeId,
      name,
      acceptedPaymentMethodsIds
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteSalePoint = async (salePointId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteSalePoint(salePointId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editSalePoint = async (
  salePointId: number,
  name: string,
  acceptedPaymentMethodsIds: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editSalePoint(
      salePointId,
      name,
      acceptedPaymentMethodsIds ?? []
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getDeposits = async (
  pagination: number,
  businessUnitId: string,
  filters: { name: string; externalId: string }
) => {
  const deposits: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getDeposits(
      organizationId,
      pagination,
      businessUnitId,
      filters
    );

    let items = response.deposits;
    totalElements = response.totalElements;
    items.deposits.forEach(function (item: any) {
      deposits.push(item);
    });
  } catch (e) {
    console.log("We have the error", e);
  }

  return { deposits, totalElements };
};

export const createDeposit = async (deposit: {
  name: string;
  capacity: number;
  externalId: string;
  branchOffices: any;
  address: string;
  businessUnitsIds: any;
  categoriesIds: any;
  latitude: number;
  longitude: number;
  capacityInUnits: number;
  capacityDispatchUnits: number;
  dispatchFrequency: number;
  capacityReception: number;
  storageCostPerUnit: number;
  minimumStock: number;
  maximumStock: number;
}) => {
  let ret: { success: boolean; deposit: any } = {
    success: false,
    deposit: null,
  };
  try {
    let response = await BrightMarketApi.createDeposit(deposit);

    ret.success = response.data.status === StatusResponse.Ok;
    ret.deposit = response.data.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteDeposit = async (depositId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteDeposit(depositId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editDeposit = async (
  depositId: number,
  deposit: {
    name: string;
    capacity: number;
    externalId: string;
    branchOffices: any;
    address: string;
    businessUnitsIds: any;
    categoriesIds: any;
    capacityInUnits: number;
    capacityDispatchUnits: number;
    dispatchFrequency: number;
    capacityReception: number;
    storageCostPerUnit: number;
    minimumStock: number;
    maximumStock: number;
    latitude: number;
    longitude: number;
  }
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editDeposit(depositId, deposit);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getAllBranchOffices = async () => {
  const branchOffices: any[] = [];

  const organziationId = await getOrganizationId();
  try {
    const response = await BrightMarketApi.getAllBranchOffices(organziationId);
    const items = response.branchOffices;
    items?.forEach(function (item: any) {
      let branchOffice = {
        id: item.id,
        name: item.name,
      };
      branchOffices.push(branchOffice);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return branchOffices;
};

export const getAllBranchOfficesByBu = async (businessUnitIds: any) => {
  const branchOffices: any[] = [];

  try {
    const response = await BrightMarketApi.getAllBranchOfficesByBu(
      businessUnitIds
    );
    return response;
  } catch (e) {
    console.log("We have the error", e);
  }
  return branchOffices;
};

export const getBranchOffices = async (pagination: number) => {
  let branchOffices: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getBranchOffices(
      organizationId,
      pagination
    );

    let items = response.branchOffices;
    totalElements = response.totalElements;
    branchOffices = items.branchOffices;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { branchOffices, totalElements };
};

export const getDepositById = async (depositId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getDepositById(depositId);
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteArticleStock = async (
  articleStockId: number,
  type: string
) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteArticleStock(
      articleStockId,
      type
    );
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const addArticleStock = async (
  stock: number,
  skuId: number,
  type: string,
  placeId: number,
  minimumStock?: number,
  leadTime?: number
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.addArticleStock(
      stock,
      skuId,
      type,
      placeId,
      minimumStock,
      leadTime
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getPurchaseOrdersByOrganization = async (
  pagination: number,
  supplierId?: number | null,
  status?: string | null,
  articlesId?: string | null,
  startDate?: string | null,
  endDate?: string | null
) => {
  const purchaseOrders: any[] = [];
  let totalElements = 0;

  try {
    const response = await BrightMarketApi.getPurchaseOrdersByOrganization(
      pagination,
      supplierId,
      status,
      articlesId,
      startDate,
      endDate
    );

    const items = response;
    totalElements = response.totalElements;

    items.purchaseOrders.forEach(function (item: any) {
      purchaseOrders.push(item);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return { purchaseOrders, totalElements };
};

export const createPurchaseOrder = async (
  body: any,
  preview: boolean,
  businessUnitId?: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createPurchaseOrder(
      body,
      preview,
      businessUnitId
    );

    if (response.data.status === StatusResponse.Ok) return response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getPurchaseOrderStatics = async () => {
  let ret;
  try {
    const response = await BrightMarketApi.getPurchaseOrderStatics();

    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }

  return ret;
};

export const getAllDeposits = async () => {
  const deposits: any[] = [];

  try {
    const response = await BrightMarketApi.getAllDeposits();
    const items = response.data.data;
    items.forEach(function (item: any) {
      let deposit = {
        id: item.id,
        name: item.name,
      };
      deposits.push(deposit);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return deposits;
};

export const deletePurchaseOrder = async (purchaseOrderId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deletePurchaseOrder(purchaseOrderId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getPurchaseOrderById = async (purchaseOrderId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getPurchaseOrderById(
      purchaseOrderId
    );
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const markReceivedOrder = async (receivedOrder: any) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.markReceivedOrder(receivedOrder);

    if (response.data.status === StatusResponse.Ok) return response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const markReceivedRepositionOrder = async (receivedOrder: any) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.markReceivedRepositionOrder(
      receivedOrder
    );

    if (response.data.status === StatusResponse.Ok) return response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const markSentArticlesRepositionOrder = async (
  repositionOrderSkuIds: any,
  arriveDate: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.markSentArticlesRepositionOrder(
      repositionOrderSkuIds,
      arriveDate
    );

    if (response.data.status === StatusResponse.Ok) return response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editPurchaseOrderStatus = async (
  purchaseOrderId: number,
  status: string,
  notCompletedReason?: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editPurchaseOrderStatus(
      purchaseOrderId,
      status,
      notCompletedReason
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editArticleStock = async (
  articleStockId: number,
  stock: string,
  type: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editArticleStock(
      articleStockId,
      stock,
      type
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getRepositionOrdersByOrganization = async (query: {
  pagination: number;
  status?: string | null;
  startCreatedDate?: string | null;
  endCreatedDate?: string | null;
  depositTo?: number | null;
  branchOfficeTo?: number | null;
  depositFrom?: number | null;
  branchOfficeFrom?: number | null;
  orderBy?: string;
  businessUnitId?: string;
  articlesIds?: number[];
  skuId?: string | null;
}) => {
  const repositionOrders: any[] = [];
  let totalElements = 0;

  try {
    const response = await BrightMarketApi.getRepositionOrdersByOrganization(
      query
    );

    const items = response;
    totalElements = response.totalElements;

    items.repositionOrders.forEach(function (item: any) {
      repositionOrders.push(item);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return { repositionOrders, totalElements };
};

export const deleteRepositionOrder = async (repositionOrderId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteRepositionOrder(
      repositionOrderId
    );
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editRepositionOrderStatus = async (
  repositionOrderId: number,
  status: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editRepositionOrderStatus(
      repositionOrderId,
      status
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getRepositionOrderStatics = async () => {
  let ret;
  try {
    const response = await BrightMarketApi.getRepositionOrderStatics();

    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }

  return ret;
};

export const getRepositionOrderById = async (repositionOrderId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getRepositionOrderById(
      repositionOrderId
    );
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createRepositionOrder = async (
  body: any,
  preview: boolean,
  businessUnitId?: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createRepositionOrder(
      body,
      preview,
      businessUnitId
    );

    if (response.data.status === StatusResponse.Ok) return response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createRepositionOrderDraft = async (
  orders: {
    skuId: number;
    branchOfficeOrDepositFrom: string;
    branchOfficeOrDepositFromId: number;
    branchOfficeOrDepositTo: string;
    branchOfficeOrDepositToId: number;
    quantity: number;
  }[],
  businessUnitId: string
) => {
  let ret = { success: false };
  try {
    let response = await BrightMarketApi.createRepositionOrderDraft(
      orders,
      businessUnitId
    );

    if (response.data.status === StatusResponse.Ok)
      return { data: response.data, success: true };
    else {
      return { success: false };
    }
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createPurchaseOrderDraft = async (
  orders: {
    skuId: number;
    branchOfficeOrDeposit: string;
    branchOfficeOrDepositId: number;
    supplierId: string;
    quantity: number;
  }[],
  businessUnitId: string
) => {
  let ret = { success: false };
  try {
    let response = await BrightMarketApi.createPurchaseOrderDraft(
      orders,
      businessUnitId
    );

    if (response.data.status === StatusResponse.Ok)
      return { data: response.data, success: true };
    else {
      return { success: false };
    }
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getRepositionOrderDraft = async (businessUnitId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getRepositionOrderDraft(
      businessUnitId
    );
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getPurchaseOrderDraft = async (businessUnitId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getPurchaseOrderDraft(
      businessUnitId
    );
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getClients = async (
  pagination: number,
  filters: {
    idNumber?: string;
    segmentId?: string;
    levelId?: string;
    birthStartDate?: string;
    birthEndDate?: string;
    registerStartDate?: string;
    registerEndDate?: string;
  }
) => {
  let clients: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getClients(
      organizationId,
      pagination,
      filters
    );

    let items = response.clients;
    totalElements = response.totalElements;
    clients = items.clients;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { clients, totalElements };
};

export const deleteClient = async (clientId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteClient(clientId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createClient = async (clientInfo: {
  idNumber: string;
  birthdate: string;
  documentType: string;
  country: string;
  city: string;
  sex: string;
  fidelity: string;
  active: boolean;
}) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createClient(clientInfo);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editClient = async (clientInfo: {
  id: number;
  birthdate: string;
  documentType: string;
  documentNumber: string;
  country: string;
  city: string;
  sex: string;
  fidelity: string;
  active: boolean;
}) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editClient(clientInfo);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getClientById = async (clientId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getClientById(clientId);
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getClientMovementsById = async (
  clientId: string,
  page: number
) => {
  let points: any[] = [];
  let totalElements = 0;

  try {
    const response = await BrightMarketApi.getClientMovementsById(
      clientId,
      page
    );
    points = response.points;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { points, totalElements };
};

export const addMovementToClient = async (
  clientId: number,
  movement: { type: string; pointsConsumed: number; comments: string }
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.addMovementToClient(
      clientId,
      movement
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const setPointsToClient = async (
  clientId: number,
  units: { activePoints: number; pendingPoints: number }
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.setPointsToClient(clientId, units);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getClientStatsByFidelity = async (fidelity: string) => {
  let ret;
  const organizationId = await getOrganizationId();

  try {
    const response = await BrightMarketApi.getClientStatsByFidelity(
      organizationId,
      fidelity
    );
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getAllClients = async () => {
  let clients: any[] = [];

  try {
    const response = await BrightMarketApi.getAllClients();
    clients = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return clients;
};

export const getClientSegments = async (pagination: number) => {
  let clientSegments: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getClientSegments(
      organizationId,
      pagination
    );

    let items = response.clientSegments;
    totalElements = response.totalElements;

    clientSegments = items;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { clientSegments, totalElements };
};

export const deleteClientSegment = async (clientSegmentId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteClientSegment(clientSegmentId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createClientSegment = async (
  segment:
    | {
        name: string;
        description: string;
        type: "DYNAMIC";
        sex: string;
        minAge: number;
        maxAge: number;
        averageTicket: number;
        purchaseChannelId: number;
        purchaseCountPeriod: number;
        purchaseCountLastDays: number;
        purchaseCountRange: number;
        purchaseCountStartDate: string;
        purchaseCountEndDate: string;
        totalSpent: number;
        birthdayStartDate: string;
        birthdayEndDate: string;
        programRegistrationStartDate: string;
        programRegistrationEndDate: string;
        active: boolean;
        articleIds: string[];
        skuId: string; //[]
        brandIds: string[];
        clientLevelId: string;
      }
    | {
        name: string;
        description: string;
        type: "STATIC";
        active: boolean;
        clientIds: string[];
      }
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createClientSegment(segment);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editClientSegment = async (
  id: number,
  segment:
    | {
        name: string;
        description: string;
        type: "DYNAMIC";
        sex: string;
        minAge: number;
        maxAge: number;
        averageTicket: number;
        purchaseChannelId: number;
        purchaseCountPeriod: number;
        purchaseCountLastDays: number;
        purchaseCountRange: number;
        purchaseCountStartDate: string;
        purchaseCountEndDate: string;
        totalSpent: number;
        birthdayStartDate: string;
        birthdayEndDate: string;
        programRegistrationStartDate: string;
        programRegistrationEndDate: string;
        active: boolean;
        articleIds: string[];
        skuId: string; //[]
        brandIds: string[];
        clientLevelId: string;
      }
    | {
        name: string;
        description: string;
        type: "STATIC";
        active: boolean;
        clientIds: string[];
      }
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editClientSegment(id, segment);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const cloneClientSegment = async (clientSegmentId: number) => {
  let ret = false;
  let segment;
  try {
    let response = await BrightMarketApi.cloneClientSegment(clientSegmentId);
    ret = response.data.status === StatusResponse.Ok;
    segment = response.data.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { success: ret, segment };
};

export const getClientSegmentById = async (clientSegmentId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getClientSegmentById(
      clientSegmentId
    );
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getClientsBySegmentId = async (
  clientSegmentId: string,
  pagination: number
) => {
  let ret;
  try {
    const response = await BrightMarketApi.getClientsBySegmentId(
      clientSegmentId,
      pagination
    );
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const addClientsToClientSegment = async (
  clientSegmentId: number,
  clientIds: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.addClientsToClientSegment(
      clientSegmentId,
      clientIds
    );
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteClientFromClientSegment = async (
  clientSegmentId: number,
  clientId: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.deleteClientFromClientSegment(
      clientSegmentId,
      clientId
    );
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getPromotions = async (pagination: number) => {
  let promotions: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getPromotions(
      organizationId,
      pagination
    );

    promotions = response.promotions.promotions;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { promotions, totalElements };
};

export const getPromotionsNoDate = async (pagination: number) => {
  let promotions: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getPromotionsNoDate(
      organizationId,
      pagination
    );

    promotions = response.promotions.promotions;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { promotions, totalElements };
};

export const getPromotionsByMonth = async (month: string) => {
  let promotions: any[] = [];

  try {
    const response = await BrightMarketApi.getPromotionsByMonth(month);

    promotions = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return promotions;
};

export const deletePromotion = async (promotionId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deletePromotion(promotionId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const cancelPromotion = async (promotionId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.cancelPromotion(promotionId);
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getPromotionById = async (promotionId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getPromotionById(promotionId);
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createPromotionBuyTogether = async (
  name: string,
  description: string,
  recurrence: any,
  startDate: any,
  endDate: any,
  discountFirstList: string,
  discountSecondList: string,
  skuIdFirstList: any,
  skuIdSecondList: any,
  branchOfficeIds: any,
  clientSegmentIds: any,
  categoryIds: any,
  brandIds: any,
  acceptedPaymentMethodIds: any,
  bines: any,
  binMin: number,
  binMax: number,
  minimumTicket: number,
  maximumTicket: number,
  quantityApplications: number,
  quantityPerArticle: number,
  quantityPerBranchOffice: number,
  quantityPerClient: number,
  accumulateOtherPromotions: boolean,
  distributeLines: boolean
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createPromotionBuyTogether(
      name,
      description,
      recurrence,
      startDate,
      endDate,
      discountFirstList,
      discountSecondList,
      skuIdFirstList,
      skuIdSecondList,
      branchOfficeIds,
      clientSegmentIds,
      categoryIds,
      brandIds,
      acceptedPaymentMethodIds,
      bines,
      binMin,
      binMax,
      minimumTicket,
      maximumTicket,
      quantityApplications,
      quantityPerArticle,
      quantityPerBranchOffice,
      quantityPerClient,
      accumulateOtherPromotions,
      distributeLines
    );
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createPromotionMxN = async (
  name: string,
  description: string,
  recurrence: any,
  startDate: any,
  endDate: any,
  skuIds: any,
  quantityForPromotion: string,
  quantityWithDiscount: string,
  discountPercent: string,
  allProducts: boolean,
  highPrice: boolean,
  forTotalArticle: boolean,
  branchOfficeIds: any,
  clientSegmentIds: any,
  categoryIds: any,
  brandIds: any,
  acceptedPaymentMethodIds: any,
  bines: any,
  binMin: number,
  binMax: number,
  minimumTicket: number,
  maximumTicket: number,
  quantityApplications: number,
  quantityPerArticle: number,
  quantityPerBranchOffice: number,
  quantityPerClient: number,
  accumulateOtherPromotions: boolean,
  distributeLines: boolean
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createPromotionMxN(
      name,
      description,
      recurrence,
      startDate,
      endDate,
      skuIds,
      quantityForPromotion,
      quantityWithDiscount,
      discountPercent,
      allProducts,
      highPrice,
      forTotalArticle,
      branchOfficeIds,
      clientSegmentIds,
      categoryIds,
      brandIds,
      acceptedPaymentMethodIds,
      bines,
      binMin,
      binMax,
      minimumTicket,
      maximumTicket,
      quantityApplications,
      quantityPerArticle,
      quantityPerBranchOffice,
      quantityPerClient,
      accumulateOtherPromotions,
      distributeLines
    );
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createPromotionRegular = async (promoInfo: {
  name: string;
  description: string;
  recurrence: any;
  startDate: any;
  endDate: any;
  regularPromotionType: string;
  branchOfficeIds: any;
  clientSegmentIds: any;
  categoryIds: any;
  brandIds: any;
  acceptedPaymentMethodIds: any;
  bines: any;
  binMin: number;
  binMax: number;
  skuIds: any;
  minimumTicket: number;
  maximumTicket: number;
  quantityApplications: number;
  quantityPerArticle: number;
  quantityPerBranchOffice: number;
  quantityPerClient: number;
  accumulateOtherPromotions: boolean;
  distributeLines: boolean;
  discountValue?: string;
  discountPercentage?: any;
  giftId?: string;
}) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createPromotionRegular(promoInfo);
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createPromotionBogo = async (
  name: string,
  description: string,
  recurrence: any,
  startDate: any,
  endDate: any,
  skuIds: any,
  discountType: string,
  giftId: string,
  branchOfficeIds: any,
  clientSegmentIds: any,
  categoryIds: any,
  brandIds: any,
  acceptedPaymentMethodIds: any,
  bines: any,
  binMin: number,
  binMax: number,
  minimumTicket: number,
  maximumTicket: number,
  quantityApplications: number,
  quantityPerArticle: number,
  quantityPerBranchOffice: number,
  quantityPerClient: number,
  accumulateOtherPromotions: boolean,
  distributeLines: boolean
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createPromotionBogo(
      name,
      description,
      recurrence,
      startDate,
      endDate,
      skuIds,
      discountType,
      giftId,
      branchOfficeIds,
      clientSegmentIds,
      categoryIds,
      brandIds,
      acceptedPaymentMethodIds,
      bines,
      binMin,
      binMax,
      minimumTicket,
      maximumTicket,
      quantityApplications,
      quantityPerArticle,
      quantityPerBranchOffice,
      quantityPerClient,
      accumulateOtherPromotions,
      distributeLines
    );
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createPromotionProgressive = async (
  name: string,
  description: string,
  recurrence: any,
  startDate: any,
  endDate: any,
  skuIds: any,
  lines: any,
  branchOfficeIds: any,
  clientSegmentIds: any,
  categoryIds: any,
  brandIds: any,
  acceptedPaymentMethodIds: any,
  bines: any,
  binMin: number,
  binMax: number,
  minimumTicket: number,
  maximumTicket: number,
  quantityApplications: number,
  quantityPerArticle: number,
  quantityPerBranchOffice: number,
  quantityPerClient: number,
  accumulateOtherPromotions: boolean,
  distributeLines: boolean
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createPromotionProgressive(
      name,
      description,
      recurrence,
      startDate,
      endDate,
      skuIds,
      lines,
      branchOfficeIds,
      clientSegmentIds,
      categoryIds,
      brandIds,
      acceptedPaymentMethodIds,
      bines,
      binMin,
      binMax,
      minimumTicket,
      maximumTicket,
      quantityApplications,
      quantityPerArticle,
      quantityPerBranchOffice,
      quantityPerClient,
      accumulateOtherPromotions,
      distributeLines
    );
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editPromotionRegular = async (
  id: number,
  promo: {
    promotion: {
      name: string;
      description: string;
      recurrence: any;
      startDate: any;
      endDate: any;
      branchOfficeIds: any;
      clientSegmentIds: any;
      categoryIds: any;
      brandIds: any;
      acceptedPaymentMethodIds: any;
      bines: any;
      binMin: number;
      binMax: number;
      minimumTicket: number;
      maximumTicket: number;
      quantityApplications: number;
      quantityPerArticle: number;
      quantityPerBranchOffice: number;
      quantityPerClient: number;
      accumulateOtherPromotions: boolean;
      distributeLines: boolean;
    };
    discountValue?: string;
    discountPercent?: any;
    giftId?: string;
    regularPromotionType: string;
    skuIds: any;
  }
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editPromotionRegular(id, promo);
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editPromotionBuyTogether = async (
  id: number,
  promo: {
    promotion: {
      name: string;
      description: string;
      recurrence: any;
      startDate: any;
      endDate: any;
      branchOfficeIds: any;
      clientSegmentIds: any;
      categoryIds: any;
      brandIds: any;
      acceptedPaymentMethodIds: any;
      bines: any;
      binMin: number;
      binMax: number;
      minimumTicket: number;
      maximumTicket: number;
      quantityApplications: number;
      quantityPerArticle: number;
      quantityPerBranchOffice: number;
      quantityPerClient: number;
      accumulateOtherPromotions: boolean;
      distributeLines: boolean;
    };
    discountFirstList: number;
    discountSecondList: number;
    skuIdFirstList: any;
    skuIdSecondList: any;
  }
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editPromotionBuyTogether(id, promo);
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editPromotionMxN = async (
  id: number,
  promo: {
    promotion: {
      name: string;
      description: string;
      recurrence: any;
      startDate: any;
      endDate: any;
      branchOfficeIds: any;
      clientSegmentIds: any;
      categoryIds: any;
      brandIds: any;
      acceptedPaymentMethodIds: any;
      bines: any;
      binMin: number;
      binMax: number;
      minimumTicket: number;
      maximumTicket: number;
      quantityApplications: number;
      quantityPerArticle: number;
      quantityPerBranchOffice: number;
      quantityPerClient: number;
      accumulateOtherPromotions: boolean;
      distributeLines: boolean;
    };
    discountPercent: number;
    quantityWithDiscount: number;
    quantityForPromotion: number;
    allProducts: boolean;
    forTotalArticles: boolean;
    highPrice: boolean;
    skuIds: any;
  }
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editPromotionMxN(id, promo);
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editPromotionProgressive = async (
  id: number,
  promo: {
    promotion: {
      name: string;
      description: string;
      recurrence: any;
      startDate: any;
      endDate: any;
      branchOfficeIds: any;
      clientSegmentIds: any;
      categoryIds: any;
      brandIds: any;
      acceptedPaymentMethodIds: any;
      bines: any;
      binMin: number;
      binMax: number;
      minimumTicket: number;
      maximumTicket: number;
      quantityApplications: number;
      quantityPerArticle: number;
      quantityPerBranchOffice: number;
      quantityPerClient: number;
      accumulateOtherPromotions: boolean;
      distributeLines: boolean;
    };
    lines: any;
    skuIds: any;
  }
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editPromotionProgressive(id, promo);
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editPromotionBogo = async (
  id: number,
  promo: {
    promotion: {
      name: string;
      description: string;
      recurrence: any;
      startDate: any;
      endDate: any;
      branchOfficeIds: any;
      clientSegmentIds: any;
      categoryIds: any;
      brandIds: any;
      acceptedPaymentMethodIds: any;
      bines: any;
      binMin: number;
      binMax: number;
      minimumTicket: number;
      maximumTicket: number;
      quantityApplications: number;
      quantityPerArticle: number;
      quantityPerBranchOffice: number;
      quantityPerClient: number;
      accumulateOtherPromotions: boolean;
      distributeLines: boolean;
    };
    skuIds: any;
    type: string;
    giftId: string;
  }
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editPromotionBogo(id, promo);
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getBrands = async (
  pagination: number,
  filters: { name: string; externalId: string }
) => {
  let brands: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getBrands(
      organizationId,
      pagination,
      filters
    );

    let items = response.brands;
    totalElements = response.totalElements;
    brands = items.brands;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { brands, totalElements };
};

export const deleteBrand = async (brandId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteBrand(brandId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createBrand = async (
  name: string,
  externalId: string,
  description: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createBrand(
      name,
      externalId,
      description
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editBrand = async (
  brandId: number,
  name: string,
  externalId: string,
  description: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editBrand(
      brandId,
      name,
      externalId,
      description
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getAllIdTypes = async () => {
  let idTypes: any[] = [];

  try {
    const response = await BrightMarketApi.getAllIdTypes();
    idTypes = response.idTypes;
  } catch (e) {
    console.log("We have the error", e);
  }
  return idTypes;
};

export const getAllSexTypes = async () => {
  let sexTypes: any[] = [];

  try {
    const response = await BrightMarketApi.getAllSexTypes();
    sexTypes = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return sexTypes;
};

export const getAllCountries = async () => {
  let countries: any[] = [];

  try {
    const response = await BrightMarketApi.getAllCountries();
    countries = response.countries;
  } catch (e) {
    console.log("We have the error", e);
  }
  return countries;
};

export const getAllCountriesWithExistingClients = async () => {
  let countries: any[] = [];
  const organizationId = await getOrganizationId();

  try {
    const response = await BrightMarketApi.getAllCountriesWithExistingClients(
      organizationId
    );
    countries = response.countries;
  } catch (e) {
    console.log("We have the error", e);
  }
  return countries;
};

export const getAllCitiesWithExistingClients = async () => {
  let cities: any[] = [];
  const organizationId = await getOrganizationId();

  try {
    const response = await BrightMarketApi.getAllCitiesWithExistingClients(
      organizationId
    );
    cities = response.states;
  } catch (e) {
    console.log("We have the error", e);
  }
  return cities;
};

export const getAllStatesByCountry = async (countryId: number) => {
  let countries: any[] = [];

  try {
    const response = await BrightMarketApi.getAllStatesByCountry(countryId);
    countries = response.states;
  } catch (e) {
    console.log("We have the error", e);
  }
  return countries;
};

export const getTickets = async (
  pagination: number,
  filters: {
    code?: string;
    branchOfficeId?: number;
    startDate?: string;
    endDate?: string;
    skuIds?: number[];
    minTotal?: number;
    maxTotal?: number;
  }
) => {
  let tickets: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getTickets(
      organizationId,
      pagination,
      filters
    );

    tickets = response.tickets.tickets;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { tickets, totalElements };
};

export const getTicketsByFidelity = async (
  pagination: number,
  fidelity: string
) => {
  let tickets: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getTicketsByFidelity(
      organizationId,
      pagination,
      fidelity
    );

    tickets = response.tickets.tickets;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { tickets, totalElements };
};

export const getTicketById = async (ticketId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getTicketById(ticketId);
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteTicket = async (promotionId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteTicket(promotionId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getAllTicketDocumentTypes = async () => {
  let documentTypes: any[] = [];

  try {
    const response = await BrightMarketApi.getAllTicketDocumentTypes();
    documentTypes = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return documentTypes;
};

export const createTicket = async (ticketInfo: any) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createTicket(ticketInfo);
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const schedulePromotion = async (
  id: number,
  startDate: any,
  endDate: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.schedulePromotion(
      id,
      startDate,
      endDate
    );
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getAllRecurrencesTypes = async () => {
  let recurrencesTypes: any[] = [];

  try {
    const response = await BrightMarketApi.getAllRecurrencesTypes();
    recurrencesTypes = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return recurrencesTypes;
};

export const getPricings = async (pagination: number) => {
  let pricings: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getPricings(
      organizationId,
      pagination
    );

    pricings = response.pricings.pricings;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { pricings, totalElements };
};

export const deletePricing = async (pricingId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deletePricing(pricingId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getPricingsByMonth = async (month: string) => {
  let promotions: any[] = [];

  try {
    const response = await BrightMarketApi.getPricingsByMonth(month);

    promotions = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return promotions;
};

export const schedulePricing = async (
  id: number,
  startDate: any,
  endDate: any
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.schedulePricing(
      id,
      startDate,
      endDate
    );
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getAllPricingTypes = async () => {
  let types: any[] = [];

  try {
    const response = await BrightMarketApi.getAllPricingTypes();
    types = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return types;
};

export const getAllEndPricingTypes = async () => {
  let types: any[] = [];

  try {
    const response = await BrightMarketApi.getAllEndPricingTypes();
    types = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return types;
};

export const createPricing = async (pricing: any) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createPricing(pricing);
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getPricingsNoDate = async (pagination: number) => {
  let pricings: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getPricingsNoDate(
      organizationId,
      pagination
    );

    pricings = response.pricings.pricings;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { pricings, totalElements };
};

export const getPricingById = async (pricingId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getPricingById(pricingId);
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getPricingTimeLineById = async (pricingId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getPricingTimeLineById(pricingId);
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editPricing = async (id: number, pricing: any) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editPricing(id, pricing);
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getSupplierPaymentMethods = async (pagination: number) => {
  const supplierPaymentMethods: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getSupplierPaymentMethods(
      organizationId,
      pagination
    );

    let items = response.supplierPaymentMethods;
    totalElements = response.totalElements;

    items.supplierPaymentMethods.forEach(function (supplierPaymentMethod: any) {
      supplierPaymentMethods.push(supplierPaymentMethod);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return { supplierPaymentMethods, totalElements };
};

export const createSupplierPaymentMethod = async (paymentMethod: {
  name: string;
  paymentDeadlineDays: number;
  typeId?: number;
}) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createSupplierPaymentMethod(
      paymentMethod
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteSupplierPaymentMethod = async (id: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteSupplierPaymentMethod(id);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editSupplierPaymentMethod = async (
  id: number,
  name: string,
  paymentDeadlineDays: number,
  typeId?: number
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editSupplierPaymentMethod(
      id,
      name,
      paymentDeadlineDays,
      typeId
    );
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getAllSuppliersPaymentMethods = async () => {
  let supplierPaymentMethods: any[] = [];

  try {
    const response = await BrightMarketApi.getAllSupplierPaymentMethods();
    const items = response.data;
    supplierPaymentMethods = items;
  } catch (e) {
    console.log("We have the error", e);
  }
  return supplierPaymentMethods;
};

export const getPurchaseUnits = async (pagination: number) => {
  const purchaseUnits: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getPurchaseUnits(
      organizationId,
      pagination
    );

    let items = response.purchaseUnits;
    totalElements = response.totalElements;

    items.purchaseUnits.forEach(function (purchaseUnit: any) {
      purchaseUnits.push(purchaseUnit);
    });
  } catch (e) {
    console.log("We have the error", e);
  }
  return { purchaseUnits, totalElements };
};

export const createPurchaseUnit = async (
  purchaseUnit: string,
  conversionFactor: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createPurchaseUnit(
      purchaseUnit,
      conversionFactor
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deletePurchaseUnit = async (purchaseUnitId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deletePurchaseUnit(purchaseUnitId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editPurchaseUnit = async (
  purchaseUnitId: number,
  purchaseUnit: string,
  conversionFactor: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editPurchaseUnit(
      purchaseUnitId,
      purchaseUnit,
      conversionFactor
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const discontinueArticle = async (articleId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.discontinueArticle(articleId);
    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const discontinueBranchOfficeArticle = async (
  branchOfficeId: number,
  articleId: number
) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.discontinueBranchOfficeArticle(
      branchOfficeId,
      articleId
    );
    ret = response.status === 200;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getExcelTemplate = async (type: string) => {
  let ret: { success: boolean; data?: any; filename?: string } = {
    success: false,
  };

  try {
    let response = await BrightMarketApi.getExcelTemplate(type);

    ret = {
      success: response.status === 200,
      data: response.data,
      filename: response.headers["content-disposition"]
        .split('filename="')[1]
        .split('"')[0],
    };
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const processExcelTemplate = async (type: string, file: any) => {
  let ret: { success: boolean; data?: any; filename?: string } = {
    success: false,
  };

  try {
    let response = await BrightMarketApi.processExcelTemplate(type, file);
    console.log(response);
    ret = {
      success: response.status === 200 && response.data.data.added > 0,
      data: response.data.data,
    };
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getNotifications = async (pagination: number) => {
  let notifications: any[] = [];
  let totalElements = 0;

  try {
    const response = await BrightMarketApi.getNotifications(pagination);

    notifications = response.notifications;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { notifications, totalElements };
};

export const readNotification = async (notificationId: number) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.readNotification(notificationId);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const unreadNotification = async (notificationId: number) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.unreadNotification(notificationId);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getSeasons = async (pagination: number) => {
  let seasons: any[] = [];
  let totalElements = 0;

  const organizationId = await getOrganizationId();

  try {
    const response = await BrightMarketApi.getSeasons(
      pagination,
      organizationId
    );

    seasons = response.seasons;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { seasons, totalElements };
};

export const getSeasonsFiltered = async (name: string) => {
  let categories = [];

  try {
    const response = await BrightMarketApi.getSeasonsFiltered(name);
    categories = response.data.content;
  } catch (e) {
    console.log("We have the error", e);
  }
  return categories;
};

export const getSeasonById = async (seasonId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getSeasonById(seasonId);
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};
export const editSeason = async (
  seasonId: string,
  name: string,
  startingDate: string,
  endingDate: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editSeason(
      seasonId,
      name,
      startingDate,
      endingDate
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createSeason = async (
  name: string,
  startingDate: string,
  endingDate: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createSeason(
      name,
      startingDate,
      endingDate
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteSeason = async (id: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteSeason(id);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getCommercialDates = async (pagination: number) => {
  let commercialDates: any[] = [];
  let totalElements = 0;

  const organizationId = await getOrganizationId();

  try {
    const response = await BrightMarketApi.getCommercialDates(
      pagination,
      organizationId
    );

    commercialDates = response.commercialDates;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { commercialDates, totalElements };
};

export const createCommercialDates = async (
  name: string,
  startingDate: string,
  endingDate: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createCommercialDates(
      name,
      startingDate,
      endingDate
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getCommercialDateById = async (commercialDateId: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getCommercialDateById(
      commercialDateId
    );
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editCommercialDate = async (
  commercialDateId: string,
  name: string,
  startingDate: string,
  endingDate: string
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editCommercialDate(
      commercialDateId,
      name,
      startingDate,
      endingDate
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteCommercialDate = async (id: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteCommercialDate(id);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getStockMovements = async (filters: {
  pagination: number;
  businessUnitId: string;
  startDate?: string | null;
  endDate?: string | null;
  articleId?: string | null;
  type?: string | null;
  branchOfficeId?: string;
  depositId?: string;
}) => {
  let stockMovements: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getStockMovements(
      organizationId,
      filters
    );

    totalElements = response.totalElements;
    stockMovements = response.stockMovements;
  } catch (e) {
    console.log("We have the error", e);
  }

  return { stockMovements, totalElements };
};

export const getMovementStockTypes = async () => {
  let types: any[] = [];

  try {
    const response = await BrightMarketApi.getMovementStockTypes();
    types = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return types;
};

export const createStockMovements = async (movement: {
  skuBranchOfficeId?: number;
  skuDepositId?: number;
  stock: number;
  reason: string;
  date: string;
  type: string;
}) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createStockMovements(movement);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createColour = async (name: string) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createColour(name);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getColours = async (
  pagination: number,
  filters: { name: string }
) => {
  let colours: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getColours(
      organizationId,
      pagination,
      filters
    );

    colours = response.colours;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { colours, totalElements };
};

export const getColoursByName = async (name: string) => {
  let totalElements = 0;
  let colours = [];

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getColoursByName(
      name,
      organizationId
    );

    colours = response.colours;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { colours, totalElements };
};

export const deleteColour = async (id: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteColour(id);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editColour = async (id: number, name: string) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editColour(id, name);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editCluster = async (id: number, name: string) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editCluster(id, {
      name,
    });

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getClusters = async (pagination: number) => {
  let clusters: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getClusters(
      organizationId,
      pagination
    );

    clusters = response.clusters;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { clusters, totalElements };
};

export const addDepositToCluster = async (
  clusterId: number,
  depositId: number
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.addDepositToCluster(
      clusterId,
      depositId
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};
export const addBranchOfficeToCluster = async (
  clusterId: number,
  branchOfficeId: number
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.addBranchOfficeToCluster(
      clusterId,
      branchOfficeId
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deletePlaceFromCluster = async (placeId: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deletePlaceFromCluster(placeId);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createCluster = async (cluster: {
  name: string;
  depositsIds: any;
  branchOfficesIds: any;
}) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createCluster(cluster);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteCluster = async (id: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteCluster(id);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getClusterById = async (id: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getClusterById(id);
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getVehicles = async (
  pagination: number,
  filters: { name?: string }
) => {
  let vehicles: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getVehicles(
      organizationId,
      pagination,
      filters
    );

    vehicles = response.vehicles;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { vehicles, totalElements };
};

export const deleteVehicle = async (id: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteVehicle(id);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createVehicle = async (vehicle: {
  name: string;
  code: string;
  brand: string;
  model: string;
  licensePlate: string;
  type: string;
  maxCapacity: number;
  fuelConsumption: number;
}) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createVehicle(vehicle);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getVehicleById = async (id: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getVehicleById(id);
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteDepositFromVehicle = async (id: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteDepositFromVehicle(id);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteBranchOfficeFromVehicle = async (id: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteBranchOfficeFromVehicle(id);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteScheduleFromVehicle = async (
  vehicleId: string,
  scheduleId: number
) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteScheduleFromVehicle(
      vehicleId,
      scheduleId
    );
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const addDepositToVehicle = async (
  vehicleId: number,
  depositId: number
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.addDepositToVehicle(
      vehicleId,
      depositId
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const addBranchOfficeToVehicle = async (
  vehicleId: number,
  branchOfficeId: number
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.addBranchOfficeToVehicle(
      vehicleId,
      branchOfficeId
    );

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const addScheduleToVehicle = async (schedule: {
  vehicleId: number;
  code: number;
  description: string;
  branchOfficeIdFrom: number | null;
  branchOfficeIdTo: number | null;
  depositIdFrom: number | null;
  depositIdTo: number | null;
  recurrence: any;
}) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.addScheduleToVehicle(schedule);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editVehicle = async (
  id: number,
  vehicle: {
    name: string;
    code: string;
    brand: string;
    model: string;
    licensePlate: string;
    type: string;
    maxCapacity: string;
    fuelConsumption: string;
  }
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editVehicle(id, vehicle);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createCurve = async (curve: {
  name: string;
  sizeStart: number;
  sizeEnd: number[];
  distribution: number[];
}) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createCurve(curve);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getCurves = async (
  pagination: number,
  filters: { name?: string }
) => {
  let curves: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getCurves(
      organizationId,
      pagination,
      filters
    );

    curves = response.curves;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { curves, totalElements };
};

export const deleteCurve = async (id: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteCurve(id);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getCurveById = async (id: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getCurveById(id);
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editCurve = async (
  id: number,
  curve: {
    name: string;
    sizeStart: number;
    sizeEnd: number[];
    distribution: number[];
  }
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editCurve(id, curve);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const addCurveToArticle = async (articleId: number, curveId: number) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.addCurveToArticle(articleId, curveId);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const deleteCurveFromArticle = async (
  articleId: number,
  curveId: number
) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteCurveFromArticle(
      articleId,
      curveId
    );
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getHistoricalStockBranchOffice = async (
  branchOfficeId: string,
  pagination: number,
  filters: { startDate?: string; endDate?: string }
) => {
  let stocks: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getHistoricalStockBranchOffice(
      organizationId,
      branchOfficeId,
      pagination,
      filters
    );

    stocks = response.stocks;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { stocks, totalElements };
};

export const getHistoricalStockDeposit = async (
  depositId: string,
  pagination: number,
  filters: { startDate?: string; endDate?: string }
) => {
  let stocks: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getHistoricalStockDeposit(
      organizationId,
      depositId,
      pagination,
      filters
    );

    stocks = response.stocks;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { stocks, totalElements };
};

export const ruleCreateRepositionOrdersForStockMin = async () => {
  let ret = false;
  try {
    let response =
      await BrightMarketApi.ruleCreateRepositionOrdersForStockMin();

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const ruleCreateRepositionOrdersForStock0 = async () => {
  let ret = false;
  try {
    let response = await BrightMarketApi.ruleCreateRepositionOrdersForStock0();

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getClientLevels = async (
  pagination: number,
  filters: { name?: string; level?: string }
) => {
  let clientLevels: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getClientLevels(
      organizationId,
      pagination,
      filters
    );

    clientLevels = response.clientLevels;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { clientLevels, totalElements };
};

export const deleteClientLevel = async (id: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteClientLevel(id);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createClientLevel = async (clientLevel: {
  name: string;
  level: number;
  description: string;
  ruleType: string;
  ruleQuantity: number;
  accumulationFactor: number;
  active: boolean;
}) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createClientLevel(clientLevel);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getClientLevelById = async (id: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getClientLevelById(id);
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editClientLevel = async (
  id: number,
  clientLevel: {
    name: string;
    level: number;
    description: string;
    ruleType: string;
    ruleQuantity: number;
    accumulationFactor: number;
    active: boolean;
  }
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editClientLevel(id, clientLevel);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getArticleGroups = async (
  pagination: number,
  filters: { name?: string }
) => {
  let articleGroups: any[] = [];
  let totalElements = 0;

  try {
    const organizationId = await getOrganizationId();

    const response = await BrightMarketApi.getArticleGroups(
      organizationId,
      pagination,
      filters
    );

    articleGroups = response.articleGroups;
    totalElements = response.totalElements;
  } catch (e) {
    console.log("We have the error", e);
  }
  return { articleGroups, totalElements };
};

export const deleteArticleGroup = async (id: number) => {
  let ret = false;

  try {
    let response = await BrightMarketApi.deleteArticleGroup(id);
    ret = response.status === 204;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const createArticleGroup = async (articleGroup: {
  name: string;
  description: string;
  categoryId: string;
  brandId: string;
  branchOfficeId: string;
  articleId: string;
  state: boolean;
}) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.createArticleGroup(articleGroup);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getArticleGroupById = async (id: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getArticleGroupById(id);
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const getArticleGroupCountById = async (id: string) => {
  let ret;
  try {
    const response = await BrightMarketApi.getArticleGroupCountById(id);
    ret = response.data;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};

export const editArticleGroup = async (
  id: number,
  articleGroup: {
    name: string;
    description: string;
    categoryId: string;
    brandId: string;
    branchOfficeId: string;
    articleId: string;
    state: boolean;
  }
) => {
  let ret = false;
  try {
    let response = await BrightMarketApi.editArticleGroup(id, articleGroup);

    ret = response.data.status === StatusResponse.Ok;
  } catch (e) {
    console.log("We have the error", e);
  }
  return ret;
};
