import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  editArticleGroup,
  getArticleGroupById,
  getArticleGroupCountById,
  getArticlesFiltered,
  getBranchOfficesFiltered,
  getBrandsFiltered,
  getCategoriesFiltered,
} from "../../../api/Services";
import {
  hasPermission,
  privilegesEnum,
} from "../../../helpers/PermissionHelper";
import {
  Form,
  Input,
  Button,
  message,
  Spin,
  Select,
  Switch,
  InputNumber,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./ViewArticleGroup.module.scss";

const ViewArticleGroup = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const [articleGroup, setArticleGroup] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [articleOptions, setArticleOptions] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [branchOffices, setBranchOffices] = useState<any[]>([]);

  const handleSearchCategories = async (value: string) => {
    const response = await getCategoriesFiltered(value);
    setCategories(response);
  };

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
        ? item.parents.filter(Boolean).join(" - ")
        : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
        ? `${parentsString} - ${categoryName}`
        : categoryName;

      options.push(
        <Select.Option value={item.id} key={item.id}>
          {categoryTree}
        </Select.Option>
      );
    });

    return options;
  };

  const handleSearchBrands = async (value: string) => {
    const response = await getBrandsFiltered(value);
    setBrands(response);
  };

  const handleSearchBranchOffices = async (value: string) => {
    const response = await getBranchOfficesFiltered(0, {
      name: value,
    });
    setBranchOffices(response.branchOffices);
  };
  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const [editForm] = Form.useForm();

  const canEdit = hasPermission(privilegesEnum.WRITE_ARTICLE_GROUP);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_ARTICLE_GROUP)) {
      handleFetchArticleGroup();
      handleFetchCount();
    }
  }, []);

  const handleFetchCount = async () => {
    const response = await getArticleGroupCountById(id!);
    editForm.setFieldsValue({ quantity: response });
  };

  const handleFetchArticleGroup = async () => {
    const response = await getArticleGroupById(id!);

    if (response) {
      setArticleGroup(response);
      handleSearchBrands(response.brand ? response.brand.name : "");
      handleSearchCategories(response.category ? response.category.name : "");
      handleSearchArticles(response.article ? response.article.name : "");
      handleSearchBranchOffices(
        response.branchOffice ? response.branchOffice.name : ""
      );
      setLoading(false);
    }
  };

  const handleSearchArticles = async (value: string) => {
    setLoadingArticles(true);
    const response = await getArticlesFiltered(0, { articleName: value });
    const options = response.articles.map((item: any) => ({
      value: item.id,
      text: item.name,
    }));
    setArticleOptions(options);
    setLoadingArticles(false);
  };

  const handleEdit = async () => {
    await editForm.validateFields().then(async (values) => {
      setLoading(true);
      const {
        name,
        description,
        state,
        categoryId,
        brandId,
        branchOfficeId,
        articleId,
      } = values;
      let response = await editArticleGroup(parseInt(id!), {
        name,
        description,
        state,
        categoryId,
        brandId,
        branchOfficeId,
        articleId,
      });

      if (response) {
        message.success(t("Common-EditSuccessMessage"));
      } else message.error(t("Common-EditErrorMessage"));

      await handleFetchArticleGroup();
      setLoading(false);
    });
  };
  const handleCancelButton = () => {
    editForm.resetFields();
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-ArticleGroup")}</h1>
        {canEdit && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEdit}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.infoWrapper}>
          <Form
            name="articleGroup"
            layout="vertical"
            form={editForm}
            autoComplete="off"
            className={styles.form}
            preserve={false}
          >
            <Form.Item
              label={t("Common-QuantityArticles")}
              name="quantity"
              className={styles.itemWrapper}
            >
              <InputNumber disabled />
            </Form.Item>
            <Form.Item
              initialValue={articleGroup?.name}
              label={t("Common-Name")}
              name="name"
              className={styles.itemWrapper}
              rules={[
                {
                  required: true,
                  message: t("Common-Name"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item
              initialValue={articleGroup?.description}
              label={t("Common-Description")}
              name="description"
              rules={[
                {
                  required: true,
                  message: t("Common-Description"),
                },
              ]}
            >
              <Input placeholder={t("Common-Description")} />
            </Form.Item>
            <Form.Item
              initialValue={articleGroup?.state}
              label={t("Common-Active")}
              name="state"
              rules={[
                {
                  required: true,
                  message: t("Common-Active"),
                },
              ]}
            >
              <Switch defaultChecked={articleGroup?.state} />
            </Form.Item>
            <Form.Item
              label={t("Common-Category")}
              name="categoryId"
              className={styles.formItem}
              initialValue={articleGroup.category?.id}
            >
              <Select
                showSearch
                allowClear
                style={{ width: "100%" }}
                onSearch={handleSearchCategories}
                placeholder={t("Common-SearchByName")}
                // defaultValue={articleGroup.category?.id}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {getOptionsCategories(categories)}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("Common-Brand")}
              initialValue={articleGroup.brand?.id}
              name="brandId"
            >
              <Select
                allowClear
                showSearch
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ width: "100%" }}
                placeholder={t("Common-SearchByName")}
                onSearch={handleSearchBrands}
              >
                {getOptions(brands)}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("Common-BranchOffice")}
              name="branchOfficeId"
              initialValue={articleGroup.branchOffice?.id}
            >
              <Select
                allowClear
                showSearch
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ width: "100%" }}
                placeholder={t("Common-SearchByName")}
                onSearch={handleSearchBranchOffices}
              >
                {getOptions(branchOffices)}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("Common-Articles")}
              name="articleId"
              initialValue={articleGroup.article?.id}
            >
              <Select
                showSearch
                allowClear
                placeholder={t("Common-SearchArticleTextPlaceholder")}
                loading={loadingArticles}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ width: "100%" }}
                onSearch={handleSearchArticles}
                options={(articleOptions || []).map((d: any) => ({
                  value: d.value,
                  label: d.text,
                }))}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default ViewArticleGroup;
