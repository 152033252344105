import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  getClientById,
  editClient,
  getAllIdTypes,
  getAllCountries,
  getAllSexTypes,
  getAllStatesByCountry,
  getTicketsByFidelity,
  getClientStatsByFidelity,
  getClientMovementsById,
  addMovementToClient,
  setPointsToClient,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Form,
  message,
  Select,
  Button,
  Input,
  Spin,
  DatePicker,
  Switch,
  Table,
  Space,
  Typography,
  Modal,
  InputNumber,
} from "antd";
import {
  EditOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./ViewClient.module.scss";
import NotFound from "../notfound/NotFound";

const ViewClient = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [idTypes, setIdTypes] = useState<any[]>([]);
  const [sexTypes, setSexTypes] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  const [statesByCountry, setStatesByCountry] = useState<any[]>([]);
  const [client, setClient] = useState<any>();
  const [tickets, setTickets] = useState<any[]>([]);
  const [actualPageTickets, setActualPageTickets] = useState(1);
  const [totalTickets, setTotalTickets] = useState(0);
  const [fidelity, setFidelity] = useState("");
  const [clientStats, setClientStats] = useState<null | {
    lastPurchaseDate: string;
    daysSinceLastPurchase: number;
    averageSpend: number;
    totalSpend: number;
    purchaseCount: number;
    returnCount: number;
    totalReturns: number;
  }>();
  const [loadingTickets, setLoadingTickets] = useState(true);
  const [clientMovements, setClientMovements] = useState<any[]>([]);
  const [loadingMovements, setLoadingMovements] = useState(true);
  const [isCreateMovementModalVisible, setIsCreateMovementModalVisible] =
    useState(false);
  const [isEditUnitsModalVisible, setIsEditUnitsModalVisible] = useState(false);
  const [totalSizeMovements, setTotalSizeMovements] = useState(0);
  const [actualPageMovements, setActualPageMovements] = useState(1);

  const [infoForm] = Form.useForm();
  const [addMovementForm] = Form.useForm();
  const [unitsForm] = Form.useForm();

  const canEdit = hasPermission(privilegesEnum.WRITE_CLIENTS);

  const { id } = useParams();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CLIENTS)) {
      handleFetchClient();
      handleFetchCountries();
      handleFetchIdTypes();
      handleFetchSexTypes();
    }
  }, []);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CLIENTS)) {
      handleFetchTickets(actualPageTickets);
      handleFetchStats();
    }
  }, [fidelity, actualPageTickets]);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CLIENTS)) {
      handleFetchClientMovements(actualPageMovements);
    }
  }, [actualPageMovements]);

  const handleFetchClient = async () => {
    setLoading(true);

    let response = await getClientById(id!);
    setClient(response);
    if (response.fidelity) setFidelity(response.fidelity);

    setLoading(false);
  };

  const handleFetchClientMovements = async (page: number) => {
    setLoadingMovements(true);
    let response = await getClientMovementsById(id!, actualPageMovements);
    setClientMovements(response.points);
    setLoadingMovements(false);
    setActualPageMovements(page);
    setTotalSizeMovements(response.totalElements);
  };
  const handleFetchStats = async () => {
    if (fidelity) {
      const response = await getClientStatsByFidelity(fidelity);
      setClientStats(response);
    }
  };

  const handleFetchTickets = async (page: number) => {
    if (fidelity) {
      setLoadingTickets(true);
      const response = await getTicketsByFidelity(page, fidelity);
      setTickets(response.tickets);
      setTotalTickets(response.totalElements);
      setActualPageTickets(page);
      setLoadingTickets(false);
    }
  };

  const handleFetchIdTypes = async () => {
    const response = await getAllIdTypes();
    setIdTypes(response);
  };

  const handleFetchCountries = async () => {
    const response = await getAllCountries();
    setCountries(response);
  };

  const handleFetchSexTypes = async () => {
    const response = await getAllSexTypes();
    setSexTypes(response);
  };

  const getIdTypesOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.descriptionEs}
        </Select.Option>
      );
    });
    return options;
  };

  const getCountriesOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });
    return options;
  };

  const handleFetchStatesByCountry = async (countryId: number) => {
    const response = await getAllStatesByCountry(countryId);
    setStatesByCountry(response);
  };

  const getSexTypesOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.value} key={item.value}>
          {item.description_es}
        </Select.Option>
      );
    });

    return options;
  };

  const handleAddMovement = async () => {
    await addMovementForm.validateFields().then(async (movement) => {
      const response = await addMovementToClient(parseInt(id!), movement);

      if (response) {
        addMovementForm.resetFields();
        await handleFetchClientMovements(1);
        setIsCreateMovementModalVisible(false);
        message.success(t("Common-AddSuccessMessage"));
      } else message.error(t("Common-ErrorMessage"));
    });
  };

  const handleEditUnits = async () => {
    await unitsForm.validateFields().then(async (units) => {
      const response = await setPointsToClient(parseInt(id!), units);
      if (response) {
        unitsForm.resetFields();
        setIsEditUnitsModalVisible(false);
        await handleFetchClient();
        message.success(t("Common-EditSuccessMessage"));
      } else message.error(t("Common-ErrorMessage"));
    });
  };

  const handleCancelButton = () => {
    infoForm.resetFields();
  };

  const handleEdit = async () => {
    setLoading(true);

    const data = infoForm.getFieldsValue();
    data.id = parseInt(id!);
    data.birthdate = data.birthdate
      ? data.birthdate.format("YYYY-MM-DD")
      : null;

    const response = await editClient(data);

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
      await handleFetchClient();
    } else message.error(t("Common-EditErrorMessage"));
    setLoading(false);
  };

  const clientSegmentsColumn = [
    {
      title: t("Common-Name"),
      key: "name",
      render: (segment: any) => (
        <a href={`/promotions/clientsegments/${segment.id}/view`}>
          {segment.name}
        </a>
      ),
    },
    {
      title: t("Common-Description"),
      dataIndex: "description",
    },
    {
      title: t("Common-Type"),
      key: "type",
      render: (segment: any) => segment.type.description_es,
    },
  ];

  const ticketColumns = [
    {
      title: t("Common-Date"),
      dataIndex: "date",
      key: "date",
      render: (date: any) => moment(date, "YYYY-MM-DD").format("DD-MM-YYYY"),
    },
    {
      title: t("Common-Code"),
      dataIndex: "code",
      key: "code",
    },
    {
      title: t("Common-Type"),
      render: (ticket: any) => ticket.documentType.description_es,
      key: "documentType",
    },
    {
      title: t("Common-BranchOffice"),
      render: (ticket: any) => (
        <a
          href={`/admin/branchoffices/${ticket.salePoint.branchOffice?.id}/view`}
        >
          {ticket.salePoint.branchOffice?.name}
        </a>
      ),
      key: "branchOffice",
    },
    {
      title: t("Common-Client"),
      dataIndex: "fidelity",
      key: "fidelity",
    },
    {
      title: t("Common-Total"),
      dataIndex: "total",
      key: "total",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {hasPermission(privilegesEnum.WRITE_TICKETS) ? (
            <EditOutlined
              href={`/admin/tickets/${record.id}/view`}
              onClick={() => navigate(`/promotions/tickets/${record.id}/view`)}
            />
          ) : (
            <EyeOutlined
              href={`/admin/tickets/${record.id}/view`}
              onClick={() => navigate(`/promotions/tickets/${record.id}/view`)}
            />
          )}
        </Space>
      ),
    },
  ];

  const movementsColumns = [
    {
      title: t("Common-Type"),
      dataIndex: "type",
      render: (type: string) => movementTypeText(type),
    },
    {
      title: t("Common-CreatedDate"),
      dataIndex: "createdAt",
      render: (date: string) => date?.split("T")[0],
    },
    {
      title: t("Common-Points"),
      dataIndex: "pointsMoved",
    },
    {
      title: t("Common-Comments"),
      dataIndex: "comments",
    },
  ];

  const movementTypeText = (term: string) => {
    const translations: Record<string, string> = {
      ACCUMULATION: t("Common-Accumulation"),
      CONSUMPTION: t("Common-Consumption"),
      ADJUSTMENT: t("Common-Adjustment"),
    };

    return translations[term.toUpperCase()] || "-";
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !client ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Client")}</h1>
        {hasPermission(privilegesEnum.WRITE_CLIENTS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEdit}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.infoWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-PersonalInfo")} `}</h1>

          <Form
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={infoForm}
          >
            <Form.Item
              className={styles.newItem}
              label={t("Common-Identifier")}
              name="documentNumber"
              initialValue={client?.idNumber}
              rules={[
                {
                  required: true,
                  message: t("Common-Identifier"),
                },
              ]}
            >
              <Input
                placeholder={t("Common-DocumentNumber")}
                disabled={!canEdit}
              />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-FidelityId")}
              name="fidelity"
              initialValue={client?.fidelity}
              rules={[
                {
                  required: true,
                  message: t("Common-FidelityId"),
                },
              ]}
            >
              <Input placeholder={t("Common-FidelityId")} disabled={!canEdit} />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-DocumentType")}
              name="documentType"
              initialValue={client?.idType?.id}
            >
              <Select
                defaultValue={client?.idType?.id}
                placeholder={t("Common-DocumentType")}
                allowClear
                disabled={!canEdit}
              >
                {getIdTypesOptions(idTypes)}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-Sex")}
              name="sex"
              initialValue={client?.sex.value}
            >
              <Select
                placeholder={t("Common-Sex")}
                allowClear
                defaultValue={client?.sex}
                disabled={!canEdit}
              >
                {getSexTypesOptions(sexTypes)}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-Birthdate")}
              name="birthdate"
              initialValue={moment(client?.birthdate, "YYYY-MM-DD")}
            >
              <DatePicker
                className={styles.dateSelector}
                defaultValue={moment(client?.birthdate, "YYYY-MM-DD")}
                disabled={!canEdit}
                // onChange={handleChangeDate}
              />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-Country")}
              name="country"
              initialValue={client?.country?.id}
            >
              <Select
                placeholder={t("Common-Country")}
                defaultValue={client?.country?.id}
                disabled={!canEdit}
                showSearch
                optionFilterProp="children"
                onChange={(value) => {
                  handleFetchStatesByCountry(value);
                }}
              >
                {getCountriesOptions(countries)}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-City")}
              name="city"
              initialValue={client?.state?.id}
            >
              <Select
                placeholder={t("Common-City")}
                defaultValue={client?.state?.id}
                onChange={(value) => {
                  handleFetchStatesByCountry(value);
                }}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
                optionFilterProp="children"
                disabled={!canEdit}
              >
                {getCountriesOptions(statesByCountry)}
              </Select>
            </Form.Item>
            <Form.Item
              initialValue={client?.active}
              label={t("Common-Active")}
              name="active"
              rules={[
                {
                  required: true,
                  message: t("Common-Active"),
                },
              ]}
            >
              <Switch defaultChecked={client?.active} />
            </Form.Item>
          </Form>
        </div>
        <div className={styles.infoWrapper}>
          <h1 className={styles.subTitle}>{"Métricas del cliente"}</h1>

          <div className={styles.tableWrapper}>
            <h1 className={styles.subTitle}>{t("Common-Segments")}</h1>

            <Table
              size="small"
              className={styles.table}
              loading={loading}
              rowKey={(record) => record.id}
              columns={clientSegmentsColumn}
              dataSource={client?.segments}
            />
          </div>
        </div>
        <div className={styles.infoWrapper}>
          <h1 className={styles.subTitle}>{t("Common-PurchaseInfo")}</h1>
          <div className={styles.statsWrapper}>
            <div className={styles.statItem}>
              <Typography.Text className={styles.label}>
                {t("Common-DateLastPurchase")}:
              </Typography.Text>
              <Typography.Text className={styles.value}>
                {clientStats?.lastPurchaseDate ?? "-"}
              </Typography.Text>
            </div>

            <div className={styles.statItem}>
              <Typography.Text className={styles.label}>
                {t("Common-DaysSinceLastPurchase")}:
              </Typography.Text>
              <Typography.Text className={styles.value}>
                {clientStats?.daysSinceLastPurchase ?? "-"}
              </Typography.Text>
            </div>

            <div className={styles.statItem}>
              <Typography.Text className={styles.label}>
                {t("Common-SpentAverage")}:
              </Typography.Text>
              <Typography.Text className={styles.value}>
                {clientStats?.averageSpend ?? "-"}
              </Typography.Text>
            </div>
            <div className={styles.statItem}>
              <Typography.Text className={styles.label}>
                {t("Common-PurchaseCount")}:
              </Typography.Text>
              <Typography.Text className={styles.value}>
                {clientStats?.purchaseCount ?? "-"}
              </Typography.Text>
            </div>
            <div className={styles.statItem}>
              <Typography.Text className={styles.label}>
                {t("Common-TotalSpent")}:
              </Typography.Text>
              <Typography.Text className={styles.value}>
                {clientStats?.totalSpend ?? "-"}
              </Typography.Text>
            </div>
            <div className={styles.statItem}>
              <Typography.Text className={styles.label}>
                {t("Common-ReturnCount")}:
              </Typography.Text>
              <Typography.Text className={styles.value}>
                {clientStats?.returnCount ?? "-"}
              </Typography.Text>
            </div>
            <div className={styles.statItem}>
              <Typography.Text className={styles.label}>
                {t("Common-TotalReturns")}:
              </Typography.Text>
              <Typography.Text className={styles.value}>
                {clientStats?.totalReturns ?? "-"}
              </Typography.Text>
            </div>
          </div>

          <div className={styles.tableWrapper}>
            <h1 className={styles.subTitle}>{t("Common-Tickets")}</h1>

            <Table
              size="small"
              className={styles.table}
              loading={loadingTickets}
              rowKey={(record) => record.id}
              columns={ticketColumns}
              dataSource={tickets}
              pagination={{
                total: totalTickets,
                showSizeChanger: false,
                onChange: handleFetchTickets,
                current: actualPageTickets,
              }}
            />
          </div>
        </div>
        <div className={styles.infoWrapper}>
          <h1 className={styles.subTitle}>{"Promociones"}</h1>
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.headerTableWrapper}>
            <h1 className={styles.subTitle}>{t("Common-AccumulateUnits")}</h1>
            <Button
              type="primary"
              className={styles.editButton}
              onClick={() => setIsEditUnitsModalVisible(true)}
              icon={<EditOutlined />}
            />
          </div>
          <div className={styles.statsWrapper}>
            <div className={styles.statItem}>
              <Typography.Text className={styles.label}>
                {t("Common-Actives")}:
              </Typography.Text>
              <Typography.Text className={styles.value}>
                {client?.activePoints ?? "-"}
              </Typography.Text>
            </div>
            <div className={styles.statItem}>
              <Typography.Text className={styles.label}>
                {t("Common-FutureExpirations")}:
              </Typography.Text>
              <Typography.Text className={styles.value}>
                {client?.futureExpirations ?? "-"}
              </Typography.Text>
            </div>
            <div className={styles.statItem}>
              <Typography.Text className={styles.label}>
                {t("Common-Pendings")}:
              </Typography.Text>
              <Typography.Text className={styles.value}>
                {client?.pendingPoints ?? "-"}
              </Typography.Text>
            </div>
            <div className={styles.statItem}>
              <Typography.Text className={styles.label}>
                {t("Common-TotalGeneratedPoints")}:
              </Typography.Text>
              <Typography.Text className={styles.value}>
                {client?.totalGeneratedPoints ?? "-"}
              </Typography.Text>
            </div>
            <div className={styles.statItem}>
              <Typography.Text className={styles.label}>
                {t("Common-Spent")}:
              </Typography.Text>
              <Typography.Text className={styles.value}>
                {client?.spentPoints ?? "-"}
              </Typography.Text>
            </div>
            <div className={styles.statItem}>
              <Typography.Text className={styles.label}>
                {t("Common-PointsLimit")}:
              </Typography.Text>
              <Typography.Text className={styles.value}>
                {client?.pointsLimit ?? "-"}
              </Typography.Text>
            </div>
            <div className={styles.statItem}>
              <Typography.Text className={styles.label}>
                {t("Common-Expired")}:
              </Typography.Text>
              <Typography.Text className={styles.value}>
                {client?.expiredPoints ?? "-"}
              </Typography.Text>
            </div>
            <div className={styles.statItem}>
              <Typography.Text className={styles.label}>
                {t("Common-PointsUntilLimit")}:
              </Typography.Text>
              <Typography.Text className={styles.value}>
                {client?.pointsUntilLimit ?? "-"}
              </Typography.Text>
            </div>
          </div>
          <div className={styles.tableWrapper}>
            <div className={styles.headerTableWrapper}>
              <h1 className={styles.subTitle}>{t("Common-PointsMovements")}</h1>
              <Button
                type="primary"
                className={styles.actionsButton}
                onClick={() => setIsCreateMovementModalVisible(true)}
                icon={<PlusOutlined />}
              />
            </div>
            <Table
              size="small"
              className={styles.table}
              loading={loadingMovements}
              rowKey={(record) => record.id}
              columns={movementsColumns}
              dataSource={clientMovements}
              pagination={{
                total: totalSizeMovements,
                showSizeChanger: false,
                onChange: handleFetchClientMovements,
                current: actualPageMovements,
              }}
            />
          </div>
        </div>
        <div className={styles.infoWrapper}>
          <h1 className={styles.subTitle}>{"Recomendaciones de productos"}</h1>
        </div>
        <div className={styles.infoWrapper}>
          <h1 className={styles.subTitle}>{"Recompensas"}</h1>
        </div>
      </div>
      <Modal
        title={t("Common-PointsMovement")}
        visible={isCreateMovementModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => setIsCreateMovementModalVisible(false)}
      >
        <Form
          name="addMovement"
          initialValues={{ remember: true }}
          autoComplete="off"
          layout="vertical"
          form={addMovementForm}
        >
          <Form.Item
            label={t("Common-Type")}
            name="type"
            rules={[
              {
                required: true,
                message: t("Common-Attribute"),
              },
            ]}
          >
            <Select>
              <Select.Option value="ACCUMULATION">
                {t("Common-Accumulation")}
              </Select.Option>
              <Select.Option value="CONSUMPTION">
                {t("Common-Consumption")}
              </Select.Option>
              <Select.Option value="ADJUSTMENT">
                {t("Common-Adjustment")}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.inputNumber}
            label={t("Common-Points")}
            name="pointsMoved"
            rules={[
              {
                required: true,
                message: t("Common-Points"),
              },
            ]}
          >
            <InputNumber className={styles.inputNumber}></InputNumber>
          </Form.Item>
          <Form.Item
            label={t("Common-Comments")}
            name="comments"
            rules={[
              {
                required: true,
                message: t("Common-Comments"),
              },
            ]}
          >
            <Input></Input>
          </Form.Item>
        </Form>
        <Button
          className={styles.addButton}
          type="primary"
          onClick={handleAddMovement}
        >
          {t("Common-Add")}
        </Button>
      </Modal>
      <Modal
        title={t("Common-Points")}
        visible={isEditUnitsModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => setIsEditUnitsModalVisible(false)}
      >
        <Form
          name="unitsForm"
          initialValues={{ remember: true }}
          autoComplete="off"
          layout="vertical"
          form={unitsForm}
        >
          <Form.Item
            className={styles.inputNumber}
            label={t("Common-Actives")}
            initialValue={client?.activePoints}
            name="activePoints"
            rules={[
              {
                required: true,
                message: t("Common-Actives"),
              },
            ]}
          >
            <InputNumber className={styles.inputNumber}></InputNumber>
          </Form.Item>
          <Form.Item
            initialValue={client?.pendingPoints}
            label={t("Common-Pendings")}
            name="pendingPoints"
            rules={[
              {
                required: true,
                message: t("Common-Pendings"),
              },
            ]}
          >
            <InputNumber className={styles.inputNumber}></InputNumber>
          </Form.Item>
        </Form>
        <Button
          className={styles.addButton}
          type="primary"
          onClick={handleEditUnits}
        >
          {t("Common-Save")}
        </Button>
      </Modal>
    </div>
  );
};

export default ViewClient;
