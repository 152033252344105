import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  createCluster,
  getBranchOfficesFiltered,
  getDepositsFiltered,
} from "../../../api/Services";
import {
  hasPermission,
  privilegesEnum,
} from "../../../helpers/PermissionHelper";
import { Form, Input, Button, message, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./NewCluster.module.scss";
const NewCluster = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [branchOffices, setBranchOffices] = useState<any[]>([]);
  const [deposits, setDeposits] = useState<any[]>([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CLUSTERS)) {
      handleSearchBranchOffices("");
      handleSearchDeposits("");
    }
  }, []);

  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const handleSearchBranchOffices = async (value: string) => {
    const response = await getBranchOfficesFiltered(0, { name: value });
    setBranchOffices(response.branchOffices);
  };

  const handleSearchDeposits = async (value: string) => {
    const response = await getDepositsFiltered(0, {
      name: value,
    });
    setDeposits(response.deposits);
  };

  const handleCreateCluster = async () => {
    setLoading(true);
    const data = form.getFieldsValue();
    const { name, depositsIds, branchOfficesIds } = data;
    const response = await createCluster({
      name,
      depositsIds,
      branchOfficesIds,
    });
    if (response) {
      message.success(t("Common-CreateSuccessMessage"));
      form.resetFields();
      navigate("/admin/clusters");
    } else message.error(t("Common-ErrorMessage"));

    setLoading(false);
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewCluster")}</h1>
        <Button
          onClick={handleCreateCluster}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.newWrapper}>
          <Form
            name="newCluster"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.formNew}
            form={form}
            onFinish={handleCreateCluster}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item
              label={t("Common-BranchOffices")}
              name="branchOfficesIds"
            >
              <Select
                mode="multiple"
                allowClear
                className={styles.branchOfficeSelector}
                onSearch={handleSearchBranchOffices}
                placeholder={t("Common-SearchByName")}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {getOptions(branchOffices)}
              </Select>
            </Form.Item>
            <Form.Item label={t("Common-Deposits")} name="depositsIds">
              <Select
                mode="multiple"
                allowClear
                className={styles.branchOfficeSelector}
                onSearch={handleSearchDeposits}
                placeholder={t("Common-SearchByName")}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {getOptions(deposits)}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default NewCluster;
