import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  getRepositionOrderById,
  markSentArticlesRepositionOrder,
} from "../../../api/Services";
import {
  hasPermission,
  privilegesEnum,
} from "../../../helpers/PermissionHelper";

import {
  Table,
  Spin,
  Form,
  Input,
  Button,
  Modal,
  DatePicker,
  Typography,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import NotFound from "../../notfound/NotFound";

import styles from "./RequestDetail.module.scss";
import moment from "moment";

const RequestDetail = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [repositionOrder, setRepositionOrder] = useState<any>();
  const [selectedFromTable, setSelectedFromTable] = useState<any[]>([]);
  const [isSelectDateModalOpen, setIsSelectDateModalOpen] = useState(false);
  const [dateSelected, setDateSelected] = useState<any>(null);
  const [infoRepositionOrder] = Form.useForm();

  const canEdit = false;

  const { id } = useParams();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_PURCHASE_ORDERS))
      handleFetchRepositionOrder();
  }, []);

  const handleFetchRepositionOrder = async () => {
    let response = await getRepositionOrderById(id!);
    setRepositionOrder(response);
    setLoading(false);
  };

  const rowSelection = {
    selectedRowKeys: selectedFromTable.map((article: any) => article.id),
    onChange: (selectedRowKeys: React.Key[], selectedArticles: any[]) => {
      setSelectedFromTable(selectedArticles);
    },
    getCheckboxProps: (record: any) => {
      if (record.sent.user_email) {
        return {
          disabled: true,
        };
      }
      return {};
    },
  };

  const sentArticles = async () => {
    const skuRepoIds = selectedFromTable.map((article: any) => article.id);

    if (skuRepoIds && dateSelected) {
      const response = await markSentArticlesRepositionOrder(
        skuRepoIds,
        dateSelected
      );
      if (response) {
        setIsSelectDateModalOpen(false);
        setSelectedFromTable([]);
        handleFetchRepositionOrder();
      }
    }
  };

  const articleColumn = [
    {
      title: t("Common-Article"),
      key: "articleName",
      render: (record: any) => (
        <a href={`/admin/articles/${record.articleId}/view`}>
          {record.articleName}
        </a>
      ),
    },
    {
      title: t("Common-Sku"),
      dataIndex: "skuName",
    },
    {
      title: t("Common-Quantity"),
      dataIndex: "quantity",
    },
    {
      title: t("Common-Sent"),
      dataIndex: "sent",
      render: (sent: any) => (sent.user_email ? "Si" : "No"),
    },
  ];

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !repositionOrder ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          {t("Common-RepositionOrder") + repositionOrder.id}
        </h1>
        {hasPermission(privilegesEnum.WRITE_PURCHASE_ORDERS) && (
          <div className={styles.actionButtonsWrapper}></div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.repositionOrderWrapper}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={infoRepositionOrder}
          >
            <Form.Item
              label={t("Common-OriginPlace")}
              name="originPlace"
              initialValue={
                repositionOrder.depositFrom
                  ? repositionOrder.depositFrom.name
                  : repositionOrder.branchOfficeFrom.name
              }
              rules={[
                {
                  required: true,
                  message: t("Common-OriginPlace"),
                },
              ]}
            >
              <Input
                placeholder={t("Suppliers-DeliverTo")}
                disabled={!canEdit}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-DeliverTo")}
              name="deliverTo"
              initialValue={
                repositionOrder.depositTo
                  ? repositionOrder.depositTo.name
                  : repositionOrder.branchOfficeTo.name
              }
              rules={[
                {
                  required: true,
                  message: t("Common-DeliverTo"),
                },
              ]}
            >
              <Input
                placeholder={t("Suppliers-DeliverTo")}
                disabled={!canEdit}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-EstimatedDeliveryDate")}
              name="estimatedDeliveryDate"
              initialValue={repositionOrder.estimatedDeliveryDate}
              rules={[
                {
                  required: true,
                  message: t("Common-EstimatedDeliveryDate"),
                },
              ]}
            >
              <Input disabled={!canEdit} />
            </Form.Item>
            <Form.Item
              label={t("Common-Status")}
              name="status"
              initialValue={repositionOrder.orderStatus.description_es}
              rules={[
                {
                  required: true,
                  message: t("Common-Status"),
                },
              ]}
            >
              <Input placeholder={t("Common-Status")} disabled={!canEdit} />
            </Form.Item>
            <Form.Item label={t("Common-Priority")} name="priority">
              <Input placeholder={"-"} disabled={!canEdit} />
            </Form.Item>
          </Form>
        </div>
        <div className={styles.articlesWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.articlesTitle}>{t("Common-Articles")}</h1>
            <Button
              type="primary"
              className={styles.actionsButton}
              onClick={() => setIsSelectDateModalOpen(true)}
              // icon={<PlusOutlined />}
              disabled={selectedFromTable.length === 0}
            >
              {t("Common-Send")}
            </Button>
          </div>
          <div className={styles.articlesTableWrapper}>
            <Table
              size="small"
              className={styles.articleTable}
              rowKey={(record) => record.id}
              loading={loading}
              columns={articleColumn}
              dataSource={repositionOrder?.skus}
              rowSelection={{
                ...rowSelection,
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        title={t("Common-Send")}
        visible={isSelectDateModalOpen}
        okText={t("Common-Send")}
        onCancel={() => setIsSelectDateModalOpen(false)}
        onOk={sentArticles}
        okButtonProps={{ disabled: dateSelected === null }}
      >
        <div className={styles.modalWrapper}>
          <Typography.Text>{t("Common-SelectArriveDate")}</Typography.Text>
          <DatePicker
            placeholder={t("Common-Date")}
            onChange={(date: any, dateString: string) =>
              setDateSelected(dateString)
            }
          />
        </div>
      </Modal>
    </div>
  );
};

export default RequestDetail;
