import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getColours, deleteColour, editColour } from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import { Table, Form, Input, Button, message, Space, Modal } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./Colours.module.scss";
import { useNavigate } from "react-router-dom";

const Colours = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [nameFilterValue, setNameFilterValue] = useState("");

  const [editForm] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_COLOURS)) fetchColours();
  }, [nameFilterValue]);

  const fetchColours = async (pagination = actualPage) => {
    const response = await getColours(pagination, { name: nameFilterValue });
    const colours = response.colours;

    setData(colours);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const showDeleteConfirm = (id: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDelete(id);
      },
    });
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    const deleteStatus = await deleteColour(id);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await fetchColours();
    setLoading(false);
  };

  function showEditModal(colour: any) {
    Modal.info({
      title: t("Common-Colour"),
      closable: true,
      maskClosable: true,
      okText: t("Common-Save"),
      icon: null,
      okButtonProps: { style: { display: "none" } },
      content: (
        <>
          <Form
            initialValues={{ remember: true }}
            form={editForm}
            autoComplete="off"
            preserve={false}
          >
            <Form.Item hidden initialValue={colour.id} name="id" />
            <Form.Item
              initialValue={colour?.name}
              label={t("Common-Name")}
              name="name"
              className={styles.editInfoItem}
              rules={[
                {
                  required: true,
                  message: t("Common-Name"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
          </Form>

          <Button
            className={styles.addButton}
            type="primary"
            onClick={handleEdit}
          >
            {t("Common-Save")}
          </Button>
        </>
      ),
    });
  }

  const handleEdit = async () => {
    setLoading(true);

    const id = editForm.getFieldValue("id");
    const name = editForm.getFieldValue("name");
    let response = await editColour(id, name);

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
    } else message.error(t("Common-EditErrorMessage"));

    Modal.destroyAll();
    await fetchColours();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    fetchColours(page);
    setActualPage(page);
  };

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {hasPermission(privilegesEnum.WRITE_COLOURS) && (
            <EditOutlined
              onClick={() => {
                showEditModal(record);
              }}
            />
          )}
          {hasPermission(privilegesEnum.DELETE_COLOURS) && (
            <DeleteOutlined
              onClick={() => {
                showDeleteConfirm(record.id);
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Colours")}</h1>
        {hasPermission(privilegesEnum.WRITE_COLOURS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={() => {
                navigate("/admin/colours/create");
              }}
              className={styles.newButton}
              type="primary"
              icon={<PlusOutlined />}
            />
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_COLOURS) && (
          <div className={styles.tableWrapper}>
            <div className={styles.headerTableWrapper}>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <Input
                    placeholder={t("Common-SearchByName")}
                    className={styles.filterInput}
                    onChange={(e) => {
                      setNameFilterValue(e.target.value);
                      setActualPage(1);
                    }}
                    value={nameFilterValue}
                  />
                </div>
              </div>
            </div>
            <Table
              size="small"
              rowKey={(record) => record.id}
              className={styles.table}
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
                current: actualPage,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Colours;
