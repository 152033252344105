import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  getArticleById,
  getCurrencies,
  getRoundingTypes,
  addArticleToSupplier,
  addAttributeToArticle,
  addPriceToArticle,
  editArticle,
  deleteAttributeFromArticle,
  editAttributeFromArticle,
  deleteArticleSupplier,
  createSkuInArticle,
  deleteSkuFromArticle,
  editSkuFromArticle,
  deleteSkuAttributeFromArticle,
  editSupplierToArticle,
  addAttributeToSku,
  getAttributesFiltered,
  getCategoriesFiltered,
  getBranchOfficesFiltered,
  getBrandsFiltered,
  getPurchaseUnitsFiltered,
  getSuppliersFiltered,
  getColoursByName,
  getSeasonsFiltered,
  addSeasonToArticle,
  deleteSeasonFromArticle,
  getCurves,
  addCurveToArticle,
  deleteCurveFromArticle,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import { BusinessUniteContext } from "../../components/laoyut/Layout";

import {
  Form,
  message,
  Space,
  Select,
  Modal,
  Button,
  Input,
  Switch,
  Spin,
  Table,
  InputNumber,
  DatePicker,
  Cascader,
  Image,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  EyeOutlined,
  CheckOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import styles from "./ViewArticle.module.scss";
import NotFound from "../notfound/NotFound";
import moment from "moment";

const ViewArticle = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    contextValue: businessUnitSelected,
    updateContextValue: setBusinessUnitSelected,
  } = useContext(BusinessUniteContext);

  const [loading, setLoading] = useState(true);
  const [loadingColours, setLoadingColours] = useState(true);
  const [isAddSupplierModalVisible, setIsAddSupplierModalVisible] =
    useState(false);
  const [isAddAttributeModalVisible, setIsAddAttributeModalVisible] =
    useState(false);
  const [isAddSeasonModalVisible, setIsAddSeasonModalVisible] = useState(false);
  const [isAddCurveModalVisible, setIsAddCurveModalVisible] = useState(false);
  const [isAddPriceModalVisible, setIsAddPriceModalVisible] = useState(false);
  const [isAddSkuModalVisible, setIsAddSkuModalVisible] = useState(false);
  const [article, setArticle] = useState<any>();
  const [branchOffices, setBranchOffices] = useState<any>();
  const [categories, setCategories] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [attributes, setAttributes] = useState<any[]>([]);
  const [currencies, setCurrencies] = useState<any[]>([]);
  const [roundingTypes, setRoundingTypes] = useState<any[]>([]);
  const [paymentMethodsSupplier, setPaymentMethodsSupplier] = useState([]);
  const [isEditingSupplierOpen, setIsEditingSupplierOpen] = useState(false);
  const [supplier, setSupplier] = useState<any>(null);
  const [purchaseUnits, setPurchaseUnits] = useState<any[]>([]);
  const [colours, setColours] = useState<any[]>([]);
  const [seasons, setSeasons] = useState<any[]>([]);
  const [curves, setCurves] = useState<any[]>([]);

  const [infoArticleForm] = Form.useForm();
  const [addSupplierForm] = Form.useForm();
  const [addSeasonForm] = Form.useForm();
  const [addCurveForm] = Form.useForm();
  const [addAttributeForm] = Form.useForm();
  const [addSkuForm] = Form.useForm();
  const [addSkuAttributesForm] = Form.useForm();
  const [editAttributeForm] = Form.useForm();
  const [addAttributeOnSkuForm] = Form.useForm();
  const [addPriceForm] = Form.useForm();
  const [attributeFilterValue, setAttributeFilterValue] = useState("");
  const [attributesSelected, setAttributesSelected] = useState<any[]>([]);

  const canEdit = hasPermission(privilegesEnum.WRITE_ARTICLES);

  const { id } = useParams();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_ARTICLES)) {
      setLoading(true);
      const fetchData = async () => {
        await handleFetchArticle();
        await handleFetchCurrencies();
        await handleFetchRoundingTypes();
        await handleSearchColours("");
        setLoading(false);
      };
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (attributeFilterValue.length > 0) {
      handleFetchAttributes(attributeFilterValue);
    }
  }, [attributeFilterValue]);

  useEffect(() => {
    if (
      article &&
      !article.businessUnits
        .map((businessUnit: any) => businessUnit.id)
        .includes(businessUnitSelected) &&
      businessUnitSelected &&
      !loading
    ) {
      navigate("/admin/articles");
    }
  }, [article, businessUnitSelected]);

  const handleChangeAttribute = (value: any, selectedOptions: any) => {
    const attributeSelected = {
      disabled: selectedOptions[0].children.length === 0,
      value: selectedOptions[0].value,
      label: selectedOptions[0].label,
      children: selectedOptions[0].children?.map((possibleValue: any) => {
        return {
          value: possibleValue.value,
          label: possibleValue.label,
        };
      }),
    };

    setAttributesSelected([...attributesSelected, attributeSelected]);
    setAttributes([]);
  };

  const handleFetchArticle = async () => {
    const response = await getArticleById(id!);
    setCategories(response.category ? [response.category] : []);
    setBrands(response.brand ? [response.brand] : []);
    const existingPurchaseUnits = response.suppliers
      ?.map((supplier: any) => supplier.purchaseUnit)
      .filter((purchaseUnit: any) => purchaseUnit !== null);
    setPurchaseUnits(existingPurchaseUnits);
    setArticle(response);
    setBusinessUnitSelected(response.businessUnits[0].id);
  };

  const handleFetchCurrencies = async () => {
    const response = await getCurrencies();
    setCurrencies(response);
  };

  const handleFetchRoundingTypes = async () => {
    const response = await getRoundingTypes();
    setRoundingTypes(response);
  };

  const handleFetchAttributes = async (name: string) => {
    const response = await getAttributesFiltered(name);
    setAttributes(response);
  };

  const attributeOptions = () => {
    const concatedAttributes = attributes
      ?.map((attribute) => {
        return {
          disabled: attribute.enums.length === 0,
          value: attribute.id,
          label: attribute.attribute,
          children: attribute.enums?.map((possibleValue: any) => {
            return {
              value: possibleValue.id,
              label: possibleValue.value,
            };
          }),
        };
      })
      .concat(attributesSelected);

    const uniqueArray = concatedAttributes.filter((obj, index) => {
      return (
        concatedAttributes.findIndex((o) => o.value === obj.value) === index
      );
    });

    return uniqueArray;
  };

  const handleSearchBranchOffices = async (value: string) => {
    if (value.length > 1) {
      const response = await getBranchOfficesFiltered(0, { name: value });
      setBranchOffices(response.branchOffices);
    } else setBranchOffices([]);
  };

  const handleSearchSeasons = async (value: string) => {
    const response = await getSeasonsFiltered(value);
    setSeasons(response);
  };

  const handleSearchCurves = async (value: string) => {
    const response = await getCurves(1, { name: value });
    setCurves(response.curves);
  };

  const handleSearchSuppliers = async (value: string) => {
    if (value.length > 2) {
      const response = await getSuppliersFiltered(0, { name: value });
      setSuppliers(response.suppliers);
    } else setSuppliers([]);
  };

  const handleSearchPurchaseUnits = async (value: string) => {
    if (value.length > 2) {
      const response = await getPurchaseUnitsFiltered(value);
      setPurchaseUnits(response);
    } else setPurchaseUnits([]);
  };

  //todo podria ser una funcion en el helper
  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
        ? item.parents.filter(Boolean).join(" - ")
        : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
        ? `${parentsString} - ${categoryName}`
        : categoryName;

      options.push(
        <Select.Option value={item.id} key={item.id}>
          {categoryTree}
        </Select.Option>
      );
    });

    return options;
  };

  const changeSelectedSupplier = (value: any) => {
    const supplier = suppliers.find((supplier) => supplier.id === value);
    if (supplier) {
      setPaymentMethodsSupplier(supplier.paymentMethods);
    } else setPaymentMethodsSupplier([]);
  };

  const getOptionsDescription = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.descriptionEs}
        </Select.Option>
      );
    });

    return options;
  };

  const handleOpenAddSupplierModal = () => {
    setIsAddSupplierModalVisible(true);
  };

  const handleCancelAddSupplierModal = () => {
    setIsAddSupplierModalVisible(false);
  };

  const handleOpenAddAttributeModal = () => {
    setIsAddAttributeModalVisible(true);
  };

  const handleCancelAddAttributeModal = () => {
    setIsAddAttributeModalVisible(false);
  };

  const handleOpenAddSeasonModal = () => {
    handleSearchSeasons("");
    setIsAddSeasonModalVisible(true);
  };

  const handleCancelAddSeasonModal = () => {
    setIsAddSeasonModalVisible(false);
  };

  const handleOpenAddCurveModal = () => {
    handleSearchCurves("");
    setIsAddCurveModalVisible(true);
  };

  const handleCancelAddCurveModal = () => {
    setIsAddCurveModalVisible(false);
  };

  const handleOpenAddPriceModal = () => {
    setIsAddPriceModalVisible(true);
  };

  const handleCancelAddPriceModal = () => {
    setIsAddPriceModalVisible(false);
  };

  const handleOpenAddSkuModal = () => {
    setIsAddSkuModalVisible(true);
  };

  const handleCancelAddSkuModal = () => {
    setIsAddSkuModalVisible(false);
  };

  const showDeleteSupplierConfirm = (supplierId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteSupplier(supplierId);
      },
    });
  };

  const showDeleteAttributeConfirm = (articleAttributeId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteAttribute(articleAttributeId);
      },
    });
  };

  const showDeleteSeasonConfirm = (articleSeasonId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteSeason(articleSeasonId);
      },
    });
  };

  const showDeleteCurveConfirm = (curveId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteCurve(curveId);
      },
    });
  };

  const showDeleteSkuAttributeConfirm = (skuAttributeId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteSkuAttribute(skuAttributeId);
      },
    });
  };

  const showDeleteSkuConfirm = (supplierId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteSku(supplierId);
      },
    });
  };

  const handleDeleteSupplier = async (articleSupplierId: number) => {
    setLoading(true);
    const deleteStatus = await deleteArticleSupplier(articleSupplierId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));

    await handleFetchArticle();
    setLoading(false);
  };

  const handleDeleteAttribute = async (articleAttributeId: number) => {
    setLoading(true);
    const deleteStatus = await deleteAttributeFromArticle(articleAttributeId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));

    await handleFetchArticle();
    setLoading(false);
  };

  const handleDeleteSeason = async (articleSeasonId: number) => {
    setLoading(true);
    const deleteStatus = await deleteSeasonFromArticle(articleSeasonId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));

    await handleFetchArticle();
    setLoading(false);
  };

  const handleDeleteCurve = async (curveId: number) => {
    setLoading(true);
    const deleteStatus = await deleteCurveFromArticle(parseInt(id!), curveId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));

    await handleFetchArticle();
    setLoading(false);
  };

  const handleDeleteSkuAttribute = async (skuAttributeId: number) => {
    setLoading(true);
    const deleteStatus = await deleteSkuAttributeFromArticle(skuAttributeId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));

    Modal.destroyAll();

    await handleFetchArticle();
    setLoading(false);
  };

  const handleDeleteSku = async (articleSkuId: number) => {
    setLoading(true);
    const deleteStatus = await deleteSkuFromArticle(articleSkuId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));

    await handleFetchArticle();
    setLoading(false);
  };

  const validateAddSku = async () => {
    await addSkuForm.validateFields().then((skuValues) => {
      addSkuAttributesForm.validateFields().then((attributesValues) => {
        const attributesBody = attributesValues.attributes?.map(
          (attribute: any) => {
            return {
              attributeId: attribute.attributeId[0],
              attributeEnumId: attribute.attributeId[1],
              isEnum: true,
            };
          }
        );
        handleAddSku(skuValues, attributesBody);
      });
    });
  };

  const handleAddSku = async (sku: any, attributes: any) => {
    setLoading(true);
    const response = await createSkuInArticle(id!, sku, attributes);
    if (response) {
      addSkuForm.resetFields();
      addSkuAttributesForm.resetFields();
      await handleFetchArticle();
      handleCancelAddSkuModal();
      message.success(t("Common-AddSuccessMessage"));
    } else message.error(t("Common-ErrorMessage"));
    setLoading(false);
  };

  const handleEditArticleInfo = async () => {
    setLoading(true);

    const {
      name,
      externalId,
      description,
      webDescription,
      categoryId,
      brandId,
      currencyId,
      roundingTypeId,
      cost,
      defaultPrice,
      minimumPrice,
      maximumPrice,
      minimumMargin,
      enable,
      discontinued,
      originType,
      photo1,
      photo2,
      photo3,
    } = infoArticleForm.getFieldsValue();

    const response = await editArticle({
      articleId: id!,
      name,
      externalId,
      description,
      webDescription,
      categoryId,
      brandId,
      currencyId,
      roundingTypeId,
      cost,
      defaultPrice,
      minimumPrice,
      maximumPrice,
      minimumMargin,
      enable,
      discontinued,
      originType,
      businessUnitsIds: [businessUnitSelected] ?? [],
      photo1,
      photo2,
      photo3,
    });

    await handleFetchArticle();

    if (response) message.success(t("Common-EditSuccessMessage"));
    else message.error(t("Common-EditErrorMessage"));
    setLoading(false);
  };

  const handleSearchColours = async (value: string) => {
    setLoadingColours(true);
    const response = await getColoursByName(value);
    setColours(response.colours);
    setLoadingColours(false);
  };

  const handleCancelButton = () => {
    infoArticleForm.resetFields();
  };

  const getOptionsPurchaseUnit = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.purchaseUnit}
        </Select.Option>
      );
    });

    return options;
  };

  const handleAddSupplier = async () => {
    await addSupplierForm.validateFields().then(async (supplier) => {
      setLoading(true);

      const {
        supplierId,
        principal,
        supplierPaymentMethodId,
        deliveryTerm,
        minimumOrder,
        purchaseUnit,
        cost,
        agreedDiscounts,
      } = supplier;

      const commercialTerm = {
        supplierPaymentMethodId: supplierPaymentMethodId,
        deliveryTerm: deliveryTerm,
        minimumOrder: minimumOrder,
        cost,
        agreedDiscounts,
      };
      const response = await addArticleToSupplier(
        parseInt(id!),
        principal,
        supplierId,
        purchaseUnit,
        commercialTerm
      );

      if (response) {
        addSupplierForm.resetFields();
        await handleFetchArticle();
        handleCancelAddSupplierModal();
        message.success(t("Common-AddSuccessMessage"));
      } else message.error(t("Common-ErrorMessage"));
      setLoading(false);
    });
  };

  const handleAddAttribute = async () => {
    await addAttributeForm.validateFields().then(async (attribute) => {
      setLoading(true);
      const { attributeId } = attribute;

      const response = await addAttributeToArticle(
        parseInt(id!),
        attributeId[0],
        attributeId[1]
      );

      if (response) {
        addSupplierForm.resetFields();
        await handleFetchArticle();
        handleCancelAddAttributeModal();
        message.success(t("Common-AddSuccessMessage"));
      } else message.error(t("Common-ErrorMessage"));
      setLoading(false);
    });
  };

  const handleAddSeason = async () => {
    await addSeasonForm.validateFields().then(async (season) => {
      setLoading(true);
      const { seasonId } = season;

      const response = await addSeasonToArticle(parseInt(id!), seasonId);

      if (response) {
        addSeasonForm.resetFields();
        await handleFetchArticle();
        handleCancelAddSeasonModal();
        message.success(t("Common-AddSuccessMessage"));
      } else message.error(t("Common-ErrorMessage"));
      setLoading(false);
    });
  };
  const handleAddCurve = async () => {
    await addCurveForm.validateFields().then(async (curve) => {
      setLoading(true);
      const { curveId } = curve;

      const response = await addCurveToArticle(parseInt(id!), curveId);

      if (response) {
        addSeasonForm.resetFields();
        await handleFetchArticle();
        handleCancelAddCurveModal();
        message.success(t("Common-AddSuccessMessage"));
      } else message.error(t("Common-ErrorMessage"));
      setLoading(false);
    });
  };

  const handleAddPrice = async () => {
    await addPriceForm.validateFields().then(async (prices) => {
      setLoading(true);
      const { branchOfficeId, price, date } = prices;
      const response = await addPriceToArticle(
        parseInt(id!),
        branchOfficeId,
        price,
        date.format("YYYY-MM-DDTHH:mm:ss")
      );

      if (response) {
        await handleFetchArticle();
        handleCancelAddPriceModal();
        addPriceForm.resetFields();
        message.success(t("Common-AddSuccessMessage"));
      } else message.error(t("Common-ErrorMessage"));
      setLoading(false);
    });
  };

  const handleAddSkuAttribute = async () => {
    await addAttributeOnSkuForm.validateFields().then(async (attribute) => {
      setLoading(true);
      const { id, attributeId } = attribute;

      const response = await addAttributeToSku(
        id,
        attributeId[0],
        attributeId[1]
      );

      if (response) {
        addSupplierForm.resetFields();
        await handleFetchArticle();
        handleCancelAddAttributeModal();
        Modal.destroyAll();
        message.success(t("Common-AddSuccessMessage"));
      } else message.error(t("Common-ErrorMessage"));
      setLoading(false);
    });
  };

  const showEditAttribute = (attributeArticle: any) => {
    Modal.info({
      title: t("Common-Attribute"),
      closable: true,
      maskClosable: true,
      icon: null,
      okButtonProps: { style: { display: "none" } },
      content: (
        <>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            form={editAttributeForm}
            autoComplete="off"
            preserve={false}
          >
            <Form.Item hidden initialValue={attributeArticle.id} name="id" />

            <Form.Item
              className={styles.modalInfoItem}
              label={t("Common-Value")}
              name="value"
              initialValue={attributeArticle.value}
              rules={[
                {
                  required: true,
                  message: t("Common-Value"),
                },
              ]}
            >
              <Input placeholder={t("Common-Value")} />
            </Form.Item>
          </Form>
          <Button
            className={styles.addButton}
            type="primary"
            onClick={handleEditAttribute}
          >
            {t("Common-Save")}
          </Button>
        </>
      ),
    });
  };

  const showAddSkuAttribute = (skuAttribute: any) => {
    Modal.info({
      title: t("Common-Attribute"),
      closable: true,
      maskClosable: true,
      icon: null,
      okButtonProps: { style: { display: "none" } },
      content: (
        <>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            form={addAttributeOnSkuForm}
            autoComplete="off"
            preserve={false}
          >
            <Form.Item hidden initialValue={skuAttribute.id} name="id" />

            <Form.Item
              className={styles.modalInfoItem}
              label={t("Common-Attribute")}
              name="attributeId"
              rules={[
                {
                  required: true,
                  message: t("Common-Attribute"),
                },
              ]}
            >
              <Cascader
                className={styles.cascader}
                showSearch
                onSearch={(value) => setAttributeFilterValue(value)}
                options={attributes?.map((attribute) => {
                  return {
                    disabled: attribute.enums.length === 0,
                    value: attribute.id,
                    label: attribute.attribute,
                    children: attribute.enums?.map((possibleValue: any) => {
                      return {
                        value: possibleValue.id,
                        label: possibleValue.value,
                      };
                    }),
                  };
                })}
              />
            </Form.Item>
          </Form>
          <Button
            className={styles.addButton}
            type="primary"
            onClick={handleAddSkuAttribute}
          >
            {t("Common-Save")}
          </Button>
        </>
      ),
    });
  };

  const showEditSku = (skuArticle: any) => {
    Modal.info({
      title: t("Common-Sku"),
      width: 800,
      closable: true,
      maskClosable: true,
      icon: null,
      okButtonProps: { style: { display: "none" } },
      content: (
        <>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            form={editAttributeForm}
            autoComplete="off"
            preserve={false}
          >
            <Form.Item hidden initialValue={skuArticle.id} name="id" />
            <div className={styles.skusItemsWrapper}>
              <Form.Item
                className={styles.skusFormItem}
                initialValue={skuArticle.name}
                label={t("Common-Name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("Common-NameNotEntered"),
                  },
                ]}
              >
                <Input disabled={!canEdit} placeholder={t("Common-Name")} />
              </Form.Item>
              <Form.Item
                className={styles.skusFormItem}
                initialValue={skuArticle.externalId}
                label={t("Common-ExternalId")}
                name="externalId"
              >
                <Input placeholder={t("Common-ExternalId")} />
              </Form.Item>
              <Form.Item
                className={styles.skusFormItem}
                initialValue={skuArticle.storageUnit}
                label={t("Common-StorageUnit")}
                name="storageUnit"
              >
                <Input
                  disabled={!canEdit}
                  placeholder={t("Common-StorageUnit")}
                />
              </Form.Item>
            </div>
            <div className={styles.skusItemsWrapper}>
              <Form.Item
                className={styles.skusFormItem}
                initialValue={skuArticle.height}
                label={t("Common-Height")}
                name="height"
              >
                <InputNumber
                  disabled={!canEdit}
                  type="number"
                  className={styles.numberInput}
                  min={0}
                  placeholder={t("Common-Height")}
                />
              </Form.Item>

              <Form.Item
                className={styles.skusFormItem}
                initialValue={skuArticle.length}
                label={t("Common-Length")}
                name="length"
              >
                <InputNumber
                  disabled={!canEdit}
                  type="number"
                  className={styles.numberInput}
                  min={0}
                  placeholder={t("Common-Length")}
                />
              </Form.Item>
              <Form.Item
                className={styles.skusFormItem}
                initialValue={skuArticle.width}
                label={t("Common-Width")}
                name="width"
              >
                <InputNumber
                  disabled={!canEdit}
                  type="number"
                  className={styles.numberInput}
                  min={0}
                  placeholder={t("Common-Width")}
                />
              </Form.Item>
            </div>
            <div className={styles.skusItemsWrapper}>
              <Form.Item
                className={styles.skusFormItem}
                initialValue={skuArticle.weight}
                label={t("Common-Weight")}
                name="weight"
              >
                <InputNumber
                  disabled={!canEdit}
                  type="number"
                  className={styles.numberInput}
                  min={0}
                  placeholder={t("Common-Weight")}
                />
              </Form.Item>
              <Form.Item
                className={styles.skusFormItem}
                initialValue={skuArticle.volumen}
                label={t("Common-Volumen")}
                name="volumen"
              >
                <InputNumber
                  disabled={!canEdit}
                  type="number"
                  className={styles.numberInput}
                  min={0}
                  placeholder={t("Common-Volumen")}
                />
              </Form.Item>

              <Form.Item
                className={styles.skusFormItem}
                initialValue={skuArticle.minimumStock}
                label={t("Common-MinimumStock")}
                name="minimumStock"
                rules={[
                  {
                    required: true,
                    message: t("Common-MinimumStock"),
                  },
                ]}
              >
                <InputNumber
                  disabled={!canEdit}
                  type="number"
                  className={styles.numberInput}
                  min={0}
                  defaultValue={1}
                  placeholder={t("Common-MinimumStock")}
                />
              </Form.Item>
            </div>
            <div className={styles.skusItemsWrapper}>
              <Form.Item
                className={styles.skusFormItem}
                initialValue={skuArticle.heightWithPackaging}
                label={t("Common-HeightWithPackaging")}
                name="heightWithPackaging"
              >
                <InputNumber
                  disabled={!canEdit}
                  type="number"
                  className={styles.numberInput}
                  min={0}
                  placeholder={t("Common-HeightWithPackaging")}
                />
              </Form.Item>

              <Form.Item
                className={styles.skusFormItem}
                initialValue={skuArticle.lengthWithPackaging}
                label={t("Common-LengthWithPackaging")}
                name="lengthWithPackaging"
              >
                <InputNumber
                  disabled={!canEdit}
                  type="number"
                  className={styles.numberInput}
                  min={0}
                  placeholder={t("Common-LengthWithPackaging")}
                />
              </Form.Item>
              <Form.Item
                className={styles.skusFormItem}
                initialValue={skuArticle.widthWithPackaging}
                label={t("Common-WidthWithPackaging")}
                name="widthWithPackaging"
              >
                <InputNumber
                  disabled={!canEdit}
                  type="number"
                  className={styles.numberInput}
                  min={0}
                  placeholder={t("Common-WidthWithPackaging")}
                />
              </Form.Item>
            </div>
            <div className={styles.skusItemsWrapper}>
              <Form.Item
                className={styles.skusFormItem}
                initialValue={skuArticle.weightWithPackaging}
                label={t("Common-WeightWithPackaging")}
                name="weightWithPackaging"
              >
                <InputNumber
                  type="number"
                  className={styles.numberInput}
                  min={0}
                  placeholder={t("Common-WeightWithPackaging")}
                />
              </Form.Item>
              <Form.Item
                className={styles.skusFormItem}
                initialValue={skuArticle.cost}
                label={t("Common-Cost")}
                name="cost"
              >
                <InputNumber
                  type="number"
                  className={styles.numberInput}
                  min={0}
                  placeholder={t("Common-Cost")}
                />
              </Form.Item>
              <Form.Item
                className={styles.skusFormItem}
                initialValue={skuArticle.salesUnit}
                label={t("Common-SalesUnit")}
                name="salesUnit"
              >
                <Input
                  disabled={!canEdit}
                  placeholder={t("Common-SalesUnit")}
                />
              </Form.Item>
            </div>
            <div className={styles.skusItemsWrapper}>
              <Form.Item
                className={styles.skusFormItem}
                label={t("Common-Color")}
                name="colour"
                initialValue={skuArticle.colour?.id}
              >
                <Select
                  className={styles.type}
                  placeholder={t("Common-SearchByName")}
                  onSearch={handleSearchColours}
                  allowClear
                  filterOption={(input: string, option: any) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  loading={loadingColours}
                  showSearch
                  style={{ width: "100%", minWidth: "150px" }}
                >
                  {getOptions(colours)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-Currency")}
                name="currencyId"
                className={styles.skusFormItem}
                initialValue={skuArticle?.currency?.id}
              >
                <Select
                  allowClear
                  style={{ width: "100%", minWidth: "150px" }}
                  placeholder=""
                  defaultValue={skuArticle?.currency?.id}
                  disabled={!canEdit}
                >
                  {getOptions(currencies)}
                </Select>
              </Form.Item>
            </div>

            <div className={styles.skusItemsWrapper}>
              <Form.Item
                className={styles.skusFormItem}
                label={t("Common-ShoeSize")}
                name="shoeSize"
                initialValue={skuArticle.shoeSize}
              >
                <InputNumber
                  type="number"
                  className={styles.numberInput}
                  min={0}
                  placeholder={t("Common-ShoeSize")}
                />
              </Form.Item>
              <Form.Item
                className={styles.skusFormItem}
                label={t("Common-ClothSizeOp1")}
                name="clothSizeOp1"
                initialValue={skuArticle.clothSizeOp1}
              >
                <InputNumber
                  type="number"
                  className={styles.numberInput}
                  min={0}
                  placeholder={t("Common-ClothSizeOp1")}
                />
              </Form.Item>
              <Form.Item
                className={styles.skusFormItem}
                label={t("Common-ClothSizeOp2")}
                name="clothSizeOp2"
                initialValue={skuArticle.clothSizeOp2}
              >
                <Input placeholder={t("Common-ClothSizeOp2")} />
              </Form.Item>
            </div>
            <div className={styles.skusItemsWrapper}>
              <Form.Item
                initialValue={skuArticle.replenish ?? false}
                label={t("Common-Replenish")}
                name="replenish"
              >
                <Switch
                  disabled={!canEdit}
                  defaultChecked={skuArticle.replenish}
                />
              </Form.Item>
              <Form.Item
                initialValue={skuArticle.enable ?? false}
                label={t("Common-Active")}
                name="enable"
              >
                <Switch
                  disabled={!canEdit}
                  defaultChecked={skuArticle.enable}
                />
              </Form.Item>
              <Form.Item
                initialValue={skuArticle.kit}
                label={t("Common-IsKit")}
                name="kit"
              >
                <Switch disabled={!canEdit} defaultChecked={skuArticle.kit} />
              </Form.Item>
            </div>
          </Form>
          <div className={styles.tablesHeader}>
            <h1 className={styles.tablesTitle}>{t("Common-Attributes")}</h1>
            {canEdit && (
              <Button
                type="primary"
                className={styles.actionsButton}
                onClick={() => showAddSkuAttribute(skuArticle)}
                icon={<PlusOutlined />}
              />
            )}
          </div>
          <Table
            size="small"
            className={styles.attributesTable}
            loading={loading}
            columns={attributesSkuColumn}
            dataSource={skuArticle.attributesWithValue}
          />
          {canEdit && (
            <Button
              className={styles.addButton}
              type="primary"
              onClick={handleEditSku}
            >
              {t("Common-Save")}
            </Button>
          )}
        </>
      ),
    });
  };

  const handleEditAttribute = async () => {
    setLoading(true);
    const { id, value } = editAttributeForm.getFieldsValue();

    const response = await editAttributeFromArticle(id!, value);

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
    } else message.error(t("Common-EditErrorMessage"));

    Modal.destroyAll();
    await handleFetchArticle();
    setLoading(false);
  };

  const handleEditSku = async () => {
    setLoading(true);
    const {
      id,
      name,
      enable,
      heightWithPackaging,
      lengthWithPackaging,
      widthWithPackaging,
      weightWithPackaging,
      height,
      length,
      width,
      weight,
      volumen,
      kit,
      salesUnit,
      storageUnit,
      minimumStock,
      shoeSize,
      clothSizeOp1,
      clothSizeOp2,
      colour,
      externalId,
      cost,
      replenish,
      currencyId,
    } = editAttributeForm.getFieldsValue();

    const response = await editSkuFromArticle(
      id,
      name,
      enable,
      heightWithPackaging,
      lengthWithPackaging,
      widthWithPackaging,
      weightWithPackaging,
      height,
      length,
      width,
      weight,
      volumen,
      kit,
      salesUnit,
      storageUnit,
      shoeSize,
      clothSizeOp1,
      clothSizeOp2,
      colour,
      minimumStock,
      externalId,
      cost,
      replenish,
      currencyId
    );
    if (response) {
      message.success(t("Common-EditSuccessMessage"));
    } else message.error(t("Common-EditErrorMessage"));

    Modal.destroyAll();
    await handleFetchArticle();
    setLoading(false);
  };

  const suppliersColumns = [
    {
      title: t("Common-Name"),
      dataIndex: "supplier",
      key: "name",
      render: (supplier: any) => (
        <a href={`/admin/suppliers/${supplier.id}/view`}>{supplier.name}</a>
      ),
    },
    {
      title: t("Common-PaymentMethod"),
      dataIndex: "commercialTerm",
      key: "supplierPaymentMethod",
      render: (commercialTerm: any) =>
        commercialTerm?.supplierPaymentMethod?.name,
    },
    {
      title: t("Common-DeliveryTerm"),
      dataIndex: "commercialTerm",
      key: "deliveryTerm",
      render: (commercialTerm: any) => commercialTerm?.deliveryTerm,
    },
    {
      title: t("Common-MinimumOrder"),
      dataIndex: "commercialTerm",
      key: "minimumOrder",
      render: (commercialTerm: any) => commercialTerm?.minimumOrder,
    },
    {
      title: t("Common-PurchaseUnit"),
      dataIndex: "purchaseUnit",
      key: "purchaseUnit",
      render: (purchaseUnit: any) => purchaseUnit?.purchaseUnit,
    },
    {
      title: t("Common-Cost"),
      dataIndex: "commercialTerm",
      key: "cost",
      render: (commercialTerm: any) => commercialTerm?.cost,
    },
    {
      title: t("Common-AgreedDiscounts"),
      dataIndex: "commercialTerm",
      key: "agreedDiscounts",
      render: (commercialTerm: any) => commercialTerm?.agreedDiscounts,
    },
    {
      title: t("NewSupplier-Principal"),
      dataIndex: "principal",
      key: "principal",
      render: (principal: boolean) => <>{principal && <CheckOutlined />}</>,
    },
    {
      title: t("Common-Actions"),
      key: "action",
      hidden: !canEdit,
      render: (text: any, record: any) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => {
              setSupplier(record);
              changeSelectedSupplier(record.supplier.id);
              setSuppliers(article.suppliers.map((s: any) => s.supplier));
              setIsEditingSupplierOpen(true);
            }}
          />
          <DeleteOutlined
            onClick={() => {
              showDeleteSupplierConfirm(record.id);
            }}
          />
        </Space>
      ),
    },
  ].filter((item) => !item.hidden);

  const skusColumns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Common-ExternalId"),
      dataIndex: "externalId",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {canEdit ? (
            <>
              <EditOutlined
                onClick={() => {
                  showEditSku(record);
                }}
              />
              <DeleteOutlined
                onClick={() => {
                  showDeleteSkuConfirm(record.id);
                }}
              />
            </>
          ) : (
            <EyeOutlined
              onClick={() => {
                showEditSku(record);
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  const handleEditSupplier = async (data: any) => {
    setLoading(true);

    const {
      id,
      supplierId,
      principal,
      supplierPaymentMethodId,
      deliveryTerm,
      minimumOrder,
      purchaseUnit,
      cost,
      agreedDiscounts,
    } = data;

    const commercialTerm = {
      supplierPaymentMethodId: supplierPaymentMethodId,
      deliveryTerm: deliveryTerm,
      minimumOrder: minimumOrder,
      cost,
      agreedDiscounts,
    };

    const response = await editSupplierToArticle(
      id,
      supplierId,
      principal,
      purchaseUnit,
      commercialTerm
    );

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
      setIsEditingSupplierOpen(false);
    } else message.error(t("Common-EditErrorMessage"));

    Modal.destroyAll();
    await handleFetchArticle();
    setLoading(false);
  };

  const handleSearchCategories = async (value: string) => {
    if (value.length > 2) {
      const response = await getCategoriesFiltered(value);
      setCategories(response);
    } else setCategories([]);
  };

  const handleSearchBrands = async (value: string) => {
    if (value.length > 2) {
      const response = await getBrandsFiltered(value);
      setBrands(response);
    } else setBrands([]);
  };

  const pricesColumn = [
    {
      title: t("Common-BranchOffice"),
      key: "branchOffice",
      render: (article: any) => article?.branchOffice.name,
    },
    {
      title: t("Common-Price"),
      dataIndex: "price",
      key: "price",
    },
    {
      title: t("Common-Date"),
      dataIndex: "date",
      key: "date",
      render: (date: any) =>
        date
          ? moment(date, "YYYY-MM-DDHH:mm:ss").format("DD-MM-YYYY HH:mm:ss")
          : "",
    },
  ];

  const pricesHistoricColumn = [
    {
      title: t("Common-Price"),
      dataIndex: "price",
      key: "price",
    },
    {
      title: t("Common-Date"),
      dataIndex: "date",
      key: "date",
      render: (date: any) =>
        date
          ? moment(date, "YYYY-MM-DDHH:mm:ss").format("DD-MM-YYYY HH:mm:ss")
          : "",
    },
  ];

  const seasonsColumn = [
    {
      title: t("Common-Name"),
      key: "name",
      render: (seasonArticle: any) => seasonArticle.season?.name,
    },
    {
      title: t("Common-Actions"),
      key: "action",
      hidden: !canEdit,
      render: (text: any, record: any) => (
        <Space size="middle">
          <DeleteOutlined
            onClick={() => {
              showDeleteSeasonConfirm(record.id);
            }}
          />
        </Space>
      ),
    },
  ].filter((item) => !item.hidden);

  const curvesColumn = [
    {
      title: t("Common-Name"),
      key: "name",
      render: (curve: any) => (
        <a href={`/admin/curves/${curve.id}/view`}>{curve.name}</a>
      ),
    },
    {
      title: t("Common-SizeStart"),
      dataIndex: "sizeStart",
    },
    {
      title: t("Common-SizeEnd"),
      dataIndex: "sizeEnd",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      hidden: !canEdit,
      render: (text: any, record: any) => (
        <Space size="middle">
          <DeleteOutlined
            onClick={() => {
              showDeleteCurveConfirm(record.id);
            }}
          />
        </Space>
      ),
    },
  ].filter((item) => !item.hidden);

  const attributesColumn = [
    {
      title: t("Common-Attribute"),
      dataIndex: "attribute",
      key: "attribute",
      render: (attribute: any) => attribute?.attribute,
    },
    {
      title: t("Common-Value"),
      dataIndex: "valueEnum",
      key: "valueEnum",
      render: (valueEnum: any) => valueEnum?.value,
    },
    {
      title: t("Common-Actions"),
      key: "action",
      hidden: !canEdit,
      render: (text: any, record: any) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => {
              showEditAttribute(record);
            }}
          />
          <DeleteOutlined
            onClick={() => {
              showDeleteAttributeConfirm(record.id);
            }}
          />
        </Space>
      ),
    },
  ].filter((item) => !item.hidden);

  const attributesSkuColumn = [
    {
      title: t("Common-Attribute"),
      dataIndex: "attribute",
      key: "attribute",
      render: (attribute: any) => attribute?.attribute,
    },
    {
      title: t("Common-Value"),
      dataIndex: "valueEnum",
      key: "valueEnum",
      render: (valueEnum: any) => valueEnum?.value,
    },
    {
      title: t("Common-Actions"),
      key: "action",
      hidden: !canEdit,
      render: (text: any, record: any) => (
        <Space size="middle">
          {/*<EditOutlined*/}
          {/*  onClick={() => {*/}
          {/*    showEditSkuAttribute(record);*/}
          {/*  }}*/}
          {/*/>*/}
          <DeleteOutlined
            onClick={() => {
              showDeleteSkuAttributeConfirm(record.id);
            }}
          />
        </Space>
      ),
    },
  ].filter((item) => !item.hidden);

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !article ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Article")}</h1>
        {hasPermission(privilegesEnum.WRITE_ARTICLES) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEditArticleInfo}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.articleInfoWrapper}>
          <Form
            name="articleInfo"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.articleForm}
            form={infoArticleForm}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              initialValue={article?.name}
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input disabled={!canEdit} placeholder={t("Common-Name")} />
            </Form.Item>

            <Form.Item
              label={t("Common-ExternalId")}
              name="externalId"
              initialValue={article?.externalId}
            >
              <Input disabled={!canEdit} placeholder={t("Common-ExternalId")} />
            </Form.Item>

            <Form.Item
              label={t("Common-Description")}
              name="description"
              initialValue={article?.description}
            >
              <Input
                disabled={!canEdit}
                placeholder={t("Common-Description")}
              />
            </Form.Item>

            <Form.Item
              label={t("Common-WebDescription")}
              name="webDescription"
              initialValue={article?.webDescription}
            >
              <Input
                disabled={!canEdit}
                placeholder={t("Common-WebDescription")}
              />
            </Form.Item>

            <Form.Item
              label={t("Common-Category")}
              name="categoryId"
              initialValue={article?.category?.id}
              rules={[
                {
                  required: true,
                  message: t("Common-Category"),
                },
              ]}
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                defaultValue={article?.category?.id}
                disabled={!canEdit}
                showSearch
                onSearch={handleSearchCategories}
                placeholder={t("Common-SearchByName")}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {getOptionsCategories(categories)}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("Common-Brand")}
              name="brandId"
              initialValue={article?.brand?.id}
              rules={[
                {
                  required: true,
                  message: t("Common-Brand"),
                },
              ]}
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                defaultValue={article?.brand?.id}
                disabled={!canEdit}
                showSearch
                onSearch={handleSearchBrands}
                placeholder={t("Common-SearchByName")}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {getOptions(brands)}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("Common-Currency")}
              name="currencyId"
              initialValue={article?.currency?.id}
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder=""
                defaultValue={article?.currency?.id}
                disabled={!canEdit}
              >
                {getOptions(currencies)}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("Common-RoundingType")}
              name="roundingTypeId"
              initialValue={article?.roundingType?.id}
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder=""
                defaultValue={article?.roundingType?.id}
                disabled={!canEdit}
              >
                {getOptionsDescription(roundingTypes)}
              </Select>
            </Form.Item>
            <Form.Item
              initialValue={article?.originType}
              label={t("Common-OriginType")}
              name="originType"
            >
              <Select allowClear style={{ width: "100%" }} placeholder="">
                <Select.Option value="BUY_IN_SQUARE">
                  {t("Common-BuyInSquare")}
                </Select.Option>
                <Select.Option value="IMPORTED_ARTICLE">
                  {t("Common-ImportedArticle")}
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={t("Common-Cost")}
              name="cost"
              rules={[
                {
                  required: true,
                  message: t("Common-Cost"),
                },
              ]}
              initialValue={article?.cost}
            >
              <Input disabled={!canEdit} placeholder={t("Common-Cost")} />
            </Form.Item>
            <Form.Item
              label={t("Common-DefaultPrice")}
              name="defaultPrice"
              initialValue={article?.defaultPrice}
              rules={[
                {
                  required: true,
                  message: t("Common-DefaultPrice"),
                },
              ]}
            >
              <Input
                disabled={!canEdit}
                placeholder={t("Common-DefaultPrice")}
              />
            </Form.Item>
            <Form.Item
              initialValue={article?.minimumPrice}
              label={t("Common-MinimumPrice")}
              name="minimumPrice"
            >
              <InputNumber
                disabled={!canEdit}
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-MinimumPrice")}
              />
            </Form.Item>
            <Form.Item
              initialValue={article?.maximumPrice}
              label={t("Common-MaximumPrice")}
              name="maximumPrice"
            >
              <InputNumber
                type="number"
                disabled={!canEdit}
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-MaximumPrice")}
              />
            </Form.Item>
            <Form.Item
              initialValue={article?.minimumMargin}
              label={t("Common-MinimumMargin")}
              name="minimumMargin"
            >
              <InputNumber
                disabled={!canEdit}
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-MinimumMargin")}
              />
            </Form.Item>
            <Form.Item
              label={`${t("Common-Photo")} 1`}
              name="photo1"
              initialValue={article?.photo1}
            >
              <Input disabled={!canEdit} placeholder={t("Common-Photo")} />
            </Form.Item>
            <Image width={200} src={article?.photo1} />
            <Form.Item
              label={`${t("Common-Photo")} 2`}
              name="photo2"
              initialValue={article?.photo2}
            >
              <Input disabled={!canEdit} placeholder={t("Common-Photo")} />
            </Form.Item>
            <Image width={200} src={article?.photo2} />
            <Form.Item
              label={`${t("Common-Photo")} 3`}
              name="photo3"
              initialValue={article?.photo3}
            >
              <Input disabled={!canEdit} placeholder={t("Common-Photo")} />
            </Form.Item>
            <Image width={200} src={article?.photo3} />
            <Form.Item
              label={t("Common-Active")}
              name="enable"
              initialValue={article?.enable}
            >
              <Switch disabled={!canEdit} defaultChecked={article?.enable} />
            </Form.Item>
            <Form.Item
              label={t("Common-Discontinued")}
              name="discontinued"
              initialValue={article?.discontinued}
            >
              <Switch
                disabled={!canEdit}
                defaultChecked={article?.discontinued}
              />
            </Form.Item>
          </Form>
        </div>
        <div className={styles.suppliersWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.tablesTitle}>{t("Common-Suppliers")}</h1>
            {canEdit && (
              <Button
                type="primary"
                className={styles.actionsButton}
                onClick={() => handleOpenAddSupplierModal()}
                icon={<PlusOutlined />}
              />
            )}
          </div>
          <div className={styles.supplierTableWrapper}>
            <Table
              size="small"
              className={styles.suppliersTable}
              rowKey={(record) => record.id}
              loading={loading}
              columns={suppliersColumns}
              dataSource={article?.suppliers}
            />
          </div>
        </div>
        <div className={styles.skusWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.tablesTitle}>{t("Common-Skus")}</h1>
            {canEdit && (
              <Button
                type="primary"
                className={styles.actionsButton}
                onClick={() => handleOpenAddSkuModal()}
                icon={<PlusOutlined />}
              />
            )}
          </div>
          <div className={styles.skusTableWrapper}>
            <Table
              size="small"
              rowKey={(record) => record.id}
              className={styles.skusTable}
              loading={loading}
              columns={skusColumns}
              dataSource={article?.skus}
            />
          </div>
        </div>
        <div className={styles.attributesWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.tablesTitle}>{t("Common-Attributes")}</h1>
            {canEdit && (
              <Button
                type="primary"
                className={styles.actionsButton}
                onClick={() => handleOpenAddAttributeModal()}
                icon={<PlusOutlined />}
              />
            )}
          </div>
          <div className={styles.attributesTableWrapper}>
            <Table
              size="small"
              className={styles.attributesTable}
              loading={loading}
              columns={attributesColumn}
              dataSource={article?.attributesWithValue}
            />
          </div>
        </div>
        <div className={styles.attributesWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.tablesTitle}>{t("Common-Prices")}</h1>
            {canEdit && (
              <Button
                type="primary"
                className={styles.actionsButton}
                onClick={() => handleOpenAddPriceModal()}
                icon={<PlusOutlined />}
              />
            )}
          </div>
          <div className={styles.attributesTableWrapper}>
            <Table
              size="small"
              className={styles.attributesTable}
              loading={loading}
              columns={pricesColumn}
              dataSource={article?.actualPrices.map((price: any) => {
                const actualPrice = price;
                actualPrice.key = price.id;
                return actualPrice;
              })}
              expandable={{
                expandedRowRender: (record) => {
                  const prices: any[] = [];
                  article?.prices.forEach((price: any) => {
                    if (price.branchOffice.id === record.branchOffice.id)
                      prices.push(price);
                  });
                  return (
                    <Table
                      size="small"
                      loading={loading}
                      columns={pricesHistoricColumn}
                      dataSource={prices.sort((a, b) =>
                        moment(a.date, "YYYY-MM-DDHH:mm:ss").diff(
                          moment(b.date, "YYYY-MM-DDHH:mm:ss")
                        )
                      )}
                      pagination={false}
                    />
                  );
                },
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <SearchOutlined onClick={(e) => onExpand(record, e)} />
                  ) : (
                    <SearchOutlined onClick={(e) => onExpand(record, e)} />
                  ),
              }}
            />
          </div>
        </div>
        <div className={styles.attributesWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.tablesTitle}>{t("Common-Seasons")}</h1>
            {canEdit && (
              <Button
                type="primary"
                className={styles.actionsButton}
                onClick={() => handleOpenAddSeasonModal()}
                icon={<PlusOutlined />}
              />
            )}
          </div>
          <div className={styles.attributesTableWrapper}>
            <Table
              size="small"
              className={styles.attributesTable}
              loading={loading}
              columns={seasonsColumn}
              dataSource={article?.articleSeasons}
            />
          </div>
        </div>
        <div className={styles.attributesWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.tablesTitle}>{t("Common-Curves")}</h1>
            {canEdit && (
              <Button
                type="primary"
                className={styles.actionsButton}
                onClick={() => handleOpenAddCurveModal()}
                icon={<PlusOutlined />}
              />
            )}
          </div>
          <div className={styles.attributesTableWrapper}>
            <Table
              size="small"
              className={styles.attributesTable}
              loading={loading}
              columns={curvesColumn}
              dataSource={article?.curves}
            />
          </div>
        </div>
      </div>
      <Modal
        title={t("Common-Supplier")}
        visible={isAddSupplierModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelAddSupplierModal}
      >
        <Form
          name="addSupplier"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={addSupplierForm}
          layout="vertical"
        >
          <Form.Item
            className={styles.modalInfoItem}
            label={t("Common-Supplier")}
            name="supplierId"
            rules={[
              {
                required: true,
                message: t("Common-Supplier"),
              },
            ]}
          >
            <Select
              showSearch
              placeholder={t("Common-SearchByName")}
              allowClear
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={handleSearchSuppliers}
              onChange={changeSelectedSupplier}
              options={(suppliers || []).map((s: any) => {
                const articleSuppliers = article.suppliers;

                const disabled = articleSuppliers.find(
                  (a: any) => a.supplier.id === s.id
                );
                return {
                  disabled: disabled,
                  value: s.id,
                  label: s.name,
                };
              })}
            />
          </Form.Item>
          <Form.Item
            className={styles.modalInfoItem}
            name="supplierPaymentMethodId"
            label={t("NewSupplier-PaymentMethod")}
          >
            <Select
              className={styles.supplierInfoItem}
              placeholder={t("NewSupplier-PaymentMethod")}
            >
              {getOptions(paymentMethodsSupplier)}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.modalInfoItem}
            name="deliveryTerm"
            label={t("NewSupplier-DeliveryTerm")}
          >
            <Input
              className={styles.modalInfoItem}
              placeholder={t("NewSupplier-DeliveryTerm")}
            />
          </Form.Item>
          <Form.Item
            className={styles.modalInfoItem}
            name="minimumOrder"
            label={t("NewSupplier-MinimumOrder")}
            rules={[
              {
                required: true,
                message: t("NewSupplier-MinimumOrder"),
              },
            ]}
          >
            <Input placeholder={t("NewSupplier-MinimumOrder")} />
          </Form.Item>
          <Form.Item
            className={styles.modalInfoItem}
            label={t("NewSupplier-PurchaseUnit")}
            name="purchaseUnit"
          >
            <Select
              allowClear
              placeholder={t("Common-SearchByName")}
              showSearch
              onSearch={handleSearchPurchaseUnits}
              optionFilterProp="children"
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getOptionsPurchaseUnit(purchaseUnits)}
            </Select>
          </Form.Item>
          <Form.Item
            label={t("Common-Cost")}
            className={styles.modalInfoItem}
            name="cost"
            rules={[
              {
                required: true,
                message: t("Common-Cost"),
              },
            ]}
          >
            <Input placeholder={t("Common-Cost")} />
          </Form.Item>
          <Form.Item
            label={t("Common-AgreedDiscounts")}
            className={styles.modalInfoItem}
            name={"agreedDiscounts"}
            rules={[
              {
                required: true,
                message: t("Common-AgreedDiscounts"),
              },
            ]}
          >
            <Input placeholder={t("Common-AgreedDiscounts")} />
          </Form.Item>
          <Form.Item
            initialValue={false}
            label={t("NewSupplier-Principal")}
            name="principal"
          >
            <Switch defaultChecked={false} />
          </Form.Item>
        </Form>
        <Button
          className={styles.addButton}
          type="primary"
          onClick={handleAddSupplier}
        >
          {t("Common-Add")}
        </Button>
      </Modal>
      <Modal
        title={t("Common-Price")}
        visible={isAddPriceModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelAddPriceModal}
      >
        <Form
          name="addPrice"
          autoComplete="off"
          form={addPriceForm}
          layout="vertical"
        >
          <Form.Item
            label={t("Common-BranchOffice")}
            name="branchOfficeId"
            rules={[
              {
                required: true,
                message: t("Common-BranchOffice"),
              },
            ]}
          >
            <Select
              onSearch={handleSearchBranchOffices}
              showSearch
              placeholder={t("Common-SearchByName")}
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getOptions(branchOffices)}
            </Select>
          </Form.Item>

          <Form.Item
            label={t("Common-Value")}
            name="price"
            rules={[
              {
                required: true,
                message: t("Common-Price"),
              },
            ]}
          >
            <Input placeholder={t("Common-Price")} />
          </Form.Item>
          <Form.Item
            label={t("Common-Date")}
            name="date"
            rules={[
              {
                required: true,
                message: t("Common-Date"),
              },
            ]}
          >
            <DatePicker
              className={styles.dateSelector}
              showTime
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
        </Form>
        <Button
          className={styles.addButton}
          type="primary"
          onClick={handleAddPrice}
        >
          {t("Common-Add")}
        </Button>
      </Modal>
      <Modal
        title={t("Common-Season")}
        visible={isAddSeasonModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelAddSeasonModal}
      >
        <Form
          name="addSeason"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={addSeasonForm}
        >
          <Form.Item
            className={styles.modalInfoItem}
            label={t("Common-Season")}
            name="seasonId"
            rules={[
              {
                required: true,
                message: t("Common-Season"),
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
              placeholder={t("Common-SearchByName")}
              onSearch={handleSearchSeasons}
            >
              {getOptions(seasons)}
            </Select>
          </Form.Item>
        </Form>
        <Button
          className={styles.addButton}
          type="primary"
          onClick={handleAddSeason}
        >
          {t("Common-Add")}
        </Button>
      </Modal>
      <Modal
        title={t("Common-Curve")}
        visible={isAddCurveModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelAddCurveModal}
      >
        <Form
          name="addCurve"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={addCurveForm}
        >
          <Form.Item
            className={styles.modalInfoItem}
            label={t("Common-Curve")}
            name="curveId"
            rules={[
              {
                required: true,
                message: t("Common-Curve"),
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
              placeholder={t("Common-SearchByName")}
              onSearch={handleSearchCurves}
            >
              {getOptions(curves)}
            </Select>
          </Form.Item>
        </Form>
        <Button
          className={styles.addButton}
          type="primary"
          onClick={handleAddCurve}
        >
          {t("Common-Add")}
        </Button>
      </Modal>
      <Modal
        title={t("Common-Attribute")}
        visible={isAddAttributeModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelAddAttributeModal}
      >
        <Form
          name="addAttribute"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={addAttributeForm}
        >
          <Form.Item
            className={styles.modalInfoItem}
            label={t("Common-Attribute")}
            name="attributeId"
            rules={[
              {
                required: true,
                message: t("Common-Attribute"),
              },
            ]}
          >
            <Cascader
              className={styles.cascader}
              showSearch
              onSearch={(value) => setAttributeFilterValue(value)}
              options={attributes?.map((attribute) => {
                return {
                  disabled: attribute.enums.length === 0,
                  value: attribute.id,
                  label: attribute.attribute,
                  children: attribute.enums?.map((possibleValue: any) => {
                    return {
                      value: possibleValue.id,
                      label: possibleValue.value,
                    };
                  }),
                };
              })}
            />
          </Form.Item>
        </Form>
        <Button
          className={styles.addButton}
          type="primary"
          onClick={handleAddAttribute}
        >
          {t("Common-Add")}
        </Button>
      </Modal>
      <Modal
        title={t("Common-NewSku")}
        visible={isAddSkuModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelAddSkuModal}
        width={800}
      >
        <Form
          name="newSku"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          className={styles.skusForm}
          form={addSkuForm}
        >
          <div className={styles.skusItemsWrapper}>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-ExternalId")}
              name="externalId"
            >
              <Input placeholder={t("Common-ExternalId")} />
            </Form.Item>

            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-StorageUnit")}
              name="storageUnit"
            >
              <Input placeholder={t("Common-StorageUnit")} />
            </Form.Item>
          </div>
          <div className={styles.skusItemsWrapper}>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-Height")}
              name="height"
            >
              <Input type="number" placeholder={t("Common-Height")} />
            </Form.Item>

            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-Length")}
              name="length"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-Length")}
              />
            </Form.Item>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-Width")}
              name="width"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-Width")}
              />
            </Form.Item>
          </div>
          <div className={styles.skusItemsWrapper}>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-Weight")}
              name="weight"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-Weight")}
              />
            </Form.Item>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-Volumen")}
              name="volumen"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-Volumen")}
              />
            </Form.Item>

            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-MinimumStock")}
              name="minimumStock"
              initialValue={1}
              rules={[
                {
                  required: true,
                  message: t("Common-MinimumStock"),
                },
              ]}
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                defaultValue={1}
                placeholder={t("Common-MinimumStock")}
              />
            </Form.Item>
          </div>
          <div className={styles.skusItemsWrapper}>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-HeightWithPackaging")}
              name="heightWithPackaging"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-HeightWithPackaging")}
              />
            </Form.Item>

            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-LengthWithPackaging")}
              name="lengthWithPackaging"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-LengthWithPackaging")}
              />
            </Form.Item>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-WidthWithPackaging")}
              name="widthWithPackaging"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-WidthWithPackaging")}
              />
            </Form.Item>
          </div>
          <div className={styles.skusItemsWrapper}>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-WeightWithPackaging")}
              name="weightWithPackaging"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-WeightWithPackaging")}
              />
            </Form.Item>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-Cost")}
              name="cost"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-Cost")}
              />
            </Form.Item>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-SalesUnit")}
              name="salesUnit"
            >
              <Input placeholder={t("Common-SalesUnit")} />
            </Form.Item>
          </div>
          <div className={styles.skusItemsWrapper}>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-Color")}
              name="colour"
            >
              <Select
                className={styles.type}
                placeholder={t("Common-SearchByName")}
                onSearch={handleSearchColours}
                allowClear
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                loading={loadingColours}
                showSearch
                style={{ width: "100%", minWidth: "150px" }}
              >
                {getOptions(colours)}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-Currency")}
              name="currencyId"
            >
              <Select
                allowClear
                style={{ width: "100%", minWidth: "150px" }}
                placeholder=""
                disabled={!canEdit}
              >
                {getOptions(currencies)}
              </Select>
            </Form.Item>
          </div>
          <div className={styles.skusItemsWrapper}>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-ShoeSize")}
              name="shoeSize"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-ShoeSize")}
              />
            </Form.Item>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-ClothSizeOp1")}
              name="clothSizeOp1"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-ClothSizeOp1")}
              />
            </Form.Item>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-ClothSizeOp2")}
              name="clothSizeOp2"
            >
              <Input placeholder={t("Common-ClothSizeOp2")} />
            </Form.Item>
          </div>
          <div className={styles.skusItemsWrapper}>
            <Form.Item
              initialValue={false}
              label={t("Common-Replenish")}
              name="replenish"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              initialValue={false}
              label={t("Common-Active")}
              name="enable"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              initialValue={false}
              label={t("Common-IsKit")}
              name="kit"
            >
              <Switch />
            </Form.Item>
          </div>
        </Form>
        <h1 className={styles.skusTitle}>{t("Common-Attributes")}</h1>

        <Form
          name="attributesSku"
          layout="vertical"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 12 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          className={styles.addAttributeForm}
          form={addSkuAttributesForm}
        >
          <Form.List name="attributes">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} className={styles.skuSpace} align="baseline">
                    <Form.Item
                      {...restField}
                      className={styles.skuInfoItem}
                      name={[name, "attributeId"]}
                      rules={[
                        {
                          required: true,
                          message: t("Common-Attribute"),
                        },
                      ]}
                    >
                      <Cascader
                        className={styles.cascader}
                        onChange={handleChangeAttribute}
                        options={attributeOptions()}
                        showSearch
                        onSearch={(value) => setAttributeFilterValue(value)}
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="primary"
                    className={styles.actionsButton}
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
        <Button
          className={styles.newSkuButton}
          type="primary"
          onClick={validateAddSku}
        >
          {t("Common-Add")}
        </Button>
      </Modal>
      <Modal
        title={t("EditArticle-Supplier")}
        visible={isEditingSupplierOpen}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => {
          setIsEditingSupplierOpen(false);
        }}
        width={500}
      >
        {supplier && (
          <Form
            name="addSupplier"
            initialValues={{ remember: true }}
            autoComplete="off"
            onFinish={handleEditSupplier}
            preserve={false}
            layout="vertical"
          >
            <Form.Item hidden initialValue={supplier.id} name="id" />
            <Form.Item
              className={styles.modalInfoItem}
              label={t("Common-Supplier")}
              name="supplierId"
              rules={[
                {
                  required: true,
                  message: t("Common-Supplier"),
                },
              ]}
              initialValue={supplier.supplier.id}
            >
              <Select
                showSearch
                placeholder={t("Common-SearchByName")}
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearchSuppliers}
                onChange={changeSelectedSupplier}
                options={(suppliers || []).map((s: any) => {
                  const articleSuppliers = article.suppliers;

                  const disabled = articleSuppliers.find(
                    (a: any) => a.supplier.id === s.id
                  );
                  return {
                    disabled: disabled,
                    value: s.id,
                    label: s.name,
                  };
                })}
              ></Select>
            </Form.Item>
            <Form.Item
              className={styles.modalInfoItem}
              name="supplierPaymentMethodId"
              label={t("NewSupplier-PaymentMethod")}
              initialValue={supplier.commercialTerm?.supplierPaymentMethod?.id}
            >
              <Select
                className={styles.supplierInfoItem}
                placeholder={t("NewSupplier-PaymentMethod")}
              >
                {getOptions(paymentMethodsSupplier)}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles.modalInfoItem}
              name="deliveryTerm"
              label={t("NewSupplier-DeliveryTerm")}
              initialValue={supplier.commercialTerm.deliveryTerm}
              rules={[
                {
                  required: true,
                  message: t("Common-DeliveryTerm"),
                },
              ]}
            >
              <Input
                className={styles.modalInfoItem}
                placeholder={t("NewSupplier-DeliveryTerm")}
              />
            </Form.Item>
            <Form.Item
              className={styles.modalInfoItem}
              name="minimumOrder"
              label={t("NewSupplier-MinimumOrder")}
              rules={[
                {
                  required: true,
                  message: t("NewSupplier-MinimumOrder"),
                },
              ]}
              initialValue={supplier.commercialTerm.minimumOrder}
            >
              <Input placeholder={t("NewSupplier-MinimumOrder")} />
            </Form.Item>
            <Form.Item
              className={styles.modalInfoItem}
              label={t("NewSupplier-PurchaseUnit")}
              name="purchaseUnit"
              initialValue={supplier.purchaseUnit?.id}
            >
              <Select
                allowClear
                placeholder={t("Common-SearchByName")}
                showSearch
                onSearch={handleSearchPurchaseUnits}
                optionFilterProp="children"
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {getOptionsPurchaseUnit(purchaseUnits)}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("Common-Cost")}
              className={styles.modalInfoItem}
              name={"cost"}
              rules={[
                {
                  required: true,
                  message: t("Common-Cost"),
                },
              ]}
              initialValue={supplier.commercialTerm.cost}
            >
              <Input
                className={styles.modalInfoItem}
                placeholder={t("Common-Cost")}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-AgreedDiscounts")}
              className={styles.modalInfoItem}
              name={"agreedDiscounts"}
              rules={[
                {
                  required: true,
                  message: t("Common-AgreedDiscounts"),
                },
              ]}
              initialValue={supplier.commercialTerm.agreedDiscounts}
            >
              <Input
                className={styles.modalInfoItem}
                placeholder={t("Common-AgreedDiscounts")}
              />
            </Form.Item>
            <Form.Item
              initialValue={supplier.principal}
              label={t("NewSupplier-Principal")}
              name="principal"
            >
              <Switch defaultChecked={supplier.principal} />
            </Form.Item>
            <Button
              className={styles.addButton}
              type="primary"
              htmlType="submit"
            >
              {t("Common-Save")}
            </Button>
          </Form>
        )}
      </Modal>
    </div>
  );
};

export default ViewArticle;
