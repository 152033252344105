import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  addArticleToSupplier,
  addContactToSupplier,
  getSupplierById,
  editSupplier,
  deleteContact,
  deleteArticleSupplier,
  editContact,
  editArticleToSupplier,
  getAllBusinessUnits,
  getAllSuppliersPaymentMethods,
  getPurchaseUnitsFiltered,
  getArticlesFiltered,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import { BusinessUniteContext } from "../../components/laoyut/Layout";
import {
  Form,
  message,
  Select,
  Modal,
  Button,
  Input,
  Switch,
  Spin,
  Table,
  Space,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  CheckOutlined,
} from "@ant-design/icons";

import styles from "./ViewSupplier.module.scss";
import NotFound from "../notfound/NotFound";

const ViewSupplier = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    contextValue: businessUnitSelected,
    updateContextValue: setBusinessUnitSelected,
  } = useContext(BusinessUniteContext);

  const [loading, setLoading] = useState(true);
  const [supplier, setSupplier] = useState<any>();
  const [isAddContactModalVisible, setIsAddContactModalVisible] =
    useState(false);
  const [isAddArticleModalVisible, setIsAddArticleModalVisible] =
    useState(false);
  const [articles, setArticles] = useState<any[]>([]);
  const [businessUnits, setBusinessUnits] = useState<any[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [purchaseUnits, setPurchaseUnits] = useState<any[]>([]);

  const [infoSupplierForm] = Form.useForm();
  const [addContactForm] = Form.useForm();
  const [addArticleForm] = Form.useForm();

  const canEdit = hasPermission(privilegesEnum.WRITE_SUPPLIERS);

  const { id } = useParams();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_SUPPLIERS)) {
      handleFetchSupplier();
      handleFetchBusinessUnits();
      handleFetchPaymentMethods();
    }
  }, []);
  useEffect(() => {
    if (
      supplier &&
      !supplier.businessUnits
        .map((businessUnit: any) => businessUnit.id)
        .includes(businessUnitSelected) &&
      businessUnitSelected &&
      !loading
    ) {
      navigate("/admin/suppliers");
    }
  }, [businessUnitSelected]);

  const handleFetchSupplier = async () => {
    let response = await getSupplierById(id!);
    setSupplier(response);
    setArticles(
      response.articles?.map((articleTerm: any) => articleTerm.article)
    );
    setBusinessUnitSelected(response.businessUnits[0].id);

    const existingPurchaseUnits = response.articles
      ?.map((article: any) => article.purchaseUnit)
      .filter((purchaseUnit: any) => purchaseUnit !== null);

    setPurchaseUnits(existingPurchaseUnits ?? []);
    setLoading(false);
  };

  const handleFetchBusinessUnits = async () => {
    const response = await getAllBusinessUnits();
    setBusinessUnits(response);
  };

  const handleSearchPurchaseUnits = async (value: string) => {
    if (value.length > 2) {
      const response = await getPurchaseUnitsFiltered(value);
      setPurchaseUnits(response);
    } else setPurchaseUnits([]);
  };

  const handleFetchPaymentMethods = async () => {
    const response = await getAllSuppliersPaymentMethods();
    setPaymentMethods(response);
  };

  const handleFetchArticles = async (nameFilterValue: string) => {
    const response = await getArticlesFiltered(0, {
      articleName: nameFilterValue,
    });
    setArticles(response.articles);
  };
  const handleCancelButton = () => {
    infoSupplierForm.resetFields();
  };

  const handleEditSupplier = async () => {
    setLoading(true);

    const {
      name,
      website,
      externalId,
      catalogueLink,
      supplierPaymentMethodsIds,
    } = infoSupplierForm.getFieldsValue();

    const response = await editSupplier(
      id!,
      name,
      externalId,
      website,
      catalogueLink,
      [businessUnitSelected] ?? [],
      supplierPaymentMethodsIds ?? []
    );

    if (response) message.success(t("Common-EditSuccessMessage"));
    else message.error(t("Common-EditErrorMessage"));
    setLoading(false);
  };

  const getArticlesOptions = () => {
    const options: any[] = [];
    const suppliersArticles = supplier.articles;

    articles?.forEach(function (item: any) {
      const disabled = suppliersArticles.find(
        (s: any) => s.article.id === item.id
      );
      options.push(
        <Select.Option value={item.id} key={item.id} disabled={disabled}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const getArticlesOptionsEditing = (articleEditingId: number) => {
    const options: any[] = [];
    const suppliersArticles = supplier.articles;

    articles?.forEach(function (item: any) {
      const disabled = suppliersArticles.find(
        (s: any) =>
          s.article.id === item.id && s.article.id !== articleEditingId
      );
      options.push(
        <Select.Option value={item.id} key={item.id} disabled={disabled}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const handleOpenAddContactModal = () => {
    setIsAddContactModalVisible(true);
  };

  const handleCancelAddContactModal = () => {
    setIsAddContactModalVisible(false);
  };

  const handleOpenAddArticleModal = () => {
    setIsAddArticleModalVisible(true);
  };

  const handleCancelAddArticleModal = () => {
    setIsAddArticleModalVisible(false);
  };

  const handleAddContact = async () => {
    setLoading(true);

    const contact = addContactForm.getFieldsValue();

    const { name, role, phoneNumber, email } = contact;

    const response = await addContactToSupplier(
      parseInt(id!),
      name,
      role,
      phoneNumber,
      email
    );

    if (response) {
      addContactForm.resetFields();
      await handleFetchSupplier();
      handleCancelAddContactModal();
      message.success(t("Common-AddSuccessMessage"));
    } else message.error(t("Common-ErrorMessage"));
    setLoading(false);
  };

  const handleAddArticle = async () => {
    setLoading(true);

    const article = addArticleForm.getFieldsValue();

    const {
      articleId,
      supplierPaymentMethodId,
      deliveryTerm,
      minimumOrder,
      purchaseUnit,
      cost,
      agreedDiscounts,
      principal,
    } = article;

    const commercialTerm = {
      deliveryTerm: deliveryTerm,
      minimumOrder: minimumOrder,
      cost: parseInt(cost),
      agreedDiscounts,
      supplierPaymentMethodId,
    };

    const response = await addArticleToSupplier(
      articleId,
      principal,
      parseInt(id!),
      purchaseUnit,
      commercialTerm
    );

    if (response) {
      addArticleForm.resetFields();
      await handleFetchSupplier();
      handleCancelAddArticleModal();
      message.success(t("Common-AddSuccessMessage"));
    } else message.error(t("Common-ErrorMessage"));
    setLoading(false);
  };

  const contactsColumns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Common-Rol"),
      dataIndex: "role",
      key: "role",
    },
    {
      title: t("NewSupplier-ContactNumber"),
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: t("NewSupplier-ContactEmail"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => {
              showEditContactModal(record);
            }}
          />
          <DeleteOutlined
            onClick={() => {
              showDeleteContactConfirm(record.id);
            }}
          />
        </Space>
      ),
      hidden: !canEdit,
    },
  ].filter((item) => !item.hidden);

  const showEditContactModal = (contact: any) => {
    Modal.info({
      title: t("EditSupplier-Contact"),
      closable: true,
      maskClosable: true,
      okButtonProps: { style: { display: "none" } },
      width: 500,
      icon: null,
      content: (
        <Form
          name="editContact"
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={handleEditContact}
          preserve={false}
        >
          <Form.Item hidden initialValue={contact.id} name="id" />
          <Form.Item
            className={styles.contactItem}
            label={t("NewSupplier-ContactName")}
            name="name"
            rules={[
              {
                required: true,
                message: t("NewSupplier-ContactNameNotEntered"),
              },
            ]}
            initialValue={contact.name}
          >
            <Input placeholder={t("NewSupplier-ContactName")} />
          </Form.Item>
          <Form.Item
            className={styles.contactItem}
            label={t("Common-Rol")}
            name="role"
            initialValue={contact.role}
          >
            <Input placeholder={t("Common-Rol")} />
          </Form.Item>
          <Form.Item
            className={styles.contactItem}
            label={t("NewSupplier-ContactNumber")}
            name="phoneNumber"
            initialValue={contact.phoneNumber}
          >
            <Input placeholder={t("NewSupplier-ContactNumber")} />
          </Form.Item>
          <Form.Item
            className={styles.contactItem}
            label={t("NewSupplier-ContactEmail")}
            name="email"
            rules={[
              {
                type: "email",
                message: t("NewSupplier-ContactEmailNotEntered"),
              },
            ]}
            initialValue={contact.email}
          >
            <Input placeholder={t("NewSupplier-ContactEmailNotEntered")} />
          </Form.Item>
          <Button
            className={styles.addContactButton}
            type="primary"
            htmlType="submit"
          >
            {t("Common-Save")}
          </Button>
        </Form>
      ),
    });
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const handleEditContact = async (data: any) => {
    setLoading(true);

    const { id, role, name, email, phoneNumber } = data;

    let response = await editContact(id, name, role, email, phoneNumber);

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
    } else message.error(t("Common-EditErrorMessage"));

    Modal.destroyAll();
    await handleFetchSupplier();
    setLoading(false);
  };

  const showDeleteContactConfirm = (contactId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteContact(contactId);
      },
    });
  };

  const getOptionsPurchaseUnit = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.purchaseUnit}
        </Select.Option>
      );
    });

    return options;
  };

  const handleDeleteContact = async (contactId: number) => {
    setLoading(true);
    const deleteStatus = await deleteContact(contactId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchSupplier();
    setLoading(false);
  };

  const articleColumn = [
    {
      title: t("Common-Name"),
      dataIndex: "article",
      key: "article",
      render: (article: any) => article?.name,
    },
    {
      title: t("NewSupplier-DeliveryTerm"),
      dataIndex: "commercialTerm",
      key: "deliveryTerm",
      render: (commercialTerm: any) => commercialTerm?.deliveryTerm,
    },
    {
      title: t("NewSupplier-MinimumOrder"),
      dataIndex: "commercialTerm",
      key: "minimumOrder",
      render: (commercialTerm: any) => commercialTerm?.minimumOrder,
    },
    {
      title: t("NewSupplier-PurchaseUnit"),
      dataIndex: "purchaseUnit",
      key: "purchaseUnit",
      render: (purchaseUnit: any) => purchaseUnit?.purchaseUnit,
    },
    {
      title: t("Common-Cost"),
      dataIndex: "commercialTerm",
      key: "cost",
      render: (commercialTerm: any) => commercialTerm?.cost,
    },
    {
      title: t("Common-AgreedDiscounts"),
      dataIndex: "commercialTerm",
      key: "agreedDiscounts",
      render: (commercialTerm: any) => commercialTerm?.agreedDiscounts,
    },
    {
      title: t("NewSupplier-Principal"),
      dataIndex: "principal",
      key: "principal",
      render: (principal: boolean) => <>{principal && <CheckOutlined />}</>,
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => {
              showEditArticleModal(record);
            }}
          />
          <DeleteOutlined
            onClick={() => {
              showDeleteArticleConfirm(record.id);
            }}
          />
        </Space>
      ),
      hidden: !canEdit,
    },
  ].filter((item) => !item.hidden);

  const showEditArticleModal = (article: any) => {
    Modal.info({
      title: t("Articles-EditArticle"),
      closable: true,
      maskClosable: true,
      okButtonProps: { style: { display: "none" } },
      width: 500,
      icon: null,
      content: (
        <Form
          name="editArticle"
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={handleEditArticle}
          preserve={false}
        >
          <Form.Item hidden initialValue={article.id} name="id" />
          <Form.Item
            className={styles.articleInfoItem}
            name="articleId"
            label={t("Common-Article")}
            rules={[
              {
                required: true,
                message: t("Common-Article"),
              },
            ]}
            initialValue={article.article.id}
          >
            <Select placeholder={t("Common-Article")}>
              {getArticlesOptionsEditing(article.article.id)}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.articleInfoItem}
            label={t("Common-PaymentMethod")}
            name="supplierPaymentMethodId"
            initialValue={article.commercialTerm.supplierPaymentMethod?.id}
          >
            <Select
              allowClear
              placeholder={t("Common-PaymentMethod")}
              showSearch
              optionFilterProp="children"
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getOptions(supplier?.paymentMethods)}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.articleInfoItem}
            name="deliveryTerm"
            label={t("NewSupplier-DeliveryTerm")}
            initialValue={article.commercialTerm.deliveryTerm}
            rules={[
              {
                required: true,
                message: t("Common-DeliveryTerm"),
              },
            ]}
          >
            <Input placeholder={t("NewSupplier-DeliveryTerm")} />
          </Form.Item>
          <Form.Item
            className={styles.articleInfoItem}
            name="minimumOrder"
            label={t("NewSupplier-MinimumOrder")}
            rules={[
              {
                required: true,
                message: t("NewSupplier-MinimumOrder"),
              },
            ]}
            initialValue={article.commercialTerm.minimumOrder}
          >
            <Input placeholder={t("NewSupplier-MinimumOrder")} />
          </Form.Item>
          <Form.Item
            className={styles.articleInfoItem}
            name="purchaseUnit"
            label={t("NewSupplier-PurchaseUnit")}
            initialValue={article.purchaseUnit?.id}
          >
            <Select
              allowClear
              placeholder={t("Common-SearchByName")}
              showSearch
              onSearch={handleSearchPurchaseUnits}
              optionFilterProp="children"
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getOptionsPurchaseUnit(purchaseUnits)}
            </Select>
          </Form.Item>
          <Form.Item
            label={t("Common-Cost")}
            className={styles.articleInfoItem}
            name={"cost"}
            rules={[
              {
                required: true,
                message: t("Common-Cost"),
              },
            ]}
            initialValue={article.commercialTerm.cost}
          >
            <Input
              className={styles.articleInfoItem}
              placeholder={t("Common-Cost")}
            />
          </Form.Item>
          <Form.Item
            label={t("Common-AgreedDiscounts")}
            className={styles.articleInfoItem}
            name={"agreedDiscounts"}
            rules={[
              {
                required: true,
                message: t("Common-AgreedDiscounts"),
              },
            ]}
            initialValue={article.commercialTerm.agreedDiscounts}
          >
            <Input
              className={styles.articleInfoItem}
              placeholder={t("Common-AgreedDiscounts")}
            />
          </Form.Item>
          <Form.Item
            initialValue={article.principal}
            label={t("NewSupplier-Principal")}
            name="principal"
          >
            <Switch defaultChecked={article.principal} />
          </Form.Item>
          <Button
            className={styles.addArticleButton}
            type="primary"
            htmlType="submit"
          >
            {t("Common-Save")}
          </Button>
        </Form>
      ),
    });
  };

  const handleEditArticle = async (article: any) => {
    setLoading(true);

    const {
      id,
      articleId,
      deliveryTerm,
      minimumOrder,
      purchaseUnit,
      cost,
      agreedDiscounts,
      principal,
      supplierPaymentMethodId,
    } = article;

    const commercialTerm = {
      deliveryTerm: deliveryTerm,
      minimumOrder: minimumOrder,
      purchaseUnit: purchaseUnit,
      agreedDiscounts,
      cost,
      supplierPaymentMethodId,
    };

    let response = await editArticleToSupplier(
      id,
      articleId,
      principal,
      purchaseUnit,
      commercialTerm
    );

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
    } else message.error(t("Common-EditErrorMessage"));

    Modal.destroyAll();
    await handleFetchSupplier();
    setLoading(false);
  };

  const showDeleteArticleConfirm = (articleId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteArticle(articleId);
      },
    });
  };

  const handleDeleteArticle = async (articleId: number) => {
    setLoading(true);
    const deleteStatus = await deleteArticleSupplier(articleId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchSupplier();
    setLoading(false);
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !supplier ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Supplier")}</h1>
        {hasPermission(privilegesEnum.WRITE_SUPPLIERS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEditSupplier}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.supplierInfoWrapper}>
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={infoSupplierForm}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              initialValue={supplier?.name}
              rules={[
                {
                  required: true,
                  message: t("Common-Name"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} disabled={!canEdit} />
            </Form.Item>
            <Form.Item
              label={t("Common-ExternalId")}
              name="externalId"
              initialValue={supplier?.externalId}
            >
              <Input disabled={!canEdit} placeholder={t("Common-ExternalId")} />
            </Form.Item>

            <Form.Item
              label={t("Common-AcceptedPaymentMethods")}
              name="supplierPaymentMethodsIds"
              className={styles.editInfoItem}
              initialValue={supplier?.paymentMethods?.map((pa: any) => pa.id)}
            >
              <Select
                allowClear
                placeholder={t("Common-AcceptedPaymentMethods")}
                showSearch
                mode="multiple"
                optionFilterProp="children"
                defaultValue={supplier?.paymentMethods?.map((pa: any) => pa.id)}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {getOptions(paymentMethods)}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("Common-Website")}
              name="website"
              initialValue={supplier?.website}
              rules={[
                {
                  type: "url",
                  message: t("Common-Website"),
                },
              ]}
            >
              <Input placeholder={t("Common-exampleURL")} disabled={!canEdit} />
            </Form.Item>
            <Form.Item
              label={t("Suppliers-CatalogueLink")}
              name="catalogueLink"
              initialValue={supplier?.catalogueLink}
              rules={[
                {
                  type: "url",
                  message: t("Suppliers-CatalogueLink"),
                },
              ]}
            >
              <Input placeholder={t("Common-exampleURL")} disabled={!canEdit} />
            </Form.Item>
          </Form>
        </div>
        <div className={styles.contactWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.contactsTitle}>
              {t("NewSupplier-Contacts")}
            </h1>
            {canEdit && (
              <Button
                type="primary"
                className={styles.actionsButton}
                onClick={() => handleOpenAddContactModal()}
                icon={<PlusOutlined />}
              />
            )}
          </div>
          <div className={styles.contactsTableWrapper}>
            <Table
              size="small"
              rowKey={(record) => record.id}
              className={styles.contactTable}
              loading={loading}
              columns={contactsColumns}
              dataSource={supplier?.contacts}
            />
          </div>
        </div>
        <div className={styles.articlesWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.articlesTitle}>{t("Common-Articles")}</h1>
            {canEdit && (
              <Button
                type="primary"
                className={styles.actionsButton}
                onClick={() => handleOpenAddArticleModal()}
                icon={<PlusOutlined />}
              />
            )}
          </div>
          <div className={styles.articlesTableWrapper}>
            <Table
              size="small"
              rowKey={(record) => record.id}
              className={styles.articleTable}
              loading={loading}
              columns={articleColumn}
              dataSource={supplier?.articles}
            />
          </div>
        </div>
      </div>
      <Modal
        title={t("Common-Contact")}
        visible={isAddContactModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelAddContactModal}
      >
        <Form
          name="addContact"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={addContactForm}
        >
          <Form.Item
            className={styles.contactItem}
            label={t("NewSupplier-ContactName")}
            name="name"
            rules={[
              {
                required: true,
                message: t("NewSupplier-ContactNameNotEntered"),
              },
            ]}
          >
            <Input placeholder={t("NewSupplier-ContactName")} />
          </Form.Item>
          <Form.Item
            className={styles.contactItem}
            label={t("Common-Rol")}
            name="role"
          >
            <Input placeholder={t("Common-Rol")} />
          </Form.Item>
          <Form.Item
            className={styles.contactItem}
            label={t("NewSupplier-ContactNumber")}
            name="phoneNumber"
          >
            <Input placeholder={t("NewSupplier-ContactNumber")} />
          </Form.Item>
          <Form.Item
            className={styles.contactItem}
            label={t("NewSupplier-ContactEmail")}
            name="email"
            rules={[
              {
                type: "email",
                message: t("NewSupplier-ContactEmailNotEntered"),
              },
            ]}
          >
            <Input placeholder={t("NewSupplier-ContactEmailNotEntered")} />
          </Form.Item>
        </Form>
        <Button
          className={styles.addContactButton}
          type="primary"
          onClick={handleAddContact}
        >
          {t("Common-Add")}
        </Button>
      </Modal>
      <Modal
        title={t("Common-Article")}
        visible={isAddArticleModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelAddArticleModal}
      >
        <Form
          name="addArticle"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={addArticleForm}
        >
          <Form.Item
            className={styles.articleInfoItem}
            name="articleId"
            label={t("Common-Article")}
            rules={[
              {
                required: true,
                message: t("Common-Article"),
              },
            ]}
          >
            <Select
              className={styles.articleInfoItem}
              allowClear
              placeholder={t("Common-SearchByName")}
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              style={{ width: "100%" }}
              onSearch={(value) => handleFetchArticles(value)}
            >
              {getArticlesOptions()}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.articleInfoItem}
            label={t("Common-PaymentMethod")}
            name="supplierPaymentMethodId"
          >
            <Select
              allowClear
              placeholder={t("Common-PaymentMethod")}
              showSearch
              optionFilterProp="children"
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getOptions(supplier?.paymentMethods)}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.articleInfoItem}
            name="deliveryTerm"
            label={t("NewSupplier-DeliveryTerm")}
            rules={[
              {
                required: true,
                message: t("Common-DeliveryTerm"),
              },
            ]}
          >
            <Input placeholder={t("NewSupplier-DeliveryTerm")} />
          </Form.Item>
          <Form.Item
            className={styles.articleInfoItem}
            name="minimumOrder"
            label={t("NewSupplier-MinimumOrder")}
            rules={[
              {
                required: true,
                message: t("NewSupplier-MinimumOrder"),
              },
            ]}
          >
            <Input placeholder={t("NewSupplier-MinimumOrder")} />
          </Form.Item>
          <Form.Item
            className={styles.articleInfoItem}
            name="purchaseUnit"
            label={t("NewSupplier-PurchaseUnit")}
          >
            <Select
              allowClear
              placeholder={t("Common-SearchByName")}
              showSearch
              onSearch={handleSearchPurchaseUnits}
              optionFilterProp="children"
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getOptionsPurchaseUnit(purchaseUnits)}
            </Select>
          </Form.Item>
          <Form.Item
            label={t("Common-Cost")}
            className={styles.articleInfoItem}
            name={"cost"}
            rules={[
              {
                required: true,
                message: t("Common-Cost"),
              },
            ]}
          >
            <Input
              className={styles.articleInfoItem}
              placeholder={t("Common-Cost")}
            />
          </Form.Item>
          <Form.Item
            label={t("Common-AgreedDiscounts")}
            className={styles.articleInfoItem}
            name={"agreedDiscounts"}
            rules={[
              {
                required: true,
                message: t("Common-AgreedDiscounts"),
              },
            ]}
          >
            <Input
              className={styles.articleInfoItem}
              placeholder={t("Common-AgreedDiscounts")}
            />
          </Form.Item>
          <Form.Item
            initialValue={false}
            label={t("NewSupplier-Principal")}
            name="principal"
          >
            <Switch />
          </Form.Item>
        </Form>
        <Button
          className={styles.addArticleButton}
          type="primary"
          onClick={handleAddArticle}
        >
          {t("Common-Add")}
        </Button>
      </Modal>
    </div>
  );
};

export default ViewSupplier;
