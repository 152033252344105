import { useTranslation } from "react-i18next";
import { resendValidateEmail } from "../../api/Services";

import { Button, message } from "antd";
import { MailOutlined } from "@ant-design/icons";

import styles from "./CheckEmail.module.css";
import { useState } from "react";

const CheckEmail = ({ organizationName, email }: any) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const sendEmailAgain = async () => {
    setLoading(true);
    const response = await resendValidateEmail(email, organizationName);
    if (response) message.success(t("CheckEmail-SendSuccesMessage"));
    else message.error(t("CheckEmail-SendErrorMessage"));
    setLoading(false);
  };

  return (
    <div className={styles.wrapper}>
      <MailOutlined className={styles.icon} />
      <h1 className={styles.title}>{t("CheckEmail-Title")}</h1>
      <Button
        className={styles.registerButton}
        onClick={sendEmailAgain}
        type="primary"
        htmlType="button"
        disabled={loading}
      >
        {t("CheckEmail-SendAgain")}
      </Button>
    </div>
  );
};

export default CheckEmail;
