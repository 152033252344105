import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createUser,
  getAllRoles,
  getBranchOfficesFiltered,
} from "../../../api/Services";
import { useNavigate } from "react-router-dom";

import { Form, message, Button, Input, Spin, Select } from "antd";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";

import styles from "./NewUser.module.scss";
import { BusinessUniteContext } from "../../../components/laoyut/Layout";
import {
  hasPermission,
  privilegesEnum,
} from "../../../helpers/PermissionHelper";

const NewUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<any[]>([]);
  const [branchOffices, setBranchOffices] = useState<any[]>([]);
  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const [form] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.WRITE_USERS)) {
      handleFetchRoles();
      handleSearchBranchOffices("");
    }
  }, [businessUnitSelected]);

  const handleSearchBranchOffices = async (value: string) => {
    if (businessUnitSelected) {
      const response = await getBranchOfficesFiltered(0, { name: value });
      setBranchOffices(response.branchOffices);
    } else setBranchOffices([]);
  };

  const handleFetchRoles: () => any = async () => {
    const roles = await getAllRoles();
    setRoles(roles);
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const handleCreate = async () => {
    await form.validateFields().then(async (data) => {
      setLoading(true);
      if (businessUnitSelected) data.businessUnitsIds = [businessUnitSelected];
      let response = await createUser(
        data.username,
        data.roles,
        data.businessUnitsIds,
        data.branchOfficesIds
      );
      if (response) {
        message.success(t("Users-CreateSuccesMessage"));
        form.resetFields();
        navigate("/admin/users");
      } else message.error(t("Users-CreateErrorMessage"));

      setLoading(false);
    });
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Users-NewUser")}</h1>
        <Button
          onClick={handleCreate}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.newWrapper}>
          <Form
            name="newUser"
            layout="vertical"
            initialValues={{ remember: true }}
            form={form}
            autoComplete="off"
            className={styles.formNew}
          >
            <Form.Item
              label={t("Common-Email")}
              name="username"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: t("Common-EmailNotEntered"),
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={t("Common-Email")}
              />
            </Form.Item>
            <Form.Item
              label={t("Users-Rol")}
              name="roles"
              rules={[
                {
                  required: true,
                  message: t("Users-Rol"),
                },
              ]}
            >
              <Select
                mode="multiple"
                className={styles.privilegesSelector}
                allowClear
                placeholder={t("Users-Rol")}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onDropdownVisibleChange={(open) => {
                  if (open) handleFetchRoles();
                }}
              >
                {getOptions(roles)}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("Common-BranchOffices")}
              name="branchOfficesIds"
            >
              <Select
                mode="multiple"
                allowClear
                className={styles.branchOfficeSelector}
                onSearch={handleSearchBranchOffices}
                placeholder={t("Common-SearchByName")}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {getOptions(branchOffices)}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default NewUser;
