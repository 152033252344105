import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { login, existsOrganizationByName } from "../../api/Services";

import { Alert, Button, Form, Input, message } from "antd";
import {
  UserOutlined,
  LockOutlined,
  EditOutlined,
  HomeOutlined,
} from "@ant-design/icons";

import styles from "./LoginForm.module.scss";

const LoginForm = () => {
  const { t } = useTranslation();

  const [subDomain, setSubDomain] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [form] = Form.useForm();

  useEffect(() => {
    const fullDomain = window.location.host;

    const appURL = process.env.REACT_APP_DOMAIN;

    const domainArray = fullDomain.split(".");
    const appUrlArray = appURL?.split(".");

    let possibleOrgArray = domainArray.filter((x) => !appUrlArray?.includes(x));

    if (possibleOrgArray.length == 1) {
      setSubDomain(possibleOrgArray[0]);
    }
  }, [subDomain]);

  const onFinish = async (data: any) => {
    const { username, password } = data;

    const organizationName =
      subDomain == "" ? data.organizationName : subDomain;

    if (subDomain === "") navigateToOrganization();
    else {
      const response = await login(username, password, organizationName);
      if (!response.status) {
        setError(true);
        setErrorMessage(response.message);
      } else {
        if (window.location.pathname === "/login") window.location.href = "/";
        else window.location.reload();
      }
    }
  };

  const navigateToOrganization = async () => {
    const organizationName = form.getFieldValue("organizationName");

    const existOrganization = await existsOrganizationByName(organizationName);

    if (existOrganization) {
      const appURL = process.env.REACT_APP_DOMAIN;
      const url = `${window.location.protocol}\\//${organizationName}.${appURL}`;
      window.location.replace(url);
    } else {
      message.error(t("LoginForm-NotFoundOrganization"));
    }
  };

  return (
    <Form
      name="login"
      form={form}
      layout="vertical"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
      className={styles.form}
    >
      {subDomain === "" ? (
        <>
          <Form.Item
            label={t("Common-OrganizationName")}
            name="organizationName"
            rules={[
              {
                required: true,
                message: t("Common-OrganizationNameNotEntered"),
              },
            ]}
          >
            <Input
              prefix={<HomeOutlined className="site-form-item-icon" />}
              placeholder={t("Common-OrganizationName")}
            />
          </Form.Item>
          <Form.Item>
            <Button
              className={styles.goButton}
              onClick={navigateToOrganization}
              type="primary"
              htmlType="button"
            >
              {t("Common-Go")}
            </Button>
            <a href="/register">{t("Common-RegisterNow")}</a>
          </Form.Item>
        </>
      ) : (
        <>
          <Form.Item
            label={t("Common-Email")}
            name="username"
            rules={[{ required: true, message: t("Common-EmailNotEntered") }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={t("Common-Email")}
            />
          </Form.Item>

          <Form.Item
            label={t("Common-Password")}
            name="password"
            rules={[
              //check rules for length etc
              { required: true, message: t("Common-PasswordNotEntered") },
            ]}
          >
            <Input.Password
              prefix={<EditOutlined className="site-form-item-icon" />}
              placeholder={t("Common-Password")}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="button"
              className={styles.forgotPasswordButton}
              href={"/password/reset"}
            >
              <LockOutlined className="site-form-item-icon" />
              {t("Common-ForgotPassword")}
            </Button>
            <Button
              type="primary"
              style={{ margin: "0 8px" }}
              htmlType="submit"
              className={styles.loginButton}
            >
              {t("Common-Login")}
            </Button>
          </Form.Item>
        </>
      )}
      {error && (
        <Form.Item>
          <Alert
            message="Error"
            description={errorMessage}
            type="error"
            showIcon
          />
        </Form.Item>
      )}
    </Form>
  );
};

export default LoginForm;
