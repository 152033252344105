import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  createCategory,
  getRoundingTypes,
  getCategoriesFiltered,
} from "../../../api/Services";
import {
  hasPermission,
  privilegesEnum,
} from "../../../helpers/PermissionHelper";

import { Button, Form, Input, message, Select, Switch, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { BusinessUniteContext } from "../../../components/laoyut/Layout";

import styles from "./NewCategory.module.scss";

const NewCategory = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const [categories, setCategories] = useState<any[]>([]);
  const [roundingTypes, setRoundingTypes] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  useEffect(() => {
    if (hasPermission(privilegesEnum.WRITE_CATEGORIES)) {
      handleFetchRoundingTypes();
    }
  }, []);

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
        ? item.parents.filter(Boolean).join(" - ")
        : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
        ? `${parentsString} - ${categoryName}`
        : categoryName;

      options.push(
        <Select.Option value={item.id} key={item.id}>
          {categoryTree}
        </Select.Option>
      );
    });

    return options;
  };

  const handleSearchCategories = async (value: string) => {
    if (value.length > 2) {
      const response = await getCategoriesFiltered(value);
      setCategories(response);
    } else setCategories([]);
  };

  const handleFetchRoundingTypes = async () => {
    const response = await getRoundingTypes();
    setRoundingTypes(response);
  };

  const getRoundingTypeOptions = () => {
    const options: any[] = [];

    roundingTypes.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.descriptionEs}
        </Select.Option>
      );
    });

    return options;
  };

  const handleCreateCategory = async () => {
    setLoading(true);
    const data = form.getFieldsValue();
    const { name, externalId, description, fatherId, roundingTypeId, enable } =
      data;

    const response = await createCategory({
      name,
      externalId,
      description,
      fatherId: fatherId ?? "",
      roundingTypeId,
      enable,
      businessUnitId: [businessUnitSelected] ?? [],
    });

    if (response) {
      message.success(t("Categories-CreateCategorySucessMessage"));
      form.resetFields();
      navigate("/admin/categories");
    } else message.error(t("Common-ErrorMessage"));

    setLoading(false);
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Categories-NewCategory")}</h1>
        <Button
          onClick={handleCreateCategory}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.newWrapper}>
          <Form
            name="newCategory"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.formNew}
            form={form}
            onFinish={handleCreateCategory}
          >
            <Form.Item
              className={styles.newItem}
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>

            <Form.Item
              className={styles.newItem}
              label={t("Common-ExternalId")}
              name="externalId"
            >
              <Input placeholder={t("Common-ExternalId")} />
            </Form.Item>

            <Form.Item
              className={styles.newItem}
              label={t("Common-Description")}
              name="description"
            >
              <Input placeholder={t("Common-Description")} />
            </Form.Item>

            <Form.Item
              className={styles.newItem}
              label={t("Categories-Father")}
              name="fatherId"
            >
              <Select
                className={styles.type}
                allowClear
                showSearch
                onSearch={handleSearchCategories}
                placeholder={t("Common-SearchByName")}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ width: "100%" }}
              >
                {getOptionsCategories(categories)}
              </Select>
            </Form.Item>

            <Form.Item
              className={styles.newItem}
              label={t("Categories-RoundingType")}
              name="roundingType"
            >
              <Select
                className={styles.type}
                allowClear
                style={{ width: "100%" }}
                placeholder=""
              >
                {getRoundingTypeOptions()}
              </Select>
            </Form.Item>

            <Form.Item
              initialValue={false}
              label={t("Common-Active")}
              name="enable"
              rules={[
                {
                  required: true,
                  message: t("Common-Active"),
                },
              ]}
            >
              <Switch />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default NewCategory;
