import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import NotFound from "../notfound/NotFound";
import {
  editBranchOffice,
  getAllBranchOfficesTypes,
  getBranchOfficeById,
  createSalePoint,
  deleteSalePoint,
  editSalePoint,
  getAllAcceptedPaymentMethods,
  getAllChannelsByBusinessUnits,
  getCategoriesFiltered,
  getBranchOfficeSkusById,
  processExcelTemplate,
  getHistoricalStockBranchOffice,
} from "../../api/Services";
import { BusinessUniteContext } from "../../components/laoyut/Layout";

import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Form,
  message,
  Select,
  Modal,
  Button,
  Input,
  Switch,
  Spin,
  Table,
  Space,
  Tag,
  InputNumber,
  DatePicker,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./ViewBranchOffice.module.scss";
import ArticleStock from "../../components/articlestock/ArticleStock";
import AddArticleModal from "../../components/addarticlemodal/AddArticleModal";
import { handleDownloadTemplate } from "../../helpers/Helper";
import ImportModal from "../../components/importmodal/ImportModal";

const ViewBranchOffice = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    contextValue: businessUnitSelected,
    updateContextValue: setBusinessUnitSelected,
  } = useContext(BusinessUniteContext);

  const [loading, setLoading] = useState(true);
  const [branchOffice, setBranchOffice] = useState<any>();
  const [belongsShoppingCenter, setBelongsShoppingCenter] = useState(
    branchOffice?.belongsShoppingCenter
  );
  const [articlesSelected, setArticlesSelected] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [channels, setChannels] = useState<any[]>([]);
  const [types, setTypes] = useState<any[]>([]);
  const [isNewSalePointModalVisible, setIsNewSalePointModalVisible] =
    useState(false);
  const [acceptedPaymentMethods, setAcceptedPaymentMethods] = useState<any[]>(
    []
  );
  const [isOnline, setIsOnline] = useState(false);
  const [skusActualPage, setSkusActualPage] = useState(1);
  const [totalSizeSkus, setTotalSizeSkus] = useState(0);
  const [skus, setSkus] = useState<any[]>([]);
  const [historicalStockActualPage, setHistoricalStockActualPage] = useState(1);
  const [totalSizeHistoricalStock, setTotalSizeHistoricalStock] = useState(0);
  const [historicalStocks, setHistoricalStock] = useState<any[]>([]);
  const [loadingSkus, setLoadingSkus] = useState(true);
  const [skuFilterValue, setSkuFilterValue] = useState<string>("");
  const [downloadingTemplate, setDownloadingTemplate] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [importErrors, setImportErrors] = useState<any>(null);
  const [selectedDates, setSelectedDates] = useState<[any, any] | null>(null);

  const [infoBranchOfficeForm] = Form.useForm();
  const [newSalePointForm] = Form.useForm();
  const [editSalePointForm] = Form.useForm();

  const canEdit = hasPermission(privilegesEnum.WRITE_BRANCH_OFFICES);

  const { id } = useParams();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_BRANCH_OFFICES)) {
      handleFetchBranchOffice();
      handleFetchBranchOfficeSkus();
      handleFetchAcceptedPaymentMethods();
      handleFetchBranchOfficesTypes();
    }
  }, []);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_BRANCH_OFFICES)) {
      handleFetchHistoricalStock();
    }
  }, [selectedDates]);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_BRANCH_OFFICES)) {
      handleFetchBranchOfficeSkus(1);
    }
  }, [skuFilterValue]);

  useEffect(() => {
    if (
      branchOffice &&
      !branchOffice.businessUnit
        .map((businessUnit: any) => businessUnit.id)
        .includes(businessUnitSelected) &&
      businessUnitSelected &&
      !loading
    ) {
      navigate("/admin/branchoffices");
    }
  }, [businessUnitSelected]);

  const handleFetchChannels = async (businessUnitsIds: any) => {
    const response = await getAllChannelsByBusinessUnits(businessUnitsIds);
    setChannels(response);
  };

  const handleFetchBranchOfficeSkus = async (pagination = skusActualPage) => {
    setLoadingSkus(true);
    const response = await getBranchOfficeSkusById(
      id!,
      pagination - 1,
      skuFilterValue
    );
    setLoadingSkus(false);
    setSkusActualPage(pagination);
    setTotalSizeSkus(response.totalElements);
    setSkus(response.skus);
  };

  const handleFetchHistoricalStock = async (
    pagination = historicalStockActualPage
  ) => {
    const params: any = {};

    if (selectedDates) {
      const [startDate, endDate] = selectedDates;
      if (startDate && endDate) {
        params.startDate = startDate.format("YYYY-MM-DD");
        params.endDate = endDate.format("YYYY-MM-DD");
      }
    }
    const response = await getHistoricalStockBranchOffice(id!, pagination, {
      startDate: params.startDate,
      endDate: params.endDate,
    });
    setHistoricalStockActualPage(pagination);
    setTotalSizeHistoricalStock(response.totalElements);
    setHistoricalStock(response.stocks);
  };
  const handleFetchBranchOffice = async () => {
    const response = await getBranchOfficeById(id!);
    setArticlesSelected(response.restrictedArticles);
    handleFetchChannels(response.businessUnit.map((bu: any) => bu.id));

    setCategories(response.restrictedCategories ?? []);
    setBranchOffice(response);
    setBusinessUnitSelected(response.businessUnit[0].id);
    setBelongsShoppingCenter(response.belongsShoppingCenter);
    if (response.type === "ECOMMERCE") setIsOnline(true);
    setLoading(false);
  };

  const handleFetchBranchOfficesTypes = async () => {
    const response = await getAllBranchOfficesTypes();
    setTypes(response);
  };

  const handleFetchAcceptedPaymentMethods = async () => {
    const response = await getAllAcceptedPaymentMethods();
    setAcceptedPaymentMethods(response);
  };

  const getTypesOptions = () => {
    const options: any[] = [];

    types.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.value} key={item.value}>
          {item.descriptionEs}
        </Select.Option>
      );
    });

    return options;
  };

  const handleCancelButton = () => {
    infoBranchOfficeForm.resetFields();
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
        ? item.parents.filter(Boolean).join(" - ")
        : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
        ? `${parentsString} - ${categoryName}`
        : categoryName;

      options.push(
        <Select.Option value={item.id} key={item.id}>
          {categoryTree}
        </Select.Option>
      );
    });

    return options;
  };

  const getAcceptedPaymentMethodsOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      if (
        !branchOffice.restrictedAcceptedPaymentMethods.find(
          (paymentMethod: any) => item.id === paymentMethod.id
        )
      )
        options.push(
          <Select.Option value={item.id} key={item.id}>
            {item.name}
          </Select.Option>
        );
    });

    return options;
  };

  const getChannelOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {`${item.businessUnit.name} - ${item.type.descriptionEs}`}
        </Select.Option>
      );
    });

    return options;
  };

  const showNewSalePointModal = () => {
    setIsNewSalePointModalVisible(true);
  };

  const handleCancelNewSalePointModal = () => {
    setIsNewSalePointModalVisible(false);
  };

  const handleEditBranchOffice = async () => {
    setLoading(true);

    const editedBranchOffice = infoBranchOfficeForm.getFieldsValue();
    editedBranchOffice.businessUnitsIds = [businessUnitSelected];
    editedBranchOffice.id = id;
    editedBranchOffice.restrictedArticlesIds = articlesSelected.map(
      (article) => article.id
    );
    const response = await editBranchOffice(editedBranchOffice);

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
      handleFetchBranchOffice();
    } else message.error(t("Common-EditErrorMessage"));
    setLoading(false);
  };

  const handleCreateSalePoint = async (data: any) => {
    setLoading(true);
    let response = await createSalePoint(
      parseInt(id!),
      data.name,
      data.acceptedPaymentMethodsIds
    );
    if (response) {
      message.success(t("Common-CreateSuccessMessage"));
      newSalePointForm.resetFields();
      handleCancelNewSalePointModal();
      await handleFetchBranchOffice();
    } else message.error(t("Common-ErrorMessage"));

    setLoading(false);
  };

  const showDeleteSalePointConfirm = (salePointId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteSalePoint(salePointId);
      },
    });
  };

  const showImportModal = () => {
    setIsImportModalVisible(true);
    setImportErrors("");
  };

  const excelButtons = (
    <>
      <Button
        onClick={showImportModal}
        className={styles.importButton}
        type="primary"
      >
        {t("Common-Import")}
      </Button>
      <Button
        onClick={() =>
          handleDownloadTemplate("branchOffices/stocks", setDownloadingTemplate)
        }
        loading={downloadingTemplate}
        className={styles.importButton}
        type="primary"
      >
        {t("Common-DownloadTemplate")}
      </Button>
    </>
  );

  const handleCancelImportModal = () => {
    setIsImportModalVisible(false);
  };

  const handleImportFile = async (file: any) => {
    if (file) {
      const response = await processExcelTemplate("branchOffices/stocks", file);
      if (response.success) {
        setImportErrors(null);
        setIsImportModalVisible(false);
        message.success(
          t("Common-ImportSuccess", { quantity: response.data.added })
        );
        handleFetchBranchOfficeSkus();
      } else {
        if (response.data?.errors) setImportErrors(response.data.errors);
        else message.error(t("Error-CommonError"));
      }
    }
  };

  const handleDeleteSalePoint = async (salePointId: number) => {
    setLoading(true);
    const deleteStatus = await deleteSalePoint(salePointId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchBranchOffice();
    setLoading(false);
  };

  const handleSearchCategories = async (value: string) => {
    if (value.length > 2) {
      const response = await getCategoriesFiltered(value);
      setCategories(response);
    } else setCategories([]);
  };

  const handleEditSalePoint = async () => {
    setLoading(true);

    const { id, name, acceptedPaymentMethodsIds } =
      editSalePointForm.getFieldsValue();
    const response = await editSalePoint(id, name, acceptedPaymentMethodsIds);

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
    } else message.error(t("Common-EditErrorMessage"));

    Modal.destroyAll();
    await handleFetchBranchOffice();
    setLoading(false);
  };

  const handleAddArticle = (articlesToAdd: any) => {
    const newArticles: any[] = [];
    articlesToAdd.forEach((articleToAdd: any) => {
      if (
        articlesSelected.find(
          (article: any) => article.id === articleToAdd.id
        ) === undefined
      )
        newArticles.push(articleToAdd);
    });
    setArticlesSelected([...articlesSelected, ...newArticles]);
  };

  const handleRemoveArticle = (articleRemove: any) => {
    const articlesWithoutRemoved = articlesSelected.filter(
      (article) => article.id !== articleRemove.id
    );

    setArticlesSelected(articlesWithoutRemoved);
  };

  const showEditSalePointModal = (salePoint: any) => {
    const editedPaymentMethods: number[] = [];
    salePoint.acceptedPaymentMethods.forEach((paymentMethod: any) =>
      editedPaymentMethods.push(paymentMethod.id)
    );

    Modal.info({
      title: t("Common-SalePoint"),
      closable: true,
      maskClosable: true,
      okText: t("Common-Save"),
      icon: null,
      okButtonProps: { style: { display: "none" } },
      content: (
        <>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            form={editSalePointForm}
            autoComplete="off"
            preserve={false}
          >
            <Form.Item hidden initialValue={salePoint.id} name="id" />
            <Form.Item
              initialValue={salePoint.name}
              className={styles.newItem}
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-AcceptedPaymentMethods")}
              name="acceptedPaymentMethodsIds"
              initialValue={editedPaymentMethods}
              rules={[
                {
                  required: true,
                  message: t("Common-AcceptedPaymentMethods"),
                },
              ]}
            >
              <Select
                className={styles.type}
                allowClear
                mode="multiple"
                style={{ width: "100%" }}
                placeholder=""
                defaultValue={editedPaymentMethods}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {getAcceptedPaymentMethodsOptions(acceptedPaymentMethods)}
              </Select>
            </Form.Item>
          </Form>

          <Button
            className={styles.addNewButton}
            type="primary"
            onClick={handleEditSalePoint}
          >
            {t("Common-Save")}
          </Button>
        </>
      ),
    });
  };

  const salesPointColumns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Common-AcceptedPaymentMethods"),
      dataIndex: "acceptedPaymentMethods",
      key: "acceptedPaymentMethods",
      render: (acceptedPaymentMethods: any[]) => (
        <>
          {acceptedPaymentMethods.map((acceptedPaymentMethod) => {
            return (
              <Tag
                className={styles.tag}
                color="blue"
                key={acceptedPaymentMethod.id}
              >
                {acceptedPaymentMethod.name}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {hasPermission(privilegesEnum.WRITE_BUSINESS_UNITS) && (
            <EditOutlined
              onClick={() => {
                showEditSalePointModal(record);
              }}
            />
          )}
          {hasPermission(privilegesEnum.WRITE_BUSINESS_UNITS) && (
            <DeleteOutlined
              onClick={() => {
                showDeleteSalePointConfirm(record.id);
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  const historicalStockColumns = [
    {
      title: t("Common-Sku"),
      dataIndex: "sku",
      render: (sku: any) => sku.name,
    },
    {
      title: t("Common-Date"),
      dataIndex: "date",
      render: (date: string) => date.split("T")[0],
    },
    {
      title: t("Common-Stock"),
      dataIndex: "stock",
    },
  ];

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !branchOffice ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-BranchOffice")}</h1>
        {hasPermission(privilegesEnum.WRITE_BUSINESS_UNITS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEditBranchOffice}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.branchOfficeInfoWrapper}>
          <Form
            name="branchOffice"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={infoBranchOfficeForm}
          >
            <div className={styles.itemWrapper}>
              <Form.Item
                label={t("Common-Code")}
                name="code"
                initialValue={branchOffice?.id}
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: t("BusinessRules-LanguageNotEntered"),
                  },
                ]}
              >
                <Input disabled={true} placeholder={t("Common-Code")} />
              </Form.Item>
              <Form.Item
                label={t("Common-Name")}
                name="name"
                initialValue={branchOffice?.name}
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: t("Common-NameNotEntered"),
                  },
                ]}
              >
                <Input disabled={!canEdit} placeholder={t("Common-Name")} />
              </Form.Item>

              <Form.Item
                label={t("Common-Type")}
                name="type"
                initialValue={branchOffice?.type}
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: t("Common-Type"),
                  },
                ]}
              >
                <Select
                  className={styles.type}
                  allowClear
                  style={{ width: "100%" }}
                  placeholder=""
                  disabled={!canEdit}
                  onChange={(value) => {
                    if (value === "ECOMMERCE") setIsOnline(true);
                    else setIsOnline(false);
                  }}
                >
                  {getTypesOptions()}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.itemWrapper}>
              <Form.Item
                label={t("Common-ValuedInventory")}
                name="valuedInventory"
                initialValue={branchOffice?.totalCostsByArticle.reduce(
                  (acc: number, item: any) => {
                    return acc + item.cost;
                  },
                  0
                )}
                className={styles.formItem}
              >
                <Input
                  disabled={true}
                  placeholder={t("Common-ValuedInventory")}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label={t("Common-ExternalId")}
                name="externalId"
                initialValue={branchOffice.externalId}
              >
                <Input placeholder={t("Common-ExternalId")} />
              </Form.Item>

              <Form.Item
                label={t("Common-RoomSpace")}
                name="roomSpace"
                initialValue={branchOffice?.roomSpace}
                className={styles.formItem}
              >
                <InputNumber
                  disabled={!canEdit}
                  placeholder={t("Common-RoomSpace")}
                  addonAfter={t("Common-mtsquare")}
                  className={styles.inputNumber}
                />
              </Form.Item>
            </div>

            <div className={styles.itemWrapper}>
              <Form.Item
                label={t("Common-ExhibitionSpace")}
                name="exhibitionSpace"
                initialValue={branchOffice?.exhibitionSpace}
                className={styles.formItem}
              >
                <InputNumber
                  disabled={!canEdit}
                  placeholder={t("Common-ExhibitionSpace")}
                  addonAfter={t("Common-mtcube")}
                  className={styles.inputNumber}
                />
              </Form.Item>

              <Form.Item
                label={t("Common-Channel")}
                name="channelId"
                className={styles.formItem}
                initialValue={branchOffice?.channel?.id}
              >
                <Select
                  allowClear
                  placeholder={t("Common-Channel")}
                  showSearch
                  optionFilterProp="children"
                  defaultValue={branchOffice?.channel?.id}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getChannelOptions(channels)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-Location")}
                name="location"
                initialValue={branchOffice?.location}
                className={styles.formItem}
              >
                <Input disabled={!canEdit} placeholder={t("Common-Location")} />
              </Form.Item>
            </div>
            <div className={styles.itemWrapper}>
              <Form.Item
                label={t("Common-Address")}
                name="address"
                initialValue={branchOffice?.address}
                className={styles.formItem}
              >
                <Input placeholder={t("Common-Address")} />
              </Form.Item>
              <Form.Item
                label={t("Common-Latitude")}
                name="latitude"
                initialValue={branchOffice?.latitude}
                className={styles.formItem}
              >
                <InputNumber
                  className={styles.inputNumber}
                  placeholder={t("Common-Latitude")}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-Longitude")}
                name="longitude"
                initialValue={branchOffice?.longitude}
                className={styles.formItem}
              >
                <InputNumber
                  className={styles.inputNumber}
                  placeholder={t("Common-Longitude")}
                />
              </Form.Item>
            </div>
            <div className={styles.itemWrapper}>
              <Form.Item
                label={t("Common-MinimumStock")}
                name="minimumStock"
                className={styles.formItem}
                initialValue={branchOffice?.minimumStock}
              >
                <InputNumber
                  disabled={!canEdit}
                  placeholder={t("Common-MinimumStock")}
                  className={styles.inputNumber}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-MaximumStock")}
                name="maximumStock"
                className={styles.formItem}
                initialValue={branchOffice?.maximumStock}
              >
                <InputNumber
                  disabled={!canEdit}
                  placeholder={t("Common-MaximumStock")}
                  className={styles.inputNumber}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-PriceVariation")}
                name="priceVariation"
                initialValue={branchOffice?.priceVariation}
                className={styles.formItem}
              >
                <Input
                  disabled={!canEdit}
                  placeholder={t("Common-PriceVariation")}
                />
              </Form.Item>

              {isOnline && (
                <>
                  <Form.Item
                    label={t("Common-Url")}
                    name="url"
                    initialValue={branchOffice?.url}
                    className={styles.formItem}
                    rules={[
                      {
                        type: "url",
                        message: t("Common-Url"),
                      },
                    ]}
                  >
                    <Input
                      disabled={!canEdit}
                      placeholder={t("Common-exampleURL")}
                    />
                  </Form.Item>

                  <Form.Item
                    label={t("Common-Marketplace")}
                    name="marketplace"
                    initialValue={branchOffice?.marketplace}
                    className={styles.formItem}
                  >
                    <Input
                      disabled={!canEdit}
                      placeholder={t("Common-Marketplace")}
                    />
                  </Form.Item>
                </>
              )}
            </div>
            <div className={styles.itemWrapperException}>
              <Form.Item
                label={t("Common-PriceRestriction")}
                name="priceRestriction"
                initialValue={branchOffice?.priceRestriction}
                className={styles.formItem}
              >
                <Input
                  disabled={!canEdit}
                  placeholder={t("Common-PriceRestriction")}
                />
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                label={t("Common-StorageCostPerUnit")}
                name="storageCostPerUnit"
                initialValue={branchOffice?.storageCostPerUnit}
              >
                <InputNumber
                  disabled={!canEdit}
                  placeholder={t("Common-StorageCostPerUnit")}
                  className={styles.inputNumber}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-BelongsShoppingCenter")}
                name="belongsShoppingCenter"
                initialValue={belongsShoppingCenter}
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: t("Common-BelongsShoppingCenter"),
                  },
                ]}
              >
                <Switch
                  defaultChecked={belongsShoppingCenter}
                  disabled={!canEdit}
                  onChange={setBelongsShoppingCenter}
                />
              </Form.Item>
              {belongsShoppingCenter && (
                <Form.Item
                  label={t("Common-ShoppingCenter")}
                  name="shoppingCenter"
                  initialValue={branchOffice?.shoppingCenter}
                  className={styles.formItem}
                >
                  <Input
                    disabled={!canEdit}
                    placeholder={t("Common-ShoppingCenter")}
                  />
                </Form.Item>
              )}
            </div>

            <div className={styles.itemWrapper}>
              <Form.Item
                label={t("Common-RestrictedCategories")}
                name="restrictedCategoriesIds"
                className={styles.formItem}
                initialValue={branchOffice?.restrictedCategories.map(
                  (categorie: any) => categorie.id
                )}
              >
                <Select
                  className={styles.multipleSelect}
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  onSearch={handleSearchCategories}
                  placeholder={t("Common-SearchByName")}
                  defaultValue={branchOffice?.restrictedCategories.map(
                    (categorie: any) => categorie.id
                  )}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptionsCategories(categories)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-RestrictedPaymentMethods")}
                initialValue={branchOffice?.restrictedAcceptedPaymentMethods.map(
                  (acceptedPaymentMethod: any) => acceptedPaymentMethod.id
                )}
                name="restrictedAcceptedPaymentMethodsIds"
                className={styles.formItem}
              >
                <Select
                  className={styles.multipleSelect}
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder=""
                  defaultValue={branchOffice?.restrictedAcceptedPaymentMethods.map(
                    (acceptedPaymentMethod: any) => acceptedPaymentMethod.id
                  )}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptions(acceptedPaymentMethods)}
                </Select>
              </Form.Item>
            </div>
            <AddArticleModal
              title={t("Common-RestrictedArticles")}
              selectedArticles={articlesSelected}
              onAddArticle={handleAddArticle}
              onRemoveArticle={handleRemoveArticle}
            />
          </Form>
        </div>
        <div className={styles.tableWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.tableTitle}>{t("Common-SalesPoints")}</h1>
            {canEdit && (
              <Button
                type="primary"
                className={styles.actionsButton}
                onClick={() => showNewSalePointModal()}
                icon={<PlusOutlined />}
              />
            )}
          </div>
          <div>
            <Table
              size="small"
              className={styles.table}
              loading={loading}
              rowKey={(record) => record.id}
              columns={salesPointColumns}
              dataSource={branchOffice.salePoints}
            />
          </div>
        </div>
        <ArticleStock
          data={skus}
          header={excelButtons}
          fetchData={handleFetchBranchOfficeSkus}
          type={"branchOffices"}
          placeId={parseInt(id!)}
          totalElements={totalSizeSkus}
          updatePage={setSkusActualPage}
          searchByName={true}
          updateName={setSkuFilterValue}
          page={skusActualPage}
          loadingPage={loadingSkus}
        />
        <div className={styles.tableWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.tableTitle}>{t("Common-HistoricalStock")}</h1>
          </div>
          <div className={styles.filtersWrapper}>
            <div className={styles.filterWrapper}>
              <DatePicker.RangePicker
                className={styles.dateSelector}
                placeholder={[t("Common-Start"), t("Common-End")]}
                onChange={(dates) => setSelectedDates(dates)}
              ></DatePicker.RangePicker>
            </div>
          </div>
          <div>
            <Table
              size="small"
              className={styles.table}
              loading={loading}
              rowKey={(record) => record.id}
              columns={historicalStockColumns}
              pagination={{
                total: totalSizeHistoricalStock,
                showSizeChanger: false,
                onChange: handleFetchHistoricalStock,
                current: historicalStockActualPage,
              }}
              dataSource={historicalStocks}
            />
          </div>
        </div>
      </div>
      {hasPermission(privilegesEnum.WRITE_BRANCH_OFFICES) && (
        <Modal
          title={t("Common-NewSalePoint")}
          visible={isNewSalePointModalVisible}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
          onCancel={handleCancelNewSalePointModal}
        >
          <Form
            name="basic"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.formNewCategory}
            form={newSalePointForm}
            onFinish={handleCreateSalePoint}
          >
            <Form.Item
              className={styles.newItem}
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item>
              <Button
                className={styles.addNewButton}
                type="primary"
                htmlType="submit"
                disabled={loading}
              >
                {loading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined className="ol-loading-spin-icon" />
                    }
                    delay={500}
                  />
                ) : (
                  t("Common-Add")
                )}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
      <Modal
        title={t("Common-ImportEntity", {
          entity: t("Common-Stock"),
        })}
        visible={isImportModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelImportModal}
      >
        <ImportModal onOk={handleImportFile} errors={importErrors} />
      </Modal>
    </div>
  );
};

export default ViewBranchOffice;
