import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createAcceptedPaymentMethod,
  getAcceptedPaymentMethodsTypes,
} from "../../../api/Services";
import {
  hasPermission,
  privilegesEnum,
} from "../../../helpers/PermissionHelper";
import { BusinessUniteContext } from "../../../components/laoyut/Layout";
import { Form, Input, Button, message, Select, Spin, InputNumber } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./NewAceptedPaymentMethod.module.scss";
const NewAcceptedPaymentMethods = () => {
  const { t } = useTranslation();
  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState<any[]>([]);

  const [form] = Form.useForm();
  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_ACCEPTED_PAYMENT_METHODS)) {
      handleFetchAcceptedPaymentMethodsTypes();
    }
  }, []);

  const handleFetchAcceptedPaymentMethodsTypes = async () => {
    const response = await getAcceptedPaymentMethodsTypes();
    setTypes(response);
  };

  const getAcceptedPaymentMethodsTypesOptions = () => {
    const options: any[] = [];

    types.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.descriptionEs}
        </Select.Option>
      );
    });

    return options;
  };

  const handleCreateAcceptedPaymentMethod = async () => {
    setLoading(true);
    const data = form.getFieldsValue();
    const { name, externalId, type, feeRangeMin, feeRangeMax } = data;
    const response = await createAcceptedPaymentMethod({
      name,
      externalId,
      typeId: type,
      feeRangeMin,
      feeRangeMax,
      businessUnitsIds: [businessUnitSelected] ?? [],
    });
    if (response) {
      message.success(t("Common-CreateSuccessMessage"));
      form.resetFields();
    } else message.error(t("Common-ErrorMessage"));

    setLoading(false);
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewAcceptedPaymentMethod")}</h1>
        <Button
          onClick={handleCreateAcceptedPaymentMethod}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.newWrapper}>
          <Form
            name="newpayment"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.formNew}
            form={form}
            onFinish={handleCreateAcceptedPaymentMethod}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item label={t("Common-ExternalId")} name="externalId">
              <Input placeholder={t("Common-ExternalId")} />
            </Form.Item>
            <Form.Item label={t("Common-Type")} name="type">
              <Select
                className={styles.type}
                allowClear
                style={{ width: "100%" }}
                placeholder=""
              >
                {getAcceptedPaymentMethodsTypesOptions()}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("Common-FeeMinRange")}
              name="feeRangeMin"
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    // from 'getFieldValue("fieldName")' we can get the current value of that field.
                    if (value > getFieldValue("feeRangeMax")) {
                      return Promise.reject(
                        t("Common-MaxMustBeGreaterThanMin")
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <InputNumber
                className={styles.inputNumber}
                min={0}
                placeholder={t("Common-FeeMinRange")}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-FeeMaxRange")}
              name="feeRangeMax"
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    // from 'getFieldValue("fieldName")' we can get the current value of that field.
                    if (value < getFieldValue("feeRangeMin")) {
                      return Promise.reject(
                        t("Common-MaxMustBeGreaterThanMin")
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <InputNumber
                className={styles.inputNumber}
                min={0}
                placeholder={t("Common-FeeMaxRange")}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default NewAcceptedPaymentMethods;
