import { useTranslation } from "react-i18next";
import { useState } from "react";

import { Alert, Button, Form, Input, Spin, Typography } from "antd";

import styles from "./ImportModal.module.scss";
import { LoadingOutlined } from "@ant-design/icons";

const ImportModal = ({ onOk, errors }: any) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);

  const handleImportFile = async (file: any) => {
    setLoading(true);
    await onOk(file);
    setLoading(false);
    setSelectedFile(null);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <Input
          type="file"
          accept=".xlsx"
          onChange={(event) => setSelectedFile(event.target?.files?.[0])}
          className={styles.fileSelector}
        />
        <Button
          onClick={() => handleImportFile(selectedFile)}
          type="primary"
          htmlType="button"
          disabled={loading || selectedFile === undefined}
        >
          {loading ? (
            <Spin
              indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
              delay={500}
            />
          ) : (
            t("Common-Import")
          )}
        </Button>
      </div>

      {errors && (
        <Alert
          message={t("Common-ErrorOnExcelFile")}
          description={errors.map((error: any) => (
            <div className={styles.errorLineWrapper}>
              <Typography.Text>
                {t("Common-ErrorOnExcelFileLineText", {
                  sheet: error.sheet,
                  row: error.row,
                  column: error.column,
                  errorMessage: error.error_es,
                })}
              </Typography.Text>
            </div>
          ))}
          type="error"
          showIcon
          className={styles.errorAlert}
        />
      )}
    </>
  );
};

export default ImportModal;
