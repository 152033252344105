import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  deleteDeposit,
  getDeposits,
  processExcelTemplate,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import { Button, Input, message, Modal, Space, Table, Tag } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./Deposits.module.scss";
import { handleDownloadTemplate } from "../../helpers/Helper";
import ImportModal from "../../components/importmodal/ImportModal";
import { useNavigate } from "react-router-dom";
import { BusinessUniteContext } from "../../components/laoyut/Layout";

const Deposits = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [downloadingTemplate, setDownloadingTemplate] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [importErrors, setImportErrors] = useState<any>(null);
  const [nameFilterValue, setNameFilterValue] = useState("");
  const [externalIdFilterValue, setExternalIdFilterValue] = useState("");

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_DEPOSITS)) {
      handleFetchDeposits();
    }
  }, [businessUnitSelected, nameFilterValue, externalIdFilterValue]);

  const handleFetchDeposits = async (pagination = actualPage) => {
    if (businessUnitSelected) {
      const response = await getDeposits(pagination - 1, businessUnitSelected, {
        name: nameFilterValue,
        externalId: externalIdFilterValue,
      });
      const allDeposits = response.deposits;
      setData(allDeposits);
      setTotalSize(response.totalElements);
      setLoading(false);
    }
  };

  const showDeleteConfirm = (depositId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteDeposit(depositId);
      },
    });
  };

  const handleDeleteDeposit = async (depositId: number) => {
    setLoading(true);
    const deleteStatus = await deleteDeposit(depositId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchDeposits();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    handleFetchDeposits(page);
    setActualPage(page);
  };

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Common-ExternalId"),
      dataIndex: "externalId",
    },
    {
      title: t("Common-CapacityStorageInmt3"),
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: t("Common-Address"),
      dataIndex: "address",
      key: "address",
    },
    {
      title: t("Common-BranchOffices"),
      dataIndex: "branchOffices",
      key: "branchOffices",
      render: (branchOffices: any[]) => (
        <>
          {branchOffices?.map((branchOffice) => {
            return (
              <Tag className={styles.tag} color="blue" key={branchOffice.branchOffice.id}>
                {branchOffice.branchOffice.name}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {hasPermission(privilegesEnum.WRITE_DEPOSITS) && (
            <EditOutlined
              onClick={() => {
                window.location.href = `/admin/deposits/${record.id}/view`;
              }}
            />
          )}
          {hasPermission(privilegesEnum.DELETE_DEPOSITS) && (
            <DeleteOutlined
              onClick={() => {
                showDeleteConfirm(record.key);
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  const handleCancelImportModal = () => {
    setIsImportModalVisible(false);
  };

  const handleImportFile = async (file: any) => {
    if (file) {
      const response = await processExcelTemplate("deposits", file);
      if (response.success) {
        setImportErrors(null);
        setIsImportModalVisible(false);
        message.success(
          t("Common-ImportSuccess", { quantity: response.data.added })
        );
        handleFetchDeposits();
      } else {
        if (response.data?.errors) setImportErrors(response.data.errors);
        else message.error(t("Error-CommonError"));
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Deposits")}</h1>
        {hasPermission(privilegesEnum.WRITE_DEPOSITS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={() => navigate("/admin/deposits/create")}
              className={styles.newButton}
              type="primary"
              icon={<PlusOutlined />}
            />
            <Button
              onClick={() => {
                setImportErrors("");
                setIsImportModalVisible(true);
              }}
              className={styles.importButton}
              type="primary"
            >
              {t("Common-Import")}
            </Button>
            <Button
              onClick={() =>
                handleDownloadTemplate("deposits", setDownloadingTemplate)
              }
              loading={downloadingTemplate}
              className={styles.importButton}
              type="primary"
            >
              {t("Common-DownloadTemplate")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_DEPOSITS) && (
          <div className={styles.tableWrapper}>
            <div className={styles.headerTableWrapper}>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <Input
                    placeholder={t("Common-SearchByName")}
                    className={styles.filterInput}
                    onChange={(e) => setNameFilterValue(e.target.value)}
                    value={nameFilterValue}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <Input
                    placeholder={t("Common-SearchByExternalId")}
                    className={styles.filterInput}
                    onChange={(e) => setExternalIdFilterValue(e.target.value)}
                    value={externalIdFilterValue}
                  />
                </div>
              </div>
            </div>
            <Table
              size="small"
              className={styles.table}
              loading={loading}
              columns={columns}
              dataSource={data}
              rowKey={(record) => record.id}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
              }}
            />
          </div>
        )}
      </div>
      <Modal
        title={t("Common-ImportEntity", { entity: t("Common-Deposits") })}
        visible={isImportModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelImportModal}
      >
        <ImportModal onOk={handleImportFile} errors={importErrors} />
      </Modal>
    </div>
  );
};

export default Deposits;
