import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  confirmUserAndSetPassword,
  confirmUserWithToken,
  validateEmail,
} from "../../api/Services";

import { Spin, Form, Input, Button } from "antd";
import { LoadingOutlined, EditOutlined } from "@ant-design/icons";

import logo from "../../assets/logo.jpg";
import styles from "./ValidateEmail.module.css";

const ValidateEmail = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [validToken, setValidToken] = useState(false);
  const [needToSetupPassword, setNeedToSetupPassword] = useState(false);
  const [userValidationComplete, setUserValidationComplete] = useState(false);
  const [token, setToken] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    handleValidateEmail();
  }, []);

  const handleValidateEmail: () => any = async () => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    setToken(token ?? "");
    const response = await validateEmail(token ?? "");

    if (response.existsToken) {
      setValidToken(true);
      if (response.needToSetupPassword) {
        setNeedToSetupPassword(true);
        setLoading(false);
      } else {
        setNeedToSetupPassword(false);
        confirmUser(token ?? "");
      }
    } else setValidToken(false);
  };

  const confirmUser: (token: string) => any = async (token: string) => {
    let response = await confirmUserWithToken(token ?? "");
    if (response) {
      setUserValidationComplete(true);
      setLoading(false);
    } else setUserValidationComplete(false);
    setLoading(false);
  };

  const formPassword: () => any = () => {
    return (
      <div className={styles.wrapper}>
        <div className={styles.logoWrapper}>
          <img className={styles.logo} src={logo} alt="Logo" />
        </div>
        <div className={styles.formWrapper}>
          <h1 className={styles.title}>
            {t("ValidateEmail-NewPasswordTitle")}
          </h1>
          <p className={styles.description}>
            {t("ValidateEmail-NewPasswordDescription")}
          </p>

          <Form
            name="basic"
            className={styles.formPassword}
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label={t("Common-Password")}
              name="password"
              className={styles.formItem}
              rules={[
                { required: true, min: 8, message: t("Common-PasswordLength") },
              ]}
            >
              <Input.Password
                prefix={<EditOutlined className="site-form-item-icon" />}
                placeholder={t("Common-Password")}
              />
            </Form.Item>
            <Form.Item>
              <Button
                className={styles.saveButton}
                type="primary"
                htmlType="submit"
                disabled={loading}
              >
                {loading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined className="ol-loading-spin-icon" />
                    }
                    delay={500}
                  />
                ) : (
                  t("Common-Save")
                )}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (data: any) => {
    setLoading(true);
    let response = await confirmUserAndSetPassword(token ?? "", data.password);
    setLoading(false);
    if (response) {
      setUserValidationComplete(true);
      setNeedToSetupPassword(false);
    } else setUserValidationComplete(false);
  };

  const resultValidation: () => any = () => {
    if (needToSetupPassword) {
      return formPassword();
    } else {
      if (userValidationComplete) {
        return (
          <div className={styles.wrapper}>
            <div className={styles.logoWrapper}>
              <img className={styles.logo} src={logo} alt="Logo" />
            </div>
            <h1>
              <a href="/login" className={styles.title}>
                {t("ValidateEmail-UserConfirmed")}
              </a>
            </h1>
            <p className={styles.rightsText}>
              {t("Common-AllRightsReserved")}
              <a>BrightMarket</a>
            </p>
          </div>
        );
      } else {
        return <div>Hubo un error</div>;
      }
    }
  };

  return loading ? (
    <div className="ol-loading-spin">
      <Spin
        indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
        delay={500}
      />
    </div>
  ) : validToken ? (
    <div>{resultValidation()}</div>
  ) : (
    <div>
      <h1>Token no válido</h1>
    </div>
  );
};

export default ValidateEmail;
