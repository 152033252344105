import { useTranslation } from "react-i18next";

import { DatePicker, Table } from "antd";

import styles from "./RepositionOrderPreviewDetail.module.scss";

const RepositionOrderPreviewDetail = (props: any) => {
  const { t } = useTranslation();
  const { order, onChangeDate, indexOrder } = props;

  const handleChangeDate = (value: any, valueString: string) => {
    onChangeDate(valueString, indexOrder);
  };

  const articlesColumns = [
    {
      title: t("Common-Article"),
      dataIndex: "articleName",
      key: "articleName",
    },
    {
      title: t("Common-Name"),
      dataIndex: "skuName",
      key: "skuName",
    },
    {
      title: t("Common-Quantity"),
      dataIndex: "quantity",
      key: "quantity",
    },
  ];

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.subTitle}>{`${t("Common-Origin")} ${
        order.branchOfficeFrom ? order.branchOfficeFrom.name : order.depositFrom.name
      }`}</h1>
      <p className={styles.text}>{`${t("Common-DeliverTo")} ${
        order.branchOfficeTo ? order.branchOfficeTo.name : order.depositTo.name
      }`}</p>
      <div className={styles.dateWrapper}>
        <p className={styles.text}>{t("Common-EstimatedDeliveryDate")}</p>
        <DatePicker
          className={styles.dateSelector}
          onChange={handleChangeDate}
        />
      </div>
      <Table
        size="small"
        className={styles.tableArticles}
        columns={articlesColumns}
        dataSource={order.skus}
      />
    </div>
  );
};

export default RepositionOrderPreviewDetail;
