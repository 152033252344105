import { Typography } from "antd";
import { readNotification, unreadNotification } from "../../api/Services";

import styles from "./NotificationItem.module.scss";

type NotifcationItemProps = {
  notification: {
    id: number;
    title: string;
    description: string;
    readed: boolean;
    date: string;
  };
  onUpdate: () => {};
};

const NotificationItem = ({ notification, onUpdate }: NotifcationItemProps) => {

  const handleStatus = async () => {
    if (notification.readed) {
      await unreadNotification(notification.id);
    } else {
      await readNotification(notification.id);
    }
    onUpdate();
  };

  return (
    <div
      key={notification.id}
      className={`${styles.wrapper} ${
        !notification.readed && styles.notReaded
      }`}
      onClick={handleStatus}
    >
      <Typography.Text className={styles.title}>
        {notification.title}
      </Typography.Text>
      <Typography.Text>{notification.description}</Typography.Text>
      <Typography.Text>{notification.date}</Typography.Text>
    </div>
  );
};

export default NotificationItem;
