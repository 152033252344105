import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";
import { getRepositionOrderById } from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import NotFound from "../notfound/NotFound";
import { BusinessUniteContext } from "../../components/laoyut/Layout";
import { downloadFile } from "../../helpers/Helper";

import { Button, Form, Input, Spin, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./ViewRepositionOrder.module.scss";

const ViewRepositionOrder = () => {
  const { t } = useTranslation();
  const { updateContextValue: setBusinessUnitSelected } =
    useContext(BusinessUniteContext);

  const [loading, setLoading] = useState(true);
  const [repositionOrder, setRepositionOrder] = useState<any>();

  const [infoRepositionOrder] = Form.useForm();

  const canEdit = false;

  const { id } = useParams();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_PURCHASE_ORDERS))
      handleFetchrepositionOrder();
  }, []);

  const handleFetchrepositionOrder = async () => {
    let response = await getRepositionOrderById(id!);
    setBusinessUnitSelected(response.businessUnit.id);
    setRepositionOrder(response);
    setLoading(false);
  };

  const articleColumn = [
    {
      title: t("Common-Article"),
      key: "articleName",
      render: (record: any) => (
        <a href={`/admin/articles/${record.articleId}/view`}>
          {record.articleName}
        </a>
      ),
    },
    {
      title: t("Common-Sku"),
      dataIndex: "skuName",
      key: "skuName",
    },
    {
      title: t("Common-Quantity"),
      dataIndex: "quantity",
      key: "quantity",
    },
  ];

  const receivedColumns = [
    {
      title: t("Common-ReceivedDate"),
      key: "receivedDate",
      dataIndex: "date",
      render: (date: any) => moment(date, "YYYY-MM-DD").format("DD-MM-YYYY"),
    },
    {
      title: t("Common-ReceivedQuantity"),
      key: "receivedQuantity",
      dataIndex: "quantity",
    },
    {
      title: t("Common-WhoReceived"),
      key: "whoReceived",
      dataIndex: "whoReceived",
    },
    {
      title: t("Common-Observations"),
      key: "observations",
      dataIndex: "observations",
    },
  ];

  const handleDownloadData = useCallback(() => {
    const orderInfo = [
      [t("Common-Id"), repositionOrder.id],
      [t("Common-BusinessUnit"), repositionOrder.businessUnit?.name || ""],
      [
        t("Common-Origin"),
        repositionOrder.depositFrom?.name ||
          repositionOrder.branchOfficeFrom?.name ||
          "",
      ],
      [
        t("Common-Destination"),
        repositionOrder.depositTo?.name ||
          repositionOrder.branchOfficeTo?.name ||
          "",
      ],
      [t("Common-Status"), repositionOrder.orderStatus?.description_es || ""],
      [
        t("Common-CreatedDate"),
        moment(repositionOrder.createdDate).format("DD-MM-YYYY"),
      ],
      [
        t("Common-EstimatedDeliveryDate"),
        repositionOrder.estimatedDeliveryDate
          ? moment(repositionOrder.estimatedDeliveryDate).format("DD-MM-YYYY")
          : "",
      ],
    ];

    const skuHeaders = [
      [],
      ["SKUs"],
      [t("Common-Article"), t("Common-Sku"), t("Common-Quantity")],
    ];

    const skuData = repositionOrder.skus.map((sku: any) => [
      sku.articleName,
      sku.skuName,
      sku.quantity,
    ]);

    const csvContent = [...orderInfo, ...skuHeaders, ...skuData]
      .map((row) => row.join(","))
      .join("\n");

    downloadFile(
      csvContent,
      `${t("Common-RepositionOrder")} - ${repositionOrder.id}.csv`,
      "application/csv"
    );
  }, [repositionOrder]);

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !repositionOrder ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          {t("Common-RepositionOrder") + repositionOrder.id}
        </h1>
        <Button
          onClick={() => handleDownloadData()}
          className={styles.downloadButton}
          type="primary"
        >
          {t("Common-Download")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.repositionOrderWrapper}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={infoRepositionOrder}
          >
            <Form.Item
              label={t("Common-OriginPlace")}
              name="originPlace"
              initialValue={
                repositionOrder.depositFrom
                  ? repositionOrder.depositFrom.name
                  : repositionOrder.branchOfficeFrom.name
              }
              rules={[
                {
                  required: true,
                  message: t("Common-OriginPlace"),
                },
              ]}
            >
              <Input
                placeholder={t("Suppliers-DeliverTo")}
                disabled={!canEdit}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-DeliverTo")}
              name="deliverTo"
              initialValue={
                repositionOrder.depositTo
                  ? repositionOrder.depositTo.name
                  : repositionOrder.branchOfficeTo.name
              }
              rules={[
                {
                  required: true,
                  message: t("Common-DeliverTo"),
                },
              ]}
            >
              <Input
                placeholder={t("Suppliers-DeliverTo")}
                disabled={!canEdit}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-EstimatedDeliveryDate")}
              name="estimatedDeliveryDate"
              initialValue={repositionOrder.estimatedDeliveryDate}
              rules={[
                {
                  required: true,
                  message: t("Common-EstimatedDeliveryDate"),
                },
              ]}
            >
              <Input disabled={!canEdit} />
            </Form.Item>
            <Form.Item
              label={t("Common-Status")}
              name="status"
              initialValue={repositionOrder.orderStatus.description_es}
              rules={[
                {
                  required: true,
                  message: t("Common-Status"),
                },
              ]}
            >
              <Input placeholder={t("Common-Status")} disabled={!canEdit} />
            </Form.Item>
          </Form>
        </div>
        <div className={styles.articlesWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.articlesTitle}>{t("Common-Articles")}</h1>
          </div>
          <div className={styles.articlesTableWrapper}>
            <Table
              size="small"
              className={styles.articleTable}
              rowKey={(record) => record.id}
              loading={loading}
              columns={articleColumn}
              dataSource={repositionOrder?.skus}
              expandable={{
                expandedRowRender: (record) => (
                  <Table
                    size="small"
                    loading={loading}
                    rowKey={(record) => record.id}
                    columns={receivedColumns}
                    dataSource={record.received}
                    pagination={false}
                  />
                ),
                rowExpandable: (record) => record.totalStock !== 0,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewRepositionOrder;
