import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  getClientSegmentById,
  editClientSegment,
  getAllCountries,
  getAllSexTypes,
  getAllCitiesWithExistingClients,
  getCategoriesFiltered,
  getBranchOfficesFiltered,
  getBrandsFiltered,
  getAllChannels,
  getClientLevels,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Form,
  message,
  Select,
  Button,
  Input,
  Spin,
  Table,
  InputNumber,
  Switch,
  DatePicker,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./ViewClientSegment.module.scss";
import NotFound from "../notfound/NotFound";
import AddArticleModal from "../../components/addarticlemodal/AddArticleModal";
import moment from "moment/moment";

const ViewClientSegment = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [clientSegment, setClientSegment] = useState<any>();
  const [sexTypes, setSexTypes] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [articlesSelected, setArticlesSelected] = useState<any[]>([]);
  const [channels, setChannels] = useState<any[]>([]);
  const [levels, setLevels] = useState<any[]>([]);

  const [infoForm] = Form.useForm();

  const canEdit = hasPermission(privilegesEnum.WRITE_CLIENTS);

  const { id } = useParams();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CLIENTS)) {
      handleFetchClientSegment();
      handleFetchCountries();
      handleFetchSexTypes();
      handleFetchCities();
      handleFetchChannels();
      handleSearchLevels("");
    }
  }, []);

  const handleFetchClientSegment = async () => {
    setLoading(true);

    let response = await getClientSegmentById(id!);
    setArticlesSelected(response.articles ?? []);
    setBrands(response.brands ?? []);
    setClientSegment(response);

    setLoading(false);
  };

  const handleFetchChannels = async () => {
    const response = await getAllChannels();
    setChannels(response);
  };

  const handleSearchLevels = async (value: string) => {
    const response = await getClientLevels(0, { name: value });
    setLevels(response.clientLevels);
  };
  const handleSearchBrands = async (value: string) => {
    const response = await getBrandsFiltered(value);
    setBrands(response);
  };

  const handleFetchSexTypes = async () => {
    const response = await getAllSexTypes();
    setSexTypes(response);
  };
  const handleFetchCities: () => any = async () => {
    const response = await getAllCitiesWithExistingClients();

    setCities(response);
  };

  const handleFetchCountries: () => any = async () => {
    // setLoading(true);
    const response = await getAllCountries();

    setCountries(response);
    // setLoading(false);
  };

  const handleCancelButton = () => {
    infoForm.resetFields();
  };

  const getSexTypesOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.value} key={item.value}>
          {item.description_es}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const handleEdit = async () => {
    setLoading(true);

    const data = infoForm.getFieldsValue();

    const { birthdate, registrationDate, purchaseCountPeriodRange } = data;
    const birthdayStartDate = birthdate
      ? birthdate[0].format("YYYY-MM-DDTHH:mm:ss")
      : null;
    const birthdayEndDate = birthdate
      ? birthdate[1].format("YYYY-MM-DDTHH:mm:ss")
      : null;
    const registrationStartDate = registrationDate
      ? registrationDate[0].format("YYYY-MM-DDTHH:mm:ss")
      : null;
    const registrationEndDate = registrationDate
      ? registrationDate[1].format("YYYY-MM-DDTHH:mm:ss")
      : null;
    const periodStartDate = purchaseCountPeriodRange
      ? purchaseCountPeriodRange[0].format("YYYY-MM-DDTHH:mm:ss")
      : null;
    const periodEndDate = purchaseCountPeriodRange
      ? purchaseCountPeriodRange[1].format("YYYY-MM-DDTHH:mm:ss")
      : null;

    let response;

    if (data.type === "STATIC") {
      response = false;
      response = await editClientSegment(parseInt(id!), {
        type: "STATIC",
        name: data.name,
        description: data.description ?? "",
        clientIds: [],
        active: data.active,
      });
    } else {
      response = await editClientSegment(parseInt(id!), {
        type: "DYNAMIC",
        name: data.name,
        minAge: data.minAge,
        maxAge: data.maxAge,
        description: data.description ?? "",
        sex: data.sex,
        averageTicket: data.averageTicket,
        purchaseChannelId: data.purchaseChannelId,
        purchaseCountPeriod: data.purchaseCountPeriod,
        purchaseCountLastDays: data.purchaseCountLastDays,
        purchaseCountRange: data.purchaseCountRange,
        purchaseCountStartDate: periodStartDate,
        purchaseCountEndDate: periodEndDate,
        totalSpent: data.totalSpent,
        birthdayStartDate: birthdayStartDate,
        birthdayEndDate: birthdayEndDate,
        programRegistrationStartDate: registrationStartDate,
        programRegistrationEndDate: registrationEndDate,
        active: data.active,
        skuId: "", //ids
        articleIds: [], //articleList, //ids
        brandIds: data.brandsIds,
        clientLevelId: data.clientLevelId,
      });
    }

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
      await handleFetchClientSegment();
    } else message.error(t("Common-EditErrorMessage"));
    setLoading(false);
  };

  const handleAddArticle = (articlesToAdd: any) => {
    const newArticles: any[] = [];
    articlesToAdd.forEach((articleToAdd: any) => {
      if (
        articlesSelected.find(
          (article: any) => article.id === articleToAdd.id
        ) === undefined
      )
        newArticles.push(articleToAdd);
    });
    setArticlesSelected([...articlesSelected, ...newArticles]);
  };

  const getChannelOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {`${item.businessUnit?.name} - ${item.type.descriptionEs}`}
        </Select.Option>
      );
    });
    return options;
  };

  const handleRemoveArticle = (articleRemove: any) => {
    const articlesWithoutRemoved = articlesSelected.filter(
      (article) => article.id !== articleRemove.id
    );

    setArticlesSelected(articlesWithoutRemoved);
  };

  const clientColumns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Common-LastName"),
      dataIndex: "lastname",
      key: "lastname",
    },
  ];

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !clientSegment ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-ClientSegment")}</h1>
        {hasPermission(privilegesEnum.WRITE_CLIENTS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEdit}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.infoWrapper}>
          <Form
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={infoForm}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
              initialValue={clientSegment?.name}
            >
              <Input disabled={!canEdit} placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item
              initialValue={clientSegment?.description}
              label={t("Common-Description")}
              name="description"
            >
              <Input
                disabled={!canEdit}
                placeholder={t("Common-Description")}
              />
            </Form.Item>
            <Form.Item
              initialValue={clientSegment?.active}
              label={t("Common-Active")}
              name="active"
            >
              <Switch defaultChecked={clientSegment?.active} />
            </Form.Item>
            <Form.Item
              label={t("Common-Type")}
              name="type"
              rules={[
                {
                  required: true,
                  message: t("Common-Type"),
                },
              ]}
              initialValue={clientSegment?.type.value}
            >
              <Select disabled placeholder={t("Common-Type")} allowClear>
                <Select.Option value="STATIC">Estático</Select.Option>
                <Select.Option value="DYNAMIC">Dinámico</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.type !== currentValues.type
              }
            >
              {({ getFieldValue }) => {
                switch (getFieldValue("type")) {
                  case "DYNAMIC":
                    return (
                      <>
                        <Form.Item
                          label={t("Common-Type")}
                          name="type"
                          rules={[
                            {
                              required: true,
                              message: t("Common-Type"),
                            },
                          ]}
                          initialValue={clientSegment?.type?.value}
                        >
                          <Select placeholder={t("Common-Type")} allowClear>
                            <Select.Option value="STATIC">
                              Estático
                            </Select.Option>
                            <Select.Option value="DYNAMIC">
                              Dinámico
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          className={styles.newItem}
                          label={t("Common-Sex")}
                          name="sex"
                          initialValue={clientSegment?.sex?.value}
                        >
                          <Select
                            defaultValue={clientSegment?.sex?.value}
                            placeholder={t("Common-Sex")}
                            allowClear
                            disabled={!canEdit}
                          >
                            {getSexTypesOptions(sexTypes)}
                          </Select>
                        </Form.Item>
                        <div className={styles.formItemsWrapper}>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-AverageTicket")}
                            name="averageTicket"
                            initialValue={clientSegment?.averageTicket}
                          >
                            <InputNumber
                              className={styles.inputNumber}
                              min={0}
                              placeholder={t("Common-AverageTicket")}
                            />
                          </Form.Item>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-Channel")}
                            name="purchaseChannelId"
                          >
                            <Select
                              mode="multiple"
                              placeholder={t("Common-Channel")}
                              allowClear
                            >
                              {getChannelOptions(channels)}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className={styles.formItemsWrapper}>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-MinAge")}
                            name="minAge"
                            initialValue={clientSegment?.minAge}
                          >
                            <InputNumber
                              className={styles.inputNumber}
                              min={0}
                              placeholder={t("Common-MinAge")}
                              disabled={!canEdit}
                            />
                          </Form.Item>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-MaxAge")}
                            name="maxAge"
                            initialValue={clientSegment?.maxAge}
                          >
                            <InputNumber
                              className={styles.inputNumber}
                              min={0}
                              placeholder={t("Common-MaxAge")}
                              disabled={!canEdit}
                            />
                          </Form.Item>
                        </div>
                        {/*<div className={styles.formItemsWrapper}>*/}
                        {/*  <Form.Item*/}
                        {/*    label={t("Common-Country")}*/}
                        {/*    name="countryIds"*/}
                        {/*    className={styles.doubleItemLine}*/}
                        {/*    initialValue={clientSegment?.countries?.map(*/}
                        {/*      (country: any) => country.id*/}
                        {/*    )}*/}
                        {/*  >*/}
                        {/*    <Select*/}
                        {/*      mode="multiple"*/}
                        {/*      allowClear*/}
                        {/*      placeholder={t("Common-SelectOneOrMore")}*/}
                        {/*      // onChange={handleChange}*/}
                        {/*      className={styles.multipleSelect}*/}
                        {/*      filterOption={(input: string, option: any) =>*/}
                        {/*        option.children*/}
                        {/*          .toLowerCase()*/}
                        {/*          .indexOf(input.toLowerCase()) >= 0*/}
                        {/*      }*/}
                        {/*      defaultValue={clientSegment?.countries.map(*/}
                        {/*        (country: any) => country.id*/}
                        {/*      )}*/}
                        {/*      disabled={!canEdit}*/}
                        {/*    >*/}
                        {/*      {getCountriesOptions(countries)}*/}
                        {/*    </Select>*/}
                        {/*  </Form.Item>*/}
                        {/*  <Form.Item*/}
                        {/*    label={t("Common-City")}*/}
                        {/*    name="stateIds"*/}
                        {/*    className={styles.doubleItemLine}*/}
                        {/*    initialValue={clientSegment?.states.map(*/}
                        {/*      (state: any) => state.id*/}
                        {/*    )}*/}
                        {/*  >*/}
                        {/*    <Select*/}
                        {/*      mode="multiple"*/}
                        {/*      allowClear*/}
                        {/*      placeholder={t("Common-SelectOneOrMore")}*/}
                        {/*      // onChange={handleChange}*/}
                        {/*      className={styles.multipleSelect}*/}
                        {/*      filterOption={(input: string, option: any) =>*/}
                        {/*        option.children*/}
                        {/*          .toLowerCase()*/}
                        {/*          .indexOf(input.toLowerCase()) >= 0*/}
                        {/*      }*/}
                        {/*      defaultValue={clientSegment?.states.map(*/}
                        {/*        (state: any) => state.id*/}
                        {/*      )}*/}
                        {/*      disabled={!canEdit}*/}
                        {/*    >*/}
                        {/*      {getCountriesOptions(cities)}*/}
                        {/*    </Select>*/}
                        {/*  </Form.Item>*/}
                        {/*</div>*/}
                        <div className={styles.formItemsWrapper}>
                          <Form.Item
                            label={t("Common-Brands")}
                            name="brandsIds"
                            className={styles.doubleItemLine}
                            initialValue={clientSegment?.brands.map(
                              (brand: any) => brand.id
                            )}
                          >
                            <Select
                              mode="multiple"
                              allowClear
                              onSearch={handleSearchBrands}
                              placeholder={t("Common-SearchByName")}
                              filterOption={(input: string, option: any) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              defaultValue={clientSegment?.brands?.map(
                                (brand: any) => brand.id
                              )}
                              disabled={!canEdit}
                            >
                              {getOptions(brands)}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-Level")}
                            name="clientLevelId"
                            initialValue={clientSegment?.level?.id}
                          >
                            <Select
                              allowClear
                              placeholder={t("Common-SearchByName")}
                              onSearch={handleSearchLevels}
                              showSearch
                              filterOption={(input: string, option: any) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {getOptions(levels)}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className={styles.formItemsWrapper}>
                          <Form.Item
                            label={t("Common-Period")}
                            name="purchaseCountPeriodRange"
                            className={styles.doubleItemLine}
                            initialValue={[
                              clientSegment?.purchaseCountStartDate
                                ? moment(
                                    clientSegment?.purchaseCountStartDate,
                                    "YYYY-MM-DDHH:mm:ss"
                                  )
                                : null,
                              clientSegment?.purchaseCountEndDate
                                ? moment(
                                    clientSegment?.purchaseCountEndDate,
                                    "YYYY-MM-DDHH:mm:ss"
                                  )
                                : null,
                            ]}
                          >
                            <DatePicker.RangePicker
                              defaultValue={[
                                clientSegment?.purchaseCountStartDate
                                  ? moment(
                                      clientSegment?.purchaseCountStartDate,
                                      "YYYY-MM-DDHH:mm:ss"
                                    )
                                  : null,
                                clientSegment?.purchaseCountEndDate
                                  ? moment(
                                      clientSegment?.purchaseCountEndDate,
                                      "YYYY-MM-DDHH:mm:ss"
                                    )
                                  : null,
                              ]}
                              placeholder={[t("Common-Start"), t("Common-End")]}
                            ></DatePicker.RangePicker>
                          </Form.Item>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-PurchaseCount")}
                            name="purchaseCountPeriod"
                            initialValue={clientSegment?.purchaseCountPeriod}
                          >
                            <InputNumber
                              className={styles.inputNumber}
                              min={0}
                              placeholder={t("Common-PurchaseCount")}
                            />
                          </Form.Item>
                        </div>
                        <div className={styles.formItemsWrapper}>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-XDays")}
                            name="purchaseCountRange"
                            initialValue={clientSegment?.purchaseCountPeriod}
                          >
                            <InputNumber
                              className={styles.inputNumber}
                              min={0}
                              placeholder={t("Common-XDays")}
                            />
                          </Form.Item>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-PurchaseCountDays")}
                            name="purchaseCountLastDays"
                            initialValue={clientSegment?.purchaseCountLastDays}
                          >
                            <Input
                              className={styles.inputNumber}
                              min={0}
                              placeholder={t("Common-PurchaseCountDays")}
                            />
                          </Form.Item>
                        </div>
                        <div className={styles.formItemsWrapper}>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-TotalSpent")}
                            name="totalSpent"
                            initialValue={clientSegment?.totalSpent}
                          >
                            <InputNumber
                              className={styles.inputNumber}
                              min={0}
                              placeholder={t("Common-TotalSpent")}
                            />
                          </Form.Item>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-BirthdayBetween")}
                            name="birthdate"
                            initialValue={[
                              clientSegment?.birthdayStartDate
                                ? moment(
                                    clientSegment?.birthdayStartDate,
                                    "YYYY-MM-DDHH:mm:ss"
                                  )
                                : null,
                              clientSegment?.birthdayEndDate
                                ? moment(
                                    clientSegment?.birthdayEndDate,
                                    "YYYY-MM-DDHH:mm:ss"
                                  )
                                : null,
                            ]}
                          >
                            <DatePicker.RangePicker
                              defaultValue={[
                                clientSegment?.birthdayStartDate
                                  ? moment(
                                      clientSegment?.birthdayStartDate,
                                      "YYYY-MM-DDHH:mm:ss"
                                    )
                                  : null,
                                clientSegment?.birthdayEndDate
                                  ? moment(
                                      clientSegment?.birthdayEndDate,
                                      "YYYY-MM-DDHH:mm:ss"
                                    )
                                  : null,
                              ]}
                              placeholder={[t("Common-Start"), t("Common-End")]}
                            ></DatePicker.RangePicker>
                          </Form.Item>
                        </div>
                        <div className={styles.formItemsWrapper}>
                          <Form.Item
                            className={styles.doubleItemLine}
                            label={t("Common-ProgramRegistrationDateBetween")}
                            name="registrationDate"
                            initialValue={[
                              clientSegment?.programRegistrationStartDate
                                ? moment(
                                    clientSegment?.programRegistrationStartDate,
                                    "YYYY-MM-DDHH:mm:ss"
                                  )
                                : null,
                              clientSegment?.programRegistrationEndDate
                                ? moment(
                                    clientSegment?.programRegistrationEndDate,
                                    "YYYY-MM-DDHH:mm:ss"
                                  )
                                : null,
                            ]}
                          >
                            <DatePicker.RangePicker
                              defaultValue={[
                                clientSegment?.programRegistrationStartDate
                                  ? moment(
                                      clientSegment?.programRegistrationStartDate,
                                      "YYYY-MM-DDHH:mm:ss"
                                    )
                                  : null,
                                clientSegment?.programRegistrationEndDate
                                  ? moment(
                                      clientSegment?.programRegistrationEndDate,
                                      "YYYY-MM-DDHH:mm:ss"
                                    )
                                  : null,
                              ]}
                              placeholder={[t("Common-Start"), t("Common-End")]}
                            ></DatePicker.RangePicker>
                          </Form.Item>
                        </div>
                        <AddArticleModal
                          selectedArticles={articlesSelected}
                          onAddArticle={handleAddArticle}
                          onRemoveArticle={handleRemoveArticle}
                        />
                      </>
                    );
                  case "STATIC":
                    return <> </>;
                }
              }}
            </Form.Item>
          </Form>
        </div>
        {hasPermission(privilegesEnum.READ_CLIENTS) && (
          <div className={styles.tableWrapper}>
            <h1 className={styles.subTitle}>{t("Common-Clients")}</h1>
            <Table
              size="small"
              className={styles.table}
              loading={loading}
              columns={clientColumns}
              dataSource={clientSegment?.clients}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewClientSegment;
