import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  discontinueBranchOfficeArticle,
  getBranchOfficesFiltered,
  getBrandsByBranchOfficeId,
  getCategoriesByBrandAndBranchOfficeId,
  getDepositsFiltered,
  getSkusByBrandCategoryAndBranchOfficeId,
} from "../../../api/Services";
import { BusinessUniteContext } from "../../../components/laoyut/Layout";
import {
  hasPermission,
  privilegesEnum,
} from "../../../helpers/PermissionHelper";

import {
  Button,
  Cascader,
  Dropdown,
  Menu,
  message,
  Select,
  Spin,
  Switch,
  Table,
} from "antd";
import { MoreOutlined, RedoOutlined, SendOutlined } from "@ant-design/icons";

import styles from "./Catalogue.module.scss";
import { useNavigate } from "react-router-dom";

enum filterSkus {
  all = "all",
  active = "active",
  inactive = "inactive",
}
const Catalogue = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loadingDeposits, setLoadingDeposits] = useState(false);
  const [loadingBranchOffices, setLoadingBranchOffices] = useState(false);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(0);
  const [actualFilterSkus, setActualFilterSkus] = useState(filterSkus.all);
  const [actualBranchOfficeId, setActualBranchOfficeId] = useState<any>(null);
  const [actualFilter, setActualFilter] = useState<
    "deposit" | "branchoffice" | null
  >(null);
  const [actualDepositId, setActualDepositId] = useState<any>(null);
  const [branchOfficeOptions, setBranchOfficeOptions] = useState([]);
  const [depositOptions, setDepositOptions] = useState([]);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [expandedRowData, setExpandedRowData] = useState<{
    [key: string]: any[];
  }>({});
  const [loadingExpandedRow, setLoadingExpandedRow] = useState<{
    [key: string]: boolean;
  }>({});
  const [expandedCategories, setExpandedCategories] = useState<string[]>([]);
  const [expandedCategoryData, setExpandedCategoryData] = useState<{
    [key: string]: any[];
  }>({});
  const [loadingCategoryRow, setLoadingCategoryRow] = useState<{
    [key: string]: boolean;
  }>({});

  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  useEffect(() => {
    handleSearchBranchOffices("");
    handleSearchDeposits("");
  }, [businessUnitSelected]);

  const handlePagination = (page: number) => {
    handleFetchArticles(actualBranchOfficeId, page);
    setActualPage(page);
  };

  const handleFetchArticles = async (
    branchOfficeId?: number,
    depositId?: number,
    filter = actualFilter,
    pagination = actualPage
  ) => {
    if (branchOfficeId || depositId) {
      setData([]);
      setLoadingArticles(true);

      const response = await getBrandsByBranchOfficeId(
        {
          branchOfficeId,
          depositId,
        },
        pagination
      );
      setData(response.brands);
      setTotalSize(response.totalElements);
      setLoadingArticles(false);
    } else message.error(t("Common-SelectBranchOfficeError"));
  };

  const handleSearchBranchOffices = async (value: string) => {
    if (businessUnitSelected) {
      setLoadingBranchOffices(true);
      const response = await getBranchOfficesFiltered(0, {
        name: value,
        businessUnitId: businessUnitSelected,
      });
      const options = response.branchOffices.map((item: any) => ({
        value: item.id,
        text: item.name,
      }));
      setBranchOfficeOptions(options);
      setLoadingBranchOffices(false);
    } else setBranchOfficeOptions([]);
  };

  const handleSearchDeposits = async (value: string) => {
    setLoadingDeposits(true);
    const response = await getDepositsFiltered(0, {
      name: value,
    });
    const options = response.deposits.map((item: any) => ({
      value: item.id,
      text: item.name,
    }));
    setDepositOptions(options);
    setLoadingDeposits(false);
  };

  const getRowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? styles.rowLight : styles.rowDark;
  };

  const handleUnsupplyArticle = async (sku: any) => {
    const deleteStatus = await discontinueBranchOfficeArticle(
      actualBranchOfficeId,
      sku.id
    );
    if (!deleteStatus) message.error(t("Common-ErrorMessage"));
    else message.success(t("Common-SuccessMessage"));
  };

  const columnsBrands = [
    {
      title: t("Common-Brand"),
      key: "name",
      render: (record: any) => record.brand?.name,
    },
    {
      title: t("Common-Stock"),
      dataIndex: "totalStock",
    },
    {
      title: t("Common-Supply"),
      key: "unsupply",
    },
    {
      title: t("Common-Analysis"),
      key: "analysis",
    },
    {
      title: t("Common-Strategy"),
      key: "strategy",
    },
    {
      title: t("Common-InventoryLevelOptimal"),
      key: "inventory",
    },
  ];

  const columnsCategories = [
    {
      title: t("Common-Category"),
      key: "name",
      render: (category: any) => {
        const parentsString = category?.parents
          ? category.parents.filter(Boolean).join(" - ")
          : "";
        const categoryName = category?.categoryName || "";

        return parentsString
          ? `${parentsString} - ${categoryName}`
          : categoryName;
      },
    },
    {
      title: t("Common-Stock"),
      dataIndex: "totalStock",
    },
    {
      title: t("Common-Supply"),
      key: "unsupply",
    },
    {
      title: t("Common-Analysis"),
      key: "analysis",
    },
    {
      title: t("Common-Strategy"),
      key: "strategy",
    },
    {
      title: t("Common-InventoryLevelOptimal"),
      key: "inventory",
    },
  ];

  const columnsSku = [
    {
      title: t("Common-Article"),
      key: "article",
      render: (sku: any) => (
        <a href={`/admin/articles/${sku.articleId}/view`}>{sku.articleName}</a>
      ),
    },
    {
      title: t("Common-Sku"),
      key: "sku",
      render: (sku: any) => sku.name,
    },
    {
      title: t("Common-Stock"),
      key: "totalStock",
      render: (sku: any) =>
        actualBranchOfficeId
          ? sku?.branchOffices.find(
              (branchOffice: any) =>
                branchOffice.branchOffice.id == actualBranchOfficeId
            )?.stock
          : sku?.deposits.find(
              (deposit: any) =>
                deposit.deposit.id == actualDepositId
            )?.stock,
    },
    {
      title: t("Common-Supply"),
      key: "unsupply",
      render: (sku: any) => (
        <Switch
          defaultChecked={
            !sku?.branchOffices.find(
              (branchOffice: any) =>
                branchOffice.branchOffice.id == actualBranchOfficeId
            )?.unsupply
          }
          onChange={() => handleUnsupplyArticle(sku)}
        />
      ),
    },
    {
      title: t("Common-Analysis"),
      render: (sku: any) =>
        sku?.branchOffices.find(
          (branchOffice: any) =>
            branchOffice.branchOffice.id == actualBranchOfficeId
        )?.analysis,
    },
    {
      title: t("Common-Strategy"),
      render: (sku: any) =>
        sku?.branchOffices.find(
          (branchOffice: any) =>
            branchOffice.branchOffice.id == actualBranchOfficeId
        )?.strategy,
    },
    {
      title: t("Common-InventoryLevelOptimal"),
    },
    {
      key: "actions",
      render: (record: any) => (
        <Dropdown
          overlay={
            <Menu>
              {hasPermission(privilegesEnum.WRITE_REPOSITION_ORDERS) && (
                <Menu.Item
                  key="edit"
                  onClick={() => {
                    navigate("/repositionorders/create", {
                      state: {
                        articleId: record.articleId,
                        articleName: record.articleName,
                      },
                    });
                  }}
                  icon={<SendOutlined />}
                >
                  {t("Common-AddRepositionOrder")}
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button
            onClick={(e) => e.preventDefault()}
            className={styles.buttonMore}
          >
            <MoreOutlined className={styles.moreIcon} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const handleFetchCategories = async (brandId: number) => {
    setLoadingExpandedRow((prev) => ({ ...prev, [brandId]: true }));
    try {
      const response = await getCategoriesByBrandAndBranchOfficeId(
        { branchOfficeId: actualBranchOfficeId, depositId: actualDepositId },
        brandId,
        0
      );
      setLoadingExpandedRow((prev) => ({ ...prev, [brandId]: false }));
      setExpandedRowData((prev) => ({
        ...prev,
        [brandId]: response.categories,
      }));
    } catch (error) {
      setLoadingExpandedRow((prev) => ({ ...prev, [brandId]: false }));
    }
  };

  const handleFetchSkus = useCallback(
    async (brandId: number, categoryId: number) => {
      setLoadingCategoryRow((prev) => ({ ...prev, [categoryId]: true }));
      try {
        const response = await getSkusByBrandCategoryAndBranchOfficeId(
          { branchOfficeId: actualBranchOfficeId, depositId: actualDepositId },
          brandId,
          categoryId,
          0,
          actualFilterSkus === filterSkus.active
            ? 0
            : actualFilterSkus === filterSkus.inactive
            ? 1
            : undefined
        );
        setLoadingCategoryRow((prev) => ({ ...prev, [categoryId]: false }));
        setExpandedCategoryData((prev) => ({
          ...prev,
          [categoryId]: response.skus,
        }));
      } catch (error) {
        setLoadingCategoryRow((prev) => ({ ...prev, [categoryId]: false }));
      }
    },
    [actualFilterSkus, actualBranchOfficeId]
  );

  const expandedCategoryRender = (brandId: string) => (record: any) => {
    const skus = expandedCategoryData[record.categoryId] || [];
    const isLoading = loadingCategoryRow[record.categoryId];

    if (isLoading) {
      return <Spin />;
    }

    return (
      <Table
        columns={columnsSku}
        dataSource={skus}
        pagination={false}
        rowKey={(record) => record.id}
      />
    );
  };

  const expandedRowRender = (record: any) => {
    const categories = expandedRowData[record.brand.id] || [];
    const isLoading = loadingExpandedRow[record.brand.id];

    if (isLoading) {
      return <Spin />;
    }

    return (
      <Table
        columns={columnsCategories}
        dataSource={categories}
        pagination={false}
        rowKey={(record) => record.categoryId}
        expandedRowRender={expandedCategoryRender(record.brand.id)}
        onExpand={(expanded, categoryRecord) => {
          if (expanded) {
            handleFetchSkus(record.brand.id, categoryRecord.categoryId);
            setExpandedCategories([
              ...expandedCategories,
              categoryRecord.categoryId,
            ]);
          } else {
            setExpandedCategories(
              expandedCategories.filter(
                (id) => id !== categoryRecord.categoryId
              )
            );
          }
        }}
        expandedRowKeys={expandedCategories}
      />
    );
  };

  const handleCascaderChange = (value: any) => {
    if (value) {
      if (value[0] === "BranchOffice") {
        const selectedBranchOfficeId = value[1];
        handleFetchArticles(selectedBranchOfficeId, undefined);
        setActualBranchOfficeId(selectedBranchOfficeId);
        setActualDepositId(undefined);
        setActualFilter("branchoffice");
      } else if (value[0] === "Deposit") {
        const selectedDepositId = value[1];
        handleFetchArticles(undefined, selectedDepositId);
        setActualBranchOfficeId(undefined);
        setActualDepositId(selectedDepositId);
        setActualFilter("deposit");
      }
    }
  };

  const handleSearch = async (inputValue: string) => {
    if (inputValue.length >= 3) {
      await handleSearchBranchOffices(inputValue);
      await handleSearchDeposits(inputValue);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Catalogue")}</h1>
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_REPOSITION_ORDERS) && (
          <div className={styles.tableWrapper}>
            <div className={styles.headerTableWrapper}>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <Cascader
                    placeholder={t(
                      "Common-SearchBranchOfficeDepositTextPlaceholder"
                    )}
                    onSearch={handleSearch}
                    className={styles.filterSelect}
                    onChange={handleCascaderChange}
                    showSearch
                    options={[
                      {
                        label: t("Common-BranchOffice"),
                        value: "BranchOffice",
                        children: (branchOfficeOptions || []).map((d: any) => ({
                          value: d.value,
                          label: d.text,
                        })),
                        disabled: branchOfficeOptions.length === 0,
                      },
                      {
                        label: t("Common-Deposit"),
                        value: "Deposit",
                        children: (depositOptions || []).map((d: any) => ({
                          value: d.value,
                          label: d.text,
                        })),
                        disabled: depositOptions.length === 0,
                      },
                    ]}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <Select
                    className={styles.filterSelect}
                    allowClear
                    defaultValue={actualFilterSkus}
                    onChange={(value) => {
                      setExpandedCategories([]);
                      setActualFilterSkus(value);
                    }}
                  >
                    <Select.Option value={filterSkus.all} key={filterSkus.all}>
                      {t("Common-All")}
                    </Select.Option>
                    <Select.Option
                      value={filterSkus.active}
                      key={filterSkus.active}
                    >
                      {t("Common-Actives")}
                    </Select.Option>
                    <Select.Option
                      value={filterSkus.inactive}
                      key={filterSkus.inactive}
                    >
                      {t("Common-Inactives")}
                    </Select.Option>
                  </Select>
                </div>
              </div>
              <Button
                icon={<RedoOutlined />}
                onClick={() =>
                  handleFetchArticles(actualBranchOfficeId, actualDepositId)
                }
              />
            </div>
            <Table
              size="small"
              className={styles.table}
              loading={loadingArticles}
              columns={columnsBrands}
              expandedRowRender={expandedRowRender}
              onExpand={(expanded, record) => {
                if (expanded) {
                  handleFetchCategories(record.brand.id);
                  setExpandedRows([...expandedRows, record.brand.id]);
                } else {
                  setExpandedRows(
                    expandedRows.filter((id) => id !== record.brand.id)
                  );
                }
              }}
              expandedRowKeys={expandedRows}
              dataSource={data}
              rowKey={(record) => record.brand?.id}
              rowClassName={getRowClassName}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Catalogue;
