import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  deleteClientSegment,
  cloneClientSegment,
  getClientSegmentsFiltered,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Table,
  Button,
  message,
  Space,
  Modal,
  Input,
  Tag,
  InputNumber,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./ClientSegments.module.scss";
import moment from "moment/moment";

const ClientSegments = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [nameFilterValue, setNameFilterValue] = useState("");
  const [descriptionFilterValue, setDescriptionFilterValue] = useState("");
  const [minMembersFilterValue, setMinMembersFilterValue] = useState<number>();
  const [maxMembersFilterValue, setMaxMembersFilterValue] = useState<number>();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CLIENTS)) {
      handleFetchClientSegments();
    }
  }, [
    nameFilterValue,
    descriptionFilterValue,
    minMembersFilterValue,
    maxMembersFilterValue,
  ]);

  const handleFetchClientSegments = async (pagination = actualPage) => {
    const response = await getClientSegmentsFiltered(pagination, {
      name: nameFilterValue,
      description: descriptionFilterValue,
      minMembers: minMembersFilterValue,
      maxMembers: maxMembersFilterValue,
    });

    setData(response.clientSegments);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const showDeleteConfirm = (clientSegmentId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteSegment(clientSegmentId);
      },
    });
  };

  const handleDeleteSegment = async (clientSegmentId: number) => {
    setLoading(true);
    const deleteStatus = await deleteClientSegment(clientSegmentId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchClientSegments();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    handleFetchClientSegments(page - 1);
    setActualPage(page);
  };

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
    },
    {
      title: t("Common-Type"),
      key: "type",
      render: (segment: any) => segment.type.description_es,
    },
    {
      title: t("Common-Description"),
      dataIndex: "description",
    },
    {
      title: t("Common-Quantity"),
      dataIndex: "totalClients",
    },
    {
      title: t("Common-TicketAverage"),
      dataIndex: "averageTicket",
    },
    {
      title: t("Common-TotalSpent"),
      dataIndex: "totalSpent",
    },
    {
      title: t("Common-Status"),
      dataIndex: "active", // green, red
      render: (enabled: boolean) => (
        <>
          <Tag color={enabled ? "green" : "red"}>
            {enabled ? t("Common-Active") : t("Common-Inactive")}
          </Tag>
        </>
      ),
    },
    {
      title: t("Common-CreatedDate"),
      dataIndex: "createdAt",
      render: (date: any) =>
        date ? moment(date, "YYYY-MM-DD").format("DD-MM-YYYY") : "",
    },
    {
      title: t("Common-Origin"),
      dataIndex: "origin",
    },
    {
      title: t("Common-Analysis"),
    },
    // {
    //   title: t("Common-MinAge"),
    //   key: "minAge",
    //   render: (segment: any) => segment.client.minAge,
    // },
    // {
    //   title: t("Common-MaxAge"),
    //   key: "maxAge",
    //   render: (segment: any) => segment.client.maxAge,
    // },
    // {
    //   title: t("Common-Sex"),
    //   key: "sex",
    //   render: (segment: any) => segment.client.sex?.description_es,
    // },
    // {
    //   title: t("Common-ClientsOld"),
    //   dataIndex: "size",
    //   key: "clients",
    // },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (segment: any) => (
        <Space size="middle">
          {hasPermission(privilegesEnum.WRITE_CLIENTS) ? (
            <>
              {/*<CopyOutlined*/}
              {/*  href={`/promotions/clientsegments/${segment.id}/view`}*/}
              {/*  onClick={() => {*/}
              {/*    cloneSegment(segment.client.id);*/}
              {/*  }}*/}
              {/*/>*/}
              <EditOutlined
                href={`/promotions/clientsegments/${segment.id}/view`}
                onClick={() => {
                  window.location.href = `/promotions/clientsegments/${segment.id}/view`;
                }}
              />
            </>
          ) : (
            <EyeOutlined
              href={`/clientsegments/${segment.id}/view`}
              onClick={() => {
                window.location.href = `/promotions/clientsegments/${segment.id}/view`;
              }}
            />
          )}
          {hasPermission(privilegesEnum.DELETE_CLIENTS) && (
            <DeleteOutlined
              onClick={() => {
                showDeleteConfirm(segment.id);
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-ClientSegments")}</h1>
        {hasPermission(privilegesEnum.WRITE_CLIENTS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              href="/promotions/clientsegments/create"
              className={styles.newButton}
              type="primary"
              icon={<PlusOutlined />}
            />
            <Button
              // onClick={handleCancelButton}
              className={styles.importButton}
              type="primary"
            >
              {t("Common-Import")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.headerTableWrapper}>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}>
                <Input
                  placeholder={t("Common-SearchByName")}
                  className={styles.filterInput}
                  onChange={(e) => {
                    setNameFilterValue(e.target.value);
                    setActualPage(1);
                  }}
                  value={nameFilterValue}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Input
                  placeholder={t("Common-Description")}
                  className={styles.filterInput}
                  onChange={(e) => {
                    setDescriptionFilterValue(e.target.value);
                    setActualPage(1);
                  }}
                  value={descriptionFilterValue}
                />
              </div>
              <div className={styles.filterWrapper}>
                <InputNumber
                  min={0}
                  placeholder={t("Common-QuantityFrom")}
                  className={styles.filterInputNumber}
                  onChange={(value: number) => {
                    setMinMembersFilterValue(value);
                    setActualPage(1);
                  }}
                />
                <InputNumber
                  min={0}
                  placeholder={t("Common-QuantityTo")}
                  className={styles.filterInputNumber}
                  onChange={(value: number) => {
                    setMaxMembersFilterValue(value);
                    setActualPage(1);
                  }}
                />
              </div>
            </div>
          </div>

          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.id}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              onChange: handlePagination,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ClientSegments;
