import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getBrandsCatalogue,
  getCategoriesByBrandCatalogue,
  getSkusByBrandCategoryCatalogue,
} from "../../../api/Services";
import {
  hasPermission,
  privilegesEnum,
} from "../../../helpers/PermissionHelper";

import { Button, Dropdown, Menu, message, Spin, Table } from "antd";
import { MoreOutlined, RedoOutlined, SendOutlined } from "@ant-design/icons";

import styles from "./Catalogue.module.scss";
import { useNavigate } from "react-router-dom";

const Catalogue = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(0);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [expandedRowData, setExpandedRowData] = useState<{
    [key: string]: any[];
  }>({});
  const [loadingExpandedRow, setLoadingExpandedRow] = useState<{
    [key: string]: boolean;
  }>({});
  const [expandedCategories, setExpandedCategories] = useState<string[]>([]);
  const [expandedCategoryData, setExpandedCategoryData] = useState<{
    [key: string]: any[];
  }>({});
  const [loadingCategoryRow, setLoadingCategoryRow] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    handleFetchArticles();
  }, []);

  const handlePagination = (page: number) => {
    handleFetchArticles(page);
    setActualPage(page);
  };

  const handleFetchArticles = async (pagination = actualPage) => {
    setData([]);
    setLoadingArticles(true);

    const response = await getBrandsCatalogue(pagination);
    setData(response.brands);
    setTotalSize(response.totalElements);
    setLoadingArticles(false);
  };

  const getRowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? styles.rowLight : styles.rowDark;
  };

  const columnsBrands = [
    {
      title: t("Common-Brand"),
      key: "name",
      render: (record: any) => record.brand.name,
    },
    {
      title: t("Common-Stock"),
      dataIndex: "totalStock",
    },

    {
      title: t("Common-Analysis"),
      key: "analysis",
    },
    {
      title: t("Common-Strategy"),
      key: "strategy",
    },
    {
      title: t("Common-InventoryLevelOptimal"),
      key: "inventory",
    },
  ];

  const columnsCategories = [
    {
      title: t("Common-Category"),
      key: "name",
      render: (category: any) => {
        const parentsString = category?.parents
          ? category.parents.filter(Boolean).join(" - ")
          : "";
        const categoryName = category?.categoryName || "";

        return parentsString
          ? `${parentsString} - ${categoryName}`
          : categoryName;
      },
    },
    {
      title: t("Common-Stock"),
      dataIndex: "totalStock",
    },
    {
      title: t("Common-Supply"),
      key: "unsupply",
    },
    {
      title: t("Common-Analysis"),
      key: "analysis",
    },
    {
      title: t("Common-Strategy"),
      key: "strategy",
    },
    {
      title: t("Common-InventoryLevelOptimal"),
      key: "inventory",
    },
  ];

  const columnsSku = [
    {
      title: t("Common-Article"),
      key: "article",
      render: (sku: any) => (
        <a href={`/admin/articles/${sku.articleId}/view`}>{sku.articleName}</a>
      ),
    },
    {
      title: t("Common-Sku"),
      key: "sku",
      render: (sku: any) => sku.name,
    },
    {
      title: t("Common-Stock"),
      dataIndex: "totalStock",
    },
    {
      title: t("Common-Analysis"),
    },
    {
      title: t("Common-Strategy"),
    },
    {
      title: t("Common-InventoryLevelOptimal"),
    },
    {
      key: "actions",
      render: (record: any) => (
        <Dropdown
          overlay={
            <Menu>
              {hasPermission(privilegesEnum.WRITE_PURCHASE_ORDERS) && (
                <Menu.Item
                  key="edit"
                  onClick={() => {
                    navigate("/purchaseorders/create");
                  }}
                  icon={<SendOutlined />}
                >
                  {t("Common-AddPurchaseOrder")}
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button
            onClick={(e) => e.preventDefault()}
            className={styles.buttonMore}
          >
            <MoreOutlined className={styles.moreIcon} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const handleFetchCategories = async (brandId: number) => {
    setLoadingExpandedRow((prev) => ({ ...prev, [brandId]: true }));
    try {
      const response = await getCategoriesByBrandCatalogue(brandId, 0);
      setLoadingExpandedRow((prev) => ({ ...prev, [brandId]: false }));
      setExpandedRowData((prev) => ({
        ...prev,
        [brandId]: response.categories,
      }));
    } catch (error) {
      setLoadingExpandedRow((prev) => ({ ...prev, [brandId]: false }));
    }
  };

  const handleFetchSkus = async (brandId: number, categoryId: number) => {
    setLoadingCategoryRow((prev) => ({ ...prev, [categoryId]: true }));
    try {
      const response = await getSkusByBrandCategoryCatalogue(
        brandId,
        categoryId,
        0
      );
      setLoadingCategoryRow((prev) => ({ ...prev, [categoryId]: false }));
      setExpandedCategoryData((prev) => ({
        ...prev,
        [categoryId]: response.skus,
      }));
    } catch (error) {
      setLoadingCategoryRow((prev) => ({ ...prev, [categoryId]: false }));
    }
  };

  const expandedCategoryRender = (brandId: string) => (record: any) => {
    const skus = expandedCategoryData[record.categoryId] || [];
    const isLoading = loadingCategoryRow[record.categoryId];

    if (isLoading) {
      return <Spin />;
    }

    return (
      <Table
        columns={columnsSku}
        dataSource={skus}
        pagination={false}
        rowKey={(record) => record.id}
      />
    );
  };

  const expandedRowRender = (record: any) => {
    const categories = expandedRowData[record.brand.id] || [];
    const isLoading = loadingExpandedRow[record.brand.id];

    if (isLoading) {
      return <Spin />;
    }

    return (
      <Table
        columns={columnsCategories}
        dataSource={categories}
        pagination={false}
        rowKey={(record) => record.categoryId}
        expandedRowRender={expandedCategoryRender(record.brand.id)}
        onExpand={(expanded, categoryRecord) => {
          if (expanded) {
            handleFetchSkus(record.brand.id, categoryRecord.categoryId);
            setExpandedCategories([...expandedCategories, categoryRecord.categoryId]);
          } else {
            setExpandedCategories(
              expandedCategories.filter((id) => id !== categoryRecord.categoryId)
            );
          }
        }}
        expandedRowKeys={expandedCategories}
      />
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Catalogue")}</h1>
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_PURCHASE_ORDERS) && (
          <div className={styles.tableWrapper}>
            <div className={styles.headerTableWrapper}>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}></div>
              </div>
              <Button
                icon={<RedoOutlined />}
                onClick={() => handleFetchArticles()}
              />
            </div>
            <Table
              size="small"
              className={styles.table}
              loading={loadingArticles}
              columns={columnsBrands}
              expandedRowRender={expandedRowRender}
              onExpand={(expanded, record) => {
                if (expanded) {
                  handleFetchCategories(record.brand.id);
                  setExpandedRows([...expandedRows, record.brand.id]);
                } else {
                  setExpandedRows(
                    expandedRows.filter((id) => id !== record.brand.id)
                  );
                }
              }}
              expandedRowKeys={expandedRows}
              dataSource={data}
              rowKey={(record) => record.brand.id}
              rowClassName={getRowClassName}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Catalogue;
