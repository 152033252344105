import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import NotFound from "../notfound/NotFound";
import {
  createChannel,
  editBusinessUnit,
  getBusinessUnitById,
  getChannelsTypes,
  getCurrencies,
  getLanguages,
} from "../../api/Services";
import { BusinessUniteContext } from "../../components/laoyut/Layout";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Form,
  message,
  Select,
  Modal,
  Button,
  Input,
  Spin,
  Table,
  Space,
  Tag,
} from "antd";
import {
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import styles from "./ViewBusinessUnit.module.scss";

const ViewBusinessUnit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    contextValue: businessUnitSelected,
    updateContextValue: setBusinessUnitSelected,
  } = useContext(BusinessUniteContext);

  const [loading, setLoading] = useState(true);
  const [businessUnit, setBusinessUnit] = useState<any>();
  const [languages, setLanguages] = useState<any[]>([]);
  const [currencies, setCurrencies] = useState<any[]>([]);
  const [channelTypes, setChannelTypes] = useState<any[]>([]);

  const [infoBusinessUnitForm] = Form.useForm();

  const canEdit = hasPermission(privilegesEnum.WRITE_BUSINESS_UNITS);

  const { id } = useParams();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_BUSINESS_UNITS)) {
      handleFetchBusinessUnit();
      handleFetchLanguages();
      handleFetchCurrencies();
      handleFetchChannelTypes();
    }
  }, []);

  useEffect(() => {
    if (
      businessUnit &&
      businessUnitSelected &&
      businessUnit.id !== businessUnitSelected &&
      !loading
    ) {
      navigate("/admin/businessrules");
    }
  }, [businessUnitSelected, businessUnit]);

  const handleFetchLanguages: () => any = async () => {
    const languages = await getLanguages();
    const children: any[] = [];

    languages.forEach(function (item: any) {
      children.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });
    setLanguages(children);
  };

  const handleFetchChannelTypes: () => any = async () => {
    const types = await getChannelsTypes();
    const children: any[] = [];
    types.forEach(function (item: any) {
      children.push(item);
    });
    setChannelTypes(children);
  };

  const handleFetchCurrencies = async () => {
    const response = await getCurrencies();
    setCurrencies(response);
  };

  const handleFetchBusinessUnit = async () => {
    let response = await getBusinessUnitById(id!);
    setBusinessUnit(response);
    setBusinessUnitSelected(response.id);
    setLoading(false);
  };

  const handleCancelButton = () => {
    infoBusinessUnitForm.resetFields();
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const handleEditBusinessUnit = async () => {
    setLoading(true);

    const { name, language, currency } = infoBusinessUnitForm.getFieldsValue();

    const response = await editBusinessUnit(parseInt(id!), {
      name,
      languageId: language,
      currencyId: currency,
      targetMargin: businessUnit.targetMargin,
    });

    if (response) message.success(t("Common-EditSuccessMessage"));
    else message.error(t("Common-EditErrorMessage"));
    setLoading(false);
  };

  const showNewChannelModal = () => {
    const options: any[] = [];
    const { channels } = businessUnit;
    channelTypes.forEach(function (item: any) {
      if (channels.find((c: { type: { id: any } }) => c.type.id === item.id)) {
        options.push(
          <Select.Option value={item.id} key={item.id} disabled>
            {item.descriptionEs}
          </Select.Option>
        );
      } else {
        options.push(
          <Select.Option value={item.id} key={item.id}>
            {item.descriptionEs}
          </Select.Option>
        );
      }
    });
    Modal.info({
      title: t("BusinessRules-NewChannel"),
      closable: true,
      maskClosable: true,
      okButtonProps: { style: { display: "none" } },
      icon: null,
      content: (
        <Form
          name="basic"
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={handleCreateChannel}
          preserve={false}
        >
          <Form.Item
            label={t("BusinessRules-ChannelType")}
            name="type"
            className={styles.newChannelItem}
            rules={[
              {
                required: true,
                message: t("BusinessRules-ChannelTypeNotEntered"),
              },
            ]}
          >
            <Select
              className={styles.type}
              allowClear
              style={{ width: "100%" }}
              placeholder=""
            >
              {options}
            </Select>
          </Form.Item>
          <Form.Item
            label={t("BusinessRules-Currency")}
            name="currency"
            className={styles.newChannelItem}
            rules={[
              {
                required: true,
                message: t("BusinessRules-CurrencyNotEntered"),
              },
            ]}
          >
            <Select
              className={styles.type}
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder=""
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getOptions(currencies)}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              className={styles.addNewUnitButton}
              type="primary"
              htmlType="submit"
              disabled={loading}
            >
              {loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined className="ol-loading-spin-icon" />
                  }
                  delay={500}
                />
              ) : (
                t("Common-Add")
              )}
            </Button>
          </Form.Item>
        </Form>
      ),
    });
  };

  const handleCreateChannel = async (data: any) => {
    setLoading(true);

    const currenciesIds = data.currency;
    const channelTypeId = data.type;

    let response = await createChannel(
      parseInt(id!),
      currenciesIds,
      channelTypeId
    );

    if (response) {
      message.success(t("BusinessRules-CreateChannelSucessMessage"));
      await handleFetchBusinessUnit();
    } else message.error(t("Common-ErrorMessage"));

    Modal.destroyAll();
    setLoading(false);
  };

  const branchOfficeColumns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Common-Code"),
      dataIndex: "id",
      key: "code",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {canEdit ? (
            <EditOutlined
              onClick={() => {
                window.location.href = `/admin/branchoffices/${record.id}/view`;
              }}
            />
          ) : (
            <EyeOutlined
              onClick={() => {
                window.location.href = `/admin/branchoffices/${record.id}/view`;
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  const channelColumns = [
    {
      title: t("Common-Type"),
      dataIndex: "type",
      key: "type",
      render: (channel: any) => channel.descriptionEs,
    },
    {
      title: t("Common-Currencies"),
      dataIndex: "currencies",
      key: "currencies",
      render: (currencies: any[]) => (
        <>
          {currencies.map((currency) => {
            return (
              <Tag className={styles.channelTag} color="blue">
                {currency.name}
              </Tag>
            );
          })}
        </>
      ),
    },
  ];

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !businessUnit ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-BusinessUnit")}</h1>
        {hasPermission(privilegesEnum.WRITE_BUSINESS_UNITS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEditBusinessUnit}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.businessUnitInfoWrapper}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={infoBusinessUnitForm}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              initialValue={businessUnit?.name}
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input disabled={!canEdit} placeholder={t("Common-Name")} />
            </Form.Item>

            <Form.Item
              label={t("BusinessRules-Language")}
              name="language"
              initialValue={businessUnit?.language?.id}
              rules={[
                {
                  required: true,
                  message: t("BusinessRules-LanguageNotEntered"),
                },
              ]}
            >
              <Select
                className={styles.language}
                allowClear
                style={{ width: "100%" }}
                placeholder=""
                defaultValue={businessUnit.language?.id}
                disabled={!canEdit}
              >
                {languages}
              </Select>
            </Form.Item>

            <Form.Item
              label={t("BusinessRules-PreferenceCurrency")}
              name="currency"
              initialValue={businessUnit?.defaultCurrency?.id}
              rules={[
                {
                  required: true,
                  message: t("BusinessRules-CurrencyNotEntered"),
                },
              ]}
            >
              <Select
                className={styles.type}
                allowClear
                style={{ width: "100%" }}
                placeholder=""
                defaultValue={businessUnit.defaultCurrency?.id}
                disabled={!canEdit}
              >
                {getOptions(currencies)}
              </Select>
            </Form.Item>
          </Form>
        </div>

        <div className={styles.tableWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.tableTitle}>{t("Common-Channels")}</h1>
            {canEdit && (
              <Button
                type="primary"
                className={styles.actionsButton}
                onClick={() => showNewChannelModal()}
                icon={<PlusOutlined />}
              />
            )}
          </div>
          <div>
            <Table
              size="small"
              className={styles.table}
              loading={loading}
              columns={channelColumns}
              dataSource={businessUnit.channels}
            />
          </div>
        </div>

        <div className={styles.tableWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.tableTitle}>{t("Common-BranchOffices")}</h1>
          </div>

          <div>
            <Table
              size="small"
              className={styles.table}
              loading={loading}
              columns={branchOfficeColumns}
              dataSource={businessUnit.branchOffices}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewBusinessUnit;
