import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Table, Input, Select } from "antd";

import styles from "./RewardsMockup.module.scss";
import { useNavigate } from "react-router-dom";

const RewardsMockup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalSize, setTotalSize] = useState(0);

  const columns = [
    {
      title: "Nombre",
    },
    {
      title: "Tipo",
    },
    {
      title: "Estado",
    },
    {
      title: "Fecha de creación",
    },
    {
      title: "Disponibilidad",
    },
    {
      title: "Acciones",
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{"Recompensas"}</h1>
        <div className={styles.actionButtonsWrapper}>
          {/*<Button*/}
          {/*  href="/pricings/create"*/}
          {/*  className={styles.newButton}*/}
          {/*  type="primary"*/}
          {/*  icon={<PlusOutlined />}*/}
          {/*/>*/}
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.headerTableWrapper}>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}>
                <Input className={styles.inputFilter} placeholder={"Nombre"} />
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  className={styles.filterSelect}
                  showSearch
                  placeholder={"Tipo"}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  className={styles.filterSelect}
                  showSearch
                  placeholder={"Estado"}
                />
              </div>
            </div>
          </div>

          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              // onChange: handlePagination,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RewardsMockup;
