import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { createVehicle } from "../../../api/Services";

import { Form, Input, Button, message, Select, Spin, InputNumber } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./NewVehicle.module.scss";
const NewVehicle = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleCreateVehicle = async () => {
    setLoading(true);
    const data = form.getFieldsValue();
    const {
      name,
      code,
      brand,
      model,
      licensePlate,
      type,
      maxCapacity,
      fuelConsumption,
    } = data;
    const response = await createVehicle({
      name,
      code,
      brand,
      model,
      licensePlate,
      type,
      maxCapacity,
      fuelConsumption,
    });
    if (response) {
      message.success(t("Common-CreateSuccessMessage"));
      form.resetFields();
      navigate("/admin/vehicles");
    } else message.error(t("Common-ErrorMessage"));

    setLoading(false);
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewVehicle")}</h1>
        <Button
          onClick={handleCreateVehicle}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.newWrapper}>
          <Form
            name="newVehicle"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.formNew}
            form={form}
            onFinish={handleCreateVehicle}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item label={t("Common-Code")} name="code">
              <Input placeholder={t("Common-Code")} />
            </Form.Item>
            <Form.Item label={t("Common-Brand")} name="brand">
              <Input placeholder={t("Common-Brand")} />
            </Form.Item>
            <Form.Item label={t("Common-Model")} name="model">
              <Input placeholder={t("Common-Model")} />
            </Form.Item>
            <Form.Item label={t("Common-LicensePlate")} name="licensePlate">
              <Input placeholder={t("Common-LicensePlate")} />
            </Form.Item>
            <Form.Item label={t("Common-Type")} name="type">
              <Select allowClear style={{ width: "100%" }} placeholder="">
                <Select.Option value="CAR">{t("Common-Car")}</Select.Option>
                <Select.Option value="VAN">{t("Common-Van")}</Select.Option>
                <Select.Option value="MOTORCYCLE">
                  {t("Common-Motorcycle")}
                </Select.Option>
                <Select.Option value="TRUCK">{t("Common-Truck")}</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={t("Common-CapacityMaxInUnits")}
              name="maxCapacity"
            >
              <InputNumber
                className={styles.inputNumber}
                placeholder={t("Common-CapacityMaxInUnits")}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-FuelConsumption")}
              name="fuelConsumption"
            >
              <InputNumber
                className={styles.inputNumber}
                placeholder={t("Common-FuelConsumption")}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default NewVehicle;
