import { useTranslation } from "react-i18next";
import React, { FunctionComponent, useEffect, useState } from "react";

import { Table, Modal, Button, Divider, message, Input, Select } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

import {
  getArticlesFiltered,
  getCategoriesFiltered,
} from "../../api/Services";

import styles from "./AddArticleModal.module.scss";

type AddArticleModalProps = {
  selectedArticles: any;
  onAddArticle: (article: any) => void;
  onRemoveArticle: (article: any) => void;
  title?: string;
};

const AddArticleModal: FunctionComponent<AddArticleModalProps> = ({
  selectedArticles,
  onAddArticle,
  onRemoveArticle,
  title,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [externalIdFilterValue, setExternalIdFilterValue] = useState("");
  const [idFilterValue, setIdFilterValue] = useState("");
  const [articleNameFilterValue, setArticleNameFilterValue] = useState("");
  const [categoryIdFilterValue, setCategoryIdFilterValue] = useState("");
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(0);
  const [articles, setArticles] = useState<any[]>([]);
  const [selectedFromTable, setSelectedFromTable] = useState<any[]>([]);
  const [categoriesOptions, setCategoriesOptions] = useState<any[]>([]);

  useEffect(() => {
    handleFetchArticles();
  }, []);

  useEffect(() => {
    handleFetchArticles();
  }, [
    externalIdFilterValue,
    idFilterValue,
    articleNameFilterValue,
    categoryIdFilterValue,
  ]);

  const handleFetchArticles = async (pagination = actualPage) => {
    setLoading(true);
    const response = await getArticlesFiltered(pagination, {
      externalId: externalIdFilterValue,
      id: idFilterValue,
      articleName: articleNameFilterValue,
      categoryId: categoryIdFilterValue,
    });
    setArticles(response.articles);
    setTotalSize(response.totalElements);
    setLoading(false);
  };
  const handlePagination = (page: number) => {
    handleFetchArticles(page - 1);
    setActualPage(page);
  };

  const rowSelection = {
    selectedRowKeys: selectedFromTable.map((article: any) => article.id),
    onChange: (selectedRowKeys: React.Key[], selectedArticles: any[]) => {
      setSelectedFromTable(selectedArticles);
    },
  };

  const handleAddArticles = () => {
    onAddArticle(selectedFromTable);
    setSelectedFromTable([]);
    message.success(t("Common-AddedSuccessMessage"));
  };

  const handleSearchCategories = async (value: string) => {
    if (value.length > 2) {
      const response = await getCategoriesFiltered(value);
      const options = response.map((item: any) => ({
        value: item.id,
        text: item.name,
      }));
      setCategoriesOptions(options);
    } else setCategoriesOptions([]);
  };

  const addColumns = [
    {
      title: t("Common-Id"),
      dataIndex: "id",
      width: 10,
    },
    {
      title: t("Common-Name"),
      dataIndex: "name",
      width: 50,
    },
    {
      title: t("Common-ExternalId"),
      dataIndex: "externalId",
      width: 30,
    },
    {
      title: t("Common-Category"),
      key: "category",
      render: (article: any) => article.category?.name,
      width: 30,
    },
  ];

  const showColumns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      width: 50,
    },
    {
      title: t("Common-ExternalId"),
      dataIndex: "externalId",
      width: 20,
    },
    {
      title: t("Common-Delete"),
      key: "remove",
      render: (record: any) => (
        <CloseOutlined
          onClick={() => {
            onRemoveArticle(record);
          }}
        />
      ),
      width: 30,
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>{title ?? t("Common-Articles")}</h2>
        <Button
          className={styles.addButton}
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setIsModalVisible(true)}
        />
      </div>

      <Table
        size="small"
        scroll={{ y: 300 }}
        rowKey={(record) => record.id}
        columns={showColumns}
        dataSource={selectedArticles}
      />
      <Modal
        title={t("Common-AddArticles")}
        visible={isModalVisible}
        destroyOnClose={true}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => setIsModalVisible(false)}
      >
        <div className={styles.actionWrapper}>
          <Button
            className={styles.addButton}
            type="primary"
            onClick={handleAddArticles}
            disabled={selectedFromTable.length === 0}
          >
            {t("Common-Add")}
          </Button>
        </div>
        <Divider />
        <div className={styles.filtersWrapper}>
          <Input
            placeholder={t("Common-SearchByExternalId")}
            className={styles.filterInput}
            onChange={(event) => setExternalIdFilterValue(event.target.value)}
          />
          <Input
            placeholder={t("Common-SearchById")}
            className={styles.filterInput}
            onChange={(event) => setIdFilterValue(event.target.value)}
          />
        </div>
        <div className={styles.filtersWrapper}>
          <Input
            placeholder={t("Common-SearchByName")}
            className={styles.filterInput}
            onChange={(event) => setArticleNameFilterValue(event.target.value)}
          />
          <Select
            className={styles.filterSelect}
            showSearch
            placeholder={t("Common-SearchByCategory")}
            onSelect={(_, option) => {
              if (option) setCategoryIdFilterValue(option.value);
            }}
            allowClear
            onClear={() => setCategoryIdFilterValue("")}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearchCategories}
            options={(categoriesOptions || []).map((d: any) => ({
              value: d.value,
              label: d.text,
            }))}
          />
        </div>

        <Table
          size="small"
          rowKey={(record) => record.id}
          loading={loading}
          columns={addColumns}
          dataSource={articles}
          pagination={{
            total: totalSize,
            showSizeChanger: false,
            onChange: handlePagination,
          }}
          rowSelection={{
            ...rowSelection,
          }}
        />
      </Modal>
    </div>
  );
};

export default AddArticleModal;
