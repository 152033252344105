import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getAcceptedPaymentMethods,
  deleteAcceptedPaymentMethod,
  processExcelTemplate,
} from "../../api/Services";
import { handleDownloadTemplate } from "../../helpers/Helper";
import ImportModal from "../../components/importmodal/ImportModal";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import { Table, Button, message, Space, Modal } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./AcceptedPaymentMethods.module.scss";
import { BusinessUniteContext } from "../../components/laoyut/Layout";

const AcceptedPaymentMethods = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [downloadingTemplate, setDownloadingTemplate] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [importErrors, setImportErrors] = useState<any>(null);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_ACCEPTED_PAYMENT_METHODS)) {
      handleFetchAcceptedPaymentMethods();
    }
  }, [businessUnitSelected]);

  const handleFetchAcceptedPaymentMethods = async (pagination = actualPage) => {
    const response = await getAcceptedPaymentMethods(
      pagination,
      businessUnitSelected ?? undefined
    );
    const allAcceptedPaymentMethods = response.acceptedPaymentMethods;
    const acceptedPaymentMethodsWithKey: any[] = [];

    allAcceptedPaymentMethods.forEach((acceptedPaymentMethod: any) => {
      acceptedPaymentMethod.key = acceptedPaymentMethod.id;
      acceptedPaymentMethodsWithKey.push(acceptedPaymentMethod);
    });
    setData(acceptedPaymentMethodsWithKey);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const showDeleteConfirm = (acceptedPaymentMethodId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteAcceptedPaymentMethod(acceptedPaymentMethodId);
      },
    });
  };

  const handleDeleteAcceptedPaymentMethod = async (
    acceptedPaymentMethodId: number
  ) => {
    setLoading(true);
    const deleteStatus = await deleteAcceptedPaymentMethod(
      acceptedPaymentMethodId
    );
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchAcceptedPaymentMethods();
    setLoading(false);
  };

  const showImportModal = () => {
    setIsImportModalVisible(true);
    setImportErrors("");
  };

  const handleCancelImportModal = () => {
    setIsImportModalVisible(false);
  };

  const handleImportFile = async (file: any) => {
    if (file) {
      const response = await processExcelTemplate(
        "acceptedPaymentMethod",
        file
      );
      if (response.success) {
        setImportErrors(null);
        setIsImportModalVisible(false);
        message.success(
          t("Common-ImportSuccess", { quantity: response.data.added })
        );
        handleFetchAcceptedPaymentMethods();
      } else {
        if (response.data?.errors) setImportErrors(response.data.errors);
        else message.error(t("Error-CommonError"));
      }
    }
  };

  const handlePagination = (page: number) => {
    handleFetchAcceptedPaymentMethods(page);
    setActualPage(page);
  };

  const columns = [
    {
      title: t("Common-Code"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Common-ExternalId"),
      dataIndex: "externalId",
    },
    {
      title: t("Common-Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Common-Type"),
      dataIndex: "type",
      key: "type",
      render: (type: any) => type?.descriptionEs,
    },
    {
      title: t("Common-FeeMinRange"),
      dataIndex: "feeRangeMin",
      key: "feeRangeMin",
    },
    {
      title: t("Common-FeeMaxRange"),
      dataIndex: "feeRangeMax",
      key: "feeRangeMax",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {hasPermission(privilegesEnum.WRITE_ACCEPTED_PAYMENT_METHODS) && (
            <EditOutlined
              onClick={() => {
                navigate(`/admin/acceptedpaymentmethods/${record.id}/view`);
              }}
            />
          )}
          {hasPermission(privilegesEnum.DELETE_ACCEPTED_PAYMENT_METHODS) && (
            <DeleteOutlined
              onClick={() => {
                showDeleteConfirm(record.key);
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-AcceptedPaymentMethods")}</h1>
        {hasPermission(privilegesEnum.WRITE_ACCEPTED_PAYMENT_METHODS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={() => navigate("/admin/acceptedpaymentmethods/create")}
              className={styles.newButton}
              type="primary"
              icon={<PlusOutlined />}
            />
            <Button
              onClick={showImportModal}
              className={styles.importButton}
              type="primary"
            >
              {t("Common-Import")}
            </Button>
            <Button
              onClick={() =>
                handleDownloadTemplate(
                  "acceptedPaymentMethod",
                  setDownloadingTemplate
                )
              }
              loading={downloadingTemplate}
              className={styles.importButton}
              type="primary"
            >
              {t("Common-DownloadTemplate")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_ACCEPTED_PAYMENT_METHODS) && (
          <div className={styles.tableWrapper}>
            <Table
              size="small"
              className={styles.table}
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
                current: actualPage,
              }}
            />
          </div>
        )}
      </div>
      <Modal
        title={t("Common-ImportEntity", {
          entity: t("Common-AcceptedPaymentMethods"),
        })}
        visible={isImportModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelImportModal}
      >
        <ImportModal onOk={handleImportFile} errors={importErrors} />
      </Modal>
    </div>
  );
};

export default AcceptedPaymentMethods;
