import { getExcelTemplate } from "../api/Services";

export const handleDownloadTemplate = async (
  type: string,
  setLoading?: (loading: boolean) => void
) => {
  if (setLoading) setLoading(true);
  const response = await getExcelTemplate(type);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const url = URL.createObjectURL(
    new Blob([response.data], { type: fileType })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", response.filename || "Excel.xlsx");
  document.body.appendChild(link);
  link.click();
  if (setLoading) setLoading(false);
};

export const downloadFile = (
  content: string,
  fileName: string,
  fileType: string
) => {
  const a = document.createElement("a");
  const file = new Blob([content], { type: fileType });
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
};
