import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  getAcceptedPaymentMethodsTypes,
  editAcceptedPaymentMethod,
  getAcceptedPaymentMethodById,
} from "../../../api/Services";
import {
  hasPermission,
  privilegesEnum,
} from "../../../helpers/PermissionHelper";
import { BusinessUniteContext } from "../../../components/laoyut/Layout";
import { Form, Input, Button, Select, message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./ViewAcceptedPaymentMethod.module.scss";
import { useParams } from "react-router-dom";

const ViewAcceptedPaymentMethod = () => {
  const { t } = useTranslation();

  const {
    contextValue: businessUnitSelected,
    updateContextValue: setBusinessUnitSelected,
  } = useContext(BusinessUniteContext);

  const { id } = useParams();

  const [acceptedPaymentMethod, setAcceptedPaymentMethod] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [types, setTypes] = useState<any[]>([]);

  const [editPaymentMethodForm] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_ACCEPTED_PAYMENT_METHODS)) {
      handleFetchAcceptedPaymentMethod();
      handleFetchAcceptedPaymentMethodsTypes();
    }
  }, []);

  const handleFetchAcceptedPaymentMethod = async () => {
    const response = await getAcceptedPaymentMethodById(id!);

    setAcceptedPaymentMethod(response);
    setBusinessUnitSelected(response.businessUnits[0].id);
    setLoading(false);
  };

  const handleFetchAcceptedPaymentMethodsTypes = async () => {
    const response = await getAcceptedPaymentMethodsTypes();
    setTypes(response);
  };

  const getAcceptedPaymentMethodsTypesOptions = () => {
    const options: any[] = [];

    types.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.descriptionEs}
        </Select.Option>
      );
    });

    return options;
  };

  const handleEditPaymentMethod = async () => {
    await editPaymentMethodForm.validateFields().then(async (values) => {
      setLoading(true);
      const { name, externalId, typeId, feeRangeMin, feeRangeMax } = values;
      let response = await editAcceptedPaymentMethod(
        parseInt(id!),
        name,
        externalId,
        typeId,
        feeRangeMin,
        feeRangeMax,
        [businessUnitSelected] ?? []
      );

      if (response) {
        message.success(t("Common-EditSuccessMessage"));
      } else message.error(t("Common-EditErrorMessage"));

      await handleFetchAcceptedPaymentMethod();
      setLoading(false);
    });
  };
  const handleCancelButton = () => {
    editPaymentMethodForm.resetFields();
  };
  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-AcceptedPaymentMethods")}</h1>
        {hasPermission(privilegesEnum.WRITE_ACCEPTED_PAYMENT_METHODS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEditPaymentMethod}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.infoWrapper}>
          <Form
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            form={editPaymentMethodForm}
            autoComplete="off"
            className={styles.acceptedPaymentMethodForm}
            preserve={false}
          >
            <Form.Item
              initialValue={acceptedPaymentMethod?.name}
              label={t("Common-Name")}
              name="name"
              className={styles.itemWrapper}
              rules={[
                {
                  required: true,
                  message: t("Common-Name"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item
              className={styles.itemWrapper}
              label={t("Common-ExternalId")}
              name="externalId"
              initialValue={acceptedPaymentMethod?.externalId}
            >
              <Input placeholder={t("Common-ExternalId")} />
            </Form.Item>

            <Form.Item
              initialValue={acceptedPaymentMethod?.type?.id}
              label={t("Common-Type")}
              name="typeId"
              className={styles.itemWrapper}
            >
              <Select
                className={styles.type}
                allowClear
                defaultValue={acceptedPaymentMethod?.type?.id}
                style={{ width: "100%" }}
                placeholder=""
              >
                {getAcceptedPaymentMethodsTypesOptions()}
              </Select>
            </Form.Item>
            <Form.Item
              initialValue={acceptedPaymentMethod?.feeRangeMin}
              label={t("Common-FeeMinRange")}
              name="feeRangeMin"
              className={styles.itemWrapper}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value > getFieldValue("feeRangeMax")) {
                      return Promise.reject(
                        t("Common-MaxMustBeGreaterThanMin")
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input placeholder={t("Common-FeeMinRange")} />
            </Form.Item>
            <Form.Item
              initialValue={acceptedPaymentMethod?.feeRangeMax}
              label={t("Common-FeeMaxRange")}
              name="feeRangeMax"
              className={styles.itemWrapper}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value < getFieldValue("feeRangeMin")) {
                      return Promise.reject(
                        t("Common-MaxMustBeGreaterThanMin")
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input placeholder={t("Common-FeeMaxRange")} />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default ViewAcceptedPaymentMethod;
