import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { editCurve, getCurveById } from "../../../api/Services";
import {
  hasPermission,
  privilegesEnum,
} from "../../../helpers/PermissionHelper";
import { Form, Input, Button, message, Spin, InputNumber } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./ViewCurve.module.scss";

const ViewCurve = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const [curve, setCurve] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [sizeDistribution, setSizeDistribution] = useState<number[]>([]);

  const [editForm] = Form.useForm();

  const canEdit = hasPermission(privilegesEnum.WRITE_CURVES);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CURVES)) {
      handleFetchCurve();
    }
  }, []);

  const handleFetchCurve = async () => {
    const response = await getCurveById(id!);

    setCurve(response);
    const { sizeStart, sizeEnd, distribution } = response;

    if (sizeStart && sizeEnd && sizeEnd >= sizeStart) {
      const distributionRange = [];
      for (let i = sizeStart; i <= sizeEnd; i++) {
        distributionRange.push(i);
      }
      setSizeDistribution(distributionRange);

      const initialDistributionValues = distributionRange.reduce(
        (acc: any, size: number, index: number) => {
          acc[`size-${size}`] = distribution[index];
          return acc;
        },
        {}
      );

      editForm.setFieldsValue({
        name: response.name,
        sizeStart: response.sizeStart,
        sizeEnd: response.sizeEnd,
        ...initialDistributionValues,
      });
    }
    setLoading(false);
  };

  const handleEdit = async () => {
    await editForm.validateFields().then(async (values) => {
      setLoading(true);
      const { name, sizeStart, sizeEnd } = values;
      const distribution = sizeDistribution.map(
        (size) => values[`size-${size}`] || 0
      );
      let response = await editCurve(parseInt(id!), {
        name,
        sizeStart,
        sizeEnd,
        distribution,
      });

      if (response) {
        message.success(t("Common-EditSuccessMessage"));
      } else message.error(t("Common-EditErrorMessage"));

      await handleFetchCurve();
      setLoading(false);
    });
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    const { sizeStart, sizeEnd } = allValues;
    if (sizeStart && sizeEnd && sizeEnd >= sizeStart) {
      const distribution = [];
      for (let i = sizeStart; i <= sizeEnd; i++) {
        distribution.push(i);
      }
      setSizeDistribution(distribution);
    } else {
      setSizeDistribution([]);
    }
  };
  const handleCancelButton = () => {
    editForm.resetFields();
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Curve")}</h1>
        {canEdit && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEdit}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.infoWrapper}>
          <Form
            name="curve"
            layout="vertical"
            onValuesChange={onValuesChange}
            initialValues={{ remember: true }}
            form={editForm}
            autoComplete="off"
            className={styles.form}
            preserve={false}
          >
            <Form.Item
              initialValue={curve?.name}
              label={t("Common-Name")}
              name="name"
              className={styles.itemWrapper}
              rules={[
                {
                  required: true,
                  message: t("Common-Name"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item
              label={t("Common-SizeStart")}
              name="sizeStart"
              initialValue={curve?.sizeStart}
              rules={[
                {
                  required: true,
                  message: t("Common-SizeStart"),
                },
              ]}
            >
              <InputNumber
                className={styles.inputNumber}
                placeholder={t("Common-SizeStart")}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-SizeEnd")}
              name="sizeEnd"
              initialValue={curve?.sizeEnd}
              rules={[
                {
                  required: true,
                  message: t("Common-SizeEnd"),
                },
              ]}
            >
              <InputNumber
                className={styles.inputNumber}
                placeholder={t("Common-SizeEnd")}
              />
            </Form.Item>
            <h1 className={styles.subTitle}>{`${t(
              "Common-Distribution"
            )} `}</h1>
            {sizeDistribution.map((size) => (
              <div key={size}>
                <Form.Item
                  label={`${t("Common-Distribution")} ${t(
                    "Common-Size"
                  )} ${size}`}
                  name={`size-${size}`}
                >
                  <InputNumber placeholder={t("Common-Quantity")} />
                </Form.Item>
              </div>
            ))}
          </Form>
        </div>
      </div>
    </div>
  );
};
export default ViewCurve;
