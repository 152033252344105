import { useTranslation } from "react-i18next";

import { Result } from "antd";

const NotFound = () => {
  const { t } = useTranslation();

  return <Result status="404" title="404" subTitle={t("NotFound-Title")} />;
};

export default NotFound;
