import { useTranslation } from "react-i18next";
import SignupForm from "../../components/signupform/SignupForm";

import logo from "../../assets/logo.jpg";
import styles from "../signup/Signup.module.css";

const Signup = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.logoWrapper}>
        <img className={styles.logo} src={logo} alt="Logo" />
      </div>
      <SignupForm />
      <p className={styles.rightsText}>
        {t("Common-AllRightsReserved")}
        <a>BrightMarket</a>
      </p>
    </div>
  );
};

export default Signup;
