import { getSuperAdmin } from "../../api/Helper";

import { useEffect, useState } from "react";
import { getOrganizations } from "../../api/Services";

const Home = () => {
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [organizationsQuantity, setOrganizationsQuantity] = useState(0);

  useEffect(() => {
    const validateSuperAdmin = async () => {
      const isUserSuperAdmin = await getSuperAdmin();
      const organizations = await getOrganizations();
      //- admin org
      setOrganizationsQuantity(organizations.length - 1);
      setIsSuperAdmin(isUserSuperAdmin);
    };

    validateSuperAdmin();
  }, []);

  return (
    <div>
      <h1>Home</h1>

      {isSuperAdmin && (
        <>
          <h1>Bienvenido super admin!</h1>
          <p>{organizationsQuantity} organizaciones activas</p>
        </>
      )}
    </div>
  );
};

export default Home;
