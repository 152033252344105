import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  addBranchOfficeToCluster,
  addDepositToCluster,
  deletePlaceFromCluster,
  editCluster,
  getBranchOfficesFiltered,
  getClusterById,
  getDepositsFiltered,
} from "../../../api/Services";
import {
  hasPermission,
  privilegesEnum,
} from "../../../helpers/PermissionHelper";
import {
  Form,
  Input,
  Button,
  message,
  Spin,
  Table,
  Space,
  Modal,
  Select,
} from "antd";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./ViewCluster.module.scss";

const ViewCluster = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const [cluster, setCluster] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [isAddDepositModalVisible, setIsAddDepositModalVisible] =
    useState(false);
  const [isAddBranchOfficeModalVisible, setIsAddBranchOfficeModalVisible] =
    useState(false);
  const [branchOffices, setBranchOffices] = useState<any[]>([]);
  const [deposits, setDeposits] = useState<any[]>([]);

  const [editClusterForm] = Form.useForm();
  const [addDepositForm] = Form.useForm();
  const [addBranchOfficeForm] = Form.useForm();

  const canEdit = hasPermission(privilegesEnum.WRITE_CLUSTERS);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CLUSTERS)) {
      handleFetchCluster();
    }
  }, []);

  const handleFetchCluster = async () => {
    const response = await getClusterById(id!);

    setCluster(response);
    setLoading(false);
  };

  const handleSearchBranchOffices = async (value: string) => {
    const response = await getBranchOfficesFiltered(0, { name: value });
    setBranchOffices(response.branchOffices);
  };

  const handleSearchDeposits = async (value: string) => {
    const response = await getDepositsFiltered(0, {
      name: value,
    });
    setDeposits(response.deposits);
  };

  const handleEdit = async () => {
    await editClusterForm.validateFields().then(async (values) => {
      setLoading(true);
      const { name } = values;
      let response = await editCluster(parseInt(id!), name);

      if (response) {
        message.success(t("Common-EditSuccessMessage"));
      } else message.error(t("Common-EditErrorMessage"));

      await handleFetchCluster();
      setLoading(false);
    });
  };
  const handleCancelButton = () => {
    editClusterForm.resetFields();
  };

  const handleOpenAddDepositModal = () => {
    handleSearchDeposits("");
    setIsAddDepositModalVisible(true);
  };

  const handleCancelAddDepositModal = () => {
    setIsAddDepositModalVisible(false);
  };

  const handleOpenAddBranchOfficeModal = () => {
    handleSearchBranchOffices("");
    setIsAddBranchOfficeModalVisible(true);
  };

  const handleCancelAddBranchOfficeModal = () => {
    setIsAddBranchOfficeModalVisible(false);
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const handleDeleteClusterPlace = async (placeId: number) => {
    setLoading(true);
    const deleteStatus = await deletePlaceFromCluster(placeId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));

    await handleFetchCluster();
    setLoading(false);
  };

  const showDeletePlaceConfirm = (
    placeId: number,
  ) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteClusterPlace(placeId);
      },
    });
  };

  const handleAddDeposit = async () => {
    await addDepositForm.validateFields().then(async (deposit) => {
      setLoading(true);
      const { depositId } = deposit;

      const response = await addDepositToCluster(parseInt(id!), depositId);

      if (response) {
        addDepositForm.resetFields();
        await handleFetchCluster();
        handleCancelAddDepositModal();
        message.success(t("Common-AddSuccessMessage"));
      } else message.error(t("Common-ErrorMessage"));
      setLoading(false);
    });
  };

  const handleAddBranchOffice = async () => {
    await addBranchOfficeForm.validateFields().then(async (branchOffice) => {
      setLoading(true);
      const { branchOfficeId } = branchOffice;

      const response = await addBranchOfficeToCluster(
        parseInt(id!),
        branchOfficeId
      );

      if (response) {
        addBranchOfficeForm.resetFields();
        await handleFetchCluster();
        handleCancelAddBranchOfficeModal();
        message.success(t("Common-AddSuccessMessage"));
      } else message.error(t("Common-ErrorMessage"));
      setLoading(false);
    });
  };

  const branchOfficesColumns = [
    {
      title: t("Common-Name"),
      key: "name",
      render: (record: any) => (
        <a href={`/admin/branchoffices/${record.branchOffice?.id}/view`}>
          {record.branchOffice?.name}
        </a>
      ),
    },
    {
      title: t("Common-Code"),
      key: "code",
      render: (record: any) => record.branchOffice?.id,
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (record: any) => (
        <Space size="middle">
          <DeleteOutlined
            onClick={() => {
              showDeletePlaceConfirm(record.id);
            }}
          />
        </Space>
      ),
    },
  ];

  const depositsColumns = [
    {
      title: t("Common-Name"),
      key: "name",
      render: (record: any) => (
        <a href={`/admin/deposits/${record.deposit?.id}/view`}>
          {record.deposit?.name}
        </a>
      ),
    },
    {
      title: t("Common-Code"),
      render: (record: any) => record.deposit?.id,
      key: "code",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (record: any) => (
        <Space size="middle">
          <DeleteOutlined
            onClick={() => {
              showDeletePlaceConfirm(record.id);
            }}
          />
        </Space>
      ),
    },
  ];

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Cluster")}</h1>
        {canEdit && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEdit}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.infoWrapper}>
          <Form
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            form={editClusterForm}
            autoComplete="off"
            className={styles.clusterForm}
            preserve={false}
          >
            <Form.Item
              initialValue={cluster?.name}
              label={t("Common-Name")}
              name="name"
              className={styles.itemWrapper}
              rules={[
                {
                  required: true,
                  message: t("Common-Name"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
          </Form>
        </div>
        <div className={styles.tableWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.tablesTitle}>{t("Common-BranchOffices")}</h1>
            {canEdit && (
              <Button
                type="primary"
                className={styles.actionsButton}
                onClick={() => handleOpenAddBranchOfficeModal()}
                icon={<PlusOutlined />}
              />
            )}
          </div>
          <div>
            <Table
              size="small"
              className={styles.table}
              rowKey={(record) => record.id}
              loading={loading}
              columns={branchOfficesColumns}
              dataSource={cluster?.clusterBranchOffices.filter(
                (cluster: any) => cluster.branchOffice !== null
              )}
            />
          </div>
        </div>
        <div className={styles.tableWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.tablesTitle}>{t("Common-Deposits")}</h1>
            {canEdit && (
              <Button
                type="primary"
                className={styles.actionsButton}
                onClick={() => handleOpenAddDepositModal()}
                icon={<PlusOutlined />}
              />
            )}
          </div>
          <div>
            <Table
              size="small"
              className={styles.table}
              rowKey={(record) => record.id}
              loading={loading}
              columns={depositsColumns}
              dataSource={cluster?.clusterDeposits.filter(
                (cluster: any) => cluster.deposit !== null
              )}
            />
          </div>
        </div>
      </div>
      <Modal
        title={t("Common-Season")}
        visible={isAddDepositModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelAddDepositModal}
      >
        <Form
          name="addSeason"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={addDepositForm}
        >
          <Form.Item
            className={styles.modalInfoItem}
            label={t("Common-Deposit")}
            name="depositId"
            rules={[
              {
                required: true,
                message: t("Common-Deposit"),
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
              placeholder={t("Common-SearchByName")}
              onSearch={handleSearchDeposits}
            >
              {getOptions(deposits)}
            </Select>
          </Form.Item>
        </Form>
        <Button
          className={styles.addButton}
          type="primary"
          onClick={handleAddDeposit}
        >
          {t("Common-Add")}
        </Button>
      </Modal>
      <Modal
        title={t("Common-BranchOffice")}
        visible={isAddBranchOfficeModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelAddBranchOfficeModal}
      >
        <Form
          name="addSeason"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={addBranchOfficeForm}
        >
          <Form.Item
            className={styles.modalInfoItem}
            label={t("Common-BranchOffice")}
            name="branchOfficeId"
            rules={[
              {
                required: true,
                message: t("Common-BranchOffice"),
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
              placeholder={t("Common-SearchByName")}
              onSearch={handleSearchBranchOffices}
            >
              {getOptions(branchOffices)}
            </Select>
          </Form.Item>
        </Form>
        <Button
          className={styles.addButton}
          type="primary"
          onClick={handleAddBranchOffice}
        >
          {t("Common-Add")}
        </Button>
      </Modal>
    </div>
  );
};
export default ViewCluster;
