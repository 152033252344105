import { useState } from "react";
import { useTranslation } from "react-i18next";
import { sendForgotPassword } from "../../api/Services";

import { Button, Input, message } from "antd";
import { LockOutlined } from "@ant-design/icons";

import logo from "../../assets/logo.jpg";

import styles from "./ForgotPassword.module.css";

const ForgotPassword = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const sendEmail = async () => {
    setLoading(true);
    const response = await sendForgotPassword(email);
    if (response) {
      message.success(t("ForgotPassword-SendSuccesMessage"));
      setEmail("");
    } else message.error(t("ForgotPassword-SendErrorMessage"));
    setLoading(false);
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <img className={styles.logo} src={logo} alt="Logo" />
      </div>
      <div className={styles.formWrapper}>
        <LockOutlined className={styles.lockIcon} />
        <h1 className={styles.title}>{t("ForgotPassword-Title")}</h1>
        <p className={styles.description}>{t("ForgotPassword-Description")}</p>
        <Input
          className={styles.emailInput}
          placeholder={t("Common-Email")}
          type="email"
          onChange={handleEmailChange}
          value={email}
        />
        <Button
          className={styles.submitButton}
          onClick={sendEmail}
          type="primary"
          disabled={loading}
        >
          {t("ForgotPassword-SendEmail")}
        </Button>
        <a href="/">{t("Common-ReturnLogin")}</a>
      </div>
    </div>
  );
};

export default ForgotPassword;
