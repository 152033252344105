import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getPricingTypes,
  createPricingType,
  deletePricingType,
  editPricingType,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import { Table, Form, Input, Button, message, Space, Modal } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import styles from "./PrincingTypes.module.scss";

const PrincingTypes = () => {
  const { t } = useTranslation();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(0);

  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_PRICING_TYPES))
      handleFetchPricingTypes();
  }, []);

  const handleFetchPricingTypes = async (pagination = actualPage) => {
    const response = await getPricingTypes(pagination);
    const allPricingTypes = response.pricingTypes;
    const pricingTypesWithKey: any[] = [];

    allPricingTypes.forEach((pricingType: any) => {
      let r = {
        key: pricingType.id,
        id: pricingType.id,
        code: pricingType.code,
        description: pricingType.description,
      };
      pricingTypesWithKey.push(r);
    });
    setData(pricingTypesWithKey);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const showDeleteConfirm = (pricingTypeId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeletePricingType(pricingTypeId);
      },
    });
  };

  const handleDeletePricingType = async (pricingTypeId: number) => {
    setLoading(true);
    const deleteStatus = await deletePricingType(pricingTypeId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchPricingTypes();
    setLoading(false);
  };

  const handleCreatePricingType = async (data: any) => {
    setLoading(true);
    let response = await createPricingType(data.name, data.description);
    if (response) {
      message.success(t("Common-CreateSuccessMessage"));
      form.resetFields();
      await handleFetchPricingTypes(actualPage);
    } else message.error(t("Common-ErrorMessage"));

    setLoading(false);
  };

  function showEditModal(pricingType: any) {
    Modal.info({
      title: t("Common-PricingType"),
      closable: true,
      maskClosable: true,
      okText: t("Common-Save"),
      icon: null,
      okButtonProps: { style: { display: "none" } },
      content: (
        <>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            form={editForm}
            autoComplete="off"
            preserve={false}
          >
            <Form.Item hidden initialValue={pricingType.id} name="id" />
            <Form.Item
              initialValue={pricingType?.code}
              label={t("Common-Code")}
              name="code"
              className={styles.editInfoItem}
              rules={[
                {
                  required: true,
                  message: t("Common-Code"),
                },
              ]}
            >
              <Input placeholder={t("Common-Code")} />
            </Form.Item>
            <Form.Item
              initialValue={pricingType?.description}
              label={t("Common-Code")}
              name="description"
              className={styles.editInfoItem}
            >
              <Input placeholder={t("Common-Description")} />
            </Form.Item>
          </Form>

          <Button
            className={styles.addButton}
            type="primary"
            onClick={handleEditPricingType}
          >
            {t("Common-Save")}
          </Button>
        </>
      ),
    });
  }

  const handleEditPricingType = async () => {
    setLoading(true);

    const id = editForm.getFieldValue("id");
    const code = editForm.getFieldValue("code");
    const description = editForm.getFieldValue("description");
    let response = await editPricingType(id, code, description);

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
    } else message.error(t("Common-EditErrorMessage"));

    Modal.destroyAll();
    await handleFetchPricingTypes();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    handleFetchPricingTypes(page);
    setActualPage(page);
  };

  const columns = [
    {
      title: t("Common-Code"),
      dataIndex: "code",
      key: "code",
    },
    {
      title: t("Common-Description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {hasPermission(privilegesEnum.WRITE_PRICING_TYPES) && (
            <EditOutlined
              onClick={() => {
                showEditModal(record);
              }}
            />
          )}
          {hasPermission(privilegesEnum.DELETE_PRICING_TYPES) && (
            <DeleteOutlined
              onClick={() => {
                showDeleteConfirm(record.key);
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-PricingTypes")}</h1>
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_PRICING_TYPES) && (
          <div className={styles.tableWrapper}>
            <Table
              size="small"
              className={styles.table}
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{ total: totalSize, showSizeChanger:false, onChange: handlePagination }}
            />
          </div>
        )}
        {hasPermission(privilegesEnum.WRITE_PRICING_TYPES) && (
          <div className={styles.newPricingTypeWrapper}>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={handleCreatePricingType}
              form={form}
              autoComplete="off"
              className={styles.formNewPricingType}
            >
              <h1 className={styles.formTitle}>{t("Common-NewPricingType")}</h1>
              <Form.Item
                label={t("Common-Code")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("Common-Code"),
                  },
                ]}
              >
                <Input placeholder={t("Common-Code")} />
              </Form.Item>

              <Form.Item label={t("Common-Description")} name="description">
                <Input placeholder={t("Common-Description")} />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  {t("Common-Add")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default PrincingTypes;
