import React, { FunctionComponent } from "react";
import { Table } from "antd";

import styles from "./TableDashboard.module.scss";

type TableProps = {
  title: string;
  columns: any[];
  data: any[];
};

const getRowClassName = (_: any, index: number) => {
  return index % 2 === 0 ? styles.rowLight : styles.rowDark;
};

const TableDashboard: FunctionComponent<TableProps> = ({
  title,
  columns,
  data,
}) => {
  return (
    <div className={styles.tableWrapper}>
      <div className={styles.headerTableWrapper}>
        <h1 className={styles.title}>{title}</h1>
      </div>
      <Table
        size="small"
        className={styles.table}
        columns={columns}
        dataSource={data}
        rowClassName={getRowClassName}
      />
    </div>
  );
};

export default TableDashboard;
