import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Table, Input, Select } from "antd";

import styles from "./RecommendationsMockup.module.scss";
import { useNavigate } from "react-router-dom";

const RecommendationsMockup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalSize, setTotalSize] = useState(0);

  const columns = [
    {
      title: "Nombre",
    },
    {
      title: "Descripción",
    },
    {
      title: "Tipo",
    },
    {
      title: "Segmentos",
    },
    {
      title: "Fecha implementación recomendada",
    },

    {
      title: "Acciones",
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          {"Recomendaciones de ofertas y promociones"}
        </h1>
        <div className={styles.actionButtonsWrapper}>
          {/*<Button*/}
          {/*  href="/pricings/create"*/}
          {/*  className={styles.newButton}*/}
          {/*  type="primary"*/}
          {/*  icon={<PlusOutlined />}*/}
          {/*/>*/}
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.headerTableWrapper}>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}>
                <Input className={styles.inputFilter} placeholder={"Nombre"} />
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  className={styles.filterSelect}
                  showSearch
                  placeholder={t("Common-Type")}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  className={styles.filterSelect}
                  showSearch
                  placeholder={"Segmentos"}
                />
              </div>
            </div>
          </div>

          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              // onChange: handlePagination,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RecommendationsMockup;
