export const getOrganizationBySubdomain = async () => {
  const fullDomain = window.location.host;
  const subdomainArray = fullDomain
    .split(".")
    .slice(0, fullDomain.includes("localhost") ? -1 : -2);

  return subdomainArray[0];
};

export const getOrganizationId = async () => {
  return (await localStorage.getItem("organizationId")) ?? "";
};

export const getSuperAdmin = async () => {
  return (await localStorage.getItem("superAdmin")) === "true";
};
