import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { en } from "./lang/en";
import { es } from "./lang/es";

//todo check this doesn't fail with other languages.
const userLanguage = window.navigator.language;
userLanguage.substring(0, 2);

const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "es",
  lng: userLanguage,
  react: { useSuspense: false }, //fix
});

export default i18n;
