import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  deleteCategory,
  getCategoriesByFather,
  processExcelTemplate,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import { handleDownloadTemplate } from "../../helpers/Helper";
import ImportModal from "../../components/importmodal/ImportModal";
import {
  Table,
  Form,
  message,
  Tag,
  Space,
  Modal,
  Button,
  Typography,
  Input,
  Select,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./Categories.module.scss";

const Categories = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [actualPage, setActualPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [categoriesMap, setCategoriesMap] = useState<any[]>([]);
  const [downloadingTemplate, setDownloadingTemplate] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [importErrors, setImportErrors] = useState<any>(null);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [subCategories, setSubCategories] = useState<{ [key: number]: any[] }>(
    {}
  );
  const [subLoading, setSubLoading] = useState<{ [key: number]: boolean }>({});
  const [nameFilterValue, setNameFilterValue] = useState("");
  const [externalIdFilterValue, setExternalIdFilterValue] = useState("");
  const [activeFilterValue, setActiveFilterValue] = useState<
    number | undefined
  >(undefined);

  const [newCategoryForm] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CATEGORIES)) handleFetchCategories();
  }, [nameFilterValue, externalIdFilterValue, activeFilterValue]);

  const handleImportFile = async (file: any) => {
    if (file) {
      const response = await processExcelTemplate("categories", file);
      if (response.success) {
        setImportErrors(null);
        setIsImportModalVisible(false);
        message.success(
          t("Common-ImportSuccess", { quantity: response.data.added })
        );
        handleFetchCategories();
      } else {
        if (response.data?.errors) setImportErrors(response.data.errors);
        else message.error(t("Error-CommonError"));
      }
    }
  };

  const handleFetchCategories = async (
    pagination = actualPage,
    fatherId?: number
  ) => {
    const response = await getCategoriesByFather(
      pagination - 1,
      {
        name: nameFilterValue,
        externalId: externalIdFilterValue,
        enable: activeFilterValue,
      },
      fatherId
    );

    setData(response.categories);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    const father = categoriesMap[categoriesMap.length - 1];

    handleFetchCategories(page, father ? father.id : null);
    setActualPage(page);
  };

  const fetchSubCategories = async (parentId: number) => {
    setSubLoading((prev) => ({ ...prev, [parentId]: true }));
    const response = await getCategoriesByFather(0, {}, parentId);
    const subCategoriesWithKey = response.categories.map(
      (subCategory: any) => ({
        ...subCategory,
        key: subCategory.id,
      })
    );
    setSubCategories((prev) => ({ ...prev, [parentId]: subCategoriesWithKey }));
    setSubLoading((prev) => ({ ...prev, [parentId]: false }));
  };

  const handleExpand = async (expanded: boolean, record: any) => {
    if (expanded) {
      await fetchSubCategories(record.id);
      setExpandedRows((prev) => [...prev, record.id]);
    } else {
      setExpandedRows((prev) => prev.filter((id) => id !== record.id));
    }
  };

  const showDeleteConfirm = (categoryId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteCategory(categoryId);
      },
    });
  };

  const handleDeleteCategory = async (categoryId: number) => {
    setLoading(true);
    const deleteStatus = await deleteCategory(categoryId);

    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else {
      message.success(t("Common-DeleteSuccessMessage"));
      await handleFetchCategories(
        actualPage,
        categoriesMap[categoriesMap.length - 1]?.id
      );
    }
    setLoading(false);
  };

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Common-ExternalId"),
      dataIndex: "externalId",
    },
    {
      title: t("Common-Description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("Categories-Father"),
      dataIndex: "father",
      key: "father",
      render: (father: any) => father?.name,
    },
    {
      title: t("Categories-Status"),
      dataIndex: "enable",
      key: "enable",
      render: (enable: boolean) => (
        <>
          <Tag color={enable ? "green" : "red"}>
            {enable ? t("Common-Active") : t("Common-Inactive")}
          </Tag>
        </>
      ),
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => navigate(`/admin/categories/${record.id}/view`)}
          />
          {record.numberOfChilds === 0 && (
            <DeleteOutlined onClick={() => showDeleteConfirm(record.id)} />
          )}
        </Space>
      ),
    },
  ];

  const expandedRowRender = (record: any) => {
    const subData = subCategories[record.id] || [];
    const subTableLoading = subLoading[record.id] || false;

    return (
      <Table
        size="small"
        columns={columns}
        className={styles.tableCategories}
        dataSource={subData}
        rowKey={(record) => record.id}
        loading={subTableLoading}
        pagination={false}
        expandable={{
          expandedRowRender,
          onExpand: handleExpand,
          expandedRowKeys: expandedRows.filter((id) =>
            subData.some((sub) => sub.id === id)
          ),
        }}
      />
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Categories-Categories")}</h1>
        {hasPermission(privilegesEnum.WRITE_CATEGORIES) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={() => navigate("/admin/categories/create")}
              className={styles.newCategoryButton}
              type="primary"
              icon={<PlusOutlined />}
            />
            <Button
              onClick={() => {
                setImportErrors("");
                setIsImportModalVisible(true);
              }}
              className={styles.importButton}
              type="primary"
            >
              {t("Common-Import")}
            </Button>
            <Button
              onClick={() =>
                handleDownloadTemplate("categories", setDownloadingTemplate)
              }
              loading={downloadingTemplate}
              className={styles.importButton}
              type="primary"
            >
              {t("Common-DownloadTemplate")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.mapCategoryWrapper}>
        {categoriesMap.length > 0 && (
          <Typography.Link
            key="all"
            className={styles.mapCategoryText}
            onClick={() => {
              handleFetchCategories(1);
              newCategoryForm.setFieldsValue({ father: null });
              setCategoriesMap([]);
            }}
          >{`${t("Common-All")} -`}</Typography.Link>
        )}
        {categoriesMap.map((category) => (
          <Typography.Link
            key={category.id}
            className={styles.mapCategoryText}
            onClick={() => {
              handleFetchCategories(1, category.id);
              newCategoryForm.setFieldsValue({ father: category.id });

              const categoriesSelected = categoriesMap;
              const indexRemove = categoriesMap.findIndex(
                (mappedCategory) => mappedCategory.id === category.id
              );
              const newCategories = categoriesSelected.slice(
                0,
                indexRemove + 1
              );
              setCategoriesMap(newCategories);
            }}
          >{`${category.name} -`}</Typography.Link>
        ))}
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_CATEGORIES) && (
          <div className={styles.tableCategoriesWrapper}>
            <div className={styles.headerTableWrapper}>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <Input
                    placeholder={t("Common-SearchByName")}
                    className={styles.filterInput}
                    onChange={(e) => {
                      setNameFilterValue(e.target.value);
                      setActualPage(1);
                    }}
                    value={nameFilterValue}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <Input
                    placeholder={t("Common-SearchByExternalId")}
                    className={styles.filterInput}
                    onChange={(e) => {
                      setExternalIdFilterValue(e.target.value);
                      setActualPage(1);
                    }}
                    value={externalIdFilterValue}
                  />
                </div>

                <div className={styles.filterWrapper}>
                  <Select
                    showSearch
                    allowClear
                    defaultValue={activeFilterValue}
                    className={styles.filterSelect}
                    placeholder={t("Common-SearchByState")}
                    filterOption={(input: string, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={(_, option) => {
                      if (option) {
                        setActiveFilterValue(option.value);
                        setActualPage(1);
                      }
                    }}
                    onClear={() => {
                      setActiveFilterValue(undefined);
                      setActualPage(1);
                    }}
                  >
                    <Select.Option value={0}>
                      {t("Common-Inactive")}
                    </Select.Option>
                    <Select.Option value={1}>
                      {t("Common-Active")}
                    </Select.Option>
                  </Select>
                </div>
              </div>
            </div>
            <Table
              size="small"
              className={styles.tableCategories}
              loading={loading}
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={data}
              expandable={{ expandedRowRender, onExpand: handleExpand }}
              expandedRowKeys={expandedRows}
              pagination={{
                current: actualPage,
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
              }}
            />
          </div>
        )}
      </div>
      <Modal
        title={t("Common-ImportEntity", { entity: t("Common-Categories") })}
        visible={isImportModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => setIsImportModalVisible(false)}
      >
        <ImportModal onOk={handleImportFile} errors={importErrors} />
      </Modal>
    </div>
  );
};

export default Categories;
