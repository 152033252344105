import { useState } from "react";
import { Button, Input, message } from "antd";
import { useTranslation } from "react-i18next";

import styles from "./ChatIa.module.scss";

const ChatIa = () => {
  const { t } = useTranslation();

  const [messages, setMessages] = useState([
    { text: "¡Hola! ¿En qué puedo ayudarte?", isUser: false },
  ]);
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(false);

  const apiIAURL = process.env.REACT_APP_API_IA_URL;

  const handleSendMessage = async () => {
    if (inputText.trim() === "") return;

    setLoading(true);
    const newUserMessage = { text: inputText, isUser: true };
    const updatedMessages = [...messages, newUserMessage];
    setMessages(updatedMessages);
    setInputText("");

    try {
      const response = await fetch(`${apiIAURL}/api/v1/prompt`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt: inputText }),
      });

      if (!response.ok) {
        message.error(t("Common-ErrorMessage"));
      }

      const data = await response.json();
      const botResponse = { text: data.result, isUser: false };
      setLoading(false);
      setMessages([...updatedMessages, botResponse]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className={styles.chatContainer}>
      <div className={styles.chatMessages}>
        {messages.map((message, index) => (
          <div
            key={index}
            className={`${styles.message} ${
              message.isUser ? styles.user : styles.bot
            }`}
          >
            {message.text}
          </div>
        ))}
      </div>
      <div className={styles.chatInput}>
        <Input
          type="text"
          placeholder={t("ChatIa-inputPlaceholder")}
          value={inputText}
          disabled={loading}
          onChange={(e) => setInputText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") handleSendMessage();
          }}
        />
        <Button onClick={handleSendMessage} loading={loading}>
          {t("ChatIa-send")}
        </Button>
      </div>
    </div>
  );
};

export default ChatIa;
