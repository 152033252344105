import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { createCurve } from "../../../api/Services";

import { Form, Input, Button, message, Spin, InputNumber } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./NewCurve.module.scss";
const NewCurve = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [sizeDistribution, setSizeDistribution] = useState<number[]>([]);

  const [form] = Form.useForm();

  const onValuesChange = (changedValues: any, allValues: any) => {
    const { sizeStart, sizeEnd } = allValues;
    if (sizeStart && sizeEnd && sizeEnd >= sizeStart) {
      const distribution = [];
      for (let i = sizeStart; i <= sizeEnd; i++) {
        distribution.push(i);
      }
      setSizeDistribution(distribution);
    } else {
      setSizeDistribution([]);
    }
  };

  const handleCreateCurve = async () => {
    setLoading(true);
    const data = form.getFieldsValue();
    const { name, sizeStart, sizeEnd } = data;
    const distribution = sizeDistribution.map(
      (size) => data[`size-${size}`] || 0
    );

    const response = await createCurve({
      name,
      sizeStart,
      sizeEnd,
      distribution,
    });
    if (response) {
      message.success(t("Common-CreateSuccessMessage"));
      form.resetFields();
      navigate("/admin/curves");
    } else message.error(t("Common-ErrorMessage"));

    setLoading(false);
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewCurve")}</h1>
        <Button
          onClick={handleCreateCurve}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.newWrapper}>
          <Form
            name="new"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            onValuesChange={onValuesChange}
            className={styles.formNew}
            form={form}
            onFinish={handleCreateCurve}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item
              label={t("Common-SizeStart")}
              name="sizeStart"
              rules={[
                {
                  required: true,
                  message: t("Common-SizeStart"),
                },
              ]}
            >
              <InputNumber
                className={styles.inputNumber}
                placeholder={t("Common-SizeStart")}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-SizeEnd")}
              name="sizeEnd"
              rules={[
                {
                  required: true,
                  message: t("Common-SizeEnd"),
                },
              ]}
            >
              <InputNumber
                className={styles.inputNumber}
                placeholder={t("Common-SizeEnd")}
              />
            </Form.Item>
            <h1 className={styles.subTitle}>{`${t(
              "Common-Distribution"
            )} `}</h1>
            {sizeDistribution.map((size) => (
              <div key={size}>
                <Form.Item
                  label={`${t("Common-Distribution")} ${t(
                    "Common-Size"
                  )} ${size}`}
                  name={`size-${size}`}
                >
                  <InputNumber placeholder={t("Common-Quantity")} />
                </Form.Item>
              </div>
            ))}
          </Form>
        </div>
      </div>
    </div>
  );
};
export default NewCurve;
