import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getArticlesByName,
  getBranchOfficesFiltered,
  getStockBreaks,
} from "../../../api/Services";
import {
  hasPermission,
  privilegesEnum,
} from "../../../helpers/PermissionHelper";
import { useNavigate } from "react-router-dom";

import { Table, Select, Typography, Button, Spin, Dropdown, Menu } from "antd";

import styles from "./StockBreaks.module.scss";
import { BusinessUniteContext } from "../../../components/laoyut/Layout";
import { MoreOutlined } from "@ant-design/icons";

const StockBreaks = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [loadingBranchOffices, setLoadingBranchOffices] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [articleOptions, setArticleOptions] = useState<any[]>([]);
  const [branchOfficeOptions, setBranchOfficeOptions] = useState<any[]>([]);
  const [articleIdFilterValue, setArticleIdFilterValue] = useState("");
  const [branchOfficeIdFilterValue, setBranchOfficeIdFilterValue] =
    useState("");

  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const handlePagination = (page: number) => {
    handleFetchStockBreaks(page);
    setActualPage(page);
  };

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_REPOSITION_ORDERS)) {
      handleFetchStockBreaks();
    }
  }, []);

  const handleFetchStockBreaks = async (
    pagination = actualPage,
    filters?: {
      articleId?: string;
      branchOfficeId?: number;
    }
  ) => {
    setLoading(true);
    const response = await getStockBreaks(pagination - 1, {
      articleId: filters?.articleId ?? articleIdFilterValue,
      branchOfficeId:
        filters?.branchOfficeId?.toString() ?? branchOfficeIdFilterValue,
    });

    const flattenedData: any = [];

    response.data.forEach((item) => {
      const { sku, stockoutDate } = item;
      const { detailedStock } = sku;

      detailedStock.branchOffices.forEach(
        (office: { id: number; name: string; stock: number }) => {
          if (
            office.id === filters?.branchOfficeId ||
            !filters?.branchOfficeId
          ) {
            flattenedData.push({
              sku: {
                id: sku.id,
                name: sku.name,
                articleName: sku.articleName,
                quantityInPurchaseOrders: sku.quantityInPurchaseOrders,
                quantityInRepositionOrders: sku.quantityInRepositionOrders,
              },
              stock: office.stock,
              branchOffice: { name: office.name, id: office.id },
              stockoutDate: stockoutDate,
              repositionOrder: item.repositionOrder,
            });
          }
        }
      );
    });

    setData(flattenedData);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const handleSearchBranchOffices = async (value: string) => {
    if (businessUnitSelected) {
      setLoadingBranchOffices(true);
      const response = await getBranchOfficesFiltered(0, {
        name: value,
        businessUnitId: businessUnitSelected,
      });
      const options = response.branchOffices.map((item: any) => ({
        value: item.id,
        text: item.name,
      }));
      setBranchOfficeOptions(options);
      setLoadingBranchOffices(false);
    } else setBranchOfficeOptions([]);
  };

  const handleSearchArticles = async (value: string) => {
    if (value.length > 2) {
      setLoadingArticles(true);
      const response = await getArticlesByName(value);
      const options = response.articles.map((item: any) => ({
        value: item.id,
        text: item.name,
      }));
      setArticleOptions(options);
      setLoadingArticles(false);
    } else setArticleOptions([]);
  };

  const columns = [
    {
      title: t("Common-Article"),
      key: "article",
      render: (record: any) => (
        <a href={`/admin/articles/${record.sku.articleId}/view`}>
          {record.sku.articleName}
        </a>
      ),
    },
    {
      title: t("Common-Sku"),
      key: "sku",
      render: (record: any) => record.sku.name,
    },
    {
      title: t("Common-BranchOffice"),
      key: "branchoffice",
      render: (record: any) => (
        <a href={`/admin/branchoffices/${record.branchOffice.id}/view`}>
          {record.branchOffice.name}
        </a>
      ),
    },
    {
      title: t("Common-Stock"),
      key: "stock",
      render: (record: any) => (
        <div className={styles.stockWrapper}>
          <Typography.Text>{`${record.stock} ${t(
            "Common-StockCurrent"
          )}`}</Typography.Text>
          <Typography.Text>{`${record.sku.quantityInRepositionOrders} ${t(
            "Common-StockInTransit"
          )}`}</Typography.Text>
        </div>
      ),
    },
    {
      title: t("Common-EstimatedStockBreak"),
      dataIndex: "stockoutDate",
    },
    {
      title: t("Common-RepositionOrder"),
      key: "repositionOrder",
      render: (record: any) =>
        record.repositionOrder.length === 0
          ? t("Common-Pending")
          : record.repositionOrder.map((order: any) => (
              <div key={order.id}>
                <a href={`/repositionorders/${order.id}/view`}>
                  {order.orderStatus.description_es}
                </a>
              </div>
            )),
    },
    {
      title: t("Common-Quantity"),
      key: "quantity",
      render: (data: any) =>
        data.repositionOrder?.map((order: any) => {
          const sku = order.skus?.find((sku: any) => sku.skuId === data.sku.id);
          return <div key={order.id}>{sku ? sku.quantity : 0}</div>;
        }),
    },
    {
      title: t("Common-Delivery"),
      key: "delivery",
      render: (data: any) =>
        data.repositionOrder.map((order: any) => (
          <div key={order.id}>{order.estimatedDeliveryDate ?? "-"}</div>
        )),
    },
    {
      key: "actions",
      render: (record: any) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="view"
                onClick={() => {
                  navigate(`/repositionorders/create`, {
                    state: {
                      articleId: record.sku.articleId,
                      skuId: record.sku.id,
                      branchOfficeId: record.branchOffice.id,
                      articleName: record.sku.articleName,
                      skuName: record.sku.name,
                    },
                  });
                }}
              >
                {t("Common-Replenish")}
              </Menu.Item>
            </Menu>
          }
        >
          <Button
            onClick={(e) => e.preventDefault()}
            className={styles.buttonMore}
          >
            <MoreOutlined className={styles.moreIcon} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-StockBreaks")}</h1>
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_REPOSITION_ORDERS) && (
          <div className={styles.tableWrapper}>
            <div className={styles.headerTableWrapper}>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <Select
                    showSearch
                    placeholder={t("Common-SearchByArticle")}
                    allowClear
                    onClear={() => {
                      setArticleIdFilterValue("");
                      handleFetchStockBreaks(1, { articleId: "" });
                    }}
                    onSelect={(_, option) => {
                      if (option) {
                        setArticleIdFilterValue(option.value);
                        handleFetchStockBreaks(1, { articleId: option.value });
                      }
                    }}
                    notFoundContent={
                      loadingArticles ? <Spin size="small" /> : null
                    }
                    className={styles.filterSelect}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearchArticles}
                    options={(articleOptions || []).map((d: any) => ({
                      value: d.value,
                      label: d.text,
                    }))}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <Select
                    showSearch
                    placeholder={t("Common-SearchBranchOfficeTextPlaceholder")}
                    onClear={() => {
                      setBranchOfficeIdFilterValue("");
                      handleFetchStockBreaks(1, { branchOfficeId: undefined });
                    }}
                    onSelect={(_, option) => {
                      if (option) {
                        setBranchOfficeIdFilterValue(option.value);
                        handleFetchStockBreaks(1, {
                          branchOfficeId: option.value,
                        });
                      }
                    }}
                    notFoundContent={
                      loadingBranchOffices ? <Spin size="small" /> : null
                    }
                    className={styles.filterSelect}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearchBranchOffices}
                    options={(branchOfficeOptions || []).map((d: any) => ({
                      value: d.value,
                      label: d.text,
                    }))}
                  />
                </div>
              </div>
            </div>
            <Table
              size="small"
              className={styles.table}
              loading={loading}
              columns={columns}
              dataSource={data}
              rowClassName={styles.row}
              rowKey={(record) => record.id}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default StockBreaks;
