import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  editCategory,
  getCategoryById,
  getAllCategories,
  getRoundingTypes,
} from "../../../api/Services";
import {
  hasPermission,
  privilegesEnum,
} from "../../../helpers/PermissionHelper";
import { BusinessUniteContext } from "../../../components/laoyut/Layout";
import { Form, message, Select, Button, Input, Switch, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "./ViewCategory.module.scss";
import NotFound from "../../notfound/NotFound";
import { useParams } from "react-router-dom";

const ViewCategory = () => {
  const { t } = useTranslation();
  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);
  const { id } = useParams();
  const [category, setCategory] = useState<any>();
  const [categories, setCategories] = useState<any[]>([]);
  const [roundingTypes, setRoundingTypes] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [editform] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CATEGORIES)) {
      handleFetchCategory();
      handleFetchAllCategories();
      handleFetchRoundingTypes();
    }
  }, []);

  const handleFetchAllCategories = async () => {
    const response = await getAllCategories();
    setCategories(response);
  };

  const handleFetchRoundingTypes = async () => {
    const response = await getRoundingTypes();
    setRoundingTypes(response);
  };
  const getCategoriesOptions = () => {
    const options: any[] = [];

    categories.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const getRoundingTypeOptions = () => {
    const options: any[] = [];

    roundingTypes.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.descriptionEs}
        </Select.Option>
      );
    });

    return options;
  };
  const handleFetchCategory = async () => {
    const response = await getCategoryById(id!);
    setCategory(response);
    setLoading(false);
  };

  const handleEditCategory = async () => {
    setLoading(true);

    const { name, externalId, description, fatherId, roundingTypeId, enable } =
      editform.getFieldsValue();
    let response = await editCategory(
      parseInt(id!),
      name,
      externalId,
      description,
      fatherId,
      roundingTypeId,
      enable,
      [businessUnitSelected] ?? []
    );

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
    } else message.error(t("Common-EditErrorMessage"));
    await handleFetchCategory();
    setLoading(false);
  };
  const handleCancelButton = () => {
    editform.resetFields();
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !category ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Category")}</h1>
        {hasPermission(privilegesEnum.WRITE_CATEGORIES) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEditCategory}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.infoWrapper}>
          <Form
            name="editform"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.categoryForm}
            form={editform}
            layout="vertical"
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              className={styles.itemWrapper}
              initialValue={category?.name}
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>

            <Form.Item
              label={t("Common-ExternalId")}
              name="externalId"
              className={styles.itemWrapper}
              initialValue={category.externalId}
            >
              <Input placeholder={t("Common-ExternalId")} />
            </Form.Item>

            <Form.Item
              label={t("Common-Description")}
              name="description"
              className={styles.itemWrapper}
              initialValue={category.description}
            >
              <Input placeholder={t("Common-Description")} />
            </Form.Item>

            <Form.Item
              label={t("Categories-Father")}
              name="fatherId"
              className={styles.itemWrapper}
              initialValue={category.fatherId}
            >
              <Select
                className={styles.type}
                allowClear
                style={{ width: "100%" }}
                placeholder=""
                defaultValue={category.fatherId}
              >
                {getCategoriesOptions()}
              </Select>
            </Form.Item>

            <Form.Item
              label={t("Categories-RoundingType")}
              name="roundingTypeId"
              className={styles.itemWrapper}
              initialValue={category.roundingType?.id}
            >
              <Select
                className={styles.type}
                allowClear
                style={{ width: "100%" }}
                placeholder=""
                defaultValue={category.roundingType?.id}
              >
                {getRoundingTypeOptions()}
              </Select>
            </Form.Item>

            <Form.Item
              initialValue={category.enable}
              label={t("Common-Active")}
              name="enable"
              className={styles.itemWrapper}
              rules={[
                {
                  required: true,
                  message: t("Common-Active"),
                },
              ]}
            >
              <Switch defaultChecked={category.enable} />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default ViewCategory;
