import moment from "moment/moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createSeason } from "../../../api/Services";
import { useNavigate } from "react-router-dom";

import { Form, message, Button, Input, Spin, DatePicker } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./NewSeason.module.scss";

const NewSeason = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleCreate = async () => {
    await form.validateFields().then(async (values) => {
      setLoading(true);
      const { name } = values;
      const startingDate = moment(values.startingDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      const endingDate = moment(values.endingDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let response = await createSeason(name, startingDate, endingDate);
      if (response) {
        message.success(t("Common-CreateSuccessMessage"));
        form.resetFields();
        navigate("/admin/parameters");
      } else message.error(t("Common-ErrorMessage"));

      setLoading(false);
    });
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewSeason")}</h1>
        <Button
          onClick={handleCreate}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.newWrapper}>
          <Form
            name="newSeason"
            layout="vertical"
            initialValues={{ remember: true }}
            form={form}
            autoComplete="off"
            className={styles.formNew}
          >
            <Form.Item
              className={styles.newItem}
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-StartDate")}
              name="startingDate"
              rules={[
                {
                  required: true,
                  message: t("Common-StartDate"),
                },
              ]}
            >
              <DatePicker
                className={styles.dateSelector}
                showTime={false}
                format="YYYY-MM-DD"
              />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-EndDate")}
              name="endingDate"
              rules={[
                {
                  required: true,
                  message: t("Common-EndDate"),
                },
              ]}
            >
              <DatePicker
                className={styles.dateSelector}
                showTime={false}
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default NewSeason;
