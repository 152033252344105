import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  deleteBranchOffice,
  getBranchOfficesFiltered,
  processExcelTemplate,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import { useNavigate } from "react-router-dom";

import { Table, Button, Space, Modal, message, Input } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./BranchOffices.module.scss";
import { handleDownloadTemplate } from "../../helpers/Helper";
import ImportModal from "../../components/importmodal/ImportModal";
import { BusinessUniteContext } from "../../components/laoyut/Layout";

const BranchOffices = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [downloadingTemplate, setDownloadingTemplate] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [importErrors, setImportErrors] = useState<any>(null);
  const [nameFilterValue, setNameFilterValue] = useState("");
  const [externalIdFilterValue, setExternalIdFilterValue] = useState("");

  const canEdit = hasPermission(privilegesEnum.WRITE_BRANCH_OFFICES);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_BRANCH_OFFICES)) {
      handleFetchBranchOffices(1);
      setActualPage(1);
    }
  }, [businessUnitSelected, nameFilterValue, externalIdFilterValue]);

  const handleFetchBranchOffices = async (pagination = actualPage) => {
    if (businessUnitSelected) {
      const response = await getBranchOfficesFiltered(pagination - 1, {
        businessUnitId: businessUnitSelected,
        name: nameFilterValue,
        externalId: externalIdFilterValue,
      });
      const allBranchOffices = response.branchOffices;

      setData(allBranchOffices);
      setTotalSize(response.totalElements);
      setLoading(false);
    }
  };

  const handlePagination = (page: number) => {
    handleFetchBranchOffices(page);
    setActualPage(page);
  };

  const handleImportFile = async (file: any) => {
    if (file) {
      const response = await processExcelTemplate("branchOffices", file);
      if (response.success) {
        setImportErrors(null);
        setIsImportModalVisible(false);
        message.success(
          t("Common-ImportSuccess", { quantity: response.data.added })
        );
        handleFetchBranchOffices();
      } else {
        if (response.data?.errors) setImportErrors(response.data.errors);
        else message.error(t("Error-CommonError"));
      }
    }
  };

  const showDeleteBranchOfficeConfirm = (branchOfficeId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteBranchOffice(branchOfficeId);
      },
    });
  };

  const handleDeleteBranchOffice = async (branchOfficeId: number) => {
    setLoading(true);
    const deleteStatus = await deleteBranchOffice(branchOfficeId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchBranchOffices();
    setLoading(false);
  };

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Common-Code"),
      dataIndex: "id",
      key: "code",
    },
    {
      title: t("Common-ExternalId"),
      dataIndex: "externalId",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {canEdit ? (
            <EditOutlined
              onClick={() => {
                navigate(`/admin/branchoffices/${record.id}/view`);
              }}
            />
          ) : (
            <EyeOutlined
              onClick={() => {
                navigate(`/admin/branchoffices/${record.id}/view`);
              }}
            />
          )}
          <DeleteOutlined
            onClick={() => {
              showDeleteBranchOfficeConfirm(record.id);
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-BranchOffices")}</h1>
        {hasPermission(privilegesEnum.WRITE_BRANCH_OFFICES) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={() => {
                navigate("/admin/branchoffices/create");
              }}
              className={styles.newButton}
              type="primary"
              icon={<PlusOutlined />}
            />
            <Button
              className={styles.importButton}
              type="primary"
              onClick={() => {
                setImportErrors("");
                setIsImportModalVisible(true);
              }}
            >
              {t("Common-Import")}
            </Button>
            <Button
              onClick={() =>
                handleDownloadTemplate("branchOffices", setDownloadingTemplate)
              }
              loading={downloadingTemplate}
              className={styles.importButton}
              type="primary"
            >
              {t("Common-DownloadTemplate")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_BRANCH_OFFICES) && (
          <div className={styles.tableWrapper}>
            <div className={styles.headerTableWrapper}>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <Input
                    placeholder={t("Common-SearchByName")}
                    className={styles.filterInput}
                    onChange={(e) => setNameFilterValue(e.target.value)}
                    value={nameFilterValue}
                  />
                </div>
                <div className={styles.filterWrapper}>
                  <Input
                    placeholder={t("Common-SearchByExternalId")}
                    className={styles.filterInput}
                    onChange={(e) => setExternalIdFilterValue(e.target.value)}
                    value={externalIdFilterValue}
                  />
                </div>
              </div>
            </div>

            <Table
              size="small"
              className={styles.table}
              rowKey={(record) => record.id}
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
                current: actualPage,
              }}
            />
          </div>
        )}
      </div>
      <Modal
        title={t("Common-ImportEntity", { entity: t("Common-Suppliers") })}
        visible={isImportModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => setIsImportModalVisible(false)}
      >
        <ImportModal onOk={handleImportFile} errors={importErrors} />
      </Modal>
    </div>
  );
};

export default BranchOffices;
