import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createStockMovements,
  getArticlesFiltered,
  getBranchOfficesFiltered,
  getBranchOfficeSkusById,
  getDepositsFiltered,
  getDepositsSkusById,
  getMovementStockTypes,
  getStockMovements,
} from "../../api/Services";

import {
  Table,
  Button,
  Modal,
  Form,
  Select,
  Input,
  Spin,
  InputNumber,
  DatePicker,
  message,
  Radio,
  Cascader,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import styles from "./StockMovements.module.scss";
import { BusinessUniteContext } from "../../components/laoyut/Layout";
import moment from "moment/moment";

enum PlaceValue {
  branchoffice,
  deposit,
}

const StockMovements = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(0);
  const [loadingBranchOffices, setLoadingBranchOffices] = useState(false);
  const [loadingDeposits, setLoadingDeposits] = useState(false);
  const [actualBranchOfficeId, setActualBranchOfficeId] = useState<any>(null);
  const [branchOfficeOptions, setBranchOfficeOptions] = useState([]);
  const [actualDepositId, setActualDepositId] = useState<any>(null);
  const [depositOptions, setDepositOptions] = useState([]);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [articleOptions, setArticleOptions] = useState<any[]>([]);
  const [articleSelected, setArticleSelected] = useState(undefined);
  const [typeOptions, setTypeOptions] = useState<any[]>([]);
  const [typeSelected, setTypeSelected] = useState(undefined);
  const [placeValue, setPlaceValue] = useState<PlaceValue>(
    PlaceValue.branchoffice
  );
  const [branchOfficeIdFilterValue, setBranchOfficeIdFilterValue] =
    useState("");
  const [depositIdFilterValue, setDepositIdFilterValue] = useState("");
  const [branchOffices, setBranchOffices] = useState<any[]>([]);

  const [actualFilterStartCreatedDate, setActualFilterStartCreatedDate] =
    useState<any>(null);
  const [actualFilterEndCreatedDate, setActualFilterEndCreatedDate] =
    useState<any>(null);
  const [skuOptions, setSkuOptions] = useState([]);
  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  useEffect(() => {
    handleSearchArticles("");
    handleFetchMovementTypes();
    handleFetchMovements();
    handleSearchBranchOfficesFilter("");
    handleSearchDeposits("");
  }, [businessUnitSelected]);

  const handleAddStock = async (data: any) => {
    setLoading(true);
    const { type, branchOffice, date, stock, reason, sku } = data;
    const response = await createStockMovements({
      stock,
      type,
      skuBranchOfficeId: sku,
      date: date.format("YYYY-MM-DDTHH:mm:ss"),
      reason,
    });
    if (response) {
      message.success(t("Common-CreateSuccessMessage"));
      form.resetFields();
      setIsModalVisible(false);
      handleFetchMovements();
    } else message.error(t("Common-ErrorMessage"));

    setLoading(false);
  };

  const handleFetchMovements = async (
    pagination = actualPage,
    filters?: {
      startDate?: string | null;
      endDate?: string | null;
      articleId?: string | null;
      type?: string | null;
      branchOfficeId?: string;
      depositId?: string;
    }
  ) => {
    if (businessUnitSelected) {
      const response = await getStockMovements({
        pagination,
        businessUnitId: businessUnitSelected,
        ...filters,
      });
      setData(response.stockMovements);
      setTotalSize(response.totalElements);
      setLoading(false);
    }
  };

  const handlePagination = (page: number) => {
    handleFetchMovements(page);
    setActualPage(page);
  };

  const handleSearchBranchOffices = async (value: string) => {
    if (businessUnitSelected) {
      setLoadingBranchOffices(true);
      const response = await getBranchOfficesFiltered(0, {
        name: value,
        businessUnitId: businessUnitSelected,
      });
      const options = response.branchOffices.map((item: any) => ({
        value: item.id,
        text: item.name,
      }));
      setBranchOfficeOptions(options);
      setLoadingBranchOffices(false);
    } else setBranchOfficeOptions([]);
  };

  const handleSearchDeposits = async (value: string) => {
    setLoadingDeposits(true);
    const response = await getDepositsFiltered(0, {
      name: value,
    });
    const options = response.deposits.map((item: any) => ({
      value: item.id,
      text: item.name,
    }));
    setDepositOptions(options);
    setLoadingDeposits(false);
  };

  const handleSearchArticles = async (value: string) => {
    setLoadingArticles(true);
    const response = await getArticlesFiltered(0, { articleName: value }, true);
    const options = response.articles.map((item: any) => ({
      value: item.id,
      text: item.name,
    }));
    setArticleOptions(options);
    setLoadingArticles(false);
  };

  const handleFetchMovementTypes = async () => {
    const response = await getMovementStockTypes();
    const options = response
      .filter((item: any) => item.value !== "ADJUSTMENT_EXCEL")
      .map((item: any) => ({
        value: item.value,
        text: item.description_es,
      }));
    setTypeOptions(options);
  };

  const handleFetchSkusBranchOffice = async (id: string, skuName: string) => {
    const response = await getBranchOfficeSkusById(id, 0, skuName);
    const options = response.skus.map((item: any) => ({
      value: item.id,
      text: item.sku.name,
    }));
    setSkuOptions(options);
  };

  const handleFetchSkusDeposits = async (id: string, skuName: string) => {
    const response = await getDepositsSkusById(id, 0, skuName);
    const options = response.skus.map((item: any) => ({
      value: item.id,
      text: item.sku.name,
    }));
    setSkuOptions(options);
  };

  const handleChangeDateFilter = (value: any) => {
    setActualFilterStartCreatedDate(
      value ? moment(value[0], "YYYY-MM-DD").format("YYYY-MM-DD") ?? null : null
    );
    setActualFilterEndCreatedDate(
      value ? moment(value[1], "YYYY-MM-DD").format("YYYY-MM-DD") ?? null : null
    );
    setActualPage(0);
    handleFetchMovements(0, {
      startDate: value
        ? value[0]
          ? moment(value[0], "YYYY-MM-DD").format("YYYY-MM-DD") ?? null
          : null
        : null,
      endDate: value
        ? value[1]
          ? moment(value[1], "YYYY-MM-DD").format("YYYY-MM-DD") ?? null
          : null
        : null,
      articleId: articleSelected,
      type: typeSelected,
      branchOfficeId: branchOfficeIdFilterValue,
      depositId: depositIdFilterValue,
    });
  };

  const handleSearchBranchOfficesFilter = async (value: string) => {
    if (businessUnitSelected) {
      const response = await getBranchOfficesFiltered(0, {
        name: value,
        businessUnitId: businessUnitSelected,
      });
      setBranchOffices(response.branchOffices);
    } else setBranchOffices([]);
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const columns = [
    {
      title: t("Common-Article"),
      key: "article",
      render: (data: any) => {
        if (data.skuDeposit) {
          return (
            <a href={`/admin/articles/${data.skuDeposit.sku.articleId}/view`}>
              {data.skuDeposit.sku.articleName}
            </a>
          );
        }
        if (data.skuBranchOffice) {
          return (
            <a
              href={`/admin/articles/${data.skuBranchOffice.sku.articleId}/view`}
            >
              {data.skuBranchOffice.sku.articleName}
            </a>
          );
        }
      },
    },
    {
      title: t("Common-Sku"),
      key: "sku",
      render: (data: any) => {
        if (data.skuDeposit) {
          return data.skuDeposit.sku.name;
        }
        if (data.skuBranchOffice) {
          return data.skuBranchOffice.sku.name;
        }
      },
    },
    {
      title: t("Common-Place"),
      key: "place",
      render: (data: any) => {
        if (data.skuDeposit) {
          return (
            <a href={`/admin/deposits/${data.skuDeposit.deposit.id}/view`}>
              {data.skuDeposit.deposit.name}
            </a>
          );
        }
        if (data.skuBranchOffice) {
          return (
            <a
              href={`/admin/branchoffices/${data.skuBranchOffice.branchOffice.id}/view`}
            >
              {data.skuBranchOffice.branchOffice.name}
            </a>
          );
        }
      },
    },
    {
      title: t("Common-Type"),
      dataIndex: "stockMovementType",
      render: (type: {
        description_en: string;
        description_es: string;
        value: string;
      }) => type.description_es,
    },
    {
      title: t("Common-Date"),
      dataIndex: "date",
      render: (date: string) => date.replace("T", " "),
    },
    {
      title: t("Common-Stock"),
      dataIndex: "stock",
    },
    {
      title: t("Common-Motive"),
      dataIndex: "reason",
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-StockMovements")}</h1>
        {/*{hasPermission(privilegesEnum.WRITE_COMPETITORS) && (*/}
        <div className={styles.actionButtonsWrapper}>
          <Button
            onClick={() => setIsModalVisible(true)}
            className={styles.newButton}
            type="primary"
            icon={<PlusOutlined />}
          />
        </div>
        {/*)}*/}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.headerTableWrapper}>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}>
                <DatePicker.RangePicker
                  className={styles.dateSelectorFilter}
                  format="YYYY-MM-DD "
                  allowEmpty={[true, true]}
                  onChange={handleChangeDateFilter}
                  placeholder={[t("Common-Start"), t("Common-End")]}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  showSearch
                  value={articleSelected}
                  allowClear
                  onClear={() => {
                    setArticleSelected(undefined);
                    handleFetchMovements(0, {
                      startDate: actualFilterStartCreatedDate,
                      endDate: actualFilterEndCreatedDate,
                      articleId: undefined,
                      type: typeSelected,
                      branchOfficeId: branchOfficeIdFilterValue,
                      depositId: depositIdFilterValue,
                    });
                  }}
                  placeholder={t("Common-SearchArticleTextPlaceholder")}
                  onSelect={(_, option) => {
                    if (option) {
                      setArticleSelected(option.value);
                      handleFetchMovements(0, {
                        startDate: actualFilterStartCreatedDate,
                        endDate: actualFilterEndCreatedDate,
                        articleId: option.value,
                        type: typeSelected,
                        branchOfficeId: branchOfficeIdFilterValue,
                        depositId: depositIdFilterValue,
                      });
                    }
                  }}
                  loading={loadingArticles}
                  className={styles.filterSelect}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearchArticles}
                  options={(articleOptions || []).map((d: any) => ({
                    value: d.value,
                    label: d.text,
                  }))}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  showSearch
                  value={typeSelected}
                  allowClear
                  onClear={() => {
                    setTypeSelected(undefined);
                    handleFetchMovements(0, {
                      startDate: actualFilterStartCreatedDate,
                      endDate: actualFilterEndCreatedDate,
                      articleId: articleSelected,
                      type: undefined,
                      branchOfficeId: branchOfficeIdFilterValue,
                      depositId: depositIdFilterValue,
                    });
                  }}
                  placeholder={t("Common-SearchByType")}
                  onSelect={(_, option) => {
                    if (option) {
                      setTypeSelected(option.value);
                      handleFetchMovements(0, {
                        startDate: actualFilterStartCreatedDate,
                        endDate: actualFilterEndCreatedDate,
                        articleId: articleSelected,
                        type: option.value,
                        branchOfficeId: branchOfficeIdFilterValue,
                        depositId: depositIdFilterValue,
                      });
                    }
                  }}
                  className={styles.filterSelect}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearchArticles}
                  options={(typeOptions || []).map((d: any) => ({
                    value: d.value,
                    label: d.text,
                  }))}
                />
              </div>
            </div>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}>
                <Select
                  allowClear
                  className={styles.filterSelect}
                  showSearch
                  onSearch={handleSearchBranchOfficesFilter}
                  placeholder={t("Common-SearchByBranchOffice")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(_, option) => {
                    if (option) {
                      setBranchOfficeIdFilterValue(option.value);
                      handleFetchMovements(0, {
                        startDate: actualFilterStartCreatedDate,
                        endDate: actualFilterEndCreatedDate,
                        articleId: articleSelected,
                        type: typeSelected,
                        branchOfficeId: option.value,
                        depositId: depositIdFilterValue,
                      });
                    }
                  }}
                  onClear={() => {
                    setBranchOfficeIdFilterValue("");
                    handleFetchMovements(0, {
                      startDate: actualFilterStartCreatedDate,
                      endDate: actualFilterEndCreatedDate,
                      articleId: articleSelected,
                      type: typeSelected,
                      branchOfficeId: undefined,
                      depositId: depositIdFilterValue,
                    });
                  }}
                >
                  {getOptions(branchOffices)}
                </Select>
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  showSearch
                  allowClear
                  onClear={() => {
                    setTypeSelected(undefined);
                    handleFetchMovements(0, {
                      startDate: actualFilterStartCreatedDate,
                      endDate: actualFilterEndCreatedDate,
                      articleId: articleSelected,
                      type: typeSelected,
                      depositId: undefined,
                      branchOfficeId: branchOfficeIdFilterValue,
                    });
                  }}
                  placeholder={t("Common-SearchDepositTextPlaceholder")}
                  onSelect={(_, option) => {
                    if (option) {
                      setDepositIdFilterValue(option.value);
                      handleFetchMovements(0, {
                        startDate: actualFilterStartCreatedDate,
                        endDate: actualFilterEndCreatedDate,
                        articleId: articleSelected,
                        type: typeSelected,
                        depositId: option.value,
                        branchOfficeId: branchOfficeIdFilterValue,
                      });
                    }
                  }}
                  className={styles.filterSelect}
                  onSearch={handleSearchDeposits}
                  options={(depositOptions || []).map((d: any) => ({
                    value: d.value,
                    label: d.text,
                  }))}
                />
              </div>
            </div>
          </div>
          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.id}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              onChange: handlePagination,
            }}
          />
        </div>
      </div>
      <Modal
        title={t("Common-AddStockMovement")}
        visible={isModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form
          name="addStockMovement"
          initialValues={{ remember: true }}
          autoComplete="off"
          className={styles.formNewStock}
          form={form}
          onFinish={handleAddStock}
        >
          <Form.Item
            className={styles.newItem}
            label={t("Common-Type")}
            name="type"
            rules={[
              {
                required: true,
                message: t("Common-Type"),
              },
            ]}
          >
            <Select
              options={(typeOptions || []).map((d: any) => ({
                value: d.value,
                label: d.text,
              }))}
              placeholder={t("Common-Type")}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: t("Common-Place"),
              },
            ]}
            label={t("Common-Place")}
          >
            <Radio.Group
              value={placeValue}
              onChange={(e) => {
                setPlaceValue(e.target.value);
                setSkuOptions([]);
              }}
            >
              <Radio value={PlaceValue.branchoffice}>
                {t("Common-BranchOffice")}
              </Radio>
              <Radio value={PlaceValue.deposit}>{t("Common-Deposit")}</Radio>
            </Radio.Group>
          </Form.Item>
          {placeValue === PlaceValue.branchoffice && (
            <Form.Item
              className={styles.newItem}
              label={t("Common-BranchOffice")}
              name="branchoffice"
              rules={[
                {
                  required: true,
                  message: t("Common-BranchOffice"),
                },
              ]}
            >
              <Select
                showSearch
                placeholder={t("Common-SearchBranchOfficeTextPlaceholder")}
                onSelect={(_, option) => {
                  if (option) {
                    setActualBranchOfficeId(option.value);
                    setSkuOptions([]);
                  }
                }}
                notFoundContent={
                  loadingBranchOffices ? <Spin size="small" /> : null
                }
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearchBranchOffices}
                options={(branchOfficeOptions || []).map((d: any) => ({
                  value: d.value,
                  label: d.text,
                }))}
              />
            </Form.Item>
          )}
          {placeValue === PlaceValue.deposit && (
            <Form.Item
              className={styles.newItem}
              label={t("Common-Deposit")}
              name="deposit"
              rules={[
                {
                  required: true,
                  message: t("Common-Deposit"),
                },
              ]}
            >
              <Select
                showSearch
                placeholder={t("Common-SearchDepositTextPlaceholder")}
                onSelect={(_, option) => {
                  if (option) {
                    setActualDepositId(option.value);
                    setSkuOptions([]);
                  }
                }}
                notFoundContent={loadingDeposits ? <Spin size="small" /> : null}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearchDeposits}
                options={(depositOptions || []).map((d: any) => ({
                  value: d.value,
                  label: d.text,
                }))}
              />
            </Form.Item>
          )}
          <Form.Item
            className={styles.newItem}
            label={t("Common-Sku")}
            name="sku"
            rules={[
              {
                required: true,
                message: t("Common-Sku"),
              },
            ]}
          >
            <Select
              showSearch
              placeholder={t("Common-SearchBySkuName")}
              notFoundContent={
                loadingBranchOffices ? <Spin size="small" /> : null
              }
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(value) =>
                placeValue === PlaceValue.branchoffice
                  ? handleFetchSkusBranchOffice(actualBranchOfficeId, value)
                  : handleFetchSkusDeposits(actualDepositId, value)
              }
              options={(skuOptions || []).map((d: any) => ({
                value: d.value,
                label: d.text,
              }))}
            />
          </Form.Item>
          <Form.Item
            className={styles.newItem}
            label={t("Common-Stock")}
            name="stock"
            rules={[
              {
                required: true,
                message: t("Common-Stock"),
              },
            ]}
          >
            <InputNumber
              className={styles.numberInput}
              placeholder={t("Common-Stock")}
            />
          </Form.Item>
          <Form.Item
            className={styles.newItem}
            label={t("Common-Date")}
            name="date"
            rules={[
              {
                required: true,
                message: t("Common-Date"),
              },
            ]}
          >
            <DatePicker
              className={styles.dateSelector}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
          <Form.Item
            className={styles.newItem}
            label={t("Common-Motive")}
            name="reason"
          >
            <Input placeholder={t("Common-Motive")} />
          </Form.Item>
          <Form.Item>
            <Button
              className={styles.addNewButton}
              type="primary"
              htmlType="submit"
              disabled={loading}
            >
              {loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined className="ol-loading-spin-icon" />
                  }
                  delay={500}
                />
              ) : (
                t("Common-Add")
              )}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default StockMovements;
