import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import NotFound from "../../../notfound/NotFound";
import { Button, DatePicker, Form, Input, message, Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import {
  editCommercialDate,
  getCommercialDateById,
} from "../../../../api/Services";
import styles from "./ViewCommercialDate.module.scss";

const ViewCommercialDate = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [commercialDate, setCommercialDate] = useState<any>();

  const [editForm] = Form.useForm();

  const { id } = useParams();

  useEffect(() => {
    handleFetchCommercialDate();
  }, []);

  const handleFetchCommercialDate = async () => {
    let response = await getCommercialDateById(id!);
    setCommercialDate(response);
    setLoading(false);
  };

  const handleEditCommercialDate = async () => {
    if (!id) return;

    const { name, startingDate, endingDate } = editForm.getFieldsValue();
    const startingDateFormatted = moment(startingDate, "YYYY-MM-DD").format(
      "YYYY-MM-DD"
    );
    const endingDateFormatted = moment(endingDate, "YYYY-MM-DD").format(
      "YYYY-MM-DD"
    );

    let response = await editCommercialDate(
      id,
      name,
      startingDateFormatted,
      endingDateFormatted
    );
    if (response) message.success(t("Common-EditSuccessMessage"));
    else message.error(t("Common-EditErrorMessage"));
  };

  const handleCancelButton = () => {
    editForm.resetFields();
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !commercialDate ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-CommercialDate")}</h1>
        <div className={styles.actionButtonsWrapper}>
          <Button
            onClick={handleEditCommercialDate}
            className={styles.saveButton}
            type="primary"
          >
            {t("Common-Save")}
          </Button>
          <Button
            onClick={handleCancelButton}
            className={styles.cancelButton}
            type="primary"
          >
            {t("Common-Cancel")}
          </Button>
        </div>
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.infoWrapper}>
          <Form
            name="editCommercialDate"
            form={editForm}
            layout="vertical"
            autoComplete="off"
            className={styles.newForm}
            preserve={false}
          >
            <Form.Item
              initialValue={commercialDate?.name}
              label={t("Common-Name")}
              name="name"
              className={styles.editInfoItem}
              rules={[
                {
                  required: true,
                  message: t("Common-Name"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>

            <Form.Item
              className={styles.editInfoItem}
              label={t("Common-StartDate")}
              name="startingDate"
              initialValue={moment(commercialDate?.startingDate, "YYYY-MM-DD")}
              rules={[
                {
                  required: true,
                  message: t("Common-StartDate"),
                },
              ]}
            >
              <DatePicker
                className={styles.dateSelector}
                showTime={false}
                defaultValue={moment(
                  commercialDate?.startingDate,
                  "YYYY-MM-DD"
                )}
                format="YYYY-MM-DD"
              />
            </Form.Item>

            <Form.Item
              className={styles.editInfoItem}
              label={t("Common-EndDate")}
              name="endingDate"
              initialValue={moment(commercialDate?.endingDate, "YYYY-MM-DD")}
              rules={[
                {
                  required: true,
                  message: t("Common-EndDate"),
                },
              ]}
            >
              <DatePicker
                className={styles.dateSelector}
                showTime={false}
                defaultValue={moment(commercialDate?.endingDate, "YYYY-MM-DD")}
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ViewCommercialDate;
