import { useState } from "react";
import { useTranslation } from "react-i18next";
import { registerOrganization } from "../../api/Services";
import CheckEmail from "../checkemail/CheckEmail";

import { Alert, Button, Form, Input, Spin } from "antd";
import {
  UserOutlined,
  EditOutlined,
  HomeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import styles from "./SignupForm.module.css";

const SignupForm = () => {
  const { t } = useTranslation();

  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [organizationName, setOrganizationName] = useState("");

  const [form] = Form.useForm();

  const onFinish = async (data: any) => {
    const organizationName = data.organizationName.trim().toLowerCase();

    setLoading(true);

    let response = await registerOrganization(
      organizationName,
      data.email,
      data.password
    );
    if (response) {
      form.resetFields();
      setEmail(data.email);
      setOrganizationName(organizationName);
      setCompleted(true);
    } else {
      setError(true);
      setErrorMessage("Hubo un error"); //deberia ser de la api
    }

    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return !completed ? (
    <Form
      name="basic"
      form={form}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label={t("Common-OrganizationName")}
        name="organizationName"
        rules={[
          {
            required: true,
            message: t("Common-OrganizationNameNotEntered"),
          },
          {
            pattern: /^[a-z0-9]+$/i,
            message: t("Common-OrganizationNameSpecialCharacters"),
          },
        ]}
      >
        <Input
          prefix={<HomeOutlined className="site-form-item-icon" />}
          placeholder={t("Common-OrganizationName")}
        />
      </Form.Item>
      <Form.Item
        label={t("Common-Email")}
        name="email"
        rules={[
          {
            required: true,
            type: "email",
            message: t("Common-EmailNotEntered"),
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder={t("Common-Email")}
        />
      </Form.Item>

      <Form.Item
        label={t("Common-Password")}
        name="password"
        rules={[
          { required: true, min: 8, message: t("Common-PasswordLength") },
        ]}
      >
        <Input.Password
          prefix={<EditOutlined className="site-form-item-icon" />}
          placeholder={t("Common-Password")}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
        <Button
          className={styles.registerButton}
          type="primary"
          htmlType="submit"
          disabled={loading}
        >
          {loading ? (
            <Spin
              indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
              delay={500}
            />
          ) : (
            t("Common-Register")
          )}
        </Button>
      </Form.Item>
      {error && (
        <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
          <Alert
            message="Error"
            description={errorMessage}
            type="error"
            showIcon
          />
        </Form.Item>
      )}
    </Form>
  ) : (
    <CheckEmail email={email} organizationName={organizationName} />
  );
};

export default SignupForm;
