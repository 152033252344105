import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  addBranchOfficeToVehicle,
  addDepositToVehicle,
  addScheduleToVehicle,
  deleteBranchOfficeFromVehicle,
  deleteDepositFromVehicle,
  deleteScheduleFromVehicle,
  editVehicle,
  getAllRecurrencesTypes,
  getBranchOfficesFiltered,
  getDepositsFiltered,
  getVehicleById,
} from "../../../api/Services";
import {
  hasPermission,
  privilegesEnum,
} from "../../../helpers/PermissionHelper";
import {
  Form,
  Input,
  Button,
  message,
  Spin,
  Table,
  Space,
  Modal,
  Select,
  InputNumber,
  Cascader,
  Checkbox,
  DatePicker,
  TimePicker,
} from "antd";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./ViewVehicle.module.scss";

const ViewVehicle = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const [vehicle, setVehicle] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [isAddDepositModalVisible, setIsAddDepositModalVisible] =
    useState(false);
  const [isAddScheduleModalVisible, setIsAddScheduleModalVisible] =
    useState(false);
  const [isAddBranchOfficeModalVisible, setIsAddBranchOfficeModalVisible] =
    useState(false);
  const [branchOffices, setBranchOffices] = useState<any[]>([]);
  const [deposits, setDeposits] = useState<any[]>([]);
  const [recurrenceTypes, setRecurrenceTypes] = useState<any[]>([]);

  const [editForm] = Form.useForm();
  const [addDepositForm] = Form.useForm();
  const [addScheduleForm] = Form.useForm();
  const [addBranchOfficeForm] = Form.useForm();

  const canEdit = hasPermission(privilegesEnum.WRITE_VEHICLES);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_VEHICLES)) {
      handleFetchVehicle();
      handleFetchRecurrenceTypes();
    }
  }, []);

  const handleFetchRecurrenceTypes = async () => {
    const response = await getAllRecurrencesTypes();
    setRecurrenceTypes(response);
  };

  const handleFetchVehicle = async () => {
    const response = await getVehicleById(id!);

    setVehicle(response);
    setLoading(false);
  };

  const handleSearchBranchOffices = async (value: string) => {
    const response = await getBranchOfficesFiltered(0, { name: value });
    setBranchOffices(response.branchOffices);
  };

  const handleSearchDeposits = async (value: string) => {
    const response = await getDepositsFiltered(0, {
      name: value,
    });
    setDeposits(response.deposits);
  };

  const handleEdit = async () => {
    await editForm.validateFields().then(async (values) => {
      setLoading(true);
      const {
        name,
        code,
        brand,
        model,
        licensePlate,
        type,
        maxCapacity,
        fuelConsumption,
      } = values;
      let response = await editVehicle(parseInt(id!), {
        name,
        code,
        brand,
        model,
        licensePlate,
        type,
        maxCapacity,
        fuelConsumption,
      });

      if (response) {
        message.success(t("Common-EditSuccessMessage"));
      } else message.error(t("Common-EditErrorMessage"));

      await handleFetchVehicle();
      setLoading(false);
    });
  };
  const handleCancelButton = () => {
    editForm.resetFields();
  };

  const handleOpenAddDepositModal = () => {
    handleSearchDeposits("");
    setIsAddDepositModalVisible(true);
  };

  const handleCancelAddDepositModal = () => {
    setIsAddDepositModalVisible(false);
  };

  const handleOpenAddScheduleModal = () => {
    setIsAddScheduleModalVisible(true);
  };

  const handleCancelAddScheduleModal = () => {
    setIsAddScheduleModalVisible(false);
  };

  const handleOpenAddBranchOfficeModal = () => {
    handleSearchBranchOffices("");
    setIsAddBranchOfficeModalVisible(true);
  };

  const handleCancelAddBranchOfficeModal = () => {
    setIsAddBranchOfficeModalVisible(false);
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const handleDeletePlace = async (
    placeId: number,
    place: "branchOffice" | "deposit"
  ) => {
    setLoading(true);

    const deleteStatus =
      place === "branchOffice"
        ? await deleteBranchOfficeFromVehicle(placeId)
        : await deleteDepositFromVehicle(placeId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));

    await handleFetchVehicle();
    setLoading(false);
  };

  const handleDeleteSchedule = async (scheduleId: number) => {
    setLoading(true);

    const deleteStatus = await deleteScheduleFromVehicle(id!, scheduleId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));

    await handleFetchVehicle();
    setLoading(false);
  };

  const getRecurrenceOptions = () => {
    const options: any[] = [];

    recurrenceTypes.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.value} key={item.value}>
          {item.description_es}
        </Select.Option>
      );
    });

    return options;
  };

  const showDeletePlaceConfirm = (
    placeId: number,
    place: "branchOffice" | "deposit"
  ) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeletePlace(placeId, place);
      },
    });
  };

  const showDeleteScheduleConfirm = (scheduleId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteSchedule(scheduleId);
      },
    });
  };

  const handleAddDeposit = async () => {
    await addDepositForm.validateFields().then(async (deposit) => {
      setLoading(true);
      const { depositId } = deposit;

      const response = await addDepositToVehicle(parseInt(id!), depositId);

      if (response) {
        addDepositForm.resetFields();
        await handleFetchVehicle();
        handleCancelAddDepositModal();
        message.success(t("Common-AddSuccessMessage"));
      } else message.error(t("Common-ErrorMessage"));
      setLoading(false);
    });
  };

  const handleAddSchedule = async () => {
    await addScheduleForm.validateFields().then(async (data) => {
      setLoading(true);

      const {
        code,
        description,
        recurrenceType,
        origin,
        destiny,
        recurrenceDays,
        dayAnually,
        dayMonthly,
        period,
      } = data;
      const startTime = period ? period[0].format("HH:mm") : null;
      const endTime = period ? period[1].format("HH:mm") : null;

      const recurrence: {
        type: any;
        dayAnually: any;
        dayMonthly: any;
        monday?: boolean;
        tuesday?: boolean;
        wednesday?: boolean;
        thursday?: boolean;
        friday?: boolean;
        saturday?: boolean;
        sunday?: boolean;
        repeatInterval?: number;
        startTime?: any;
        endTime?: any;
      } | null = {
        type: recurrenceType,
        dayAnually: dayAnually ? dayAnually.format("YYYY-MM-DD") : null,
        dayMonthly,
        startTime,
        endTime,
      };

      recurrenceDays?.forEach(
        (
          element:
            | "monday"
            | "tuesday"
            | "wednesday"
            | "thursday"
            | "friday"
            | "saturday"
            | "sunday"
        ) => (recurrence[element] = true)
      );

      const depositIdFrom = origin[0] === "Deposit" ? origin[1] : null;
      const branchOfficeIdFrom =
        origin[0] === "BranchOffice" ? origin[1] : null;
      const depositIdTo = destiny[0] === "Deposit" ? destiny[1] : null;
      const branchOfficeIdTo =
        destiny[0] === "BranchOffice" ? destiny[1] : null;

      const response = await addScheduleToVehicle({
        vehicleId: parseInt(id!),
        code,
        description,
        depositIdFrom,
        branchOfficeIdFrom,
        depositIdTo,
        branchOfficeIdTo,
        recurrence,
      });

      if (response) {
        addDepositForm.resetFields();
        await handleFetchVehicle();
        handleCancelAddScheduleModal();
        message.success(t("Common-AddSuccessMessage"));
      } else message.error(t("Common-ErrorMessage"));
      setLoading(false);
    });
  };

  const handleAddBranchOffice = async () => {
    await addBranchOfficeForm.validateFields().then(async (branchOffice) => {
      setLoading(true);
      const { branchOfficeId } = branchOffice;

      const response = await addBranchOfficeToVehicle(
        parseInt(id!),
        branchOfficeId
      );

      if (response) {
        addBranchOfficeForm.resetFields();
        await handleFetchVehicle();
        handleCancelAddBranchOfficeModal();
        message.success(t("Common-AddSuccessMessage"));
      } else message.error(t("Common-ErrorMessage"));
      setLoading(false);
    });
  };

  const branchOfficesColumns = [
    {
      title: t("Common-Name"),
      key: "name",
      render: (record: any) => (
        <a href={`/admin/branchoffices/${record.branchOffice?.id}/view`}>
          {record.branchOffice?.name}
        </a>
      ),
    },
    {
      title: t("Common-Code"),
      key: "code",
      render: (record: any) => record.branchOffice?.id,
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (record: any) => (
        <Space size="middle">
          <DeleteOutlined
            onClick={() => {
              showDeletePlaceConfirm(record.id, "branchOffice");
            }}
          />
        </Space>
      ),
    },
  ];

  const depositsColumns = [
    {
      title: t("Common-Name"),
      key: "name",
      render: (record: any) => (
        <a href={`/admin/deposits/${record.deposit?.id}/view`}>
          {record.deposit?.name}
        </a>
      ),
    },
    {
      title: t("Common-Code"),
      render: (record: any) => record.deposit?.id,
      key: "code",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (record: any) => (
        <Space size="middle">
          <DeleteOutlined
            onClick={() => {
              showDeletePlaceConfirm(record.id, "deposit");
            }}
          />
        </Space>
      ),
    },
  ];

  const schedulesColumns = [
    {
      title: t("Common-Code"),
      dataIndex: "code",
    },
    {
      title: t("Common-Description"),
      dataIndex: "description",
    },
    {
      title: t("Common-Origin"),
      key: "origin",
      render: (record: any) =>
        record.branchOfficeFrom ? (
          <a href={`/admin/branchoffices/${record.branchOfficeFrom.id}/view`}>
            {record.branchOfficeFrom.name}
          </a>
        ) : (
          <a href={`/admin/deposits/${record.depositFrom?.id}/view`}>
            {record.depositFrom?.name}
          </a>
        ),
    },
    {
      title: t("Common-Destiny"),
      key: "destiny",
      render: (record: any) =>
        record.branchOfficeTo ? (
          <a href={`/admin/branchoffices/${record.branchOfficeTo.id}/view`}>
            {record.branchOfficeTo.name}
          </a>
        ) : (
          <a href={`/admin/deposits/${record.depositTo?.id}/view`}>
            {record.depositTo?.name}
          </a>
        ),
    },
    {
      title: t("Common-Type"),
      dataIndex: "type",
      render: (type: string) => {
        switch (type) {
          case "MONTHLY":
            return t("Common-Monthly");
          case "WEEKLY":
            return t("Common-Weekly");
          case "ANUALLY":
            return t("Common-Anually");
          default:
            return "";
        }
      },
    },
    {
      title: t("Common-StartTime"),
      dataIndex: "startTime",
    },
    {
      title: t("Common-EndTime"),
      dataIndex: "endTime",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (record: any) => (
        <Space size="middle">
          <DeleteOutlined
            onClick={() => {
              showDeleteScheduleConfirm(record.id);
            }}
          />
        </Space>
      ),
    },
  ];

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Vehicle")}</h1>
        {canEdit && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEdit}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.infoWrapper}>
          <Form
            name="vehicle"
            layout="vertical"
            initialValues={{ remember: true }}
            form={editForm}
            autoComplete="off"
            className={styles.form}
            preserve={false}
          >
            <Form.Item
              initialValue={vehicle?.name}
              label={t("Common-Name")}
              name="name"
              className={styles.itemWrapper}
              rules={[
                {
                  required: true,
                  message: t("Common-Name"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item
              initialValue={vehicle?.code}
              label={t("Common-Code")}
              name="code"
              className={styles.itemWrapper}
            >
              <Input placeholder={t("Common-Code")} />
            </Form.Item>
            <Form.Item
              initialValue={vehicle?.brand}
              label={t("Common-Brand")}
              name="brand"
            >
              <Input placeholder={t("Common-Brand")} />
            </Form.Item>
            <Form.Item
              initialValue={vehicle?.model}
              label={t("Common-Model")}
              name="model"
            >
              <Input placeholder={t("Common-Model")} />
            </Form.Item>
            <Form.Item
              initialValue={vehicle?.licensePlate}
              label={t("Common-LicensePlate")}
              name="licensePlate"
            >
              <Input placeholder={t("Common-LicensePlate")} />
            </Form.Item>
            <Form.Item
              initialValue={vehicle?.type}
              label={t("Common-Type")}
              name="type"
            >
              <Select allowClear style={{ width: "100%" }} placeholder="">
                <Select.Option value="CAR">{t("Common-Car")}</Select.Option>
                <Select.Option value="VAN">{t("Common-Van")}</Select.Option>
                <Select.Option value="MOTORCYCLE">
                  {t("Common-Motorcycle")}
                </Select.Option>
                <Select.Option value="TRUCK">{t("Common-Truck")}</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              initialValue={vehicle?.maxCapacity}
              label={t("Common-CapacityMaxInUnits")}
              name="maxCapacity"
            >
              <InputNumber
                className={styles.inputNumber}
                placeholder={t("Common-CapacityMaxInUnits")}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-FuelConsumption")}
              name="fuelConsumption"
              initialValue={vehicle?.fuelConsumption}
            >
              <InputNumber
                className={styles.inputNumber}
                placeholder={t("Common-FuelConsumption")}
              />
            </Form.Item>
          </Form>
        </div>
        <div className={styles.tableWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.tablesTitle}>{t("Common-Schedules")}</h1>
            {canEdit && (
              <Button
                type="primary"
                className={styles.actionsButton}
                onClick={() => handleOpenAddScheduleModal()}
                icon={<PlusOutlined />}
              />
            )}
          </div>
          <div>
            <Table
              size="small"
              className={styles.table}
              rowKey={(record) => record.id}
              loading={loading}
              columns={schedulesColumns}
              dataSource={vehicle?.schedules}
            />
          </div>
        </div>

        <div className={styles.tableWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.tablesTitle}>{t("Common-BranchOffices")}</h1>
            {canEdit && (
              <Button
                type="primary"
                className={styles.actionsButton}
                onClick={() => handleOpenAddBranchOfficeModal()}
                icon={<PlusOutlined />}
              />
            )}
          </div>
          <div>
            <Table
              size="small"
              className={styles.table}
              rowKey={(record) => record.id}
              loading={loading}
              columns={branchOfficesColumns}
              dataSource={vehicle?.vehicleBranchOffices?.filter(
                (vehicle: any) => vehicle.branchOffice !== null
              )}
            />
          </div>
        </div>
        <div className={styles.tableWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.tablesTitle}>{t("Common-Deposits")}</h1>
            {canEdit && (
              <Button
                type="primary"
                className={styles.actionsButton}
                onClick={() => handleOpenAddDepositModal()}
                icon={<PlusOutlined />}
              />
            )}
          </div>
          <div>
            <Table
              size="small"
              className={styles.table}
              rowKey={(record) => record.id}
              loading={loading}
              columns={depositsColumns}
              dataSource={vehicle?.vehicleDeposits?.filter(
                (vehicle: any) => vehicle.deposit !== null
              )}
            />
          </div>
        </div>
      </div>
      <Modal
        title={t("Common-Transfer")}
        visible={isAddScheduleModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelAddScheduleModal}
      >
        <Form
          name="addSchedule"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={addScheduleForm}
          layout="vertical"
        >
          <Form.Item
            label={t("Common-Code")}
            name="code"
            rules={[
              {
                required: true,
                message: t("Common-Code"),
              },
            ]}
          >
            <InputNumber
              className={styles.inputNumber}
              placeholder={t("Common-Code")}
            />
          </Form.Item>
          <Form.Item label={t("Common-Description")} name="description">
            <Input placeholder={t("Common-Description")} />
          </Form.Item>
          <Form.Item
            label={t("Common-Origin")}
            name="origin"
            rules={[
              {
                required: true,
                message: t("Common-Origin"),
              },
            ]}
          >
            <Cascader
              placeholder={t("Common-Origin")}
              className={styles.filterSelect}
              // onChange={handleChangeDestinyFilter}
              options={[
                {
                  label: t("Common-Deposit"),
                  value: "Deposit",
                  children: vehicle?.vehicleDeposits.map(
                    (depositVehicle: any) => {
                      return {
                        label: depositVehicle.deposit.name,
                        value: depositVehicle.deposit.id,
                      };
                    }
                  ),
                  disabled: vehicle?.vehicleDeposits.length === 0,
                },
                {
                  label: t("Common-BranchOffice"),
                  value: "BranchOffice",
                  children: vehicle?.vehicleBranchOffices.map(
                    (vehicleBo: any) => {
                      return {
                        label: vehicleBo.branchOffice.name,
                        value: vehicleBo.branchOffice.id,
                      };
                    }
                  ),
                  disabled: vehicle?.vehicleBranchOffices.length === 0,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={t("Common-Destiny")}
            name="destiny"
            rules={[
              {
                required: true,
                message: t("Common-Destiny"),
              },
            ]}
          >
            <Cascader
              placeholder={t("Common-Destiny")}
              className={styles.filterSelect}
              options={[
                {
                  label: t("Common-Deposit"),
                  value: "Deposit",
                  children: vehicle?.vehicleDeposits.map(
                    (depositVehicle: any) => {
                      return {
                        label: depositVehicle.deposit.name,
                        value: depositVehicle.deposit.id,
                      };
                    }
                  ),
                  disabled: vehicle?.vehicleDeposits.length === 0,
                },
                {
                  label: t("Common-BranchOffice"),
                  value: "BranchOffice",
                  children: vehicle?.vehicleBranchOffices.map(
                    (vehicleBo: any) => {
                      return {
                        label: vehicleBo.branchOffice.name,
                        value: vehicleBo.branchOffice.id,
                      };
                    }
                  ),
                  disabled: vehicle?.vehicleBranchOffices.length === 0,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={t("Common-Recurrence")}
            name="recurrenceType"
            rules={[
              {
                required: true,
                message: t("Common-Recurrence"),
              },
            ]}
          >
            <Select
              allowClear
              placeholder={t("Common-Recurrence")}
              showSearch
              optionFilterProp="children"
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getRecurrenceOptions()}
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.recurrenceType !== currentValues.recurrenceType
            }
          >
            {({ getFieldValue }) => {
              switch (getFieldValue("recurrenceType")) {
                case "WEEKLY":
                  return (
                    <Form.Item
                      name="recurrenceDays"
                      label={t("Common-On")}
                      rules={[{ required: true, message: t("Common-On") }]}
                    >
                      <Checkbox.Group
                        options={[
                          { label: t("Common-Monday"), value: "monday" },
                          {
                            label: t("Common-Tuesday"),
                            value: "tuesday",
                          },
                          {
                            label: t("Common-Wednesday"),
                            value: "wednesday",
                          },
                          {
                            label: t("Common-Thursday"),
                            value: "thursday",
                          },
                          { label: t("Common-Friday"), value: "friday" },
                          {
                            label: t("Common-Saturday"),
                            value: "saturday",
                          },
                          { label: t("Common-Sunday"), value: "sunday" },
                        ]}
                      />
                    </Form.Item>
                  );
                case "MONTHLY":
                  return (
                    <Form.Item
                      label={t("Common-Day")}
                      name="dayMonthly"
                      rules={[
                        {
                          required: true,
                          message: t("Common-Day"),
                        },
                      ]}
                    >
                      <InputNumber
                        min="1"
                        max="31"
                        className={styles.inputNumber}
                        placeholder={t("Common-Day")}
                      />
                    </Form.Item>
                  );
                case "ANUALLY":
                  return (
                    <Form.Item
                      label={t("Common-Day")}
                      name="dayAnually"
                      rules={[
                        {
                          required: true,
                          message: t("Common-Day"),
                        },
                      ]}
                    >
                      <DatePicker
                        className={styles.dateSelector}
                        placeholder={t("Common-Date")}
                        // onChange={handleChangeDate}
                      />
                    </Form.Item>
                  );
              }
            }}
          </Form.Item>
          <Form.Item
            name="period"
            label={t("Common-Hour")}
            rules={[
              {
                required: true,
                message: t("Common-Hour"),
              },
            ]}
          >
            <TimePicker.RangePicker
              format="HH:mm"
              className={styles.dateSelector}
              placeholder={[t("Common-StartTime"), t("Common-EndTime")]}
            />
          </Form.Item>
        </Form>
        <Button
          className={styles.addButton}
          type="primary"
          onClick={handleAddSchedule}
        >
          {t("Common-Add")}
        </Button>
      </Modal>
      <Modal
        title={t("Common-Deposit")}
        visible={isAddDepositModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelAddDepositModal}
      >
        <Form
          name="addDeposit"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={addDepositForm}
        >
          <Form.Item
            className={styles.modalInfoItem}
            label={t("Common-Deposit")}
            name="depositId"
            rules={[
              {
                required: true,
                message: t("Common-Deposit"),
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
              placeholder={t("Common-SearchByName")}
              onSearch={handleSearchDeposits}
            >
              {getOptions(deposits)}
            </Select>
          </Form.Item>
        </Form>
        <Button
          className={styles.addButton}
          type="primary"
          onClick={handleAddDeposit}
        >
          {t("Common-Add")}
        </Button>
      </Modal>
      <Modal
        title={t("Common-BranchOffice")}
        visible={isAddBranchOfficeModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelAddBranchOfficeModal}
      >
        <Form
          name="addBranchOffice"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={addBranchOfficeForm}
        >
          <Form.Item
            className={styles.modalInfoItem}
            label={t("Common-BranchOffice")}
            name="branchOfficeId"
            rules={[
              {
                required: true,
                message: t("Common-BranchOffice"),
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
              placeholder={t("Common-SearchByName")}
              onSearch={handleSearchBranchOffices}
            >
              {getOptions(branchOffices)}
            </Select>
          </Form.Item>
        </Form>
        <Button
          className={styles.addButton}
          type="primary"
          onClick={handleAddBranchOffice}
        >
          {t("Common-Add")}
        </Button>
      </Modal>
    </div>
  );
};
export default ViewVehicle;
