import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {deleteCurve, deleteVehicle, getCurves, getVehicles} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import { Table, Button, message, Space, Modal, Input } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./Curves.module.scss";
import { useNavigate } from "react-router-dom";

const Curves = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [nameFilterValue, setNameFilterValue] = useState("");

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CURVES)) fetchCurves();
  }, [nameFilterValue]);

  const fetchCurves = async (pagination = actualPage) => {
    const response = await getCurves(pagination, { name: nameFilterValue });

    setData(response.curves);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const showDeleteConfirm = (id: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDelete(id);
      },
    });
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    const deleteStatus = await deleteCurve(id);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await fetchCurves();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    fetchCurves(page);
    setActualPage(page);
  };

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
    },
    {
      title: t("Common-SizeStart"),
      dataIndex: "sizeStart",
    },
    {
      title: t("Common-SizeEnd"),
      dataIndex: "sizeEnd",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {hasPermission(privilegesEnum.WRITE_CURVES) ? (
            <EditOutlined
              onClick={() => {
                navigate(`/admin/curves/${record.id}/view`);
              }}
            />
          ) : (
            <EyeOutlined
              onClick={() => {
                navigate(`/admin/curves/${record.id}/view`);
              }}
            />
          )}
          {hasPermission(privilegesEnum.DELETE_CURVES) && (
            <DeleteOutlined
              onClick={() => {
                showDeleteConfirm(record.id);
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Curves")}</h1>
        {hasPermission(privilegesEnum.WRITE_CURVES) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={() => {
                navigate("/admin/curves/create");
              }}
              className={styles.newButton}
              type="primary"
              icon={<PlusOutlined />}
            />
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_VEHICLES) && (
          <div className={styles.tableWrapper}>
            <div className={styles.headerTableWrapper}>
              <div className={styles.filtersWrapper}>
                <div className={styles.filterWrapper}>
                  <Input
                    placeholder={t("Common-SearchByName")}
                    className={styles.filterInput}
                    onChange={(e) => {
                      setNameFilterValue(e.target.value);
                      setActualPage(1);
                    }}
                    value={nameFilterValue}
                  />
                </div>
              </div>
            </div>
            <Table
              size="small"
              rowKey={(record) => record.id}
              className={styles.table}
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
                current: actualPage,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Curves;
