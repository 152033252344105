import { useState } from "react";
import { useTranslation } from "react-i18next";
import { sendResetPassword } from "../../api/Services";

import { Button, Input, message } from "antd";
import { LockOutlined } from "@ant-design/icons";

import logo from "../../assets/logo.jpg";

import styles from "./ChangePassword.module.css";

const ChangePassword = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");

  const resetPassword = async () => {
    setLoading(true);

    if (password.length < 8) {
      message.error(t("Common-PasswordLength"));
      setLoading(false);
      return;
    }
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const response = await sendResetPassword(password, token ?? "");

    if (response) {
      message.success(t("ChangePassword-SendSuccesMessage"));
      window.location.href = "/";
    } else {
      message.error(t("ChangePassword-SendErrorMessage"));
    }

    setLoading(false);
  };

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <img className={styles.logo} src={logo} alt="Logo" />
      </div>
      <div className={styles.formWrapper}>
        <LockOutlined className={styles.lockIcon} />
        <h1 className={styles.title}>{t("ChangePassword-Title")}</h1>
        <p className={styles.description}>{t("ChangePassword-Description")}</p>
        <Input.Password
          className={styles.passwordInput}
          placeholder={t("Common-Password")}
          type="password"
          onChange={handlePasswordChange}
          minLength={8}
        />
        <Button
          onClick={resetPassword}
          type="primary"
          className={styles.submitButton}
          disabled={loading}
        >
          {t("ChangePassword-ResetPassword")}
        </Button>
        <a href="/">{t("Common-ReturnLogin")}</a>
      </div>
    </div>
  );
};

export default ChangePassword;
