import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { Button, Form, Input, message, Select, Spin, Switch } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import {
  createArticleGroup,
  getArticlesFiltered,
  getBranchOfficesFiltered,
  getBrandsFiltered,
  getCategoriesFiltered,
} from "../../../api/Services";

import styles from "./NewArticleGroup.module.scss";

const NewArticleGroup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [articleOptions, setArticleOptions] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [branchOffices, setBranchOffices] = useState<any[]>([]);

  const [form] = Form.useForm();

  useEffect(() => {
    handleSearchArticles("");
    handleSearchBrands("");
    handleSearchCategories("");
  }, []);

  const handleSearchCategories = async (value: string) => {
    const response = await getCategoriesFiltered(value);
    setCategories(response);
  };

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
        ? item.parents.filter(Boolean).join(" - ")
        : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
        ? `${parentsString} - ${categoryName}`
        : categoryName;

      options.push(
        <Select.Option value={item.id} key={item.id}>
          {categoryTree}
        </Select.Option>
      );
    });

    return options;
  };

  const handleSearchBrands = async (value: string) => {
    const response = await getBrandsFiltered(value);
    setBrands(response);
  };

  const handleSearchBranchOffices = async (value: string) => {
    const response = await getBranchOfficesFiltered(0, {
      name: value,
    });
    setBranchOffices(response.branchOffices);
  };
  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const handleCreate = async () => {
    setLoading(true);
    const data = form.getFieldsValue();
    const {
      name,
      description,
      state,
      categoryId,
      brandId,
      branchOfficeId,
      articleId,
    } = data;
    const response = await createArticleGroup({
      name,
      description,
      state,
      categoryId,
      brandId,
      branchOfficeId,
      articleId,
    });
    if (response) {
      message.success(t("Common-CreateSuccessMessage"));
      form.resetFields();
      navigate("/promotions/articlegroups");
    } else message.error(t("Common-ErrorMessage"));

    setLoading(false);
  };

  const handleSearchArticles = async (value: string) => {
    setLoadingArticles(true);
    const response = await getArticlesFiltered(0, { articleName: value });
    const options = response.articles.map((item: any) => ({
      value: item.id,
      text: item.name,
    }));
    setArticleOptions(options);
    setLoadingArticles(false);
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewArticleGroup")}</h1>
        <Button
          onClick={handleCreate}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.formWrapper}>
        <Form
          name="articleGroup"
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={form}
          className={styles.newForm}
        >
          <Form.Item
            label={t("Common-Name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("Common-Name"),
              },
            ]}
          >
            <Input placeholder={t("Common-Name")} />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: t("Common-Description"),
              },
            ]}
            label={t("Common-Description")}
            name="description"
          >
            <Input placeholder={t("Common-Description")} />
          </Form.Item>
          <Form.Item
            initialValue={false}
            label={t("Common-Active")}
            name="state"
            rules={[
              {
                required: true,
                message: t("Common-Active"),
              },
            ]}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={t("Common-Category")}
            name="categoryId"
            className={styles.formItem}
          >
            <Select
              showSearch
              allowClear
              style={{ width: "100%" }}
              onSearch={handleSearchCategories}
              placeholder={t("Common-SearchByName")}
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getOptionsCategories(categories)}
            </Select>
          </Form.Item>
          <Form.Item label={t("Common-Brand")} name="brandId">
            <Select
              allowClear
              showSearch
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
              placeholder={t("Common-SearchByName")}
              onSearch={handleSearchBrands}
            >
              {getOptions(brands)}
            </Select>
          </Form.Item>
          <Form.Item label={t("Common-BranchOffice")} name="branchOfficeId">
            <Select
              allowClear
              showSearch
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
              placeholder={t("Common-SearchByName")}
              onSearch={handleSearchBranchOffices}
            >
              {getOptions(branchOffices)}
            </Select>
          </Form.Item>
          <Form.Item label={t("Common-Articles")} name="articleId">
            <Select
              showSearch
              allowClear
              placeholder={t("Common-SearchArticleTextPlaceholder")}
              loading={loadingArticles}
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
              onSearch={handleSearchArticles}
              options={(articleOptions || []).map((d: any) => ({
                value: d.value,
                label: d.text,
              }))}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default NewArticleGroup;
