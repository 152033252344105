import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Table, Button, Modal, Input, Select } from "antd";
import { CalendarOutlined, PlusOutlined } from "@ant-design/icons";

import styles from "./PricingsMockup.module.scss";
import { useNavigate } from "react-router-dom";

const PricingsMockup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalSize, setTotalSize] = useState(0);

  const columns = [
    {
      title: t("Common-Id"),
    },
    {
      title: t("Common-Name"),
    },
    {
      title: t("Common-Brand"),
    },
    {
      title: "Canal de venta",
    },
    {
      title: "Sucursal",
    },
    {
      title: "Precio actual",
    },
    {
      title: "Fecha del precio",
    },
    {
      title: "Vigencia del precio",
    },
    {
      title: "Estado del precio",
    },
    {
      title: "Promoción",
    },
    {
      title: "Costo del artículo",
    },
    {
      title: "Margen",
    },
    {
      title: "Estrategia aplicada",
    },
    {
      title: "Precio óptimo",
    },
    {
      title: "Stock actual",
    },
    {
      title: "Demanda estimada",
    },
    {
      title: "Moneda",
    },
    {
      title: "Acciones",
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{"Fijacion de Precios"}</h1>
        <div className={styles.actionButtonsWrapper}>
          <Button
            onClick={() => navigate("/pricings/calendar")}
            icon={<CalendarOutlined />}
          ></Button>
          <Button
            href="/pricings/create"
            className={styles.newButton}
            type="primary"
            icon={<PlusOutlined />}
          />
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.headerTableWrapper}>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}>
                <Select
                  className={styles.filterSelect}
                  showSearch
                  placeholder={t("Common-SearchByCategory")}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  className={styles.filterSelect}
                  showSearch
                  placeholder={t("Common-SearchByBrand")}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  className={styles.filterSelect}
                  showSearch
                  placeholder={t("Common-SearchByBranchOffice")}
                />
              </div>
            </div>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}>
                <Select
                  className={styles.filterSelect}
                  showSearch
                  placeholder={"Buscar por canal"}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  className={styles.filterSelect}
                  showSearch
                  placeholder={"Buscar por artículo"}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  className={styles.filterSelect}
                  showSearch
                  placeholder={"Buscar por grupo de artículos"}
                />
              </div>
            </div>
          </div>

          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              // onChange: handlePagination,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PricingsMockup;
