import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getPromotionTypes,
  createPromotionType,
  deletePromotionType,
  editPromotionType,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import { Table, Form, Input, Button, message, Space, Modal } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import styles from "./PromotionTypes.module.scss";

const PromotionTypes = () => {
  const { t } = useTranslation();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(0);

  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_PROMOTION_TYPES))
      handleFetchPromotionTypes();
  }, []);

  const handleFetchPromotionTypes = async (pagination = actualPage) => {
    const response = await getPromotionTypes(pagination);
    const allPromotionTypes = response.promotionTypes;
    const promotionTypesWithKey: any[] = [];

    allPromotionTypes.forEach((promotionType: any) => {
      let r = {
        key: promotionType.id,
        id: promotionType.id,
        code: promotionType.code,
        name: promotionType.name,
      };
      promotionTypesWithKey.push(r);
    });
    setData(promotionTypesWithKey);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const showDeleteConfirm = (promotionTypeId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeletePromotionType(promotionTypeId);
      },
    });
  };

  const handleDeletePromotionType = async (promotionTypeId: number) => {
    setLoading(true);
    const deleteStatus = await deletePromotionType(promotionTypeId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchPromotionTypes();
    setLoading(false);
  };

  const handleCreatePromotionType = async (data: any) => {
    setLoading(true);
    let response = await createPromotionType(data.code, data.name);
    if (response) {
      message.success(t("Common-CreateSuccessMessage"));
      form.resetFields();
      await handleFetchPromotionTypes(actualPage);
    } else message.error(t("Common-ErrorMessage"));

    setLoading(false);
  };

  function showEditModal(promotionType: any) {
    Modal.info({
      title: t("Common-PromotionType"),
      closable: true,
      maskClosable: true,
      okText: t("Common-Save"),
      icon: null,
      okButtonProps: { style: { display: "none" } },
      content: (
        <>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            form={editForm}
            autoComplete="off"
            preserve={false}
          >
            <Form.Item hidden initialValue={promotionType.id} name="id" />
            <Form.Item
              initialValue={promotionType?.code}
              label={t("Common-Code")}
              name="code"
              className={styles.editInfoItem}
              rules={[
                {
                  required: true,
                  message: t("Common-Code"),
                },
              ]}
            >
              <Input placeholder={t("Common-Code")} />
            </Form.Item>
            <Form.Item
              initialValue={promotionType?.name}
              label={t("Common-Name")}
              name="name"
              className={styles.editInfoItem}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
          </Form>

          <Button
            className={styles.addButton}
            type="primary"
            onClick={handleEditPromotionType}
          >
            {t("Common-Save")}
          </Button>
        </>
      ),
    });
  }

  const handleEditPromotionType = async () => {
    setLoading(true);

    const id = editForm.getFieldValue("id");
    const code = editForm.getFieldValue("code");
    const name = editForm.getFieldValue("name");
    let response = await editPromotionType(id, code, name);

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
    } else message.error(t("Common-EditErrorMessage"));

    Modal.destroyAll();
    await handleFetchPromotionTypes();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    handleFetchPromotionTypes(page);
    setActualPage(page);
  };

  const columns = [
    {
      title: t("Common-Code"),
      dataIndex: "code",
      key: "code",
    },
    {
      title: t("Common-Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {hasPermission(privilegesEnum.WRITE_PROMOTION_TYPES) && (
            <EditOutlined
              onClick={() => {
                showEditModal(record);
              }}
            />
          )}
          {hasPermission(privilegesEnum.DELETE_PROMOTION_TYPES) && (
            <DeleteOutlined
              onClick={() => {
                showDeleteConfirm(record.key);
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-PromotionTypes")}</h1>
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_PROMOTION_TYPES) && (
          <div className={styles.tableWrapper}>
            <Table
              size="small"
              className={styles.table}
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{ total: totalSize, showSizeChanger:false, onChange: handlePagination }}
            />
          </div>
        )}
        {hasPermission(privilegesEnum.WRITE_PROMOTION_TYPES) && (
          <div className={styles.newPromotionTypeWrapper}>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={handleCreatePromotionType}
              form={form}
              autoComplete="off"
              className={styles.formNewPromotionType}
            >
              <h1 className={styles.formTitle}>
                {t("Common-NewPromotionType")}
              </h1>
              <Form.Item
                label={t("Common-Code")}
                name="code"
                rules={[
                  {
                    required: true,
                    message: t("Common-Code"),
                  },
                ]}
              >
                <Input placeholder={t("Common-Code")} />
              </Form.Item>

              <Form.Item label={t("Common-Name")} name="name">
                <Input placeholder={t("Common-Name")} />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  {t("Common-Add")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default PromotionTypes;
