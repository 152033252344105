import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getPurchaseOrderById } from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import NotFound from "../notfound/NotFound";

import { Button, Form, Input, Spin, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./ViewPurchaseOrder.module.scss";
import moment from "moment";
import { purchaseOrderStatus } from "../../helpers/Constants";
import { downloadFile } from "../../helpers/Helper";

const ViewPurchaseOrder = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [purchaseOrder, setPurchaseOrder] = useState<any>();

  const [infoPurchaseOrder] = Form.useForm();

  // const canEdit = hasPermission(privilegesEnum.WRITE_PURCHASE_ORDERS);
  const canEdit = false;

  const { id } = useParams();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_PURCHASE_ORDERS))
      handleFetchPurchaseOrder();
  }, []);

  const handleFetchPurchaseOrder = async () => {
    let response = await getPurchaseOrderById(id!);
    setPurchaseOrder(response);
    setLoading(false);
  };

  const handleDownloadData = useCallback(() => {
    const orderInfo = [
      [t("Common-Id"), purchaseOrder.id],
      [t("Common-BusinessUnit"), purchaseOrder.businessUnit?.name || ""],
      [t("Common-BranchOffice"), purchaseOrder.branchOffice?.name],
      [t("Common-Supplier"), purchaseOrder.supplier?.name],
      [t("Common-Status"), purchaseOrder.orderStatus?.description_es || ""],
      [
        t("Common-CreatedDate"),
        moment(purchaseOrder.createdDate).format("DD-MM-YYYY"),
      ],
      [
        t("Common-EstimatedDeliveryDate"),
        purchaseOrder.estimatedDeliveryDate
          ? moment(purchaseOrder.estimatedDeliveryDate).format("DD-MM-YYYY")
          : "",
      ],
    ];

    const skuHeaders = [
      [],
      ["SKUs"],
      [t("Common-Article"), t("Common-Sku"), t("Common-Quantity")],
    ];

    const skuData = purchaseOrder.skus.map((sku: any) => [
      sku.articleName,
      sku.skuName,
      sku.quantity,
    ]);

    const csvContent = [...orderInfo, ...skuHeaders, ...skuData]
      .map((row) => row.join(","))
      .join("\n");

    downloadFile(
      csvContent,
      `${t("Common-PurchaseOrder")} - ${purchaseOrder.id}.csv`,
      "application/csv"
    );
  }, [purchaseOrder]);

  const articleColumn = [
    {
      title: t("Common-Article"),
      // dataIndex: "articleName",
      // key: "articleName",
      render: (record: any) => (
        <a href={`/admin/articles/${record.articleId}/view`}>
          {record.articleName}
        </a>
      ),
    },
    {
      title: t("Common-Sku"),
      dataIndex: "skuName",
      key: "skuName",
    },
    {
      title: t("Common-Quantity"),
      dataIndex: "quantity",
      key: "quantity",
    },
  ];

  const receivedColumns = [
    {
      title: t("Common-ReceivedDate"),
      key: "receivedDate",
      dataIndex: "date",
      render: (date: any) => moment(date, "YYYY-MM-DD").format("DD-MM-YYYY"),
    },
    {
      title: t("Common-ReceivedQuantity"),
      key: "receivedQuantity",
      dataIndex: "quantity",
    },
    // commented ticket #52
    // {
    //   title: t("Common-WhoReceived"),
    //   key: "whoReceived",
    //   dataIndex: "whoReceived",
    // },
    {
      title: t("Common-Observations"),
      key: "observations",
      dataIndex: "observations",
    },
  ];

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !purchaseOrder ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          {t("Common-PurchaseOrder") + purchaseOrder.id}
        </h1>
        <Button
          onClick={() => handleDownloadData()}
          className={styles.downloadButton}
          type="primary"
        >
          {t("Common-Download")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.purchaseOrderWrapper}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={infoPurchaseOrder}
          >
            <Form.Item
              label={t("Common-Supplier")}
              name="supplier"
              initialValue={purchaseOrder.supplier?.name}
              rules={[
                {
                  required: true,
                  message: t("Common-Supplier"),
                },
              ]}
            >
              <Input placeholder={t("Common-Supplier")} disabled={!canEdit} />
            </Form.Item>
            <Form.Item
              label={t("Common-DeliverTo")}
              name="deliverTo"
              initialValue={
                purchaseOrder.deposit
                  ? purchaseOrder.deposit.name
                  : purchaseOrder.branchOffice.name
              }
              rules={[
                {
                  required: true,
                  message: t("Common-DeliverTo"),
                },
              ]}
            >
              <Input
                placeholder={t("Suppliers-DeliverTo")}
                disabled={!canEdit}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-EstimatedDeliveryDate")}
              name="estimatedDeliveryDate"
              initialValue={purchaseOrder.estimatedDeliveryDate}
              rules={[
                {
                  required: true,
                  message: t("Common-EstimatedDeliveryDate"),
                },
              ]}
            >
              <Input disabled={!canEdit} />
            </Form.Item>
            <Form.Item
              label={t("Common-Status")}
              name="status"
              initialValue={purchaseOrder.orderStatus.description_es}
              rules={[
                {
                  required: true,
                  message: t("Common-Status"),
                },
              ]}
            >
              <Input placeholder={t("Common-Status")} disabled={!canEdit} />
            </Form.Item>
            {purchaseOrder.orderStatus.status ===
              purchaseOrderStatus.NOT_COMPLETED && (
              <Form.Item
                label={t("Common-Reason")}
                name="notCompletedReason"
                initialValue={purchaseOrder.notCompletedReason}
                rules={[
                  {
                    required: true,
                    message: t("Common-Reason"),
                  },
                ]}
              >
                <Input disabled={!canEdit} />
              </Form.Item>
            )}
            <Form.Item
              label={t("Common-Origin")}
              name="origin"
              initialValue={purchaseOrder.orderOrigin.description_es}
              rules={[
                {
                  required: true,
                  message: t("Common-Origin"),
                },
              ]}
            >
              <Input placeholder={t("Common-Status")} disabled={!canEdit} />
            </Form.Item>
          </Form>
        </div>
        <div className={styles.articlesWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.articlesTitle}>{t("Common-Articles")}</h1>
            {/*{canEdit && (*/}
            {/*  <Button*/}
            {/*    type="primary"*/}
            {/*    className={styles.actionsButton}*/}
            {/*    onClick={() => handleOpenAddArticleModal()}*/}
            {/*    icon={<PlusOutlined />}*/}
            {/*  />*/}
            {/*)}*/}
          </div>
          <div className={styles.articlesTableWrapper}>
            <Table
              size="small"
              className={styles.articleTable}
              loading={loading}
              columns={articleColumn}
              rowKey={(record) => record.id}
              dataSource={purchaseOrder?.skus}
              expandable={{
                expandedRowRender: (record) => (
                  <Table
                    size="small"
                    loading={loading}
                    columns={receivedColumns}
                    dataSource={record.received}
                    pagination={false}
                  />
                ),
                rowExpandable: (record) => record.totalStock !== 0,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPurchaseOrder;
