import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { createClientLevel } from "../../../api/Services";

import {
  Form,
  Input,
  Button,
  message,
  Select,
  Spin,
  InputNumber,
  Switch,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./NewClientLevel.module.scss";
const NewClientLevel = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleCreate = async () => {
    setLoading(true);
    const data = form.getFieldsValue();
    const {
      name,
      level,
      description,
      ruleType,
      ruleQuantity,
      accumulationFactor,
      active,
    } = data;
    const response = await createClientLevel({
      name,
      level,
      description,
      ruleType,
      ruleQuantity,
      accumulationFactor,
      active,
    });
    if (response) {
      message.success(t("Common-CreateSuccessMessage"));
      form.resetFields();
      navigate("/promotions/clientlevels");
    } else message.error(t("Common-ErrorMessage"));

    setLoading(false);
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewClientLevel")}</h1>
        <Button
          onClick={handleCreate}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.newWrapper}>
          <Form
            name="newClientLevel"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.formNew}
            form={form}
            onFinish={handleCreate}
          >
            <Form.Item
              label={t("Common-Level")}
              name="level"
              rules={[
                {
                  required: true,
                  message: t("Common-Level"),
                },
              ]}
            >
              <InputNumber
                className={styles.inputNumber}
                placeholder={t("Common-Level")}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item label={t("Common-Description")} name="description">
              <Input placeholder={t("Common-Description")} />
            </Form.Item>
            <Form.Item
              label={t("Common-AccumulationFactor")}
              name="accumulationFactor"
              rules={[
                {
                  required: true,
                  message: t("Common-AccumulationFactor"),
                },
              ]}
            >
              <InputNumber
                className={styles.inputNumber}
                placeholder={t("Common-AccumulationFactor")}
              />
            </Form.Item>
            <Form.Item label={t("Common-RuleType")} name="ruleType">
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder={t("Common-RuleType")}
              >
                <Select.Option value="ACCOUNT_POINTS">
                  {t("Common-AccountPoints")}
                </Select.Option>
                <Select.Option value="MONTHS_IN_PROGRAM">
                  {t("Common-MonthsInProgram")}
                </Select.Option>
                <Select.Option value="TOTAL_SPENT">
                  {t("Common-TotalSpent")}
                </Select.Option>
                <Select.Option value="AMMOUNT_SPENT">
                  {t("Common-AmmountSpent")}
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label={t("Common-RuleQuantity")} name="ruleQuantity">
              <InputNumber
                className={styles.inputNumber}
                placeholder={t("Common-RuleQuantity")}
              />
            </Form.Item>
            <Form.Item
              initialValue={false}
              label={t("Common-Active")}
              name="active"
              rules={[
                {
                  required: true,
                  message: t("Common-Active"),
                },
              ]}
            >
              <Switch />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default NewClientLevel;
